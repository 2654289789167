import { generatePath, useNavigate, useParams } from 'react-router-dom'

import CodeMirror from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'
import { lintGutter } from '@codemirror/lint'
import { EditorView } from '@codemirror/view'

import { Box, Button, FormHelperText, Grid, IconButton, Typography } from '@mui/material'
import SyncIcon from '@mui/icons-material/Sync'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'

import { ROUTES } from 'Routes/constants'
import { cardContainer } from 'styles/globalStyles'
import { LoadingButton } from '@mui/lab'
import { useEffect, useState } from 'react'
import { useGetGenAiDataFlow } from 'hooks/genAIApiHooks'
import { useAddDataEntityFlow, useGetDataEntityFlow } from 'hooks/entityHooks'
import { DataEntityFlow } from 'businessObjects'
import ResultStatus from 'ui-components/ResultStatus'
import { truncate } from 'fs'

const options = {
  autoFocus: true,
  lineNumbers: true,
  lineWrapping: true,
  autocompletion: true,
  highlightActiveLine: true,
  highlightSelectionMatches: true,
  mode: {
    name: 'javascript',
    json: true,
    statementIndent: 2,
  },
}

const theme = EditorView.theme({
  '.cm-activeLine': {
    backgroundColor: 'var(--codemirror-active-line)',
  },
  '.cm-content': {
    height: 'var(--codemirror-height)',
  },
  '.cm-scroller': {
    overflow: 'hidden',
  },
  '.cm-wrap': {
    height: 'var(--codemirror-height)',
    border: '1px solid silver',
  },
})

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}

export const EntityDataFlow = (props: Props) => {
  const { dataPodId } = useParams<ViewResultsParams>()

  const [dataEntityFlow, setDataEntityFlow] = useState<DataEntityFlow>()
  const [dataFlowData, setDataFlowData] = useState<string>('')
  const [serviceConfigObject, setServiceConfigObject] = useState<{ [key: string]: string }>({})
  const [invalidJson, setInvalidJson] = useState<boolean>(false)

  const { fetchEntityDataFlow, isDataFlowGenerating } = useGetGenAiDataFlow()
  const { addedDataEntityFlow, isDataEntityFlowAdding, errorAddingDataFlow, addDataEntityFlow } =
    useAddDataEntityFlow(dataPodId)
  const { dbDataEntityFlow, isDataEntityFlowFetching, errorFetchingDataFlow, getDataEntityFlow } =
    useGetDataEntityFlow(dataPodId)

  const [successMessage, setSuccessMessage] = useState<string>()
  const [successState, setSuccessState] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const isEdit = true
  const isView = true

  const navigate = useNavigate()

  useEffect(() => {
    if (dataPodId && dataPodId.length > 0) {
      getDataEntityFlow()
    }
  }, [dataPodId])

  useEffect(() => {
    if (errorAddingDataFlow || errorFetchingDataFlow) {
      setErrorState(true)
    }
  }, [errorAddingDataFlow, errorFetchingDataFlow])

  useEffect(() => {
    if (dbDataEntityFlow) {
      setDataEntityFlow(dbDataEntityFlow)
      setDataFlowData(dbDataEntityFlow.dataFlow)
    }
    if (addedDataEntityFlow) {
      setSuccessState(true)
      setSuccessMessage('Data Flow added successfully.')
    }
  }, [dbDataEntityFlow, addedDataEntityFlow])

  const fetchDataFlow = async () => {
    if (dataPodId && dataPodId.length > 0) {
      const response = await fetchEntityDataFlow(dataPodId)

      if (response) {
        handleEditor(JSON.stringify(response.data, null, 2))
        // setDataFlowData(JSON.stringify(response.data))
      }
    }
  }

  const saveDataEntityFlow = () => {
    const payload: DataEntityFlow = {
      id: 0,
      coreEntityId: -1,
      coreEntityName: '',
      dataPodId: dataPodId ? dataPodId : '',
      dataFlow: dataFlowData,
    }

    addDataEntityFlow(payload)
  }

  const onClickBack = () => {
    const entityReconciliationRoute = generatePath(ROUTES.DataModelingEntityReconciliation, { dataPodId: dataPodId })
    navigate(entityReconciliationRoute)
  }

  const onClickHome = () => {
    const homeRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(homeRoute)
  }

  const handleEditor = (dataFlowDataChanges: string) => {
    // setDataFlowData(dataFlowDataChanges)
    setInvalidJson(false)
    try {
      const jsonObj = JSON.parse(dataFlowDataChanges)

      setDataFlowData(JSON.stringify(jsonObj))
    } catch (error) {
      setInvalidJson(true)
    }
  }

  return (
    <>
      {successMessage && (
        <ResultStatus
          severtiy="success"
          showStatus={successState}
          closeStatus={() => setSuccessState(false)}
          alertMessage={successMessage}
        />
      )}
      {errorState && errorAddingDataFlow && (
        <ResultStatus
          severtiy="error"
          showStatus={errorState}
          closeStatus={() => setErrorState(false)}
          alertMessage={errorAddingDataFlow}
        />
      )}
      {errorState && errorFetchingDataFlow && (
        <ResultStatus
          severtiy="error"
          showStatus={errorState}
          closeStatus={() => setErrorState(false)}
          alertMessage={errorFetchingDataFlow}
        />
      )}

      <Grid item container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={onClickBack}>
            <ArrowBackIcon />
            <Typography>Back</Typography>
          </IconButton>
        </Grid>
      </Grid>
      <Box style={cardContainer}>
        <Grid item container spacing={2}>
          <Grid item container>
            <Grid item xs={1}>
              <IconButton onClick={onClickHome}>
                <HomeOutlinedIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item sx={{ mb: 2 }} xs={3}>
          <LoadingButton
            color="primary"
            variant="contained"
            onClick={() => fetchDataFlow()}
            startIcon={<SyncIcon fontSize="small" />}
            disabled={isDataFlowGenerating && !dataPodId}
            loading={isDataFlowGenerating}
          >
            Generate Data Flow
          </LoadingButton>
        </Grid>
        <Grid item xs={12} container sx={{ display: 'flex', flexDirection: 'column' }}>
          <Grid item sx={{ height: '45vh', overflowY: 'scroll' }}>
            <CodeMirror
              {...options}
              editable={!isView || isEdit}
              value={dataFlowData == '' ? '\n'.repeat(25) : dataFlowData}
              basicSetup={options}
              onChange={(value) => handleEditor(value)}
              extensions={[EditorView.lineWrapping, json(), lintGutter(), theme]}
            />
          </Grid>
          <Grid item>
            {invalidJson && (
              <FormHelperText error>
                <Typography variant="h4">Invalid JSON Format</Typography>{' '}
              </FormHelperText>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} container sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            onClick={saveDataEntityFlow}
            disabled={invalidJson || isDataFlowGenerating || dataFlowData.length == 0 || isDataEntityFlowAdding}
            // startIcon={<TaskAltIcon />}
            title="View Details"
          >
            Save to database
          </Button>
        </Grid>
      </Box>
    </>
  )
}
