import React from 'react'
import { Stepper, Step, StepLabel, Box, Typography, Grid, Container } from '@mui/material'
import { useIsAuthenticated } from '@azure/msal-react'

type Props = {
  steps: any[]
  activeStep: number
}

const StepperComponent = ({ steps, activeStep }: Props) => {
  const isAuthenticated = useIsAuthenticated()

  return (
    <>
      <Grid item sx={{ xs: 12 }}>
      {isAuthenticated ? 
      <>
        <Grid item sx={{ xs: 12, mb: 6, paddingLeft: '20%' }}>
            <Typography variant="h2" gutterBottom>
              Step {activeStep + 1}
            </Typography>
            <Typography variant="body1" gutterBottom sx={{ wordWrap: 'break-word', width: '90%' }}>
              {steps[activeStep]?.description}
            </Typography>
        </Grid>
        <Grid item sx={{ xs: 12, mb: 6, paddingLeft: '20%' }}>
          <Stepper activeStep={activeStep} orientation="vertical">
            {steps?.map((step, index) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Grid>
      </>
      :
      <Container maxWidth="md">
        <Box>
          <Typography variant="body2" align="justify" gutterBottom>
            <small>
              Your Modern Gen AI Era Data & Analytics Management Platform powered by Gen AI and LLM models. Empower 100%
              Data exploration and curation, accelerating development 20x. Achieve 10x Cost Savings with Rapid Data Prep
              and BI readiness. Ensure Enterprise-Grade Security and Data Privacy.{' '}
            </small>
          </Typography>
        </Box>
      </Container>
    }
    </Grid>
    </>
  )
}

export default StepperComponent
