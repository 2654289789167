import { useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  IconButton,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  TextField,
  TextareaAutosize,
  Typography,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  useAddMeasureNoteBook,
  useGetDataScienceModels,
  useGetMeasureNotebook,
  useMeasureNotebook,
  useUpdateMeasureNB,
} from '../../hooks/analyticsMeasureHooks'
import { ProgressBar } from '../../ui-components/ProgressBar'
import ResultStatus from '../../ui-components/ResultStatus'
import { LucidJupyter } from '../../ui-components/JupyterComponents/LucidJupyter'
import { Insights, AddOutlined, Preview } from '@mui/icons-material'
import AnimatedLoader from '../../ui-components/AnimatedLoader'
import { LucidPopUp } from '../../ui-components/LucidPopUp/LucidPopUp'
import { usePostCreateDatabricksJob, usePostDatabricksJobRunNew } from '../../hooks/databricksHooks'
import {
  CreateJobParams,
  CreateJobResponse,
  JobClusterConfig,
  GenerateKPI,
  CustomDictionary,
  RunStatusResponse,
  TechniqueDetails,
  OperationState,
  RunNewJobParams,
  FabricNotebookCreateRequest,
  JobDetails,
} from '../../businessObjects'
import { useAppDispatch, useAppSelector } from '../../app/hooks'
import { RootState } from '../../app/store'
import { filterActiveRunIds, initiateActiveRunId } from '../../features/notification/notificationSlice'
import { useGetServiceConnection } from '../../hooks/dataEngineeringHooks'
import constants from '../constants'
import { ROUTES } from 'Routes/constants'
import SettingsIcon from '@mui/icons-material/Settings'
import { useCreateAndRunNewJob, useGetCreateJobPayload, useGetRunNewJobPayload, useRunNewJob } from 'hooks/executionHooks'

type SingleObject = {
  key: string
  value: string
}

type ViewResultsParams = {
  dataPodId: string
}

type Props = {
  measureId: number | undefined
  measureDescription?: string
  measureName?: string
  editMeasure?: () => void
  callSyncDimensions?: () => void
  generateKPI?: () => void
  generateKPILoading?: boolean
  generateKPIResponse?: GenerateKPI
  isEditOrAddMode?: boolean
  groupId?: string
}

const GenerateMeasureNotebook = ({
  measureId,
  measureDescription,
  measureName,
  editMeasure,
  callSyncDimensions,
  generateKPI,
  generateKPILoading,
  generateKPIResponse,
  isEditOrAddMode,
  groupId,
}: Props) => {
  const { dataPodId } = useParams<ViewResultsParams>()
  // const {
  //   getMeasureNotebook,
  //   // generateKPI,
  //   addMeasureNotebookLoading,
  //   measureNotebookResponse,
  //   addMeasureNotebook,
  //   noteBookError,
  // } = useMeasureNotebook(dataPodId)

  const { measureNotebook, loadingNoteBook, noNB } = useGetMeasureNotebook(dataPodId, measureId)
  const { addMeasureNotebook } = useAddMeasureNoteBook()
  const { updateMeasureNotebook } = useUpdateMeasureNB()
  const navigate = useNavigate()

  const [isNotebookExists, setIsNotebookExists] = useState<boolean>(false)

  const { getConnections } = useGetServiceConnection()

  const {getCreateJobPayload} = useGetCreateJobPayload()
  const {createAndRunNewJob} = useCreateAndRunNewJob()

  const {runNewJob} = useRunNewJob()
  const { getRunNewJobPayload } = useGetRunNewJobPayload();

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const activeFabricRunStatus = useAppSelector((state: RootState) => state.notification.fabricRunStatus)
  const dispatch = useAppDispatch()

  const [processingReq, setProcessingReq] = useState<boolean>(false)
  const [notebookString, setNotebookString] = useState<string>(constants.defaultNotebookString)
  const [commitMessage, setCommitMessage] = useState<string>('initial commit')

  const [dataScienceModels, setDataScienceModels] = useState<TechniqueDetails[]>()
  const [selectedDataScModel, setSelectedDataScModel] = useState<TechniqueDetails>()
  const [resultState, setResultState] = useState<OperationState>()
  const [anchorEl, setAnchorEl] = useState(null)
  const [selectedValue, setSelectedValue] = useState<string>('')
  const [loadToWarehouse, setLoadToWarehouse] = useState<boolean>(false)
  const [runId, setRunId] = useState<number | string>()

  // const [addedSuccess, setAddedSuccess] = useState<boolean>(false)
  // const [errorMessage, setErrorMessage] = useState<string>()
  // const [errorState, setErrorState] = useState<boolean>(false)
  // const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [generateKpiClcicked, setGenerateKpiClicked] = useState<boolean>(false)
  const [jobClusterParams, setJobClusterParams] = useState<JobClusterConfig | null>(null)
  // const [measure_Name, setmeasure_Name] = useState<string>()
  const [measure_Id, setmeasure_Id] = useState<number>()

  useEffect(() => {
    const fetchJobClusterParams = async () => {
      if (dataPodId) {
        const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')

        if (serviceConnection && serviceConnection.length > 0) {
          const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate
          const parsedJson = JSON.parse(jsonTemplate)
          const params: JobClusterConfig = {
            url: parsedJson.workspace_Url,
            token: parsedJson.PAT_Token,
            clusterKey: parsedJson.cluster_Id,
            sparkVersion: parsedJson.sparkVersion,
            nodeTypeId: parsedJson.nodeTypeId,
          }
          setJobClusterParams(params)
        }
      }
    }

    fetchJobClusterParams()
  }, [])

  useEffect(() => {
    if (measureNotebook && measureId && !noNB) {
      setmeasure_Id(measureId)

      setIsNotebookExists(true)
      setNotebookString(measureNotebook)
      setDataScienceModels(undefined)
      setSelectedDataScModel(undefined)
    }else if(noNB){
      setIsNotebookExists(false)
    }
  }, [measureId, measureNotebook, noNB])

  const measure_Name = useMemo(() => {
    if (!measureName?.length) return
    return measureName
  }, [measureName])

  useEffect(() => {
    if (generateKPIResponse?.notebook) {
      setNotebookString(generateKPIResponse.notebook)
    }
  }, [generateKPIResponse?.notebook])

  const addMeasureNotebookString = async () => {
    if (measureId && dataPodId && notebookString && commitMessage) {
      setProcessingReq(true)
      const success = await addMeasureNotebook({
        datapodID: dataPodId,
        measureId,
        notebookToRun: notebookString,
        commitMessage,
      })
      setProcessingReq(false)
      if (success && success) {
        setIsNotebookExists(true)
        setResultState({ display: true, message: 'Saved NoteBook', result: 'success' })
      } else {
        setResultState({ display: true, message: 'Error Saving NoteBook', result: 'error' })
      }
      setGenerateKpiClicked(false)
    }
  }

  const handleNotebookString = () => {
    if (!generateKpiClcicked && isNotebookExists) {
      // console.log("there already exists a notebook")
      setShowPopUp(true)
    } else if (!isNotebookExists) {
      // } else if (!measureNotebook && !notebookString?.includes(constants.defaultNotebookString)) {
      addMeasureNotebookString()
    }

    callSyncDimensions && callSyncDimensions()
  }

  const handleNext = async () => {
    if (measureId && notebookString && commitMessage && dataPodId) {
      setProcessingReq(true)
      const updateSuccess = await updateMeasureNotebook({
        datapodID: dataPodId,
        measureId,
        notebookToRun: notebookString,
        commitMessage,
      })
      if (updateSuccess && updateSuccess) {
        setResultState({ display: true, message: 'Saved NoteBook', result: 'success' })
      } else {
        setResultState({ display: true, message: 'Error Updating NoteBook', result: 'error' })
      }
      setShowPopUp(false)
      setProcessingReq(false)
    }
  }

  const generateGroupMeasure = () => {
    if (isEditOrAddMode && generateKPI) {
      generateKPI()
    }
  }

  const executeNotebook = async () => {
    if (dataPodId && measureId && jobClusterParams) {
      const databricksParams: CreateJobParams = {
        dataPodId,
        measureID: measureId
      }

      const fabricParams: FabricNotebookCreateRequest = {
        workspaceId: '',
        displayName: dataPodId + "_" + measureName || '',
        description: `Executing measure ${measureName} for datapodId ${dataPodId}`,
        accessToken: '',
        code: notebookString,
        executionData: {},
        environmentId: '',
      }

      const routePage = generatePath(ROUTES.DataAnalyticsMeasuresResults, {
        dataPodId: dataPodId,
        groupId: groupId,
        measureId: measureId,
        action: 'edit',
      })

      const jobDetails: JobDetails = {
        triggerType: 'KPI Code Execution',
        parameters: {
          id: measure_Id,
          name: measure_Name,
          route: routePage,
        },
      }

      try{
        const payload = await getCreateJobPayload(dataPodId, databricksParams, fabricParams)
  
        if(payload) {
          await createAndRunNewJob(jobDetails, payload)
        } else {
          setResultState({ display: true, message: 'Failed to create Databricks Job', result: 'error' })
        }
      } catch(error: any) {
        setResultState({ display: true, message: error.message, result: 'error' })
      }
    }
  }

  const onTextChange = (name: string, value: string) => {
    setCommitMessage(value)
  }

  // const closeErrorMessage = () => {
  //   setErrorState(false)
  //   setErrorMessage(undefined)
  // }

  const clickDataMode = (model: TechniqueDetails) => {
    if (!dataScienceModels) return

    setSelectedDataScModel(model)
  }

  const disableButton = useMemo(() => {
    return !measureId || loadingNoteBook || generateKPILoading
  }, [notebookString, measureId, loadingNoteBook, generateKPILoading])

  const onPreviewButtonClick = async () => {
    const columProfileRoute = generatePath(ROUTES.DataAnalyticsMeasuresDataPreview, {
      dataPodId: dataPodId,
      dataMeasureID: Number(measureId),
    })
    navigate(columProfileRoute)
  }

  const loadOptions = [{ value: 'Data Warehouse Load', label: 'Data Warehouse Load' }]

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    if (activeRunIds && runId) {
      const temp = activeRunIds.find((element) => element.runId === runId)
      if (temp && temp.state.resultState === 'SUCCESS') {
        // console.log({ temp })
        if (temp.runName?.includes('LDH_LoadMeasuresToDataWarehouse') && measureId && dataPodId) {
          setLoadToWarehouse(false)
        }
      }
    }
  }, [activeRunIds])

  useEffect(() => {
    if (activeFabricRunStatus && runId) {
      const temp = activeFabricRunStatus.find((element) => element.id === runId)
      if (temp && temp.status === 'Completed') {
        // console.log({ temp })
        if (temp.jobDetails?.triggerType === 'Data Warehouse Load' && measureId && dataPodId) {
          setLoadToWarehouse(false)
        }
      }
    }
  }, [activeFabricRunStatus])

  const runDatawarehouseLoadJob = async () => {
    if(dataPodId && measureId) {
      const requestBody = {
        i_data_pod_id: dataPodId || '',
        i_data_entity_id: JSON.stringify([measureId]),
      };
      
      const jobParams = {
        name: measureName || '',
        route: generatePath(ROUTES.DataAnalyticsGroupResults, {
          dataPodId: dataPodId,
        }),
      };

      try {

        const payload = await getRunNewJobPayload(dataPodId, requestBody, 'dataWarehouse_Job_Id');
        
        if(payload) {
          const runId = await runNewJob(activeRunIds, jobParams, 'Data Warehouse Load', payload);
          
          if(runId){
            setRunId(runId)
          }
        }
      } catch(error: any) {
        setResultState({ display: true, message: error.message, result: 'error' })
      }
    }
  };

  const handleSubmit = (value: string) => {
    if (measureId) {
      // console.log(value)
      handleClose()
      switch (value) {
        case 'Data Warehouse Load':
          runDatawarehouseLoadJob()
          break
      }
    }
  }

  return (
    <>
      {/* <ProgressBar loading={addMeasureNotebookLoading || processingReq} /> */}
      <AnimatedLoader height="50%" width="40%" loading={loadingNoteBook || processingReq} />
      <ResultStatus
        showStatus={!!resultState?.display}
        alertMessage={resultState?.message}
        severtiy={resultState?.result}
        closeStatus={() => setResultState(undefined)}
      />
      {/* <ResultStatus
        severtiy="success"
        showStatus={addedSuccess}
        closeStatus={() => setAddedSuccess(false)}
        alertMessage={`SUCCESS: ${measureNotebookResponse?.notebook ? 'Notebook generated !' : 'Notebook saved !'}`}
      /> */}
      <Card sx={{ p: 2, mb: 2 }}>
        <Grid item xs={12} justifyContent={'space-between'} display={'flex'} flexDirection={'row'} sx={{ mt: 2 }}>
          {isEditOrAddMode && (
            <>
              <Grid item xs={3}>
                <LoadingButton
                  loading={loadingNoteBook || generateKPILoading}
                  variant="outlined"
                  color="primary"
                  startIcon={<Insights />}
                  onClick={generateGroupMeasure}
                  disabled={!measureId}
                  sx={{ mr: 2 }}
                >
                  Generate code
                </LoadingButton>
              </Grid>
              <Grid item xs={3}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleNotebookString}
                  startIcon={<AddOutlined />}
                  disabled={disableButton}
                >
                  Save Notebook
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button variant="outlined" onClick={onPreviewButtonClick}>
                  Data Preview
                </Button>
              </Grid>
              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={anchorEl ? 'long-menu' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <SettingsIcon />
                </IconButton>
                <Menu id="long-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  {loadOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => handleSubmit(option.value)}
                      selected={option.value === selectedValue}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </>
          )}
        </Grid>
        <Grid item xs={12} justifyContent={'flex-end'} display={'flex'} flexDirection={'row'} sx={{ mt: 2 }}>
          {!isEditOrAddMode && (
            <>
              <Grid item xs={3}>
                <Button variant="outlined" color="primary" disabled={disableButton} onClick={editMeasure}>
                  {notebookString?.includes(constants.defaultNotebookString)
                    ? 'Edit Measure'
                    : 'Review and Generate code'}
                </Button>
              </Grid>
              <Grid item xs={3}>
                <Button variant="outlined" onClick={onPreviewButtonClick} disabled={!measureId}>
                  Data Preview
                </Button>
              </Grid>

              <div>
                <IconButton
                  aria-label="more"
                  id="long-button"
                  aria-controls={anchorEl ? 'long-menu' : undefined}
                  aria-haspopup="true"
                  onClick={handleClick}
                >
                  <SettingsIcon />
                </IconButton>
                <Menu id="long-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
                  {loadOptions.map((option) => (
                    <MenuItem
                      key={option.value}
                      onClick={() => handleSubmit(option.value)}
                      selected={option.value === selectedValue}
                    >
                      {option.label}
                    </MenuItem>
                  ))}
                </Menu>
              </div>
            </>
          )}
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            <LucidJupyter
              executeNotebook={executeNotebook}
              noteBookString={notebookString}
              headingText={constants.codeHeaderText}
              updateNoteBookString={setNotebookString}
              commitMessage={commitMessage}
              updateCommitMessage={setCommitMessage}
            />
          </Grid>
        </Grid>
      </Card>
      <LucidPopUp
        showCloseIcon
        closePopup={setShowPopUp}
        openPopUp={showPopUp}
        onConfirm={handleNext}
        headingText=""
        confirmText="Commit"
      >
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12}>
            <Link href={'https://github.com/Lucid-Data-Hub/gen-datalake-dev'} target="blank">
              Git Hub Repository
            </Link>
          </Grid>
          <Grid item xs={12}>
            <TextField
              sx={{ fontSize: 12 }}
              fullWidth
              id="outlined-basic"
              label="Enter Commit Message"
              variant="outlined"
              color="secondary"
              required
              rows={2}
              multiline
              value={commitMessage}
              onChange={({ target }) => onTextChange('commitMessage', target.value)}
            />
          </Grid>
        </Grid>
      </LucidPopUp>
    </>
  )
}

export default GenerateMeasureNotebook
