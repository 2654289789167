import { CardContent, Grid, IconButton, Typography } from '@mui/material'
import DataPodDetailRibbon from './DataPodDetailRibbon/DataPodDetailRibbon'
import { useGetSessionPodInfo } from '../hooks/getSessionPodInfo'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'

type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}

export const DatapodDetails = () => {
  const dataPod = useGetSessionPodInfo()
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPod })
    navigate(dataProfilingRoute)
  }
  return (
    <>
      <Grid container direction="row" justifyContent="flex-start" alignItems="baseline">
        <Grid item xs={0}>
          <IconButton>
            <HomeOutlinedIcon onClick={onClickHome} />
          </IconButton>
        </Grid>
        <Grid item xs={11.5}>
          <DataPodDetailRibbon
            dataPodId={dataPod?.id}
            dataPodName={dataPod?.name}
            dataPodIndustryName={dataPod?.industryName}
          />
        </Grid>
      </Grid>
    </>
  )
}
