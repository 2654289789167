import { Grid, Typography, Card, IconButton } from '@mui/material'
import React, { useEffect, useMemo, useState, useCallback } from 'react'
import { HeadingLabel, LucidTextLabel } from 'ui-components/LucidTextLabel'
import { ColumnProfilingCanType, DistData } from '../profilingDataTypes'
import { useGetDataColumnProfilingByAttributeId } from 'hooks/generativeIntegartionHooks'
import { generatePath, useLocation, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import { ArrowBack, Key } from '@mui/icons-material'
import ActionPage from 'ui-components/ActionPage'
import { BarChart } from '@mui/x-charts/BarChart'
import ToggleView, { ViewType } from 'ui-components/ToggleView/ToggleView'
import Divider from '@mui/material/Divider'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataAttributeID: string
}

export const CanonicalColumnProfileResults = (props: Props) => {
  const { dataPodId, dataAttributeID } = useParams<ViewResultsParams>()
  const [cardView, setCardView] = useState<ViewType>('Grid')
  const location = useLocation();
  const attrId = Number(location.state.attrId)
  const dp = location.state.dp
  const { getDataColumnProfilingByAttributeId, tableColumnProfiling } = useGetDataColumnProfilingByAttributeId()
  const scrollableContainer = {
    maxHeight: '61vh',
    overflowY: 'auto',
  }

  useEffect(() => {
    if (dataAttributeID) {
      getDataColumnProfilingByAttributeId(Number(dataAttributeID))
    }
  }, [dataAttributeID, getDataColumnProfilingByAttributeId])

  const columnProfilingData = useMemo(() => {
    if (!tableColumnProfiling.length) return []
    return tableColumnProfiling
  }, [tableColumnProfiling])

  const colProfile: ColumnProfilingCanType[] = useMemo(() => {
    if (!columnProfilingData) return []

    try {
      const parsedProfiles = columnProfilingData.map((item) => {
        const parsedData = JSON.parse(item.profilingData) as ColumnProfilingCanType
        const tableData = JSON.parse(item.dataTableProfilingData)
        parsedData['Table Name'] = tableData['Table Name']
        parsedData.overlapPercentage = item.overlapPercentage
        parsedData.relationshipType = item.relationshipType
        parsedData.schemaCompatibility = item.schemaCompatibility
        return parsedData
      })
      // console.log(parsedProfiles)
      return parsedProfiles
    } catch (error) {
      console.error('Error parsing column profiling data:', error)
      return []
    }
  }, [columnProfilingData])

  const navigate = useNavigate()
  const handleView = (vt: ViewType) => {
    if (vt !== cardView) {
      setCardView(vt)
    }
  }
  const onClickBack = () => {
    const dataModellingCanonicalPage = generatePath(ROUTES.DataModelingCanonicalModel, {
      dataPodId: dp,
    })
    navigate(dataModellingCanonicalPage)
  }

  return (
    <ActionPage>
      <Grid item container direction={'row'} justifyContent={'center'} alignItems={'inLine'} xs={14} spacing={1}>
        <Grid item xs={4}>
          <IconButton onClick={onClickBack} size="small" title="Back to Data Mapping">
            <ArrowBack />
            Back
          </IconButton>
        </Grid>
        <Grid xs={4} sx={{ marginBottom: 2 }}>
          <Typography sx={{ fontSize: 18 }} color="primary">
            Column Profile Information
          </Typography>
        </Grid>
        <Grid xs={4} />
        <Card sx={{ backgroundColor: 'white', width: '100%', height: '100%' }}>
          <Grid direction="column" spacing={2} padding={2} sx={{ ...scrollableContainer }}>
            {colProfile.map((profile, profileIndex) => (
              <Grid key={profileIndex} container direction="column" spacing={2}>
                {/* Dynamic header labels for each column profile */}
                <Grid item container padding={3} spacing={2}>
                  <Grid xs={12} />
                  <Grid item xs={3}>
                    <LucidTextLabel labelText={'Table Name'} />
                    <Typography variant="body2">{profile['Table Name'] ?? ''}</Typography>
                  </Grid>
                  {Object.keys(profile).map((key, index) => {
                    if (['Column Name', 'Data Type', 'Rows', 'Set Type', 'Max Value', 'Min Value'].includes(key)) {
                      return (
                        <Grid key={index} item xs={3}>
                          <LucidTextLabel labelText={key} />
                          <Typography variant="body2">
                            {profile[key as keyof ColumnProfilingCanType]
                              ? profile[key as keyof ColumnProfilingCanType]
                              : 'Null'}
                          </Typography>
                        </Grid>
                      )
                    }
                    return null
                  })}
                  <Grid item xs={3}>
                    <LucidTextLabel labelText={'Overlap Percentage'} />
                    <Typography variant="body2">
                      {profile.overlapPercentage ? profile.overlapPercentage : 'Null'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LucidTextLabel labelText={'Relationship Type'} />
                    <Typography variant="body2">
                      {profile.relationshipType ? profile.relationshipType : 'Null'}
                    </Typography>
                  </Grid>
                  <Grid item xs={3}>
                    <LucidTextLabel labelText={'Schema Compatibility'} />
                    <Typography variant="body2">
                      {profile.schemaCompatibility ? String(profile.schemaCompatibility) : ' '}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item container spacing={1}>
                  <Grid item xs={10.5}>
                    <HeadingLabel headingText="Sample Distribution Set" />
                  </Grid>
                  <Grid item xs={1}>
                    <ToggleView viewType={cardView} handleToggle={handleView} />
                  </Grid>
                </Grid>
                <Card
                  sx={{
                    backgroundColor: '#f2f2f2',
                    marginLeft: 4,
                    marginTop: 1,
                    marginBottom: 4,
                    width: '96%',
                    height: '100%',
                  }}
                >
                  {cardView === 'Grid' && (
                    <Grid item sx={{ ...scrollableContainer }}>
                      {profile.Distribution && (
                        <BarChart
                          xAxis={[
                            {
                              scaleType: 'band',
                              data: Object.keys(profile.Distribution),
                              tickSize: 25,
                            },
                          ]}
                          series={[{ data: Object.values(profile.Distribution).map((value) => Number(value)) }]}
                          width={1200}
                          height={300}
                          margin={{ top: 20, right: 10, bottom: 50, left: 40 }}
                          borderRadius={4}
                        />
                      )}
                    </Grid>
                  )}
                  {cardView === 'Table' && (
                    <Grid item container sx={{ ...scrollableContainer, marginLeft: 4, marginTop: 2, marginBottom: 2 }}>
                      {profile.Distribution && (
                        <>
                          {Object.keys(profile.Distribution).map((key, distIndex) => (
                            <Grid key={distIndex + key} item container xs={4} spacing={2} justifyContent={'left'}>
                              <Grid item xs={6}>
                                <Typography variant="body2">{key}: </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="subtitle1" gutterBottom>
                                  {profile.Distribution ? JSON.stringify(profile.Distribution[key]) ?? '' : ''}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      )}
                    </Grid>
                  )}
                </Card>
                <Grid xs={12} />
                <Divider variant="middle" flexItem />
                <Grid xs={12} />
              </Grid>
            ))}
          </Grid>
        </Card>
      </Grid>
    </ActionPage>
  )
}
