import React, { useEffect, useMemo, useState } from 'react'
import ActionPage from '../../../../ui-components/ActionPage'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { useAddNewAttributeMap, useAddttributeMap } from '../../../../hooks/attributeProfileHooks'
import {
  AppProfileAttribute,
  CreateJobParams,
  CreateJobResponse,
  DataAttribute,
  FabricNotebookCreateRequest,
  JobClusterConfig,
  JobDetails,
  NewAttribute,
  NewAttributeMap,
  NewAttributeMapBody,
  RunStatusResponse,
} from '../../../../businessObjects'
import { useGetProfileAttibuteOutput, useGetProfileEntitites } from '../../../../hooks/dataProfilingHooks'
import {
  Grid,
  Typography,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  TextField,
  MenuItem,
  Menu,
  Button,
  IconButton,
  Box,
  Card,
  Link,
} from '@mui/material'
import { AddOutlined, HomeOutlined } from '@mui/icons-material'
import { ROUTES } from '../../../../Routes/constants'

import { GenSparkCode, SparkCode, useGenerateCustomAttribute } from '../../../../hooks/sparkHooks'
import {
  useAddNewDataAttribute,
  useGetDataAttributesByEntityId,
  useGetCustomAttribeNotebook,
  useSaveCustomAttribeNotebook,
  useUpdateCustomAttribeNotebook,
  useGetDataAttributeByID,
  useGetCustomDataAttributeByID,
  useDeleteAttributeMap,
  useUpdateAttributeDetails,
} from '../../../../hooks/dataAttrbutesHooks'
import { isValidDescription, isValidEntry } from '../../../../utils/constants'
import { LucidJupyter } from '../../../../ui-components/JupyterComponents/LucidJupyter'
import { ProgressBar } from '../../../../ui-components/ProgressBar'
import {
  useAddNewCustomAttributeTransformer,
  useGetDataAttributeTransformer,
  useUpdateDataTransformer,
} from '../../../../hooks/dataTransformerHooks'
import { useGetEntityByID } from '../../../../hooks/entityHooks'
import AnimatedLoader from '../../../../ui-components/AnimatedLoader'
import { LucidPopUp } from '../../../../ui-components/LucidPopUp/LucidPopUp'
import { usePostCreateDatabricksJob } from '../../../../hooks/databricksHooks'
import { RootState } from '../../../../app/store'
import { useAppDispatch, useAppSelector } from '../../../../app/hooks'
import { filterActiveRunIds, initiateActiveRunId } from '../../../../features/notification/notificationSlice'
import ResultStatus from '../../../../ui-components/ResultStatus'
import { useGetServiceConnection } from '../../../../hooks/dataEngineeringHooks'
import constants from '../../../constants'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { PreviousPage } from 'ui-components/Button/PreviousPage';

import { Loading } from 'ui-components/Loading'
import { LoadingButton } from '@mui/lab'
import { useCreateAndRunNewJob, useGetCreateJobPayload } from 'hooks/executionHooks'
type Props = {}
type CustomAttributePageParams = {
  dataPodId: string
  dataSystemID: string
  entityID: string
  tableID: string
  dataAttributeID?: string
}

export const CusomAttribute = (props: Props) => {
  const { dataPodId, entityID, dataAttributeID } = useParams<CustomAttributePageParams>()
  const defDataAttrib = {
    dataEntityId: Number(entityID),
    dataAttributeDataType: '',
    dataAttributeName: '',
    dataClassification: '',
    isBusinessKey: false,
    description: '',
    dataAttributeType: 'simple',
  }

  const currentAttribeId = dataAttributeID ? Number(dataAttributeID) : undefined
  // const [currentAttribeId, setCurrentAttribeId] = useState(
  //   dataAttributeID ? Number(dataAttributeID) : undefined
  // );

  const [newAttibute, setNewAttribute] = useState<NewAttribute>(defDataAttrib)
  const [userPrompt, setUserPrompt] = useState<string>('')
  const [query, setQuery] = useState<GenSparkCode>()
  const [customAttribId, setCustomAttributeId] = useState<number>()
  const [customAttribName, setCustomAttributeName] = useState<string>()
  const [processingReq, setProcessingReq] = useState<boolean>(false)
  //const [isNoteBookUpdated, setIsNoteBookUpdated] = useState(false)
  const [curNotebookString, setCurNotebookString] = useState<string>()
  const [commitMessage, setCommitMessage] = useState<string>('initial commit')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [userInput, setUserInput] = useState<string>('')
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [isFormEdited, setIsFromEdited] = useState<boolean>(false)
  const [isPromptEdited, setIsPromptEdited] = useState<boolean>(false)

  const navigate = useNavigate()

  const { entity } = useGetEntityByID(dataPodId, Number(entityID))
  const { attributeProfiles } = useGetDataAttributesByEntityId(Number(entityID))

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const dispatch = useAppDispatch()

  const [newAttributeFunc] = useAddNewDataAttribute()
  const { addAttributeMapFunc } = useAddttributeMap(dataPodId)
  const { genAttribute } = useGenerateCustomAttribute(dataPodId)
  const { saveNoteBook } = useSaveCustomAttribeNotebook(dataPodId)
  const { addTransformer } = useAddNewCustomAttributeTransformer(dataPodId)
  const { updateTransformer, transformerUpdateStatus } = useUpdateDataTransformer()
  const { updateAttibuteFunc } = useUpdateAttributeDetails(dataPodId)
  const { dataTransformers } = useGetDataAttributeTransformer(dataPodId, currentAttribeId)
  const [attributeMapID, setAttributeMapId] = useState<NewAttributeMap>()
  const { getConnections } = useGetServiceConnection()

  const {getCreateJobPayload} = useGetCreateJobPayload()
  const {createAndRunNewJob} = useCreateAndRunNewJob()

  //const { dataAttributes } = useGetDataAttributeByID(dataAttributeID ? Number(dataAttributeID) : undefined)
  const { dataAttributes, childAttributes, childAttributesMaps } = useGetCustomDataAttributeByID(
    dataPodId,
    currentAttribeId,
  )

  const { noteBookString } = useGetCustomAttribeNotebook(dataPodId, currentAttribeId)
  const { updateAttributeNoteBook } = useUpdateCustomAttribeNotebook(dataPodId)
  const { deleteAttributeMapId } = useDeleteAttributeMap()
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [jobClusterParams, setJobClusterParams] = useState<JobClusterConfig | null>(null)
  const [customAttributeMapId, setCustomAttributeMapId] = useState<number>()
  const [dataTransformerId, setDataTransformerId] = useState<number>()
  const [dataAttribute, setDataAttribute] = useState<DataAttribute>()
  const [createdAttributeMaps, setCreatedAttributeMaps] = useState<NewAttributeMap[]>([])

  const columnMenuItems = useMemo(() => {
    if (!attributeProfiles.length) return []
    return attributeProfiles
  }, [attributeProfiles.length])

  const exAttributeMaps = useMemo(() => {
    if (childAttributesMaps.length) {
      return childAttributesMaps
    }
    return []
  }, [childAttributesMaps])
  // useEffect(() => {
  //   if (dataAttribute && dataAttribute.id) {
  //     console.log(currentAttribeId)
  //     // setCurrentAttribeId(dataAttribute.id);
  //   }
  // }, [dataAttribute]);


  useEffect(() => {
    const fetchJobClusterParams = async () => {
      if (dataPodId) {
        const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')

        if (serviceConnection && serviceConnection.length > 0) {
          const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate
          const parsedJson = JSON.parse(jsonTemplate)
          const params: JobClusterConfig = {
            url: parsedJson.workspace_Url,
            token: parsedJson.PAT_Token,
            clusterKey: parsedJson.cluster_Id,
            sparkVersion: parsedJson.sparkVersion,
            nodeTypeId: parsedJson.nodeTypeId,
          }
          setJobClusterParams(params)
        }
      }
    }

    fetchJobClusterParams()
  }, [])

  useEffect(() => {
    if (dataAttributes?.length && !newAttibute.dataAttributeName) {
      setNewAttribute({
        dataEntityId: Number(entityID),
        dataAttributeDataType: dataAttributes[0].dataAttributeDataType,
        dataAttributeName: dataAttributes[0].dataAttributeName,
        dataClassification: dataAttributes[0].dataClassification,
        isBusinessKey: dataAttributes[0].isBusinessKey,
        description: dataAttributes[0].description,
        dataAttributeType: dataAttributes[0].dataAttributeDataType,
      })
      setCustomAttributeId(dataAttributes[0].id)
      setCustomAttributeName(dataAttributes[0].dataAttributeName)
    }
  }, [dataAttributes])

  useEffect(() => {
    if (childAttributes.length && !userInput) {
      const prvInput = childAttributes.map((cr) => `@${cr.dataAttributeName}`).join(' ')
      setUserInput(prvInput)
      setIsFromEdited(true)
    }
  }, [childAttributes])

  useEffect(() => {
    if (userPrompt.length) {
      setIsPromptEdited(true)
    }
  }, [userPrompt])

  useEffect(() => {
    if (dataTransformers.length && !userPrompt) {
      setUserPrompt(dataTransformers[0].transformerText)
    }
  }, [dataTransformers])

  useEffect(() => {
    if (noteBookString?.length) {
      // console.log('Note book string is present')
      setCurNotebookString(noteBookString)
      if (dataAttributeID) {
        setIsEditMode(true)
      }
    } else {
      setIsEditMode(false)
    }
  }, [noteBookString])

  // useEffect(()=>{
  //   if(userPrompt.length && curNotebookString?.length){
  //     setIsEditMode(true)
  //   }
  // },[userPrompt, userPrompt.length])

  const onChangeNewAttribute = (name: string, value: unknown) => {
    setNewAttribute(
      (prv) =>
        ({
          ...prv,
          [name]: value,
        } as NewAttribute),
    )
    setIsFromEdited(true)
    if (currentAttribeId && !isEditMode) {
      // setIsEditMode(true)
    }
  }

  const transformationTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // setIsEditMode(true)
    setUserInput(value)
    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
    if (currentAttribeId && !isEditMode) {
      // setIsEditMode(true)
    }
  }

  const handleSelectMention = (columnName: string) => {
    const columnStr = userInput.replace(/@$/, `@${columnName}`)
    setUserInput(columnStr)
    if (currentAttribeId && !isEditMode) {
      // setIsEditMode(true)
    }
  }

  const saveNotebookCode = async () => {
    if (curNotebookString && customAttribId) {
      setProcessingReq(true)
      const success = await saveNoteBook(curNotebookString, customAttribId, commitMessage)
      if (success) {
        // resetStates()
        // onClickHome()
        setProcessingReq(false)
        setIsEditMode(true)
      }
    }
  }

  const onClickHome = () => {
    const dataAttributePage = generatePath(ROUTES.DataModelingCanonicalModel, {
      dataPodId: dataPodId,
    })
    navigate(dataAttributePage)
  }

  const entityInfo = useMemo(() => {
    if (entity?.length) return entity[0]
  }, [entity, entityID])

  const saveCustomAttribute = async () => {
    if (newAttibute && dataPodId && !customAttribId) {
      // console.log("Inside if in save custom attribute")
      const tosave = {
        ...newAttibute,
        dataAttributeType: 'CustomAttribute',
        isBusinessKey: Boolean(newAttibute.isBusinessKey),
      }
      const columnVals: string[] = userInput
        .split('@')
        .filter((uv) => uv && uv)
        .map((cs) => cs.trim())

      // find all attribute Object matching the selected columnName
      const selAttributes = columnMenuItems.filter((ci) => columnVals.some((cv) => ci.dataAttributeName === cv))

      // filter only unique attributes
      const uniq = selAttributes.filter((sa, ix) => selAttributes.findIndex((sela) => sela.id === sa.id) === ix)
      // console.log({ columnVals }, { selAttributes }, { uniq })
      if (columnVals.length != uniq.length) {
        return
      }
      setProcessingReq(true)
      // console.log({ tosave })
      const dAtt = await newAttributeFunc(tosave, dataPodId)
      let allMapsSuccess: number[] = []
      let tempRespArray: NewAttributeMap[] = []
      if (dAtt?.id && entityID && uniq.length) {
        // console.log("Inside the triple if")
        for (let i = 0; i < uniq.length; i++) {
          // console.log(i)
          const attribMap: NewAttributeMapBody = {
            //dataTableId: Number(tableID),
            dataEntityId: Number(entityID),
            //dataColumnId: uniq[i].dataColumnId,
            //dataEntityMapId: uniq[i].dataEntityMapId,
            dataAttributeId: dAtt?.id,
            dataSourceAttributeId: uniq[i].id,
            runid: 0,
          }
          const resp = await addAttributeMapFunc(attribMap)

          if (resp && resp.id) {
            // const transformSuccess = await addTransformer({
            //   dataAttributeMapId: resp.id,
            //   transformerText: userPrompt,
            //   transformerQuery: '',
            //   dataEntityId: attribMap.dataEntityId,
            //   transformerTypeName: 'compostite Attribute',
            // })
            // console.log('transformSuccess', transformSuccess)
            tempRespArray.push(resp)
            setCreatedAttributeMaps(tempRespArray)
            allMapsSuccess = [...allMapsSuccess, resp.id]
          }
        }
        if (allMapsSuccess.length == columnVals.length) {
          setCustomAttributeMapId(allMapsSuccess[0])
          const result = await addTransformer({
            dataAttributeMapId: allMapsSuccess[0],
            transformerText: userPrompt,
            transformerQuery: '',
            dataEntityId: Number(entityID),
            transformerTypeName: 'Composite',
          })
          if (result) {
            const { success: transformSuccess, newTransformer } = result
            if (transformSuccess) {
              // console.log(newTransformer)
              if (newTransformer) {
                // console.log("this is the new Transformer", { newTransformer })
                // console.log(newTransformer.id)
                setDataTransformerId(newTransformer.id)
              }
              const res = await genAttribute(userPrompt, dAtt?.id)
              if (res?.data) {
                setQuery(res)
                setCurNotebookString(res.data)
                setCustomAttributeId(dAtt.id)
                setCustomAttributeName(dAtt.dataAttributeName)
                setDataAttribute(dAtt)
                setProcessingReq(false)
                setIsFromEdited(false)
              }
            } else {
              console.log('some thing gone wrong')
            }
          }
        }
      }
    } else if (isPromptEdited && dataPodId && !isFormEdited) {
      // console.log("inside else if prompt is changed, inside the save method")
      updateTransformerAndCallGenAIAPI(dataPodId)
    }
  }

  const clickNextLabel = useMemo(() => {
    return 'Generate Code'
  }, [customAttribId])

  const clickNextAction = async () => {
    // console.log({ isEditMode }, { customAttribId }, { isPromptEdited }, { isFormEdited })
    // console.log(currentAttribeId)
    if (!isEditMode && !customAttribId) {
      // console.log("Inside not is Edit Mode")
      saveCustomAttribute()
    }
    // if (customAttribId && curNotebookString && !isEditMode) {
    //   // const success = await saveNoteBook(curNotebookString, customAttribId, commitMessage)
    //   // if (success) {
    //   //   resetStates()
    //   //   onClickHome()
    //   // }
    //   console.log("saving the notebook code")
    //   saveNotebookCode()
    // }
    if (customAttribId || isFormEdited || (isEditMode && isPromptEdited)) {
      // console.log("Inside the edit mode scenario")
      await onUpdateSave()
    } else {
      if (isPromptEdited && !isEditMode && dataPodId && !isFormEdited) {
        console.log("Inside prompt edited")
        // updateTransformerAndCallGenAIAPI(dataPodId)
      }
    }
  }

  const resetStates = () => {
    setCustomAttributeId(undefined)
    setUserInput('')
    setUserPrompt('')
    setNewAttribute(defDataAttrib)
    setProcessingReq(false)
    setCurNotebookString(constants.defaultNotebookString)
    setQuery(undefined)
    setProcessingReq(false)
  }

  // const handleAttributeSelection = (sel: typeof attributeTypeOption) => {
  //   setAttributeTypeOption(sel)
  //   if (sel === 'simpleAttribute') {
  //     resetStates()
  //   }
  // }
  const disableContinue = useMemo(() => {
    return !dataAttributeID
      ? processingReq ||
          !newAttibute.dataAttributeName ||
          !newAttibute.description ||
          !newAttibute.dataAttributeDataType ||
          !newAttibute.dataClassification ||
          !userPrompt
      : false

    // return (
    //   processingReq ||
    //   !newAttibute.dataAttributeName ||
    //   !newAttibute.description ||
    //   !newAttibute.dataAttributeDataType ||
    //   !newAttibute.dataClassification ||
    //   !selectedColumnId
    // )
  }, [
    processingReq,
    //attributeTypeOption,
    userInput,
    newAttibute.dataAttributeName,
    newAttibute.description,
    newAttibute.dataAttributeDataType,
    newAttibute.dataClassification,
    userPrompt,
  ])
  const updateNewNoteBook = async () => {
    if ((noteBookString?.length || curNotebookString?.length) && (currentAttribeId || dataAttribute)) {
      setShowPopUp(true)
      // const updateSuccess = await updateAttributeNoteBook(curNotebookString, currentAttribeId, commitMessage)
      // if (updateSuccess) {

      // }
    }
  }

  const updateTransformerAndCallGenAIAPI = async (dataPodId: string) => {
    if (
      (childAttributesMaps.length || customAttributeMapId) &&
      (dataTransformers.length || dataTransformerId) &&
      customAttribId
    ) {
      // console.log("Inside the if")
      // const dataAttributeMapId = customAttributeMapId || childAttributesMaps[0].id;
      // const transformerId = dataTransformers[0].id || dataTransformerId
      const dataAttributeMapId =
        customAttributeMapId !== undefined && customAttributeMapId !== null
          ? customAttributeMapId
          : childAttributesMaps[0].id

      const transformerId =
        dataTransformers[0]?.id !== undefined && dataTransformers[0]?.id !== null
          ? dataTransformers[0].id
          : dataTransformerId

      if (typeof transformerId !== 'number') {
        // console.error('Transformer ID is not a valid number');
        return
      }

      setProcessingReq(true)
      const transformerSuccess = await updateTransformer(
        {
          id: transformerId,
          dataAttributeMapId: dataAttributeMapId,
          transformerText: userPrompt,
          transformerQuery: '',
          dataEntityId: Number(entityID),
          transformerTypeName: 'Composite',
        },
        dataPodId,
      )
      // console.log({ transformerSuccess }, { transformerUpdateStatus })
      if (transformerSuccess && transformerSuccess.transformerUpdateStatus) {
        // console.log("Inside if")
        // const res = await genAttribute(userPrompt, customAttribId)
        // console.log("Second checkpoint")
        // console.log({ res })
        // if (res?.data) {
          // setQuery(res)
          // setCurNotebookString(res.data)
          //setCustomAttributeId(updatedAttb.id)
          //setIsNoteBookUpdated(true)
          setProcessingReq(false)
          setIsFromEdited(false)
          setIsPromptEdited(false)
        // }
      }
    }
  }

  const onUpdateSave = async () => {
    if ((dataAttributeID && dataAttributes?.length) || (customAttribId && dataAttribute)) {
      // console.log("Inside the if")
      const exAtt = dataAttributes?.length ? dataAttributes[0] : dataAttribute
      // console.log(exAtt)
      if (!exAtt) {
        console.error('No valid attribute found.')
        return
      }
      setProcessingReq(true)
      const updatedAttb = await updateAttibuteFunc({
        id: exAtt.id,
        name: newAttibute.dataAttributeName,
        description: newAttibute.description,
        dataAttributeDataType: newAttibute.dataAttributeDataType,
        dataClassification: newAttibute.dataClassification,
        isBusinessKey: newAttibute.isBusinessKey,
        coreEntity: false,
        entityType: '',
      })
      const columnVals: string[] = userInput
        .split('@')
        .filter((uv) => uv && uv)
        .map((cs) => cs.trim())

      // find all attribute Object matching the selected columnName
      const selAttributes = columnMenuItems.filter((ci) => columnVals.some((cv) => ci.dataAttributeName === cv))

      // filter only unique attributes
      const uniq = selAttributes.filter((sa, ix) => selAttributes.findIndex((sela) => sela.id === sa.id) === ix)

      // console.log({ uniq }, { columnVals })

      if (columnVals.length != uniq.length) {
        setProcessingReq(false)
        return
      }

      let exAtMapIds: (number | undefined)[]

      let nAttribMapIds: Number[]

      let newMaps: DataAttribute[]

      let toDeleteMaps: NewAttributeMap[]

      // console.log({ exAttributeMaps })

      if (exAttributeMaps.length || createdAttributeMaps.length) {
        // console.log("exAttributeMaps Exist")
        // exAtMapIds = exAttributeMaps.map((exm) => exm.dataSourceAttributeId)
        exAtMapIds =
          exAttributeMaps.length > 0
            ? exAttributeMaps.map((exm) => exm.dataSourceAttributeId)
            : createdAttributeMaps.map((cm) => cm.dataSourceAttributeId)

        nAttribMapIds = uniq.map((uam) => uam.id)

        if (createdAttributeMaps.length) {
          // console.log("Inside the createdAttributeMaps", createdAttributeMaps)
          newMaps = uniq.filter(
            (um) =>
              !exAtMapIds.includes(um.id) && !createdAttributeMaps.some((cm) => cm.dataSourceAttributeId === um.id),
          )
        } else {
          newMaps = uniq.filter((um) => !exAtMapIds.includes(um.id))
        }

        toDeleteMaps = exAttributeMaps.filter(
          (chlMaps) =>
            chlMaps.dataSourceAttributeId === undefined || !nAttribMapIds.includes(chlMaps.dataSourceAttributeId),
        )

        // console.log({ selAttributes }, { uniq }, { exAtMapIds }, { nAttribMapIds }, { newMaps }, { toDeleteMaps })

        for (let delIdx = 0; delIdx < toDeleteMaps.length; delIdx++) {
          const delSuccess = await deleteAttributeMapId(toDeleteMaps[delIdx].id)
          if (!delSuccess) {
            return
          }
        }
        // console.log("Comes till this point", { updatedAttb }, { entityID }, { newMaps }, { uniq })
        let allMapsSuccess: number[] = []
        let tempRespArray: NewAttributeMap[] = []
        if (updatedAttb?.id && entityID && newMaps.length) {
          // console.log(updatedAttb)
          for (let i = 0; i < newMaps.length; i++) {
            const attribMap: NewAttributeMapBody = {
              //dataTableId: Number(tableID),
              dataEntityId: Number(entityID),
              //dataColumnId: uniq[i].dataColumnId,
              //dataEntityMapId: uniq[i].dataEntityMapId,
              dataAttributeId: exAtt?.id,
              dataSourceAttributeId: newMaps[i].id,
              runid: 0,
            }
            // console.log(attribMap)
            const resp = await addAttributeMapFunc(attribMap)

            if (resp && resp.id) {
              // const transformSuccess = await addTransformer({
              //   dataAttributeMapId: resp.id,
              //   transformerText: userPrompt,
              //   transformerQuery: '',
              //   dataEntityId: attribMap.dataEntityId,
              //   transformerTypeName: 'compostite Attribute',
              // })
              tempRespArray.push(resp)
              tempRespArray = [...createdAttributeMaps, ...tempRespArray]
              // console.log({tempRespArray})
              setCreatedAttributeMaps(tempRespArray)
              allMapsSuccess = [...allMapsSuccess, resp.id]
            }
          }
          // console.log(allMapsSuccess)
          if (
            allMapsSuccess.length == newMaps.length &&
            newMaps.length &&
            dataPodId &&
            (dataTransformerId || dataTransformers.length)
          ) {
            // console.log("Inside the success and about to call the update Transformer")
            const transformIdToUse = dataTransformerId ? dataTransformerId : dataTransformers[0].id
            const transformSuccess = await updateTransformer(
              {
                id: transformIdToUse,
                dataAttributeMapId: allMapsSuccess[0],
                transformerText: userPrompt,
                transformerQuery: '',
                dataEntityId: Number(entityID),
                transformerTypeName: 'Composite',
              },
              dataPodId,
            )
            // if (transformSuccess && transformSuccess) {
            // }
          }
        } else {
          console.log('Inside else')
        }
        if (!newMaps.length && dataPodId) {
          // console.log("No new maps")
          updateTransformerAndCallGenAIAPI(dataPodId)
        }
      }
      setProcessingReq(true)
      const res = await genAttribute(userPrompt, exAtt?.id)
      if (res?.data) {
        setQuery(res)
        setCurNotebookString(res.data)
        setCustomAttributeId(exAtt.id)
        setCustomAttributeName(updatedAttb?.dataAttributeName)
        //setIsNoteBookUpdated(true)
        setProcessingReq(false)
        // setIsEditMode(false)
      }
    }
  }

  const clickUpdateLabel = useMemo(() => {
    if (isEditMode) {
      return 'Update Notebook'
    } else {
      return 'Save Notebook'
    }
  }, [isEditMode])

  const executeNotebook = async () => {
    if (dataPodId && currentAttribeId && curNotebookString) {
      const databricksParams: CreateJobParams = {
        dataPodId,
        customAttributeID: currentAttribeId,
      }

      const fabricParams: FabricNotebookCreateRequest = {
        workspaceId: '',
        displayName: dataPodId + "_" + customAttribName || '',
        description: `Executing custom attribute: ${customAttribName} for datapodId ${dataPodId}`,
        accessToken: '',
        code: curNotebookString,
        executionData: {},
        environmentId: '',
      }

      const routePage = generatePath(ROUTES.DataModelingCanonicalModelEditCustomAttribute, {
        dataPodId: dataPodId,
        entityID: entityInfo?.id,
        dataAttributeID: customAttribId,
      })

      const jobDetails: JobDetails = {
        triggerType: 'Custom Attribute Code Execution',
        parameters: {
          id: currentAttribeId,
          name: customAttribName,
          route: routePage,
        },
      }

      
      try {
        const payload = await getCreateJobPayload(dataPodId, databricksParams, fabricParams)
        if(payload) {
          await createAndRunNewJob(jobDetails, payload)
        } else {
          setErrorState(true)
          setErrorMessage("Failed to execute notebook.")
        }
      } catch (error: any) {
        setErrorState(false)
        setErrorMessage(error.message)
      }
    }
  }

  const handleNotebookString = async () => {
    // console.log("Inside handle Notebook String Save and Update")
    if (isEditMode) {
      // console.log("called the update")
      await updateNewNoteBook()
    } else {
      // console.log("called the save")
      saveNotebookCode()
    }
  }

  const handleUserPrompt = (newText: string) => {
    if (currentAttribeId && !isEditMode) {
      setIsEditMode(true)
    }
    setUserInput(newText)
  }
  const handleNext = async () => {
    let attrId: number | undefined;
    if (currentAttribeId) {
      attrId = currentAttribeId;
    } else if (dataAttribute && dataAttribute.id) {
      attrId = dataAttribute.id;
    }
  
    if (attrId && commitMessage && curNotebookString) {
      try {
        const updatedCode = await updateAttributeNoteBook(curNotebookString, attrId, commitMessage);
        if (updatedCode) {
          setShowPopUp(false);
          setIsEditMode(true);
        }
      } catch (error) {
        console.error('Failed to update notebook:', error);
      }
    } else {
      console.log("Attr id is missing");
    }
  };
  const onTextChange = (name: string, value: string) => {
    setCommitMessage(value)
  }
  // const updateNoteBookInputString = (codeString: string) => {
  //   setIsNoteBookUpdated(true)
  //   setCurNotebookString(codeString)
  // }

  const closeErrorMessage = () => {
    setErrorState(false)
    setErrorMessage(undefined)
  }
  const onClickBack = (event: any): void => {
    onClickHome()
  }

  const onPreviewButtonClick = async () => {
    const columProfileRoute = generatePath(ROUTES.DataModelingCanonicalDataPreview, {
      dataPodId: dataPodId,
      dataEntityID: Number(entityID),
    })
    navigate(columProfileRoute)
  }

  return (
    <>
      {errorMessage && (
        <ResultStatus
          showStatus={errorState}
          alertMessage={errorMessage}
          severtiy="error"
          closeStatus={() => closeErrorMessage()}
        />
      )}
      <ActionPage>
        <Grid
          item
          container
          xs={15}
          alignItems="center"
          spacing={2}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'flex-start'}
        >
          <Grid item xs={2}>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
            >
              Back
            </Button>
          </Grid>
          <Grid item xs={8}>
            <Typography variant="h3" color="primary" gutterBottom>
              Custom Attribute
            </Typography>
          </Grid>
        </Grid>

        <Grid container item xs={14} alignItems={'stretch'} alignContent={'center'} spacing={2} paddingTop={'10px'}>
          <Grid
            item
            xs={5}
            container
            alignItems={'stretch'}
            alignContent={'baseline'}
            spacing={1}
            sx={{ pl: 5, flex: 2 }}
          >
            {/* <Grid item xs={12} alignItems={'stretch'}> */}
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Entity Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                value={entityInfo?.dataEntityName}
                required
                disabled={true}
              />
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Source Attributes <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                size="small"
                helperText={!userInput ? 'Type @ to mention' : ''}
                value={userInput}
                fullWidth
                onChange={transformationTextChangeHandler}
              />
              <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
                {columnMenuItems
                  .filter((cm) => !userInput.split('@').includes(cm.dataAttributeName))
                  ?.map((ea, ix) => (
                    <MenuItem
                      key={ea.id + ix + ea.dataAttributeName}
                      onClick={() => {
                        handleSelectMention(ea.dataAttributeName)
                        setAnchorEl(null)
                      }}
                    >
                      {ea.dataAttributeName}
                    </MenuItem>
                  ))}
              </Menu>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Custom Attribute Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                value={newAttibute?.dataAttributeName ?? ''}
                error={!isValidEntry(newAttibute.dataAttributeName)}
                onChange={({ target }) => onChangeNewAttribute('dataAttributeName', target.value)}
                required
                disabled={(customAttribId || currentAttribeId)? true: false}
              />
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Attribute Description <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                rows={3}
                multiline
                value={newAttibute?.description ?? ''}
                error={!isValidDescription(newAttibute.description)}
                onChange={({ target }) => onChangeNewAttribute('description', target.value)}
                required
              />
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                isBusinessKey <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-select-industry"
                color="secondary"
                size="small"
                select
                multiline
                value={newAttibute?.isBusinessKey ?? false}
                onChange={({ target }) => onChangeNewAttribute('isBusinessKey', target.value)}
                required
              >
                <MenuItem value={'true'}>true</MenuItem>
                <MenuItem value={'false'}>false</MenuItem>
              </TextField>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Classification <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                color="secondary"
                size="small"
                select
                onChange={({ target }) => onChangeNewAttribute('dataClassification', target.value)}
                value={newAttibute?.dataClassification ?? ''}
                required
              >
                {['Internal', 'External', 'Public Data'].map((dtype) => (
                  <MenuItem key={dtype} value={dtype}>
                    {dtype}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Datatype <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                fullWidth
                id="outlined-basic"
                variant="outlined"
                color="secondary"
                size="small"
                select
                onChange={({ target }) => onChangeNewAttribute('dataAttributeDataType', target.value)}
                value={newAttibute?.dataAttributeDataType ?? ''}
                required
              >
                {['String', 'Integer', 'Double', 'Date', 'Boolean', 'Binary', 'Timestamp', 'Long'].map((dtype) => (
                  <MenuItem key={dtype} value={dtype}>
                    {dtype}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
            <br></br>
            <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                <span style={{ color: 'red' }}>*</span> Enter Prompt Text
              </Typography>
              <TextField
                multiline
                rows={5}
                fullWidth
                value={userPrompt}
                onChange={({ target }) => setUserPrompt(target.value)}
              />
            </Grid>
            {/* <Grid item xs={10}>
              <Typography variant="h4" color="primary" gutterBottom>
                Source Attribute Name <span style={{ color: 'red' }}>*</span>
              </Typography>
              <TextField
                size="small"
                helperText={!userInput ? 'Type @ to mention' : ''}
                value={userInput}
                fullWidth
                onChange={transformationTextChangeHandler}
              />
              <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
                {columnMenuItems
                  .filter((cm) => !userInput.split('@').includes(cm.dataAttributeName))
                  ?.map((ea, ix) => (
                    <MenuItem
                      key={ea.id + ix + ea.dataAttributeName}
                      onClick={() => {
                        handleSelectMention(ea.dataAttributeName)
                        setAnchorEl(null)
                      }
                      }
                    >
                      {ea.dataAttributeName}
                    </MenuItem>
                  ))}
              </Menu>
            </Grid> */}
            {/* </Grid> */}
          </Grid>

          {/* <Grid container sx={{ pr: 5, flex: 2 }}> */}
          <Grid item xs={7} alignItems={'stretch'}>
            <Card sx={{ width: '100%' }}>
              {/* <ProgressBar loading={processingReq} /> */}
              {/* <AnimatedLoader height="100%" width="100%" loading={processingReq} /> */}
              <Grid display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                <Grid item xs={3}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleNotebookString}
                    startIcon={<AddOutlined />}
                    disabled={!curNotebookString}
                    loading={processingReq}
                >
                    {clickUpdateLabel}
                  </LoadingButton>
                </Grid>
                <Grid item xs={2}>
                  <Button variant="outlined" onClick={onPreviewButtonClick} disabled={!entityID}>
                    Data Preview
                  </Button>
                </Grid>
              </Grid>
              <LucidJupyter
                executeNotebook={executeNotebook}
                noteBookString={curNotebookString}
                headingText={constants.codeHeaderText}
                updateNoteBookString={setCurNotebookString}
                commitMessage={commitMessage}
                updateCommitMessage={setCommitMessage}
              />
            </Card>
          </Grid>
          <br></br>
          <Grid item xs={7} container spacing={4}>
            <Grid item xs={3.5}>
              <Button variant="contained" fullWidth onClick={onClickHome}>
                Cancel
              </Button>
            </Grid>

            <Grid item xs={3.5}>
              {(
                <LoadingButton loading={processingReq} fullWidth variant="contained" disabled={disableContinue} onClick={clickNextAction}>
                  {clickNextLabel}
                </LoadingButton>
              )}
            </Grid>
            {/* <Grid item xs={4}>
              {currentAttribeId && (
                <Button
                  fullWidth
                  variant="contained"
                  disabled={isEditMode ? !userPrompt : processingReq || !curNotebookString}
                  onClick={clickUpdateAction}
                >
                  {clickUpdateLabel}
                </Button>
              )}
            </Grid> */}
          </Grid>
        </Grid>
        {/* </Grid> */}
        <LucidPopUp
          showCloseIcon
          closePopup={setShowPopUp}
          openPopUp={showPopUp}
          onConfirm={handleNext}
          headingText=""
          confirmText="Commit"
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Link href={'https://github.com/Lucid-Data-Hub/gen-datalake-dev'} target="blank">
                Git Hub Repository
              </Link>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ fontSize: 12 }}
                fullWidth
                id="outlined-basic"
                label="Enter Commit Message"
                variant="outlined"
                color="secondary"
                required
                rows={2}
                multiline
                value={commitMessage}
                onChange={({ target }) => onTextChange('commitMessage', target.value)}
              />
            </Grid>
          </Grid>
        </LucidPopUp>
      </ActionPage>
    </>
  )
}
