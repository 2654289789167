import { useMemo, useEffect, useState } from 'react'
import { Button, Grid, Link, Typography } from '@mui/material'
import AddServiceConnection from '../Settings/ServiceConnection/AddServiceConnection'
import {
  usePostServiceConnection,
  ServiceConnectionType,
  useQueryServiceConnections,
  useDeleteServiceConnection,
} from '../../hooks/serviceConnectionHooks'
import NoEntries from '../../ui-components/NoEntries'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../ui-components/DataTable/DataTableTypes'
import DataTable from '../../ui-components/DataTable/DataTable'
import ServiceNameWithIcon from '../../ui-components/ServiceNameWithIcon'
import ResultStatus from '../../ui-components/ResultStatus'
import AnimatedLoader from '../../ui-components/AnimatedLoader'
import {
  DataSystem,
  DatapodServiceConnection,
  NewServiceConnection,
  OperationState,
  UpdateServiceConnection,
} from '../../businessObjects'
import { LucidPopUp } from '../../ui-components/LucidPopUp/LucidPopUp'
// import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp'
// import { DataSystem } from 'businessObjects'

type Props = {
  dataPodId: string
  connections: DatapodServiceConnection[]
  refreshConnections: () => Promise<void>
  // handleService: (args: any) => void
  // serviceInfo?: ServiceConnectionType
  dataSystemsList: DataSystem[]
}

export const DataContextService = ({ dataPodId, connections, dataSystemsList, refreshConnections }: Props) => {
  const [dataPodConnections, setDataPodConnections] = useState<DataTableRow[]>([])
  const [proccessingConnections, setProccessingConnections] = useState(false)
  // const [connectionsErrorMsg, setConnectionsErrorMsg] = useState('')
  // const { connectionsLoading, getServiceConnectionsByPodId } = useQueryServiceConnections(dataPodId)
  // const { postComplete, deleteServiceConnection, postError, deleteStatus } = usePostServiceConnection(dataPodId)
  const [resultState, setResultState] = useState<OperationState>()
  const [selectedConnection, setSelectConnection] = useState<NewServiceConnection>()
  const [isAddService, setIsAddService] = useState<boolean>(false)
  const [openWarning, setOpenWarning] = useState<boolean>(false)
  const [selectedId, setSelectedId] = useState<number>()
  const { deleteConnFn } = useDeleteServiceConnection()
  const dataPodColumns: DataTableColumn[] = useMemo(() => {
    return [
      {
        label: 'Id',
      },
      {
        label: 'Connection Name',
      },
      {
        label: 'Connection Type',
      },
      {
        label: 'Service Name',
      },
      {
        label: 'Actions',
      },
    ]
  }, [])

  // useEffect(() => {
  //   if ((dataPodId && postError) || deleteStatus?.status) {
  //     //Add/Edit - handling error message.
  //     setResultState(true)
  //     // getServiceConnectionsByPodId(dataPodId)
  //   }
  // }, [postError, deleteStatus])

  // console.log({ connections })

  useEffect(() => {
    if (connections.length) {
      const tRows = connections.map((conn) => ({
        id: `${conn.id}`,
        values: [
          conn.id,
          conn.connectionName,
          conn.connectionType,
          // eslint-disable-next-line react/jsx-key
          (<ServiceNameWithIcon serviceName={conn.serviceTypeName} />) as unknown as string,
        ],
      })) as DataTableRow[]
      setDataPodConnections(tRows)
    }
  }, [connections])

  // // useEffect(() => {
  // //   const tRows = connections?.map((conn: any) => ({
  // //     id: `${conn.id}`,
  // //     values: [
  // //       conn.id,
  // //       conn.connectionName,
  // //       conn.connectionType,
  // //       // eslint-disable-next-line react/jsx-key
  // //       <ServiceNameWithIcon serviceName={conn.serviceTypeName} />,
  // //     ],
  // //   })) as DataTableRow[]
  // //   //below will refresh list page. upon service creation
  // //   if (dataPodId && serviceInfo?.action === 'error') {
  // //     setResultState(true)
  // //   }

  // //   if ((dataPodId && serviceInfo?.action === 'list') || postComplete?.dataPodId) {
  // //     setAddService(false)
  // //     selectServiceConnection({} as any)
  // //     handleService({} as any)
  // //     getServiceConnectionsByPodId(dataPodId)
  // //   }
  // //   setServiceConnections(tRows)
  // // }, [connections, serviceInfo?.action, postComplete])

  // useEffect(() => {
  //   if (isAddService) {
  //     handleService({ action: 'new' })
  //   } else if (selectedConnection?.id) {
  //     handleService({ action: 'edit' })
  //   }
  // }, [isAddService, selectedConnection?.id])

  const clickTableButton = (id: string, action: DataItemAction): void => {
    setSelectedId(Number(id))

    if (id && action) {
      if (action === 'delete') {
        setOpenWarning(true)
      } else {
        const selected = connections.find((c) => c.id === parseInt(id))
        if (selected) {
          setSelectConnection({ ...selected })
          setIsAddService(true)
        }
      }
    }
  }

  const confirmDelete = async () => {
    if (selectedId) {
      setProccessingConnections(true)
      const connName = connections.find((c) => c.id === selectedId)?.connectionName ?? ''

      const deleteSuccess = await deleteConnFn(selectedId)
      setProccessingConnections(false)
      setOpenWarning(false)
      if (deleteSuccess) {
        setSelectedId(undefined)
        refreshConnections()
        setResultState({
          display: true,
          message: connName + ' : Deleted Service Connection Successfully',
          result: 'success',
        })
      } else {
        setResultState({
          display: true,
          message: 'Error: Deleting Service Connection ' + connName,
          result: 'error',
        })
      }
    }
  }

  const handleService = (serviceConnId?: number) => {
    if (serviceConnId) {
      setIsAddService(false)
      refreshConnections()
      setResultState({
        display: true,
        message: 'Updated/Created new service connections',
        result: 'success',
      })
    } else {
      setResultState({
        display: true,
        message: 'Failed to Add/Update Service Connection',
        result: 'error',
      })
    }
  }

  const onClickAdd = () => {
    setSelectConnection(undefined)
    setIsAddService(true)
    setSelectedId(undefined)
  }

  return (
    <>
      <LucidPopUp
        openPopUp={openWarning}
        closePopup={setOpenWarning}
        headingText={
          <Typography variant="h4" color={'error'}>
            Are you sure to delete?
          </Typography>
        }
        confirmText="Confirm"
        cancelText="Cancel"
        disabledConfirm={dataSystemsList.some((ds) => ds.serviceConnectionId === selectedId)}
        onConfirm={confirmDelete}
      >
        <Typography>
          Delete will effect all dependencies. Please delete Data Systems before proceeding this action
        </Typography>
      </LucidPopUp>
      <Grid item alignContent="center" justifyContent={'center'} xs={14} sx={{ mt: 4, p: 4 }}>
        <AnimatedLoader height="50%" width="40%" loading={proccessingConnections} />
        {isAddService && (
          <AddServiceConnection
            dataPodId={dataPodId}
            selectedConnection={selectedConnection}
            closeAddForm={() => setIsAddService(false)}
            serviceConnectionId={selectedId}
            handleService={handleService}
            isAddNewPodStep={true}
          />
        )}
        {connections.length > 0 && !isAddService ? (
          <>
            <Grid item xs={12} spacing={2} sx={{ mb: 2, mt: 2 }}>
              <Grid item xs={12} display={'flex'} justifyContent={'end'}>
                <Button variant="contained" onClick={onClickAdd}>
                  {'Add Service Connection'}
                </Button>
              </Grid>
              <DataTable
                columns={dataPodColumns}
                rows={dataPodConnections}
                allowedActions={{ editable: true, deletable: true }}
                filterColumn={1}
                // fromPage={'service'}
                onButtonClicked={clickTableButton}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
        {!connections.length && !isAddService && (
          <NoEntries
            onClick={() => setIsAddService(true)}
            bodyText="Service connection represents a grouping of associated cloud partner like Azure, AWS and Google. It contains predefined service configuration template."
            headingText={'No Service Connections are Created'}
            buttonText={'Add Service Connection'}
          />
        )}
        <ResultStatus
          showStatus={!!resultState?.display}
          alertMessage={resultState?.message}
          severtiy={resultState?.result}
          closeStatus={() => setResultState({ display: undefined, message: undefined, result: undefined })}
        />
      </Grid>
    </>
  )
}
