import { useEffect, useState } from 'react'
import { DataTable, DataTableBody, DataTableUpdateParams } from '../businessObjects'

import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import {
  createDataTable,
  dataTableServices,
  getAllDataTables,
  getDataTableByPodIdAndDataSystemId,
  getDataTablesById,
  getDataTablesByName,
} from '../apiServices/dataTable'
import { AxiosError, HttpStatusCode } from 'axios'

type dataPodId = string
type AddNewDataTable = (body: DataTableBody, datapodId: dataPodId) => Promise<void>

type dataTableId = number
type dataSystemId = number
type dataTableName = string
type AllParams = dataPodId | dataSystemId
type NameParams = dataTableName | dataPodId
type DataTableParams = AllParams | NameParams | dataTableId
type GetDataTables = (params: DataTableParams, dataTableId: dataTableId) => Promise<void>

export const useAddNewDataTable = (): [
  AddNewDataTable,
  DataTableBody | undefined,
  boolean | undefined,
  boolean | undefined,
] => {
  const [newDataTable, setNewDataTable] = useState<DataTable>()
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const addNewDataTable = async (params: DataTableBody, datapodId: dataPodId): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await createDataTable(bearerToken, params, datapodId)
        // console.log(data)
        if (status == 200) {
          //console.log(status)
          setNewDataTable(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setCreateError(true)
      setLoading(false)
    }
  }
  return [addNewDataTable, newDataTable, loading, createError]
}
export const useGetDataTables = (
  byId: boolean,
  byDatapodId: boolean,
): [GetDataTables, DataTable[] | undefined, boolean | undefined, boolean | undefined] => {
  const [dataTables, setDataTables] = useState<DataTable[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchDataTables = async (params: DataTableParams, dataTableID?: dataTableId): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        if (byId && typeof params == 'number' && !byDatapodId) {
          const { data, status } = await getDataTablesById(bearerToken, params)
          if (status == 200) {
            //console.log(status)
            setDataTables(data)
            setLoading(false)
          }
        } else if (!byId && typeof params == 'string' && !byDatapodId) {
          const { data, status } = await getDataTablesByName(bearerToken, params)
          if (status == 200) {
            //console.log(status)
            setDataTables(data)
            setLoading(false)
          }
        } else if (typeof params == 'string' && byDatapodId) {
          const { data, status } = await getAllDataTables(bearerToken, params)
          if (status == 200) {
            //console.log(status)
            setDataTables(data)
            setLoading(false)
          }
        } else if (typeof params == 'string' && byDatapodId && dataTableID) {
          const { data, status } = await getAllDataTables(bearerToken, params, dataTableID)
          if (status == 200) {
            //console.log(status)
            setDataTables(data)
            setLoading(false)
          }
        }
      }
    } catch (error) {
      setCreateError(true)
      setLoading(false)
    }
  }
  return [fetchDataTables, dataTables, loading, createError]
}

export const useGetAllDataTables = (
  dataPodId?: string,
  dataSystemID?: number,
  searchText?: string,
  dataSystemType?: number,
): { dataTables?: DataTable[]; loading?: boolean; error?: boolean } => {
  const [dataTables, setDataTables] = useState<DataTable[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  // console.log({ dataPodId }, { dataSystemID })
  const fetchDataTables = async (): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await getAllDataTables(
          bearerToken,
          dataPodId,
          dataSystemID,
          searchText,
          dataSystemType,
        )
        if (status == 200) {
          //console.log(status)
          setDataTables(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }
  useEffect(() => {
    fetchDataTables()
  }, [dataPodId, dataSystemID, searchText, dataSystemType])
  return { dataTables: dataTables, loading, error }
}

export const useGetTableByDataPodAndDataSystemId = (dataPodId?: string, dataSystemID?: number, searchText?: string) => {
  const [dataTables, setDataTables] = useState<DataTable[]>([])

  const { getBearerToken } = useLucidAuthContext()

  const fetchDataTables = async (): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && dataSystemID) {
        const { data, status } = await getDataTableByPodIdAndDataSystemId(
          bearerToken,
          dataPodId,
          dataSystemID,
          searchText,
        )
        if (status == 200) {
          //console.log(status)
          setDataTables(data)
        }
      }
    } catch (error) {
      const { message } = error as AxiosError
      console.log({ message })
    }
  }
  useEffect(() => {
    fetchDataTables()
  }, [dataPodId, dataSystemID, searchText])
  return { dataTables: dataTables, refetchDataTables: fetchDataTables }
}

export const useGetAllDataTablesProfiling = (): // dataPodId?: string,
// dataSystemID?: number,
// searchText?: string,
// dataSystemType?: number,
{
  fetchDataTables: (dataPodId?: string, dataSystemID?: number, searchText?: string, dataSystemType?: number) => void
  dataTables?: DataTable[]
  loading?: boolean
  error?: boolean
} => {
  const [dataTables, setDataTables] = useState<DataTable[]>()
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  // console.log({ dataPodId }, { dataSystemID })
  const fetchDataTables = async (
    dataPodId?: string,
    dataSystemID?: number,
    searchText?: string,
    dataSystemType?: number,
  ): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await getAllDataTables(
          bearerToken,
          dataPodId,
          dataSystemID,
          searchText,
          dataSystemType,
        )
        if (status == 200) {
          //console.log(status)
          setDataTables(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }
  // useEffect(() => {
  //   fetchDataTables()
  // }, [fetchDataTables,dataPodId, dataSystemID, searchText, dataSystemType])
  return { fetchDataTables, dataTables: dataTables, loading, error }
}

export const useGetAllDataTablesByDataSourceId = (dataPodId?: string, dataSystemID?: number) => {
  const [dataTables, setDataTables] = useState<DataTable[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [error, setError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  // console.log({ dataPodId }, { dataSystemID })
  const fetchDataTables = async (searchText?: string): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && dataSystemID) {
        const { data, status } = await getAllDataTables(bearerToken, dataPodId, dataSystemID, searchText, undefined)
        if (status == 200) {
          //console.log(status)
          // const sortedTableData: DataTable[] = data
          setDataTables(data)
          setLoading(false)
        }
      }
    } catch (error) {
      setError(true)
      setLoading(false)
    }
  }

  useEffect(() => {
    if (dataPodId && dataSystemID) {
      fetchDataTables()
    }
  }, [dataPodId, dataSystemID])

  return { fetchDataTables, dataTables: dataTables, loading, error }
}

export const useUpdateDataTable = (datapodId?: string) => {
  const [updatingDataTable, setUpdatingDataTable] = useState<boolean>(false)
  const [updateDataTableStatus, setUpdateDataTableStatus] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()

  const confirmUpdateMultipleTable = async (reqUpdateDataTable: DataTableUpdateParams[]) => {
    try {
      const token = await getBearerToken()
      setUpdatingDataTable(true)
      let allSuccess = []
      for (let i = 0; i <= reqUpdateDataTable.length; i++) {
        const req = reqUpdateDataTable[i]

        if (token && datapodId) {
          const { status } = await dataTableServices.updateDataTable(token, datapodId, req)
          if (status == HttpStatusCode.Ok) {
            allSuccess[i] = req.id
          }
        }
      }
      if (allSuccess.length === reqUpdateDataTable.length) {
        setUpdatingDataTable(false)
        setUpdateDataTableStatus(true)
      }
    } catch (error) {
      setUpdatingDataTable(false)
      console.log({ error })
    }
  }

  const confirmUpdateTable = async (reqUpdateDataTable: DataTableUpdateParams) => {
    try {
      const token = await getBearerToken()
      setUpdatingDataTable(true)

      if (token && datapodId) {
        const { status } = await dataTableServices.updateDataTable(token, datapodId, reqUpdateDataTable)
        setUpdatingDataTable(false)
        return status == HttpStatusCode.Ok
      }
    } catch (error) {
      setUpdatingDataTable(false)
      console.log({ error })
    }
  }
  return { confirmUpdateMultipleTable, confirmUpdateTable, updatingDataTable, updateDataTableStatus }
}
