import { useEffect, useState } from 'react'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { attributeProfileServices } from '../apiServices/attributeProfile'
import { dataAttributesServices, getDataAttributesByEntityId } from '../apiServices/dataAttributes'
import { entityServices } from '../apiServices/entities'
import { genServices } from '../apiServices/lucidGenAIServices'
import {
  ApiStatus,
  AttributeColumnDetail,
  CustomEntityData,
  DataAttribute,
  DataAttributeMappingRequest,
  DataColumn,
  DataTable,
  DataTableUpdateParams,
  GenAIApiStatus,
  GenAiGenerateMappingResponse,
  GenAttributeDataTypes,
  NewAttribute,
  NewAttributeMapBody,
  NewEntityData,
  NewEntityMap,
  NewEntityMapBody,
} from '../businessObjects'
import { getDataColumnByName } from '../apiServices/dataColumn'
import { genDatapodServices } from 'apiServices/genAiDatapodServices'
import { dataTableServices } from 'apiServices/dataTable'
import { AxiosError, HttpStatusCode } from 'axios'
import { useAppDispatch } from 'app/hooks'
import { addGenAIApiStatus } from 'features/notification/notificationSlice'
import { environment } from 'utils/environment'

type DataProfilingBody = {
  dataPodId: string
  dataSystemId: number
  dataTableId?: number
}

export const useGenerateMappings = (dataPodId?: string, dataSystemId?: number) => {
  const [generating, setGenerating] = useState<boolean>(false)
  const [generatingError, setGeneratingError] = useState<boolean>(false)
  const [generateSuccess, setGenerateSuccess] = useState<boolean>(false)
  const [data, setData] = useState<AttributeColumnDetail[]>([])
  const [genAIApiStatus, setGenAIApiStatus] = useState<GenAIApiStatus>()
  const [reqBody, setReqBody] = useState<DataProfilingBody>()
  const dispatch = useAppDispatch()

  const token = 'Lucid123'
  const { getBearerToken, accountId } = useLucidAuthContext()

  const scanAndGen = async (tables: DataTable[]) => {
    const genAIApiStatusTemp: GenAIApiStatus = {
      GenAIApiStatusId: Date.now(),
      title: 'Generating mappings',
      status: ApiStatus.Initiated,
      description: 'Initiated generate mappings',
      apiUrl: 'API URL',
    }

    setGenAIApiStatus(genAIApiStatusTemp)
    setGeneratingError(false)
    setGenerateSuccess(false)

    try {
      const token = await getBearerToken()
      if (token && dataPodId && dataSystemId && tables.length) {
        setGenerating(true)
        const dataTableId = tables[0].id
        setReqBody({
          dataPodId,
          dataSystemId,
          dataTableId,
        })
        genAIApiStatusTemp.GenAIApiStatusId = Date.now()
        genAIApiStatusTemp.description = 'Tables: ' + tables.map((table) => table.name).join(', ')

        setGenAIApiStatus(genAIApiStatusTemp)
        dispatch(addGenAIApiStatus(genAIApiStatusTemp))

        const { data, status } = await genServices.generateMappings(dataPodId, dataSystemId, tables[0].id)
        if (status == 200) {
          const genMapp: GenAiGenerateMappingResponse = data
          await loadDataToDb(token, dataPodId, dataTableId, genMapp)
          dispatch(
            addGenAIApiStatus({
              ...genAIApiStatusTemp,
              description: 'Mappings generated successfully.',
              status: ApiStatus.Success,
            }),
          )
        }
      }
    } catch (error: any) {
      if (genAIApiStatusTemp)
        dispatch(
          addGenAIApiStatus({
            ...genAIApiStatusTemp,
            description: 'Failed to load mappings.',
            status: ApiStatus.Failed,
          }),
        )

      setGeneratingError(true)
      setGenerateSuccess(false)
      setGenerating(false)
    }
  }

  const loadDataToDb = async (
    token: string,
    dataPodId: string,
    dataTableId: number,
    genMapp: GenAiGenerateMappingResponse,
  ) => {
    try {
      const entityPayload: NewEntityData = {
        dataEntityName: genMapp.data[0].entity_name,
        profileName: '',
        description: genMapp.data[0].entity_description,
        coreEntity: false,
        entityType: genMapp.data[0].entity_type,
        dataLakeZone: 0,
      }

      const entity: NewEntityMap | undefined = await entityMapToTable(token, dataPodId, dataTableId, entityPayload)

      if (!entity) {
        console.log('No entity Id')
        if (genAIApiStatus)
          dispatch(
            addGenAIApiStatus({
              ...genAIApiStatus,
              description: 'Failed to add entity to Database.',
              status: ApiStatus.Failed,
            }),
          )
        return
      }

      const attributeColumnDetails: AttributeColumnDetail[] = []
      const resLength = genMapp.data[0].columns.length

      for (let i = 0; i < resLength; i++) {
        const item = genMapp.data[0]
        const temp: AttributeColumnDetail = {
          column: item.columns[i], // Adjust indexing as needed
          attribute: item.attributes[i],
          attribute_description: item.attribute_description[i],
          dataClassification: item.dataClassification[i],
          entity_name: item.entity_name,
          table_name: item.table_name,
          entity_description: item.entity_description,
          entity_type: item.entity_type,
        }

        attributeColumnDetails.push(temp)
      }

      const attrMappingPayload: DataAttributeMappingRequest = {
        attributeColumnDetails,
        dataTableId: dataTableId,
        dataEntityId: entity.dataEntityId,
        dataEntityMapId: entity.id,
      }

      //Map Attrs to the columns
      const attrMappings = await attributesMapToColumns(token, dataPodId, attrMappingPayload)

      if (!attrMappings) {
        console.log('Failed to load to database.')
      }

      const genDataAttType: GenAttributeDataTypes = {
        dataPodId: dataPodId,
        dataEntityId: entity.dataEntityId,
        dataTableId: dataTableId,
      }

      if (genAIApiStatus) {
        dispatch(addGenAIApiStatus({ ...genAIApiStatus, status: ApiStatus.Success }))
      }

      const { status } = await genDatapodServices.generateAttributeDataTypes('Lucid123', genDataAttType)

      if (status == 200) {
        console.log('generateAttributeDataTypes success')
      }

      setGenerateSuccess(true)
      setGeneratingError(false)
      setGenerating(false)
    } catch (error) {
      console.log(error)

      if (genAIApiStatus) {
        dispatch(
          addGenAIApiStatus({
            ...genAIApiStatus,
            description: 'Failed to load data to database.',
            status: ApiStatus.Failed,
          }),
        )
      }

      setGeneratingError(true)
      setGenerateSuccess(false)
      setGenerating(false)
    }
  }

  const entityMapToTable = async (
    token: string,
    dataPodId: string,
    dataTableId: number,
    requestBody: NewEntityData,
  ) => {
    const { data, status } = await entityServices.addOrGetEntityWithMap(token, dataPodId, dataTableId, requestBody)

    if (status == 200) {
      return data as NewEntityMap
    }
  }

  const attributesMapToColumns = async (token: string, dataPodId: string, requestBody: DataAttributeMappingRequest) => {
    //Pass the columns and attrNames to server to map and return status

    // DataAttributes/addMultipleAttributeWithMapping

    const { data, status } = await attributeProfileServices.addMultipleAttributeWithMapping(
      token,
      dataPodId,
      requestBody,
    )
    if (status == 200) {
      return data
    }
  }

  return { generatingError, generating, generateSuccess, scanAndGen, data }
}

export const useScanAndGenerateMappings = (dataPodId?: string, dataSystemId?: number) => {
  //Redux variables
  const dispatch = useAppDispatch()

  const [generating, setGenerating] = useState<boolean>(false)
  const [generatingError, setGeneratingError] = useState<boolean>(false)
  const [generateSuccess, setGenerateSuccess] = useState<boolean>(false)
  const [genAIApiStatus, setGenAIApiStatus] = useState<GenAIApiStatus>()
  const [entityReused, setEntityResused] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()
  const [data, setData] = useState<AttributeColumnDetail[]>([])
  const [reqBody, setReqBody] = useState<DataProfilingBody>()
  const token = 'Lucid123'
  const [dataTableId, setDataTableId] = useState<number>()

  // const fetchStream = async () => {
  //   try {
  //     const reqUrl = 'http://127.0.0.1:5000/' + 'DataPod/' + 'GenerateMappingsByColumn'
  //     const response = await fetch(reqUrl, {
  //       method: 'POST',
  //       headers: {
  //         'Authorization': token,
  //         'Content-Type': 'application/json'
  //       },
  //       body: JSON.stringify({...reqBody})
  //     });
  //     console.log(response,response.body)
  //     if(response){
  //       setGenerating(false)
  //     }
  // if (!response.ok) {
  //   console.error('Error: ', response.statusText);
  //   return;
  // }

  // const reader = response.body.getReader();
  // const decoder = new TextDecoder();

  // while (true) {
  //   const { done, value } = await reader.read();
  //   if (done) break;
  //   const chunk = decoder.decode(value);
  //   setData((prevData) => [...prevData, chunk]);
  // }
  //     } catch (error) {
  //       console.error('Fetch error:', error);
  //     }
  //   };

  //   fetchStream();
  // }, [generating]);

  useEffect(() => {
    let buffer = ''
    let num = 1

    const fetchStream = async () => {
      try {
        if (reqBody) {
          const reqUrl = environment.lucidGenApi + 'DataPod/' + 'GenerateMappingsByColumn'
          // const reqUrl = 'http://127.0.0.1:5000/DataPod/GenerateMappingsByColumn'
          const response = await fetch(reqUrl, {
            method: 'POST',
            headers: {
              Authorization: token,
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ ...reqBody }),
          })

          if (!response.ok) {
            console.error('Error: ', response.statusText)

            if (genAIApiStatus) {
              dispatch(addGenAIApiStatus({ ...genAIApiStatus, status: ApiStatus.Failed }))
            }
            setGenerating(false)

            return
          }
          if (response.body) {
            const reader = response.body.getReader()
            const decoder = new TextDecoder()

            let mappings: AttributeColumnDetail[] = []

            while (true) {
              const { done, value } = await reader.read()
              if (done) {
                if (genAIApiStatus) {
                  dispatch(addGenAIApiStatus({ ...genAIApiStatus, status: ApiStatus.Success }))
                }
                setGenerating(false)

                break
              }
              const chunk = decoder.decode(value, { stream: true })

              buffer += chunk

              // const dataRemoved = chunk

              // const lines = buffer;
              // // const dataRemoved = lines.split('data: ')[1]

              // const jsonStringArray = dataRemoved.slice(1, -1).split(/,(?=\{)/);

              // const result = jsonStringArray[0].split("',").map((res:any) => {
              //    return JSON.parse(res.split("'")[1])
              // })
              // buffer = lines[lines.length - 1];
              // setData(result)

              console.log(chunk)
            }

            // if(buffer.length > 0) {
            //   console.log(buffer)
            //   const lines = buffer.split('data: ')

            //   buffer = lines[lines.length - 1]

            //   // console.log(buffer)

            //   const jsonStringArray = buffer.slice(1, -1).split(/,(?=\{)/)

            //   // console.log(jsonStringArray)

            //   const result = jsonStringArray[0].split("',").map((res: any) => {
            //     return JSON.parse(res.split("'")[1])
            //   })

            //   // console.log(result)
            //   setData(result)

            //   // mappings = result
            // }

            if (mappings.length > 0 && dataPodId && dataTableId) {
              const requestBody: DataAttributeMappingRequest = {
                dataTableId,
                dataEntityId: 0,
                dataEntityMapId: 0,
                attributeColumnDetails: mappings,
              }

              // console.log(mappings)
              // saveGenerateMappings(dataPodId, requestBody)
            }
          }
        }
      } catch (error) {
        console.error('Fetch error:', error)

        console.log(buffer)
        if (genAIApiStatus) {
          dispatch(addGenAIApiStatus({ ...genAIApiStatus, status: ApiStatus.Failed }))
        }
        setGenerating(false)
      }
    }

    if (generating == true) {
      fetchStream()
    }
  }, [generating])

  // useEffect(() => {
  //   if(data.length > 0 && generating == false && dataPodId && dataTableId){
  //     const requestBody : DataAttributeMappingRequest = {
  //       dataTableId,
  //       dataEntityId: 0,
  //       dataEntityMapId: 0,
  //       attributeColumnDetails: data
  //     }

  //     saveGenerateMappings(dataPodId, requestBody)
  //   }
  // }, [data, generating])

  const scanAndGen = async (tables: DataTable[]) => {
    const genAIApiStatusTemp: GenAIApiStatus = {
      GenAIApiStatusId: Date.now(),
      title: 'Generating mappings',
      status: ApiStatus.Initiated,
      description: 'Initiated generate mappings',
      apiUrl: 'API URL',
    }

    setGenAIApiStatus(genAIApiStatusTemp)

    try {
      const token = await getBearerToken()
      if (token && dataPodId && dataSystemId && tables.length) {
        setGenerating(true)
        setDataTableId(tables[0].id)
        const dataTableId = tables[0].id
        setReqBody({
          dataPodId,
          dataSystemId,
          dataTableId,
        })
        genAIApiStatusTemp.GenAIApiStatusId = Date.now()
        genAIApiStatusTemp.description = 'Tables: ' + tables.map((table) => table.name).join(', ')

        setGenAIApiStatus(genAIApiStatusTemp)
        dispatch(addGenAIApiStatus(genAIApiStatusTemp))

        // dispatch(addGenAIApiStatus({ ...genAIApiStatus, status: ApiStatus.Success }))

        // setGenerateSuccess(true)
        // setGeneratingError(false)
        // setGenerating(false)
      }
    } catch (error) {
      dispatch(addGenAIApiStatus({ ...genAIApiStatusTemp, status: ApiStatus.Failed }))
      setGeneratingError(true)
      setGenerateSuccess(false)
      setGenerating(false)
    }
  }

  const saveGenerateMappings = async (datapodId: string, requestBody: DataAttributeMappingRequest) => {
    try {
      const token = await getBearerToken()
      if (token && datapodId && requestBody && requestBody.attributeColumnDetails.length > 0) {
        const requestEntityBody: NewEntityData = {
          dataEntityName: requestBody.attributeColumnDetails[0].entity_name,
          profileName: '',
          description: requestBody.attributeColumnDetails[0].entity_description,
          coreEntity: false,
          entityType: requestBody.attributeColumnDetails[0].entity_type,
          dataLakeZone: 0,
        }

        // Map entity to the table
        const entity: NewEntityMap | undefined = await entityMapToTable(
          token,
          datapodId,
          requestBody.dataTableId,
          requestEntityBody,
        )

        if (!entity) {
          console.log('No entity Id')
          return
        }

        requestBody.dataEntityId = entity.dataEntityId
        requestBody.dataEntityMapId = entity.id

        //Map Attrs to the columns
        const attrMappings = await attributesMapToColumns(token, datapodId, requestBody)

        if (!attrMappings) {
          console.log('Failed to load attr mappings')
        }

        const genDataAttType: GenAttributeDataTypes = {
          dataPodId: datapodId,
          dataEntityId: entity.dataEntityId,
          dataTableId: requestBody.dataTableId,
        }

        if (genAIApiStatus) {
          dispatch(addGenAIApiStatus({ ...genAIApiStatus, status: ApiStatus.Success }))
        }

        const { status } = await genDatapodServices.generateAttributeDataTypes('Lucid123', genDataAttType)

        if (status == 200) {
          console.log('generateAttributeDataTypes success')
        }

        let entityCreatedOrExistingId = 0
        setGenerateSuccess(true)
        setGeneratingError(false)
        // setGenerating(false)
      }
    } catch (error) {
      console.log(error)

      if (genAIApiStatus) {
        dispatch(addGenAIApiStatus({ ...genAIApiStatus, status: ApiStatus.Failed }))
      }

      setGeneratingError(true)
      setGenerateSuccess(false)
      setGenerating(false)
    }
  }

  const entityMapToTable = async (
    token: string,
    dataPodId: string,
    dataTableId: number,
    requestBody: NewEntityData,
  ) => {
    const { data, status } = await entityServices.addOrGetEntityWithMap(token, dataPodId, dataTableId, requestBody)

    if (status == 200) {
      return data as NewEntityMap
    }
  }

  const attributesMapToColumns = async (token: string, dataPodId: string, requestBody: DataAttributeMappingRequest) => {
    //Pass the columns and attrNames to server to map and return status

    // DataAttributes/addMultipleAttributeWithMapping

    const { data, status } = await attributeProfileServices.addMultipleAttributeWithMapping(
      token,
      dataPodId,
      requestBody,
    )
    if (status == 200) {
      return data
    }
  }

  return { generatingError, generating, generateSuccess, scanAndGen, data }
}

export const scanAndGenerateSelectedTableId = async (
  tableIds: DataTable[],
  dataPodId: string,
  dataSystemId: number,
  token: string,
  updateSuccess: (success: boolean) => void,
  updateGenerating: (inProgress: boolean) => void,
  updateError: (err: boolean) => void,
): Promise<void> => {
  try {
    let allTableIdSuccess: number[] = []
    updateGenerating(true)
    for (let i = 0; i < tableIds.length; i++) {
      const tableToUpdate = tableIds[i]
      const tableId = tableIds[i].id
      if (token && dataPodId && dataSystemId && tableId) {
        const { data, status } = await genServices.generateMappings(dataPodId, dataSystemId, tableId)
        if (status == 200) {
          const genMapp: GenAiGenerateMappingResponse = data
          let entityCreatedOrExistingId = 0
          if (genMapp.data.length) {
            let allEntitySuccess: number[] = []
            let allAttributesSuccess: number[] = []
            const totalEntites = genMapp.data.length
            let totalAttributes = 0
            let isEntityReused = false
            for (let i = 0; i < genMapp.data.length; i++) {
              const nEntity = genMapp.data[i]
              let Response: CustomEntityData
              let status: any = 0
              let entityName: string = nEntity.entity_name
              entityName = entityName.replace(/\s/g, '')
              // console.log(entityName)
              const { data: dataEnt } = await entityServices.getEntityName(
                token,
                undefined,
                undefined,
                entityName,
                dataPodId,
                undefined,
                undefined,
                undefined,
                undefined,
              )
              if (dataEnt.message.includes('No Records Found') || dataEnt.dataEntities === null) {
                // console.log('inside if')
                const newEntity: NewEntityData = {
                  dataEntityName: entityName,
                  description: nEntity.entity_description,
                  coreEntity: false,
                  entityType: nEntity.entity_type,
                  profileName: 'generated',
                  dataLakeZone: 1,
                }
                const { data: entityRes, status: newEntityStatus } = await entityServices.addNewCustomEntity(
                  token,
                  dataPodId,
                  newEntity,
                )
                Response = entityRes
                status = newEntityStatus
                isEntityReused = false
              } else {
                console.log('Inside else')
                Response = dataEnt.dataEntities[0]
                isEntityReused = true
              }
              if (Response) {
                const newEnt: CustomEntityData = Response
                entityCreatedOrExistingId = newEnt.id
                const nEntMap: NewEntityMapBody = {
                  dataEntityId: newEnt.id,
                  dataTableId: tableId,
                  runId: 0,
                  profileName: 'generated',
                }
                const { data: mapRes, status: mapStatus } = await entityServices.addEntityMap(token, dataPodId, nEntMap)
                if (
                  mapStatus == 200 &&
                  nEntity.attributes.length === nEntity.attribute_description.length &&
                  nEntity.attributes.length === nEntity.columns.length
                ) {
                  const entMap: NewEntityMap = mapRes
                  allEntitySuccess = [...allEntitySuccess, newEnt.id]
                  totalAttributes = nEntity.attributes.length

                  for (let ati = 0; ati < nEntity.attributes.length; ati++) {
                    let attributeName: string = nEntity.attributes[ati]
                    attributeName = attributeName.replace(/\s/g, '')
                    const attributeDetails: NewAttribute = {
                      dataEntityId: newEnt.id,
                      dataAttributeName: attributeName,
                      dataAttributeDataType: 'string',
                      description: nEntity.attribute_description[ati],
                      isBusinessKey: false,
                      dataClassification: nEntity.dataClassification[ati],
                      dataAttributeType: 'simple',
                    }
                    const { data: columData, status: colStatus } = await getDataColumnByName(
                      token,
                      nEntity.columns[ati],
                      dataPodId,
                      tableId,
                    )
                    const dataTableColumn: DataColumn[] = columData
                    const { data: exDataAttributeList, status: foundAttributeStatus } =
                      await getDataAttributesByEntityId(token, newEnt.id)
                    const foundDataAttributes: DataAttribute[] = exDataAttributeList

                    if (colStatus == 200 && dataTableColumn.length) {
                      if (
                        !foundDataAttributes.some((at) => at.dataAttributeName === attributeDetails.dataAttributeName)
                      ) {
                        let attributeResponse: DataAttribute
                        const { data: attr } = await dataAttributesServices.getDataAttributeName(
                          token,
                          dataPodId,
                          attributeDetails.dataAttributeName,
                          attributeDetails.dataEntityId,
                        )
                        if (isEntityReused) {
                          if (attr.message.includes('No Records found') || attr.dataAttributes === null) {
                            const { data: attrResp, status: attribStatus } =
                              await dataAttributesServices.createDataAttribute(token, attributeDetails, dataPodId)
                            attributeResponse = attrResp
                          } else {
                            attributeResponse = attr.dataAttributes[0]
                          }
                        } else {
                          const { data: attrResp, status: attribStatus } =
                            await dataAttributesServices.createDataAttribute(token, attributeDetails, dataPodId)
                          attributeResponse = attrResp
                        }
                        if (attributeResponse) {
                          const dAtt: DataAttribute = attributeResponse
                          const attrMap: NewAttributeMapBody = {
                            dataTableId: tableId,
                            dataEntityId: newEnt.id,
                            dataAttributeId: dAtt.id,
                            dataColumnId: dataTableColumn[0].id,
                            dataEntityMapId: entMap.id,
                            runid: 0,
                          }
                          const { data: attrMapRes, status: attrMapStatus } =
                            await attributeProfileServices.addNewAttributeMap(token, dataPodId, attrMap)
                          if (attrMapStatus == 200) {
                            allAttributesSuccess = [...allAttributesSuccess, dAtt.id]
                          }
                        }
                      } else {
                        const exAttrb = foundDataAttributes.find(
                          (at) => at.dataAttributeName === attributeDetails.dataAttributeName,
                        )
                        if (exAttrb?.id) {
                          const exAttrMap: NewAttributeMapBody = {
                            dataTableId: tableId,
                            dataEntityId: newEnt.id,
                            dataAttributeId: exAttrb.id,
                            dataColumnId: dataTableColumn[0].id,
                            dataEntityMapId: entMap.id,
                            runid: 0,
                          }
                          const { data: attrMapRes, status: attrMapStatus } =
                            await attributeProfileServices.addNewAttributeMap(token, dataPodId, exAttrMap)
                          if (attrMapStatus == 200) {
                            allAttributesSuccess = [...allAttributesSuccess, foundDataAttributes[0].id]
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            if (totalEntites === allEntitySuccess.length && totalAttributes === allAttributesSuccess.length) {
              const updateTableBody: DataTableUpdateParams = {
                id: tableToUpdate.id,
                name: tableToUpdate.name,
                containerDirectory: tableToUpdate.containerDirectory,
                dataSystemId: tableToUpdate.dataSystemId,
                description: tableToUpdate.description,
                profileStatus: tableToUpdate.profileStatus,
                mappingStatus: true,
                isActive: !!tableToUpdate.isActive,
              }
              const { status: updateTableStatus } = await dataTableServices.updateDataTable(
                token,
                dataPodId,
                updateTableBody,
              )
              // console.log({ updateTableStatus })

              if (entityCreatedOrExistingId != 0) {
                const genDataAttType: GenAttributeDataTypes = {
                  dataPodId: dataPodId,
                  dataEntityId: entityCreatedOrExistingId,
                  dataTableId: tableId,
                }
                const { status } = await genDatapodServices.generateAttributeDataTypes('Lucid123', genDataAttType)
                if (updateTableStatus == HttpStatusCode.Ok && status == HttpStatusCode.Ok) {
                  allTableIdSuccess = [...allTableIdSuccess, tableId]
                }
                // const genBusinessKey: GenBusinessKey = {
                //   dataPodId: dataPodId,
                //   entityId: entityCreatedOrExistingId,
                // }
                // const {} = await genDatapodServices.generateBusiniessKey('Lucid123', genBusinessKey)
              }
            }
          }
        }
      }
    }
    if (allTableIdSuccess.length === tableIds.length) {
      updateSuccess(true)
      updateError(false)
      updateGenerating(false)
    }
  } catch (error) {
    updateError(true)
    updateSuccess(false)
    updateGenerating(false)
  }
}

export const scanAndGenerateSelectedTableIdFromGenAI = async (
  tableIds: DataTable[],
  dataPodId: string,
  dataSystemId: number,
  token: string,
  data: GenAiGenerateMappingResponse,
  updateSuccess: (success: boolean) => void,
  updateGenerating: (inProgress: boolean) => void,
  updateError: (err: boolean) => void,
): Promise<boolean | undefined> => {
  try {
    let allTableIdSuccess: number[] = []
    updateGenerating(true)
    for (let i = 0; i < tableIds.length; i++) {
      const tableToUpdate = tableIds[i]
      const tableId = tableIds[i].id
      if (token && dataPodId && dataSystemId && tableId && data) {
        // const { data, status } = await genServices.generateMappings(dataPodId, dataSystemId, tableId)
        // if (status == 200) {
        const genMapp: GenAiGenerateMappingResponse = data
        let entityCreatedOrExistingId = 0
        if (genMapp.data.length) {
          let allEntitySuccess: number[] = []
          let allAttributesSuccess: number[] = []
          const totalEntites = genMapp.data.length
          let totalAttributes = 0
          let isEntityReused = false
          for (let i = 0; i < genMapp.data.length; i++) {
            const nEntity = genMapp.data[i]
            let Response: CustomEntityData
            let status: any = 0
            let entityName: string = nEntity.entity_name
            entityName = entityName.replace(/\s/g, '')
            // console.log(entityName)
            const { data: dataEnt } = await entityServices.getEntityName(
              token,
              undefined,
              undefined,
              entityName,
              dataPodId,
              undefined,
              undefined,
              undefined,
              undefined,
            )
            if (dataEnt.message.includes('No Records Found') || dataEnt.dataEntities === null) {
              // console.log('inside if')
              const newEntity: NewEntityData = {
                dataEntityName: entityName,
                description: nEntity.entity_description,
                coreEntity: false,
                entityType: nEntity.entity_type,
                profileName: 'generated',
                dataLakeZone: 1,
              }
              const { data: entityRes, status: newEntityStatus } = await entityServices.addNewCustomEntity(
                token,
                dataPodId,
                newEntity,
              )
              Response = entityRes
              status = newEntityStatus
              isEntityReused = false
            } else {
              console.log('Inside else')
              Response = dataEnt.dataEntities[0]
              isEntityReused = true
            }
            if (Response) {
              const newEnt: CustomEntityData = Response
              entityCreatedOrExistingId = newEnt.id
              const nEntMap: NewEntityMapBody = {
                dataEntityId: newEnt.id,
                dataTableId: tableId,
                runId: 0,
                profileName: 'generated',
              }
              const { data: mapRes, status: mapStatus } = await entityServices.addEntityMap(token, dataPodId, nEntMap)
              if (
                mapStatus == 200 &&
                nEntity.attributes.length === nEntity.attribute_description.length &&
                nEntity.attributes.length === nEntity.columns.length
              ) {
                const entMap: NewEntityMap = mapRes
                allEntitySuccess = [...allEntitySuccess, newEnt.id]
                totalAttributes = nEntity.attributes.length

                for (let ati = 0; ati < nEntity.attributes.length; ati++) {
                  let attributeName: string = nEntity.attributes[ati]
                  attributeName = attributeName.replace(/\s/g, '')
                  const attributeDetails: NewAttribute = {
                    dataEntityId: newEnt.id,
                    dataAttributeName: attributeName,
                    dataAttributeDataType: 'string',
                    description: nEntity.attribute_description[ati],
                    isBusinessKey: false,
                    dataClassification: nEntity.dataClassification[ati],
                    dataAttributeType: 'simple',
                  }
                  const { data: columData, status: colStatus } = await getDataColumnByName(
                    token,
                    nEntity.columns[ati],
                    dataPodId,
                    tableId,
                  )
                  const dataTableColumn: DataColumn[] = columData
                  const { data: exDataAttributeList, status: foundAttributeStatus } = await getDataAttributesByEntityId(
                    token,
                    newEnt.id,
                  )
                  const foundDataAttributes: DataAttribute[] = exDataAttributeList

                  if (colStatus == 200 && dataTableColumn.length) {
                    if (
                      !foundDataAttributes.some((at) => at.dataAttributeName === attributeDetails.dataAttributeName)
                    ) {
                      let attributeResponse: DataAttribute
                      const { data: attr } = await dataAttributesServices.getDataAttributeName(
                        token,
                        dataPodId,
                        attributeDetails.dataAttributeName,
                        attributeDetails.dataEntityId,
                      )
                      if (isEntityReused) {
                        if (attr.message.includes('No Records found') || attr.dataAttributes === null) {
                          const { data: attrResp, status: attribStatus } =
                            await dataAttributesServices.createDataAttribute(token, attributeDetails, dataPodId)
                          attributeResponse = attrResp
                        } else {
                          attributeResponse = attr.dataAttributes[0]
                        }
                      } else {
                        const { data: attrResp, status: attribStatus } =
                          await dataAttributesServices.createDataAttribute(token, attributeDetails, dataPodId)
                        attributeResponse = attrResp
                      }
                      if (attributeResponse) {
                        const dAtt: DataAttribute = attributeResponse
                        const attrMap: NewAttributeMapBody = {
                          dataTableId: tableId,
                          dataEntityId: newEnt.id,
                          dataAttributeId: dAtt.id,
                          dataColumnId: dataTableColumn[0].id,
                          dataEntityMapId: entMap.id,
                          runid: 0,
                        }
                        const { data: attrMapRes, status: attrMapStatus } =
                          await attributeProfileServices.addNewAttributeMap(token, dataPodId, attrMap)
                        if (attrMapStatus == 200) {
                          allAttributesSuccess = [...allAttributesSuccess, dAtt.id]
                        }
                      }
                    } else {
                      const exAttrb = foundDataAttributes.find(
                        (at) => at.dataAttributeName === attributeDetails.dataAttributeName,
                      )
                      if (exAttrb?.id) {
                        const exAttrMap: NewAttributeMapBody = {
                          dataTableId: tableId,
                          dataEntityId: newEnt.id,
                          dataAttributeId: exAttrb.id,
                          dataColumnId: dataTableColumn[0].id,
                          dataEntityMapId: entMap.id,
                          runid: 0,
                        }
                        const { data: attrMapRes, status: attrMapStatus } =
                          await attributeProfileServices.addNewAttributeMap(token, dataPodId, exAttrMap)
                        if (attrMapStatus == 200) {
                          allAttributesSuccess = [...allAttributesSuccess, foundDataAttributes[0].id]
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          if (totalEntites === allEntitySuccess.length && totalAttributes === allAttributesSuccess.length) {
            const updateTableBody: DataTableUpdateParams = {
              id: tableToUpdate.id,
              name: tableToUpdate.name,
              containerDirectory: tableToUpdate.containerDirectory,
              dataSystemId: tableToUpdate.dataSystemId,
              description: tableToUpdate.description,
              profileStatus: tableToUpdate.profileStatus,
              mappingStatus: true,
              isActive: !!tableToUpdate.isActive,
            }
            const { status: updateTableStatus } = await dataTableServices.updateDataTable(
              token,
              dataPodId,
              updateTableBody,
            )
            // console.log({ updateTableStatus })

            if (entityCreatedOrExistingId != 0) {
              const genDataAttType: GenAttributeDataTypes = {
                dataPodId: dataPodId,
                dataEntityId: entityCreatedOrExistingId,
                dataTableId: tableId,
              }
              const { status } = await genDatapodServices.generateAttributeDataTypes('Lucid123', genDataAttType)
              if (updateTableStatus == HttpStatusCode.Ok && status == HttpStatusCode.Ok) {
                allTableIdSuccess = [...allTableIdSuccess, tableId]
                return true
              } else {
                return false
              }
              // const genBusinessKey: GenBusinessKey = {
              //   dataPodId: dataPodId,
              //   entityId: entityCreatedOrExistingId,
              // }
              // const {} = await genDatapodServices.generateBusiniessKey('Lucid123', genBusinessKey)
            }
          }
        }
        // }
      }
    }
    if (allTableIdSuccess.length === tableIds.length) {
      updateSuccess(true)
      updateError(false)
      updateGenerating(false)
    }
  } catch (error) {
    updateError(true)
    updateSuccess(false)
    updateGenerating(false)
    return false
  }
}
