import {
  CardContent,
  CardHeader,
  Card,
  Grid,
  IconButton,
  Typography,
  TextField,
  Button,
  Chip,
  MenuItem,
  FormGroup,
  FormControlLabel,
  InputLabel,
  Select,
  FormControl,
  OutlinedInput,
  Container,
  Box,
} from '@mui/material'
import ActionPage from '../../../ui-components/ActionPage'
import { useEffect, useState } from 'react'
import { useParams, generatePath, useNavigate } from 'react-router-dom'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import { useGetServiceConnections } from '../../../hooks/serviceConnectionHooks'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import { useGetServiceConnection, usePostDataLake } from '../../../hooks/dataEngineeringHooks'
import { LucidDataGrid } from '../../../ui-components/LucidDataTable/LucidDatatable'
import { GridColDef, GridRowSelectionModel, GridValidRowModel } from '@mui/x-data-grid'
import ResultStatus from '../../../ui-components/ResultStatus'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { ROUTES } from '../../../Routes/constants'
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined'
import PendingActionsOutlinedIcon from '@mui/icons-material/PendingActionsOutlined'
import WarningOutlinedIcon from '@mui/icons-material/WarningOutlined'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { RootState } from 'app/store'
import { useGetAllEntitiesByDataPodId, useGetEntity } from 'hooks/entityHooks'
import { ResultStatusSeverity, RunNewJobParams, RunStatusResponse, ServiceConnections } from 'businessObjects'
import { filterActiveRunIds, initiateActiveRunId } from 'features/notification/notificationSlice'
import { usePostDatabricksJobRunNew } from 'hooks/databricksHooks'
import { useGetRunNewJobPayload, useRunNewJob } from 'hooks/executionHooks'

type Props = {}

type ViewResultsParams = {
  dataPodId: string
}
export const DataLakeResults = (props: Props) => {
  const { dataPodId } = useParams<ViewResultsParams>()
  const { dataLakeResults, dataLakeZoneResults, getDataLake } = useGetServiceConnections(dataPodId)
  const [showServiceConnectionPopup, setShowServiceConnectionPopup] = useState(false)
  const { getConnections } = useGetServiceConnection()
  const { addDataLakes, postDataLakeStatus, postError } = usePostDataLake(dataPodId || '')
  const [serviceConnections, setServiceConnections] = useState<any>()
  const [dataLakeZone, setDataLakeZone] = useState<string>('')
  const [tRows, setTRows] = useState<GridValidRowModel[]>([])
  const [selectedEntityIds, setSelectedEntityIds] = useState<GridRowSelectionModel>([])
  const [addedSuccess, setAddedSuccess] = useState<boolean>(false)
  const [selectedEntityType, setSelectedEntityType] = useState<number>(1)
  const [dataLakeLoad, setDataLakeLoad] = useState<boolean>(false)
  const [loadToWarehouse, setLoadToWarehouse] = useState<boolean>(false)
  const [runId, setRunId] = useState<number | string>()
  const [showResultStatus,setShowResultStatus] = useState<boolean>(false)
  const [severity, setSeverity] = useState<ResultStatusSeverity>('success')
  const [statusMessage, setStatusMessage] = useState<string>()

  const {runNewJob} = useRunNewJob()
  const { getRunNewJobPayload } = useGetRunNewJobPayload();

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const activeFabricRunStatus = useAppSelector((state: RootState) => state.notification.fabricRunStatus)
  const [fetchAllEntities, entity] = useGetAllEntitiesByDataPodId()
  const [entities] = useGetEntity({}, dataPodId, undefined, undefined, true)
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const tableColumns: GridColDef[] = [
    {
      field: 'entityId',
      headerName: 'Entity ID',
      flex: 10,
    },
    {
      field: 'entityName',
      headerName: 'Entity Name',
      flex: 10,
    },
    {
      field: 'dataTableName',
      headerName: 'Data Table Name',
      flex: 10,
    },
    {
      field: 'dataLakeZone',
      headerName: 'Datalake Zone',
      flex: 10,
    },
    {
      field: 'dataTableStatus',
      headerName: 'Data Table Status',
      flex: 10,
    },
  ]

  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }
  useEffect(() => {
    if (dataLakeResults?.length) {
      const tRows = dataLakeResults.map((entityData: any) => ({
        id: entityData.entityid,
        entityId: entityData.entityid,
        entityName: entityData.dataEntityName,
        dataTableName: entityData.dataTableName,
        dataLakeZone: entityData.dataLakeZone === 1 ? 'Canonical' : 'Enriched',
        dataTableStatus: entityData.dataTableStatus,
      }))
      setTRows(tRows)
    }
  }, [dataLakeResults])

  useEffect(() => {
    if (postError) {
      //Add/Edit - handling error message.
      setAddedSuccess(true)
    }
  }, [postError])

  useEffect(() => {
    if (dataLakeZoneResults?.length) {
      setDataLakeZone(dataLakeZoneResults[0].id)
    }
    if (postDataLakeStatus?.length && dataPodId) {
      setSelectedEntityIds([])
      setAddedSuccess(true)
    }
  }, [dataLakeZoneResults, postDataLakeStatus])

  useEffect(() => {
    if (dataPodId) {
      getConnections(dataPodId, undefined, 'DL')
        .then((response) => {
          if (response?.length) {
            setServiceConnections(response)
          }
        })
        .catch((error) => console.log(error))
    }
  }, [dataPodId])

  useEffect(() => {
    if (dataPodId) {
      getDataLake(dataPodId)
    }
  }, [postDataLakeStatus])

  const onSubmit = () => {
    setShowServiceConnectionPopup(!showServiceConnectionPopup)
  }

  // const onClickHome = () => {
  //   const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
  //   navigate(dataProfilingRoute)
  // }

  const handleSelect = (rowIds: GridRowSelectionModel) => {
    setSelectedEntityIds(rowIds)
  }

  const handleDeployDataLake = () => {
    if (selectedEntityIds && selectedEntityIds.length && serviceConnections?.length) {
      addDataLakes({
        entityids: selectedEntityIds,
        serviceConnectionId: serviceConnections[0]?.id,
        dataLakeZoneId: dataLakeZone,
      })
    }
  }

  useEffect(() => {
    if (activeRunIds && runId) {
      const temp = activeRunIds.find((element) => element.runId === runId)
      if (temp && temp.state.resultState === 'SUCCESS') {
        console.log({ temp })
        if (temp.runName?.includes('LDH_CanonicalLoad') && selectedEntityIds && dataPodId) {
          setDataLakeLoad(false)
          fetchAllEntities({}, dataPodId, undefined, undefined)
          setSelectedEntityIds([])
        } else if (temp.runName?.includes('LDH_LoadToDataWarehouse') && selectedEntityIds && dataPodId) {
          setLoadToWarehouse(false)
          setSelectedEntityIds([])
        }
      }
    }
  }, [activeRunIds])

  useEffect(() => {
    if (activeFabricRunStatus && runId) {
      const temp = activeFabricRunStatus.find((element) => element.id === runId)
      if (temp && temp.status === 'Completed') {
        if (temp.jobDetails?.triggerType === 'Data Lake Load' && selectedEntityIds && dataPodId) {
          setDataLakeLoad(false)
          fetchAllEntities({}, dataPodId, undefined, undefined)
          setSelectedEntityIds([])
        } else if (temp.jobDetails?.triggerType === 'Data Warehouse Load' && selectedEntityIds && dataPodId) {
          setLoadToWarehouse(false)
          setSelectedEntityIds([])
        }
      }
    }
  }, [activeFabricRunStatus])

  const runDatawarehouseLoadJob = async () => {
    if(dataPodId && selectedEntityIds.length) {
      const requestBody = {
        i_data_pod_id: dataPodId || '',
        i_data_entity_id: JSON.stringify(selectedEntityIds),
      };
      
      const jobParams = {
        name: selectedEntityIds
          .map((id) => {
            const dataEntity = entities.find((de) => de.id === id)
            return dataEntity ? dataEntity.dataEntityName : null
          })
          .filter((name) => name !== null)
          .join(', '),
        route: generatePath(ROUTES.DataLakeResults, {
          dataPodId: dataPodId,
        }),
      }
      try {
        const payload = await getRunNewJobPayload(dataPodId, requestBody, 'dataWarehouse_Job_Id');
        
        if(payload) {
          const runId = await runNewJob(activeRunIds, jobParams, 'Data Warehouse Load', payload);
          
          if(runId){
            setRunId(runId)
          }
        }
      } catch (error: any) {
        setStatusMessage(error.message)
        setShowResultStatus(true)
        setSeverity('error')
      }
    }
  };

  const runDataLakeNewJob = async () => {
    if(dataPodId && selectedEntityIds.length) {
      const requestBody = {
        i_data_pod_id: dataPodId || '',
        i_data_entity_id: JSON.stringify(selectedEntityIds),
      };
      
      const jobParams = {
        name: selectedEntityIds
          .map((id) => {
            const dataEntity = entities.find((de) => de.id === id)
            return dataEntity ? dataEntity.dataEntityName : null
          })
          .filter((name) => name !== null)
          .join(', '),
        route: generatePath(ROUTES.DataLakeResults, {
          dataPodId: dataPodId,
        }),
      }

      try {
        const payload = await getRunNewJobPayload(dataPodId, requestBody, 'dataCanonical_Job_Id');
        
        if(payload) {
          const runId = await runNewJob(activeRunIds, jobParams, 'Data Lake Load', payload);
          
          if(runId){
            setRunId(runId)
          }
        }
      } catch (error: any) { 
        setStatusMessage(error.message)
        setShowResultStatus(true)
        setSeverity('error')
      }
    }
  };

  // const entityTypes = [
  //   { id: 1, value: 'Canonical' },
  //   { id: 2, value: 'Enriched' },
  //   { id: 3, value: 'Custom Enriched' },
  // ]

  const renderDeploymentStatus = (value: any) => {
    if (value == 'ReDeploy')
      return (
        <Chip
          color="warning"
          icon={<PendingActionsOutlinedIcon fontSize="small" />}
          label={<Typography variant="caption">{value}</Typography>}
          variant="outlined"
        />
      )
    if (value == 'Deployed')
      return (
        <Chip
          color="success"
          icon={<CheckCircleOutlineOutlinedIcon fontSize="small" />}
          label={<Typography variant="caption">{value}</Typography>}
          variant="outlined"
        />
      )
    if (value == 'Not Deployed' || value == 'Not deployed')
      return (
        <Chip
          color="error"
          icon={<WarningOutlinedIcon fontSize="small" />}
          label={<Typography variant="caption">{value}</Typography>}
          variant="outlined"
        />
      )
    return (
      <Chip
        color="info"
        icon={<InfoOutlinedIcon fontSize="small" />}
        label={<Typography variant="caption">{value}</Typography>}
        variant="outlined"
      />
    )
  }

  return (
    <ActionPage>
      {/* <LucidPopUp
        openPopUp={showServiceConnectionPopup}
        onConfirm={onSubmit}
        closePopup={setShowServiceConnectionPopup}
        headingText={<Typography variant="h4">Service Connections</Typography>}
        disabledConfirm
        showCloseIcon
      >
        <Container maxWidth="lg">
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={9}>
                <pre style={{ whiteSpace: 'pre-wrap' }}>
                  {JSON.stringify(serviceConnections, null, 2)}
                </pre>
              </Grid>
            </Grid>
          </Box>
        </Container>
      </LucidPopUp> */}
      <Grid item container spacing={2} alignItems={'flex-start'} justifyContent={'center'}>
        <Grid item xs={12}>
          <Typography variant="h4" gutterBottom color="primary">
            DataLakes
          </Typography>
        </Grid>
        <Grid item xs={2} />
      </Grid>
      {/* <Grid item xs={0.5}>
        <IconButton onClick={onClickHome}>
          <HomeOutlinedIcon />
        </IconButton>
      </Grid> */}

      <ResultStatus
        severtiy={postError?.message ? 'error' : 'success'}
        showStatus={addedSuccess}
        closeStatus={() => {
          setAddedSuccess(false)
        }}
        alertMessage={postError?.message || 'Datalake pages have been deployed !'}
      />
      <Card sx={{ backgroundColor: 'white', boxShadow: 3, borderRadius: 3 }}>
        <Grid container columnSpacing={2}>
          <Grid item container spacing={2} sx={{ mt: 2 }} justifyContent={'center'}>
            {/* <Grid item xs={2}>
              <FormControl fullWidth size="small">
                <InputLabel id="demo-multiple-chip-label">DataLake Zone</InputLabel>
                <Select
                  labelId="demo-multiple-chip-label"
                  id="demo-multiple-chip"
                  value={selectedEntityType}
                  fullWidth
                  size="small"
                  onChange={({ target }) => setSelectedEntityType(target.value as number)}
                  input={<OutlinedInput id="select-multiple-chip" label="Data Lake Zone" />}
                  renderValue={(selected) => (
                    <Chip size="small" label={entityTypes.find((et) => et.id === selected)?.value} />
                  )}
                  MenuProps={MenuProps}
                >
                  {entityTypes.map((name, ix) => (
                    <MenuItem key={name.id} value={name.id}>
                      {name.value}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid> */}
            {/* <Grid item xs={3}>
              <Button fullWidth color="success" variant="contained" onClick={() => setShowServiceConnectionPopup(true)}>
                {' '}
                View Service Connection Details
              </Button>
            </Grid> */}
            <Grid item xs={6} />
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginLeft: 4 }}
                onClick={runDataLakeNewJob}
                disabled={!selectedEntityIds.length}
              >
                Load Datalake
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                variant="contained"
                color="primary"
                sx={{ marginLeft: 4 }}
                onClick={runDatawarehouseLoadJob}
                disabled={!selectedEntityIds.length}
              >
                Load Data Warehouse
              </Button>
            </Grid>
            <Grid item xs={12} />
          </Grid>
        </Grid>

        <Grid item xs={12} container spacing={2} alignItems={'center'}>
          <LucidDataGrid
            rows={tRows}
            columns={tableColumns}
            actions={{ checkable: true, editable: false, cellEditable: false }}
            onRowSelectionChanged={handleSelect}
            selectedRows={selectedEntityIds}
          />
        </Grid>

        <LucidPopUp
          openPopUp={showServiceConnectionPopup}
          onConfirm={onSubmit}
          closePopup={setShowServiceConnectionPopup}
          headingText={<Typography variant="h4">Service Connection Details</Typography>}
          disabledConfirm
          showCloseIcon
        >
          <Container maxWidth="lg">
            <Box>
              <Grid container spacing={2}>
                <Grid item xs={9}>
                  <pre style={{ whiteSpace: 'pre-wrap' }}>{JSON.stringify(serviceConnections, null, 2)}</pre>
                </Grid>
              </Grid>
            </Box>
          </Container>
        </LucidPopUp>
      </Card>
    </ActionPage>
  )
}
