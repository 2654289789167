import { Configuration, JobRequestParams, VnetConfig } from 'businessObjects'
import axios from 'axios'
import { environment } from 'utils/environment'
import { generatefabricToken } from 'utils/generateFabricToken'
import { sendRequest } from 'requestManager/sendRequest'

const vnetConfigPath = {
  getVnetConfiguration: "VnetConfig/getVnetConfig",
  addVnetConfiguration: "VnetConfig/addVnetConfig",
  updateVnetConfiguration: "VnetConfig/updateVnetConfig",
  deleteConfiguration: "",
}

const getVnetConfiguration = async ( token: string, accountId: string ) => {
  const path = vnetConfigPath.getVnetConfiguration + `?accountid=${accountId}`;
  
  return sendRequest(path, 'GET', token)
}

const addVnetConfiguration = async (token: string, config: VnetConfig) => {
  const path = vnetConfigPath.addVnetConfiguration;

  return sendRequest(path, 'POST', token, config);
}

const updateVnetConfiguration = async (token: string, config: VnetConfig) => {
  const path = vnetConfigPath.updateVnetConfiguration;

  return sendRequest(path, 'PUT', token, config);
}

const deleteConfiguration = async (token: string, id: number) => {
  const path = vnetConfigPath.deleteConfiguration + `ID=${id}`;

  return sendRequest(path, 'DELETE', token);
}

export const vnetConfigServices = {
  getVnetConfiguration,
  addVnetConfiguration,
  updateVnetConfiguration,
  deleteConfiguration,
}