import { LoadingButton } from '@mui/lab'
import { Box, Container, Grid, Typography } from '@mui/material'
import { createDataHubBody, DataPodInfo, getDataHubResponse, Industry, ResultStatusSeverity, updateDataHubBody } from 'businessObjects'
import { useAddDataHub, useGetDataHubById, useUpdateDataHub } from 'hooks/dataHubHooks'
import { useGetDataPodTypes } from 'hooks/datapodHooks'
import { useCreateIndustry } from 'hooks/industryHooks'
import { useLucidAuthContext } from 'LucidAuthContext/LucidAuthContext'
import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import ActionPage from 'ui-components/ActionPage'
import { PreviousPage } from 'ui-components/Button/PreviousPage'
import ResultStatus from 'ui-components/ResultStatus'
import TextFormInput from 'ui-components/TextForm/TextFormInput'
import { isValidEntry, isValidDescription } from 'utils/constants'

type ViewResultsParams = {
    dataHubId?: string
}

export const AddDataHubs = () => {
    const { dataHubId } = useParams<ViewResultsParams>();
    const [dataPodInfo, setDataPodInfo] = useState<getDataHubResponse>()
    const [pageHeader, setPageHeader] = useState<string>('Add Data Hub')

    const [editMode, setEditMode] = useState<boolean>(false)

    const { allDatapodTypes } = useGetDataPodTypes()

    const { accountId, lucidUserId } = useLucidAuthContext()

    const [submitNewdataHub] = useAddDataHub()

    const [updateDataHubById] = useUpdateDataHub()

    const navigate = useNavigate()

    const [fetchDataHubById, dataHubs] = useGetDataHubById()
    const [showResultStatus,setShowResultStatus] = useState<boolean>(false)
    const [severity, setSeverity] = useState<ResultStatusSeverity>('success')
    const [statusMessage, setStatusMessage] = useState<string>()
    const [saveOrEditLoading, setSaveOrEditLoading] = useState<boolean>(false)

    useEffect(() => {
        if (dataHubId?.length) {
            setPageHeader('Edit Data Hub')
            fetchAndSetDataHub()
        }
    }, [dataHubId])

    const changeDataPodInfo = (name: string, value: unknown) => {
        setDataPodInfo(
            (prev) =>
            ({
                ...prev,
                [name]: value,
            } as getDataHubResponse),

        )
        if (editMode) {
            setEditMode(false)
        }
    }

    const validateNameText = useMemo(() => {
        if (!dataPodInfo?.dataHubName) return
        return !isValidEntry(dataPodInfo?.dataHubName) ? 'Invalid Name' : undefined
    }, [dataPodInfo?.dataHubName])

    const validateDescText = useMemo(() => {
        if (!dataPodInfo?.dataHubDesc) return
        return !isValidDescription(dataPodInfo?.dataHubDesc) ? 'Invalid Entry' : undefined
    }, [dataPodInfo?.dataHubDesc])

    const handleSaveButtonClick = async () => {
        if (dataPodInfo && accountId && !dataHubId) {
            setSaveOrEditLoading(true)
            const params: createDataHubBody[] = [{
                dataHubName: dataPodInfo?.dataHubName,
                dataHubDesc: dataPodInfo.dataHubDesc,
                accountId: accountId
            }]
            const resp = await submitNewdataHub(params)
            if (resp && resp.flag) {
                setShowResultStatus(true)
                setSeverity('success')
                setStatusMessage('Data Hub Created')
                setSaveOrEditLoading(false)
                setDataPodInfo(undefined)
                if(resp.data && resp.data.id){
                    navigate(generatePath(ROUTES.ViewDataHub, {dataHubId:resp.data.id}))
                }
            } else {
                setShowResultStatus(true)
                setSeverity('error')
                setStatusMessage('Error while creating the data hub. Check the Hub Name if already exists')
                setSaveOrEditLoading(false)
            }
        }else if(dataPodInfo && accountId && dataHubId){
            const params:updateDataHubBody[] =[{
                id:dataHubId,
                dataHubName:dataPodInfo.dataHubName,
                dataHubDesc:dataPodInfo.dataHubDesc,
                accountId:accountId
            }]
            setSaveOrEditLoading(true)
            const resp = await updateDataHubById(params)
            if(resp && resp.includes('Update successful.')){
                setSeverity('success')
                setShowResultStatus(true)
                setStatusMessage(resp)
                setSaveOrEditLoading(false)
                navigate(generatePath(ROUTES.ViewDataHub, {dataHubId:dataHubId}))
            }else{
                setSeverity('error')
                setShowResultStatus(true)
                setStatusMessage(resp)
            }
        }

    }
    const fetchAndSetDataHub = async () => {
        if (dataHubId) {
            const dataHub = await fetchDataHubById(dataHubId)
            if (dataHub) {
                setDataPodInfo(dataHub)
                setEditMode(true)
            }
        }
    }
    return (
        <>
            <ActionPage>
                <ResultStatus
                    severtiy={severity}
                    showStatus={showResultStatus}
                    closeStatus={setShowResultStatus}
                    alertMessage={statusMessage}
                />
                <Grid container display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
                    <Grid item xs={2} ml={'10'}>
                        <Typography
                            variant='h3'
                            gutterBottom
                            color="primary"
                        >
                            {pageHeader}
                        </Typography>
                    </Grid>
                    <Grid item xs={3}>
                        <PreviousPage onClick={() => navigate(ROUTES.DataHubs)} pageName='Data Hubs Home' />
                    </Grid>
                </Grid>
                <Container maxWidth="lg">
                    <Box sx={{ mt: 10 }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Typography color={'text.primary'}>
                                    <span style={{ color: 'red' }}>*</span> Indicates a required field
                                </Typography>
                            </Grid>
                            <TextFormInput
                                value={dataPodInfo?.dataHubName ?? ''}
                                labelText="Name"
                                required
                                placeholderText="Enter Data Hub Name"
                                changeHandle={(podName) => changeDataPodInfo('dataHubName', podName)}
                                validateInput={!!validateNameText}
                                validateErrorText={validateNameText}
                                columnSize={12}
                            />
                            <TextFormInput
                                value={dataPodInfo?.dataHubDesc ?? ''}
                                labelText="Description"
                                required
                                placeholderText="Enter Data Hub Description"
                                changeHandle={(podDesc) => changeDataPodInfo('dataHubDesc', podDesc)}
                                validateInput={!!validateDescText}
                                validateErrorText={validateDescText}
                                multiline={4}
                            />
                        </Grid>
                        <Grid container display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                            <Grid item xs={2} mt={2}>
                                <LoadingButton
                                    color='primary'
                                    variant='contained'
                                    fullWidth
                                    onClick={handleSaveButtonClick}
                                    disabled={
                                        !dataPodInfo?.dataHubName ||
                                        !dataPodInfo.dataHubDesc ||
                                        !!validateNameText ||
                                        !!validateDescText ||
                                        editMode
                                    }
                                    loading={saveOrEditLoading}
                                >
                                    Save Data Hub
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Box>
                </Container>
            </ActionPage >
        </>
    )
}
