import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  Radio,
  RadioGroup,
  Skeleton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useAddSubjectArea, useDeleteSubjectAreaByID, useUpdateSubjectArea } from '../../hooks/subjectAreaHooks'
import { DataPodInfo, DataSystem, Industry, NewSubjectArea, SubjectArea, SubjectAreaTable } from '../../businessObjects'
import NoEntries from '../../ui-components/NoEntries'
import { LucidPopUp } from '../../ui-components/LucidPopUp/LucidPopUp'
import { DataItemAction, DataTableColumn, DataTableRow } from '../../ui-components/DataTable/DataTableTypes'
import DataTable from '../../ui-components/DataTable/DataTable'
import { ProgressBar } from '../../ui-components/ProgressBar'
import { isValidDescription, isValidEntry } from '../../utils/constants'
import AnimatedLoader from '../../ui-components/AnimatedLoader'
import LucidWidePopUp from '../../ui-components/LucidWidePopUp'
import ShadowTable from '../../ui-components/ShadowTable/ShadowTable'
import React from 'react'
import { AddOutlined, DeleteOutlineOutlined, EditOutlined, SendOutlined, TabUnselected } from '@mui/icons-material'
import saveAs from 'file-saver'
import { DataContextGenerateSampleData } from './DataContextGenerateSampleData'
import { useRecommendSubjectAreas, userGenerateSubjectAreas } from 'hooks/genAIApiHooks'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ResultStatus from 'ui-components/ResultStatus'
import { HeadingLabel } from 'ui-components/LucidTextLabel'

type Props = {
  //onChangeNewSubjectArea: (subjArea: NewSubjectArea) => void
  refetchSubjectAreas: () => Promise<void>
  dataPodId: string
  dataSystemsList: DataSystem[]
  currentDataPodInfo?: DataPodInfo
  subjectAreas: SubjectArea[]
  subjectAreaInp?: NewSubjectArea
  dataPodIndusty?: Industry
  deleteSubjArea: (delSubjId: number) => Promise<boolean>
}

export type RecommendedSubjectArea = NewSubjectArea & {
  id?: number
}

export const DataContextSubjectArea = ({
  subjectAreas,
  refetchSubjectAreas,
  dataSystemsList,
  dataPodId,
  dataPodIndusty,
  deleteSubjArea,
}: Props) => {
  //const [subjectAreaDetails, setSubjectAreaDetails] = useState<SubjectArea[]>([])
  const [openPop, setOpenPopUp] = useState<boolean>(false)
  const [editMode, setEditMode] = useState<boolean>(false)
  const [newSubjectArea, setNewSubjectArea] = useState<NewSubjectArea>()
  const [editSubjectArea, setEditSubjectArea] = useState<SubjectArea>()
  const [subjectAreaId, setSubjectAreaId] = useState<number>()
  const [subjectAreaList, setSubjectAreasList] = useState<SubjectArea[]>([])
  const [subjectTableRows, setSubjectRows] = useState<DataTableRow[]>([])
  const [isGenerateSampleData, setIsGenerateSampleData] = useState<boolean>(false)
  const [dynamicColumns, setDynamicColumns] = useState<DataTableColumn[]>([])
  const [showDetailsPopup, setShowDetailsPopup] = useState<boolean>(false)
  // const [recommendations, setRecommendations] = useState<RecommendedSubjectArea[]>([])
  const [combinedSubjectAreas, setCombinedSubjectAreas] = useState<RecommendedSubjectArea[]>([])
  // const [recommendationsRow, setRecommendationRows] = useState<DataTableRow[]>([])
  // const [recommendationError, setRecommendationError] = useState<boolean>(false)
  // const [loadingRecommendations, setLoadingRecommendations] = useState<boolean>(false)

  const recommendedColumns = useMemo(() => {
    return [{ label: 'Data Domain Name' }, { label: 'Description' }, { label: 'Actions' }]
  }, [])

  const [openWarning, setOpenWarning] = useState<boolean>(false)
  const [saveSubjectArea] = useAddSubjectArea()
  const [updateSubjectArea] = useUpdateSubjectArea()
  const [loading, setLoading] = useState<boolean>(false)
  // const { fetchSubjectAreas } = userGenerateSubjectAreas()
  const { recommendations, loadingRecommendations, recommendationError } = useRecommendSubjectAreas(
    dataPodId,
    dataPodIndusty,
  )

  // useEffect(() => {
  //   const subjectTableColumns: DataTableColumn[] = [
  //     { label: 'Data Domain Name' },
  //     { label: 'Description' },
  //     { label: 'Actions' },
  //   ]

  //   if (isGenerateSampleData) {
  //     subjectTableColumns.push({ label: 'Generate Sample Data' })
  //   }

  //   setDynamicColumns(subjectTableColumns)
  // }, [isGenerateSampleData])
  // const updateRecommendations = async () => {
  //   setLoadingRecommendations(true)
  //   const sa = await fetchSubjectAreas(dataPodId)
  //   if (sa?.result === 'Success') {
  //     setLoadingRecommendations(false)
  //     if (sa.data.length) {
  //       const newSubj: NewSubjectArea[] = sa.data.map((subj) => ({
  //         name: subj.SubjectAreaName,
  //         description: subj.SubjectAreaDescription,
  //         industryname: dataPodIndusty?.industryName ?? '',
  //         industryId: dataPodIndusty?.id ?? 0,
  //       }))
  //       setRecommendations(newSubj)
  //     } else {
  //       setRecommendationError(true)
  //     }
  //   }
  // }
  // useEffect(() => {
  //   if (!recommendations.length) {
  //     updateRecommendations()
  //   }
  // }, [dataPodId])

  const sortedDomains = (domain1: RecommendedSubjectArea, domain2: RecommendedSubjectArea) => {
    return domain1.id && domain2.id ? domain1.id - domain2.id : domain1.name > domain2.name ? 1 : -1
  }

  useEffect(() => {
    if (subjectAreas.length) {
      setSubjectAreasList(subjectAreas)
      const exSubjAreas = subjectAreas
        .map((sb) => ({
          id: sb.id,
          name: sb.subjectAreaName,
          description: sb.subjectAreaDesc,
          industryname: sb.industryName,
          industryId: sb.industryId,
        }))
        .sort(sortedDomains)

      // const filteredEx = recommendations.filter(
      //   (sb) => !subjectAreas.some((exsubj) => exsubj.subjectAreaName === sb.name),
      // )

      setCombinedSubjectAreas(exSubjAreas)
    }
  }, [subjectAreas])

  const filteredRecommendations = useMemo(() => {
    if (!subjectAreaList.length) return recommendations.sort(sortedDomains)
    return recommendations
      .filter((rc) => !subjectAreas.some((exs) => exs.subjectAreaName === rc.name))
      .sort(sortedDomains)
  }, [recommendations, subjectAreaList])

  const confirmSave = async () => {
    setLoading(true)
    if (newSubjectArea && dataPodId && dataPodIndusty) {
      const subjAreaParms: NewSubjectArea = {
        ...newSubjectArea,
        industryId: dataPodIndusty.id,
        industryname: dataPodIndusty.industryName,
      }
      await saveSubjectArea(dataPodId, subjAreaParms)
      await refetchSubjectAreas()
      // await updateRecommendations()
      setLoading(false)
    }
  }

  const confirmUpdate = async () => {
    if (editSubjectArea && dataPodId && newSubjectArea) {
      const toUpdate: SubjectArea = {
        ...editSubjectArea,
        subjectAreaName: newSubjectArea.name,
        subjectAreaDesc: newSubjectArea.description,
      }
      await updateSubjectArea(dataPodId, toUpdate)
      await refetchSubjectAreas()
      // await updateRecommendations()
    }
  }
  const handleNewSubj = (name: string, value: string) => {
    setNewSubjectArea(
      (prv) =>
        ({
          ...prv,
          [name]: value,
        } as NewSubjectArea),
    )
  }

  const clickSave = () => {
    if (!editSubjectArea) {
      confirmSave()
    } else {
      confirmUpdate()
      setEditMode(false)
    }
    setOpenPopUp(false)
  }

  const clickTableButton = (id: number, action: DataItemAction): void => {
    setSubjectAreaId(Number(id))

    if (action === 'edit') {
      setEditMode(true)
      const editSubjArea = subjectAreaList.find((sa) => sa.id === Number(id))
      setEditSubjectArea(editSubjArea)
      const editSubjectArea: NewSubjectArea = {
        name: editSubjArea?.subjectAreaName ?? '',
        industryId: editSubjArea?.industryId ?? 0,
        industryname: editSubjArea?.industryName ?? '',
        description: editSubjArea?.subjectAreaDesc ?? '',
      }
      setNewSubjectArea(editSubjectArea)
      setOpenPopUp(true)
    }

    if (action === 'delete') {
      setOpenWarning(true)
    }

    if (action === 'generate') {
      setShowDetailsPopup(true)
    }
  }

  const onClickAdd = () => {
    setNewSubjectArea(undefined)
    setOpenPopUp(true)
  }

  const onClosePopUp = () => {
    setOpenPopUp(false)
    setNewSubjectArea(undefined)
    setEditMode(false)
  }

  const confirmDelete = async () => {
    if (subjectAreaId) {
      const deleteSuccess = await deleteSubjArea(subjectAreaId)
      if (deleteSuccess) {
        setSubjectAreaId(undefined)
        setOpenWarning(false)
        const filteredRows = subjectTableRows.filter((r) => r.id != String(subjectAreaId))
        const filterdCurrentDomains = combinedSubjectAreas.filter((sa) => sa.id != subjectAreaId)
        setCombinedSubjectAreas(filterdCurrentDomains)
        setSubjectRows(filteredRows)
        setSubjectAreaId(undefined)
      }
    }
  }

  const disableUpdateSubmit = useMemo(() => {
    return !isValidDescription(newSubjectArea?.description) || !isValidEntry(newSubjectArea?.name)
  }, [newSubjectArea])

  const addRecommendation = (subjArea: NewSubjectArea) => {
    setNewSubjectArea(subjArea)
    setOpenPopUp(true)
    // await saveSubjectArea(dataPodId, subjArea)
    // await refetchSubjectAreas()
  }

  const isLoading = useMemo(() => {
    return loading || loadingRecommendations
  }, [loading, loadingRecommendations])

  const renderDomainData = (domainData: RecommendedSubjectArea[]) => {
    return (
      <>
        {domainData.map((domainArea, index) => (
          <Grid
            item
            xs={4}
            key={index + '.' + domainArea.name}
            justifyContent="stretch"
            container
            direction="column"
            spacing={1}
          >
            {/* <Card sx={{ m: 1, p: 1 }}> */}
            <Card sx={{ backgroundColor: 'white', boxShadow: 3, borderRadius: 3, height: 190 }}>
              {/* <Grid container spacing={2}> */}
              <CardHeader sx={{ mb: -2 }} title={domainArea.name} titleTypographyProps={{ variant: 'h6' }} />
              <CardContent sx={{ maxHeight: 150 }}>
                <Grid item xs={12}>
                  {domainArea.description.length > 150 ? (
                    <Tooltip title={domainArea.description} placement="top-start">
                      <Typography color="text.secondary">{domainArea.description.slice(0, 150) + '...'}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography color="text.secondary">{domainArea.description}</Typography>
                  )}
                </Grid>
                <Grid item xs={12} container spacing={2}>
                  <Grid item xs={12} />
                  {!domainArea.id && (
                    <Grid item xs={8}>
                      <Button
                        variant="outlined"
                        disabled={!!domainArea.id || isLoading}
                        title="Add this Recommendation"
                        onClick={() => addRecommendation(domainArea)}
                        startIcon={<AddOutlined fontSize="small" />}
                      >
                        Data Domain
                      </Button>
                    </Grid>
                  )}
                  {domainArea.id && (
                    <>
                      <Grid item xs={4}>
                        <Button
                          fullWidth
                          variant="contained"
                          disabled={isLoading}
                          onClick={() => domainArea.id && clickTableButton(domainArea.id, 'edit')}
                          startIcon={<EditOutlined fontSize="small" />}
                        >
                          Edit
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          fullWidth
                          variant="contained"
                          color="error"
                          disabled={isLoading}
                          onClick={() => domainArea.id && clickTableButton(domainArea.id, 'delete')}
                          startIcon={<DeleteOutlineOutlined fontSize="small" />}
                        >
                          Delete
                        </Button>
                      </Grid>
                      <Grid item xs={4}>
                        <Button
                          variant="contained"
                          fullWidth
                          disabled={!isGenerateSampleData}
                          onClick={() => domainArea.id && clickTableButton(domainArea.id, 'generate')}
                          startIcon={<SendOutlined fontSize="small" />}
                        >
                          Generate
                        </Button>
                      </Grid>
                    </>
                  )}
                </Grid>
                <Grid item xs={12} />
              </CardContent>
              {/* </Grid> */}
            </Card>
          </Grid>
        ))}
      </>
    )
  }

  return (
    <>
      <LucidPopUp
        openPopUp={openWarning}
        closePopup={setOpenWarning}
        headingText={
          <Typography variant="h4" color={'error'}>
            Area you sure to delete?
          </Typography>
        }
        confirmText="Confirm"
        cancelText="Cancel"
        disabledConfirm={dataSystemsList.some((ds) => ds.subjectAreaId === subjectAreaId)}
        onConfirm={confirmDelete}
      >
        <Typography>
          Delete will effect all dependencies. Please delete Data Sources before proceeding this action
        </Typography>
      </LucidPopUp>
      <Grid item container spacing={2}>
        {/* <ProgressBar loading={loading} /> */}

        <Grid item xs={12} />
        {!combinedSubjectAreas.length ? (
          <>
            {/* {!recommendations.length && !loadingRecommendations && (
              <NoEntries
                onClick={() => setOpenPopUp(true)}
                bodyText="Data Domains, refer to specific category of business process or sub-organization department within your organization. It represents a grouping of data that is related aspect of business function or sub-organization/ department. For example, in your provided Retail organization following are the potential subject areas you could register in the data pod or you can choose to create new subject area."
                headingText={'No Data Domains Created'}
                buttonText={'Add Data Domain'}
              />
            )} */}
          </>
        ) : (
          <></>
        )}
        <Grid item container spacing={2} sx={{ mb: 2, mt: 2 }}>
          <Grid item container spacing={2}>
            <Grid item xs={12} display={'flex'} justifyContent={'end'}>
              <Button variant="contained" sx={{ mr: 1 }} onClick={onClickAdd}>
                Add Data Domain
              </Button>

              <Button variant="contained" onClick={() => setIsGenerateSampleData((prevState) => !prevState)}>
                Generate Sample Data
              </Button>
            </Grid>
            {/* <DataTable
                columns={dynamicColumns}
                rows={subjectTableRows}
                allowedActions={{ editable: true, deletable: true, generate: isGenerateSampleData }}
                onButtonClicked={clickTableButton}
              /> */}
          </Grid>
          <ResultStatus
            severtiy="error"
            showStatus={recommendationError}
            // closeStatus={setRecommendationError}
            alertMessage="Sorry! Error generating recommendations for data domains"
          />
          <Grid item container justifyContent="center">
            <HeadingLabel headingText="Data Domain" />
          </Grid>

          <Grid item container alignItems="baseline" xs={12}>
            {/* <Grid item xs={7}>
                  <Typography align="justify" variant="subtitle1" gutterBottom>
                    Below are the Lucid AI recommended data domains for{' '}
                    <em>
                      <span> {dataPodIndusty?.industryName} </span>
                    </em>
                    . Please choose or specify any additional data domains that should be included in this data pod.
                  </Typography>
                </Grid>
                <br />
                <Grid item xs={7}>
                  <Typography align="justify" variant="subtitle1" gutterBottom>
                    What is Data Domain?
                  </Typography>
                </Grid>
                <br />
                <Grid item xs={7}>
                  <Typography align="justify" variant="subtitle2" gutterBottom>
                    Data Domains, refer to specific category of business process or sub-organization department within
                    your organization. It represents a grouping of data that is related aspect of business function or
                    sub-organization/ department. For example, in your provided Retail organization following are the
                    potential subject areas you could register in the data pod or you can choose to create new subject
                    area.
                  </Typography>
                </Grid> */}
            {/* <Accordion>
              <AccordionSummary expandIcon={<ExpandMoreIcon />}> */}
            <Grid item container alignItems="center">
              <Grid item xs={12} sx={{ textAlign: 'justify' }}>
                <Typography variant="subtitle1" align="inherit" gutterBottom>
                  Below are the Lucid AI recommended data domains for{' '}
                  <span style={{ color: 'purple' }}>{dataPodIndusty?.industryName}</span>. Please choose or specify any
                  additional data domains that should be included in this data pod.
                </Typography>
              </Grid>

              <br />
              <Grid item xs={12}>
                <Typography align="justify" variant="subtitle1" gutterBottom>
                  What is Data Domain?
                </Typography>
              </Grid>
              <br />
              <Grid item xs={12}>
                <Typography align="justify" variant="subtitle2" gutterBottom>
                  Data Domains, refer to specific category of business process or sub-organization department within
                  your organization. It represents a grouping of data that is related aspect of business function or
                  sub-organization/ department. For example, in your provided Retail organization following are the
                  potential subject areas you could register in the data pod or you can choose to create new subject
                  area.
                </Typography>
              </Grid>
              {!filteredRecommendations.length && !isLoading && (
                <Grid item xs={12} sx={{ textAlign: 'justify' }}>
                  <Typography variant="subtitle2" gutterBottom color="warning.main">
                    All Recommendations added. Click on <em>Add Data Domain</em> to add new.
                  </Typography>
                </Grid>
              )}
              {/* <Button
                      variant="text"
                      onClick={onClickReccommend}
                      disabled={loadingRecommendations}
                      title="show recommendations for Data Domain"
                    >
                      Show Recommendations
                    </Button> */}
              {/* <Button
                      variant="contained"
                      disabled={!recommendations.length}
                      onClick={() => setRecommendations([])}
                      // startIcon={<AddOutlined fontSize="small" />}
                    >
                      Undo Recommendedation
                    </Button> */}
            </Grid>
            {/* </AccordionSummary>
              <AccordionDetails> */}
            {/* <Box width="30%"> */}

            <ProgressBar loading={isLoading} displayText="generating recommendations..." />

            <Grid container spacing={4} marginTop={1}>
              {renderDomainData(combinedSubjectAreas)}
              {/* {isLoading && (
                <Grid item xs={10} justifyContent="stretch" container direction="column" spacing={1}>
                  <Skeleton sx={{ boxShadow: 3, borderRadius: 3 }} width={210} height={190} />
                </Grid>
              )} */}
              {renderDomainData(filteredRecommendations)}
            </Grid>
            {/* </Box> */}
            {/* </AccordionDetails>
            </Accordion> */}
          </Grid>
        </Grid>

        <LucidPopUp
          openPopUp={openPop}
          onConfirm={clickSave}
          closePopup={onClosePopUp}
          headingText={!editMode ? 'Add Data Domain ' : 'Update Data Domain'}
          confirmText="Save"
          disabledConfirm={disableUpdateSubmit}
          showCloseIcon={true}
        >
          <Grid justifyContent={'center'} alignItems={'center'} container spacing={2}>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>
                <span style={{ color: 'red' }}>*</span> Indicates a required field
              </Typography>
            </Grid>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>Data Domain </Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter Data Domain Name"
                variant="outlined"
                color="secondary"
                size="small"
                value={newSubjectArea?.name ?? ''}
                onChange={({ target }) => handleNewSubj('name', target.value)}
                error={!isValidEntry(newSubjectArea?.name)}
                helperText={!isValidEntry(newSubjectArea?.name) ? 'Invalid Name' : ''}
              />
            </Grid>
            <Grid item xs={9}>
              <Typography color={'text.primary'}>Description</Typography>
            </Grid>
            <Grid item xs={9}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter description"
                variant="outlined"
                color="secondary"
                required
                rows={4}
                value={newSubjectArea?.description ?? ''}
                onChange={({ target }) => handleNewSubj('description', target.value)}
                multiline
                error={!isValidDescription(newSubjectArea?.description)}
                helperText={!isValidDescription(newSubjectArea?.description) ? 'Invalid Entry' : ''}
              />
            </Grid>
          </Grid>
        </LucidPopUp>
      </Grid>

      <DataContextGenerateSampleData
        dataPodId={dataPodId}
        subjectAreaId={subjectAreaId}
        subjectAreaList={subjectAreaList}
        showDetailsPopup={showDetailsPopup}
        setShowDetailsPopup={setShowDetailsPopup}
      />
    </>
  )
}
