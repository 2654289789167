import {
  Box,
  Grid,
  Button,
  IconButton,
  Typography,
  MenuItem,
  TextField,
  InputAdornment,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Skeleton,
  Select,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Card,
  Divider,
  CardHeader,
  ListItemButton,
  Menu,
  Checkbox,
  Autocomplete,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../styles/globalStyles'
import ActionPage from '../../ui-components/ActionPage'
import HomeRoundedIcon from '@mui/icons-material/HomeRounded'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../Routes/constants'

import { useGetAllDataPods, useGetDataPodInfo } from '../../hooks/datapodHooks'
import { useGetJobStatus, useGetProfileInputs } from '../../hooks/dataProfilingHooks'
import { jobNames } from '../../hooks/constants'
import { useSparkPost, useGetDataSystemByDataSystemId } from '../../hooks/dataSystemHooks'
import { useGetSubjectArea } from '../../hooks/subjectAreaHooks'

import {
  useGetAllDataTablesByDataSourceId,
  useGetAllDataTablesProfiling,
  useUpdateDataTable,
} from '../../hooks/dataTableHooks'
import { TableColumnCard } from './TableColumnCards'
import { useGetAllDataColumnsByTableId, useGetDataColumnsByTable } from '../../hooks/dataColumnHooks'
import { useNotebookToQueryString } from '../../hooks/notebookHooks'
import {
  DataColumn,
  DataTable,
  DataTableProfiling,
  NotebookParams,
  ResultStatusSeverity,
  RunNewJobParams,
  RunStatusResponse,
  UpdateDataProfilingData,
} from '../../businessObjects'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import ShadowTable, { ShadowTableAction } from '../../ui-components/ShadowTable/ShadowTable'
import { DataTableColumn, DataTableRow } from '../../ui-components/DataTable/DataTableTypes'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { LucidJupyter } from '../../ui-components/JupyterComponents/LucidJupyter'
import { useAppSelector, useAppDispatch } from '../../app/hooks'
import { RootState } from '../../app/store'
import { filterActiveRunIds, initiateActiveRunId } from '../../features/notification/notificationSlice'
import { usePostDatabricksJobRunNew } from '../../hooks/databricksHooks'
import { useGetServiceConnection } from '../../hooks/dataEngineeringHooks'
import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp'
import { isValidEntry } from 'utils/constants'
import ToggleView, { ViewType } from 'ui-components/ToggleView/ToggleView'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import { useGetQueryString } from 'hooks/queryStringHook'
import { useGetDataTableColumnProfiling, useGetDataTableProfiling, useUpdateDataTableProfiling } from 'hooks/generativeIntegartionHooks'
import { ColumnProfilingType, ProfilingDataType } from './profilingDataTypes'
import { HeadingLabel, LucidTextLabel } from 'ui-components/LucidTextLabel'
import LucidWidePopUp from 'ui-components/LucidWidePopUp'
import { green } from '@mui/material/colors'
import ToggleOffIcon from '@mui/icons-material/ToggleOff'
import ToggleOnIcon from '@mui/icons-material/ToggleOn'
import { BarChart } from '@mui/x-charts/BarChart'
import AnimatedLoader from 'ui-components/AnimatedLoader'
import ResultStatus from 'ui-components/ResultStatus'
import { useGetRunNewJobPayload, useRunNewJob } from 'hooks/executionHooks'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
type FileType = 'CSV' | 'Parquet' | 'Delta' | 'SQL' | undefined
type FileTypes = {
  id: number
  name: FileType
}
export const DataProfilingViewResults = (props: Props) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const dataSourceId = dataSystemID ? Number(dataSystemID) : undefined
  const [selectedSourceID, setSelectedSourceID] = useState<number>()
  const selTableId = useGetQueryString('tableId')
  // const [tableView, setTableView] = useState<boolean>(true)
  const [cardView, setCardView] = useState<ViewType>('Table')
  const [tableSearch, setTableSearch] = useState<'Table' | 'Column'>('Table')
  const [searchText, setSearchText] = useState<string>()
  // const [tableData, setTableData] = useState<DataTable[]>([])

  // const { dataPodInfo } = useGetDataPodInfo(dataPodId)

  const { sparkApiLoading, injectDataParamsToSpark } = useSparkPost(dataSystemID, dataPodId)
  // const [tableData, setTableData] = useState<DataTable[]>([])
  // const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  // const { getNotebookStringBySystemId } = useNotebookToQueryString(dataPodId, dataSystemID)
  const { profileInputs } = useGetProfileInputs(dataPodId ?? undefined)
  const { dataSystemInfo } = useGetDataSystemByDataSystemId(selectedSourceID)
  const { subjectAreaInfo } = useGetSubjectArea(dataSystemInfo?.subjectAreaId)
  const [selectedTableID, setSelectedTableID] = useState<number>()
  // const { dataTables } = useGetAllDataTables(dataPodId, selectedSourceID, searchText)
  const { postRunNewJob } = usePostDatabricksJobRunNew()
  const {runNewJob} = useRunNewJob()
  const { getRunNewJobPayload } = useGetRunNewJobPayload();

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const activeFabricRunStatus = useAppSelector((state: RootState) => state.notification.fabricRunStatus)
  const dispatch = useAppDispatch()
  const { getConnections } = useGetServiceConnection()
  const { fetchDataTables, dataTables, loading } = useGetAllDataTablesByDataSourceId(dataPodId, selectedSourceID)
  const [showNotebookPopUp, setShowNotebookPopup] = useState<boolean>(false)
  const [dataSize, setDataSize] = useState<number>()
  // const [fileType, setFileType] = useState<'csv'|'parquet'|'delta'|'other'>()
  const [fileType, setFileType] = useState<FileType>()
  const [relativePath, setRelativePath] = useState<string>()
  // const [fileTypes, setFileTypes] = useState<FileTypes[]>([])
  const [selectedFolderOption, setSelectedFolderOption] = useState<string>('')
  const [showRadioSection, setShowRadioSection] = useState<boolean>(true)
  const [toScanTables, setToScanTables] = useState<DataTable[]>([])
  const [advanceTableScanRunInitated, setAdvanceTableScaneInitiated] = useState<boolean>(false)
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedFolderOption((event.target as HTMLInputElement).value)
  }
  const [runId, setRunId] = useState<number | string>()
  const [dataBricksJobStatus, setdataBricksJobStatus] = useState<boolean>(false)
  const [showResultStatus,setShowResultStatus] = useState<boolean>(false)
  const [severity, setSeverity] = useState<ResultStatusSeverity>('success')
  const [statusMessage, setStatusMessage] = useState<string>()

  useEffect(() => {
    if (dataSourceId) {
      setSelectedSourceID(dataSourceId)
    }
  }, [])
  const selectedDSTable = useMemo(() => {
    if (!dataTables?.length) return []
    return dataTables
  }, [dataTables, searchText, dataSourceId])

  const fileTypes = useMemo(() => {
    return [
      { id: 1, name: 'CSV' as FileType },
      { id: 2, name: 'Parquet' as FileType },
      { id: 3, name: 'Delta' as FileType },
      { id: 4, name: 'SQL' as FileType },
    ]
  }, [])
  // const { getJobStatus, jobStatus } = useGetJobStatus(jobNames.SCAN_AND_PROFILE, dataPodId)

  // useEffect(() => {
  //   if (dataPodId && selectedSourceID && !searchText) {
  //     fetchDataTables()
  //   }
  // }, [selectedSourceID])

  useEffect(() => {
    if (fileType === ('SQL' as FileType) || fileType === ('Delta' as FileType)) {
      setShowRadioSection(false)
    } else {
      setShowRadioSection(true)
    }
  }, [fileType as FileType])

  useEffect(() => {
    if (selectedDSTable.length && tableSearch === 'Table') {
      setSelectedTableID(dataTables[0].id)
    }
  }, [selectedDSTable])
  useEffect(() => {
    if (selTableId) {
      setSelectedTableID(Number(selTableId))
    }
  }, [selTableId])
  // useEffect(() => {
  //   if (searchText?.length && tableSearch === 'Table') {
  //     // console.log("Fetch the Table with the search Text")
  //     fetchDataTables( searchText)
  //   }
  // }, [searchText?.length, tableSearch])
  // useEffect(() => {
  //   if (dataSystemID && !selectedSourceID) {
  //     setSelectedSourceID(Number(dataSystemID))
  //   }
  // }, [dataSystemID])
  useEffect(() => {
    if (activeRunIds && runId) {
      const temp = activeRunIds.find((element) => element.runId === runId)
      if (temp && temp.state.resultState === 'SUCCESS') {
        if (temp.runName?.includes('LDH_DataSourceScan') && selectedSourceID) {
          fetchDataTables()
        } else if (temp.runName?.includes('LDH_TableScan') && selectedTableID) {
          fetchDataTables()
          setdataBricksJobStatus(true)
        } else if (temp.runName?.includes('AdvanceDataTableScan') && selectedTableID) {
          setAdvanceTableScaneInitiated(true)
        }
      }
    }
  }, [activeRunIds])

  useEffect(() => {
    if (activeFabricRunStatus && runId) {
      const temp = activeFabricRunStatus.find((element) => element.id === runId)
      if (temp && temp.status === 'Completed') {
        if (temp.jobDetails?.triggerType === 'SourceScan' && selectedSourceID) {
          fetchDataTables()
        } else if (temp.jobDetails?.triggerType === 'TableScan' && selectedTableID) {
          fetchDataTables()
          setdataBricksJobStatus(true)
        } 
      }
    }
  }, [activeFabricRunStatus])
  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  const dataSources = useMemo(() => {
    if (!profileInputs) return []
    return profileInputs
  }, [profileInputs])

  // const subjectArea = useMemo(() => {
  //   if (!subjectAreaInfo) return
  //   return subjectAreaInfo
  // }, [subjectAreaInfo])

  const handleSourceChange = (sourceId: string) => {
    const dataProfilingViewResultsRoute = generatePath(ROUTES.DataProfilingViewResults, {
      dataPodId: dataPodId,
      dataSystemID: sourceId,
    })
    setToScanTables([])
    setSearchText(undefined)
    setTableSearch('Table')
    setSelectedSourceID(Number(sourceId))
    navigate(dataProfilingViewResultsRoute)
  }

  const handleReScan = () => {
    injectDataParamsToSpark()
    setSelectedTableID(0)
  }

  const showRequiredDetailsPopUpForScan = () => {
    setShowNotebookPopup(true)
  }
  // const handleNewPopUpData = (field:string, value:string)=>{
  //   console.log(field, value)
  //   // if(field === '')
  // }

  const runDataSourceScan = async () => {
    if (dataPodId) {
      const requestBody = {
        i_data_pod_id: dataPodId,
        i_data_system_id: dataSystemID || '',
        i_data_sample_size: String(dataSize) || '',
        i_file_type: String(fileType) || '',
        i_relative_path: selectedFolderOption || '',
      }

      const jobParams = {
        name: dataSources.find((ds) => ds.dataSystemID == selectedSourceID)?.dataSystemName || '',
        route: generatePath(ROUTES.DataProfilingViewResults, {
          dataPodId: dataPodId,
          dataSystemID: dataSystemID,
        })
      }

      try {
        const payload = await getRunNewJobPayload(dataPodId, requestBody, 'dataSource_Job_Id');
  
        setShowNotebookPopup(false)
        setDataSize(undefined)
        setFileType(undefined)
  
        if(payload) {
          const runId = await runNewJob(activeRunIds, jobParams, 'SourceScan', payload);
          
          if(runId){
            setRunId(runId)
          }
          
          setdataBricksJobStatus(false)
          setAdvanceTableScaneInitiated(false)
        }
      } catch(error: any){
        setStatusMessage(error.message)
        setShowResultStatus(true)
        setSeverity('error')
      }
    }
  }

  const runTableScan = async () => {
    if (dataPodId) {

      const requestBody = {
        i_data_pod_id: dataPodId || '',
        i_data_system_id: dataSystemID || '',
        i_data_table_id: JSON.stringify(toScanTables.map((tb) => tb.id)) || '',
      }

      const jobParams = {
        name: toScanTables.map((tb) => tb.name).join(', '),
        route: generatePath(ROUTES.DataProfilingViewResults, {
          dataPodId: dataPodId,
          dataSystemID: dataSystemID,
        })
      }

      try {
        const payload = await getRunNewJobPayload(dataPodId, requestBody, 'dataTable_Job_Id');
  
        if(payload) {
          const runId = await runNewJob(activeRunIds, jobParams, 'TableScan', payload);
          
          if(runId){
            setRunId(runId)
          }
  
          setdataBricksJobStatus(false)
          setAdvanceTableScaneInitiated(false)
        }
      } catch(error: any){ 
        setStatusMessage(error.message)
        setShowResultStatus(true)
        setSeverity('error')
      }
    }
  }

  const handleView = (vt: ViewType) => {
    if (vt !== cardView) {
      setCardView(vt)
      if (vt === 'Grid') {
        setTableSearch('Table')
      }
    }
  }

  const handleTableSelect = (tableId: number) => {
    setSelectedTableID(tableId)
  }

  const handleRadioButton = (selectedRadio: string) => {
    setSearchText(undefined)
    setTableSearch(selectedRadio as typeof tableSearch)
  }

  const handleSearchText = (text: string) => {
    setSearchText(text)
    if (tableSearch === 'Table') {
      fetchDataTables(text)
    }
  }

  return (
    <>
      <ResultStatus
        severtiy={severity}
        showStatus={showResultStatus}
        closeStatus={setShowResultStatus}
        alertMessage={statusMessage}
      />
      <LucidPopUp
        openPopUp={showNotebookPopUp}
        closePopup={setShowNotebookPopup}
        showCloseIcon
        headingText={
          <Typography variant="h4" color={'error'}>
            Enter the following details
          </Typography>
        }
        confirmText="Confirm"
        cancelText="Cancel"
        disabledConfirm={
          !dataSize ||
          !fileType ||
          (fileType != ('SQL' as FileType) && fileType != ('Delta' as FileType) && !selectedFolderOption)
        }
        onConfirm={runDataSourceScan}
      >
        <Grid justifyContent={'center'} alignItems={'center'} container spacing={2}>
          <Grid item xs={9} justifyContent={'center'}>
            <Typography color={'text.primary'}>
              <span style={{ color: 'red' }}>*</span> Indicates a required field
            </Typography>
          </Grid>
          {/* <Grid item xs={14} display={'flex'} flexDirection={'row'}> */}
          <Grid item xs={9} justifyContent={'center'}>
            <Typography color={'text.primary'}>Sample Data Size *</Typography>
          </Grid>
          <Grid item xs={9}>
            <TextField
              style={{ width: '50%' }}
              id="outlined-basic"
              label="Enter data size"
              variant="outlined"
              color="secondary"
              size="small"
              type="number"
              value={dataSize ?? ''}
              onChange={({ target }) => {
                const value = parseFloat(target.value)
                if (value >= 0 && value <= 1) {
                  setDataSize(value)
                }
              }}
              required
              inputProps={{ step: 0.1, min: 0, max: 1 }}
            // error={!isValidEntry(dataSize)}
            // helperText={!isValidEntry(newSubjectArea?.name) ? 'Invalid Name' : ''}
            />
          </Grid>
          <Grid item xs={9} justifyContent={'center'}>
            <Typography color={'text.primary'}>File Type*</Typography>
          </Grid>
          <Grid item xs={9} justifyItems={'center'}>
            <Select
              label="File Type"
              value={fileType}
              onChange={(event) => setFileType(event.target.value as FileType)}
              required
              style={{ width: '50%' }}
            >
              {fileTypes.map((role) => (
                <MenuItem key={role.id} value={role.name}>
                  {role.name}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          {showRadioSection && (
            <>
              <Grid item xs={9} justifyContent={'center'}>
                <Typography color={'text.primary'}>Choose the folder structure type * </Typography>
              </Grid>
              <Grid item xs={9}>
                <FormControl component="fieldset">
                  <RadioGroup aria-label="options" name="options" value={selectedFolderOption} onChange={handleChange}>
                    <FormControlLabel value="1" control={<Radio />} label="Each Directory is a Data File/Table" />
                    <FormControlLabel value="2" control={<Radio />} label="Each File is a Data File/Table" />
                    <FormControlLabel value="3" control={<Radio />} label="Each Directory Contains Data Files/Tables" />
                    <FormControlLabel
                      value="4"
                      control={<Radio />}
                      label="Each Directory Contains Directories as Data Files/Tables"
                    />
                  </RadioGroup>
                </FormControl>
              </Grid>
            </>
          )}
        </Grid>
      </LucidPopUp>
      <ActionPage>
        {/* <Grid item container spacing={2}>
          <Grid item xs={2}>
            <IconButton onClick={onClickHome}>
              <HomeOutlinedIcon />
            </IconButton>
          </Grid>
        </Grid> */}
        <AnimatedLoader height="50%" width="40%" loading={loading} />
        <Box style={cardContainer}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Box>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={5}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data Source Name
                      </Typography>
                    </Grid>
                    <Grid item xs={0.5} />
                    <Grid item xs={5}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data Domain Name
                      </Typography>
                    </Grid>
                    <Grid item xs={1} />

                    <Grid item xs={12} md={15} />

                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          select
                          variant="outlined"
                          value={selectedSourceID ?? 0}
                          required
                          size="small"
                          // type="number"
                          onChange={({ target }) => handleSourceChange(target.value)}
                        >
                          {dataSources.map((d) => (
                            <MenuItem key={d.dataSystemID} value={d.dataSystemID}>
                              <Typography fontSize={'small'}> {d.dataSystemName}</Typography>
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={0.5} />
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={subjectAreaInfo?.subjectAreaName}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={0} alignItems={'end'}>
                        <ToggleView viewType={cardView} handleToggle={handleView} />
                        {/* <IconButton color={tableView ? 'primary' : 'inherit'} onClick={() => toggleView(true)}>
                <TableRowsOutlinedIcon />
              </IconButton>
              <IconButton color={cardView ? 'primary' : 'inherit'} onClick={() => toggleView(false)}>
                <GridViewOutlinedIcon />
              </IconButton> */}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} />
          </Grid>
          <Grid item container spacing={2} alignItems={'center'}>
            <Grid item container xs={2}>
              <Grid item xs={4} />
              <FormControl>
                <FormLabel id="Search By Label">Search By</FormLabel>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  value={tableSearch}
                  onChange={({ target }) => handleRadioButton(target.value)}
                  name="radio-buttons-group"
                >
                  <Grid item xs={8} alignContent={'space-evenly'} container>
                    <Grid item xs={4}>
                      <FormControlLabel value={'Table'} control={<Radio />} label={'Table'} />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={4}>
                      <FormControlLabel
                        value={'Column'}
                        disabled={cardView === 'Grid'}
                        control={<Radio />}
                        label={'Column'}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              </FormControl>
            </Grid>

            <Grid item xs={3}>
              <TextField
                placeholder="Search"
                value={searchText ?? ''}
                onChange={({ target }) => handleSearchText(target.value)}
                color="primary"
                margin="dense"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: searchText ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => handleSearchText('')}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>
            <Grid xs={0.5} />
            <Grid item container xs={2}>
              <Button
                variant="contained"
                onClick={showRequiredDetailsPopUpForScan}
                title={`Schema Scan`}
              >{`Schema Scan`}</Button>
            </Grid>
            <Grid item container xs={2}>
              <Button
                disabled={!toScanTables.length}
                variant="contained"
                onClick={runTableScan}
                title={`Data Scan`}
              >{`Data Scan`}</Button>
            </Grid>
            {/* <Grid item container xs={2}>
              <Button
                disabled={!toScanTables.length}
                variant="contained"
                onClick={runAdvanceTableScan}
                title={`Scan Advance Data Table`}
              >{`Scan Advance Data Table`}</Button>
            </Grid> */}

            <Grid item xs={12} container>
              {cardView === 'Table' && (
                <ResultsTable
                  dataSourceTables={selectedDSTable}
                  searchText={tableSearch === 'Column' ? searchText : undefined}
                  dataPodId={dataPodId}
                  dataSourceId={selectedSourceID}
                  databricksTableScanFlag={dataBricksJobStatus}
                  advanceTableScanFlag={advanceTableScanRunInitated}
                  tableSearch={tableSearch}
                  selectedTableId={selectedTableID}
                  updateTableId={handleTableSelect}
                  tablesToScan={toScanTables}
                  updateTablesToScan={setToScanTables}
                />
              )}
              {cardView === 'Grid' && (
                <Grid container spacing={2}>
                  {selectedDSTable.map((tb) => (
                    <Grid item xs={4} key={tb.id}>
                      <TableColumnCard
                        tableId={tb.id}
                        tableName={tb.name}
                        searchText={tableSearch === 'Table' ? searchText : undefined}
                      />
                    </Grid>
                  ))}
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item container spacing={2} alignItems={'center'}>
            <Grid item md={12} />
            <Grid item container md={12}>
              {sparkApiLoading ? (
                <Grid item md={12}>
                  <Skeleton variant="rectangular" height={100} />
                  <Skeleton variant="rectangular" height={100} />
                </Grid>
              ) : (
                <Grid item md={12}></Grid>
              )}
            </Grid>
          </Grid>
        </Box>
      </ActionPage>
    </>
  )
}

type ResultTableProps = {
  dataSourceTables: DataTable[]
  dataPodId?: string
  searchText?: string
  dataSourceId?: number
  databricksTableScanFlag?: boolean
  advanceTableScanFlag?: boolean
  tableSearch: 'Table' | 'Column'
  selectedTableId?: number
  updateTableId: (tableId: number) => void
  tablesToScan: DataTable[]
  updateTablesToScan: (tables: DataTable[]) => void
}
type DeltaKey = {
  'Column Name': string;
  'Max Date': string;
  'Min Date': string;
  'Null Values': number;
}

const ResultsTable = ({
  dataSourceTables,
  dataSourceId,
  dataPodId,
  searchText,
  databricksTableScanFlag,
  advanceTableScanFlag,
  selectedTableId,
  updateTableId,
  tableSearch,
  tablesToScan,
  updateTablesToScan,
}: ResultTableProps) => {
  // const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const [leftTable, setLeftTable] = useState<DataTable[]>([])
  const [rightTable, setRightTable] = useState<DataTableRow[]>([])
  // const [notebookString, setNotebookString] = useState<string>()
  const [openColumnProfiling, setOpenColumnProfiling] = useState<boolean>(false)
  //This is getting the right side data and we are providing this first tableId from left side to the getDataColumns.
  const { getDataColumns, dataColumns } = useGetDataColumnsByTable()
  // const { notebookResponse } = useNotebookToQueryString(dataPodId, dataSourceId, selectedTableId)
  const [isTableAsc, setIsTableAsc] = useState<boolean>(false)
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null)
  const [filterApplied, setFilterApplied] = useState<'profiled' | 'unprofiled' | 'all'>('all')
  const { getDataTableProfilingData, tableProfiling } = useGetDataTableProfiling(dataPodId, selectedTableId)
  const { confirmUpdateTable } = useUpdateDataTable(dataPodId)
  const [cardView, setCardView] = useState<ViewType>('Table')
  const [profileCardView, setProfileCardView] = useState<ViewType>('Grid')
  const { getDataColumnProfilingData, tableColumnProfiling } = useGetDataTableColumnProfiling(
    dataPodId,
    selectedTableId,
  )
  const [columnData,setColumnData] = useState<string[]>([])
  const [showDeltaKeySelectionOption, setDeltaKeySelectionOption] = useState<boolean>(false)
  const [deltaKeyValue, setDeltaKeyValue] = useState<string | null>(null);
  const [deltaKeyData, setDeltaKeyData] = useState<DeltaKey[]>()
  const [profilingDataId, setProfilingDataId] = useState<number>()
  const {updateDataTableProfilingData} = useUpdateDataTableProfiling()
  const [showResultStatus,setShowResultStatus] = useState<boolean>(false)
  const [severity, setSeverity] = useState<ResultStatusSeverity>('success')
  const [statusMessage, setStatusMessage] = useState<string>()
  const sortTableFn = (t1: DataTable, t2: DataTable) => {
    if (t1.name < t2.name) {
      return isTableAsc ? -1 : 1
    }
    if (t1.name > t2.name) {
      return isTableAsc ? 1 : -1
    }
    return 0
  }
  const rightTableColumns = useMemo(() => {
    return [
      {
        label: 'Column ID',
        sortableColumn: true,
      },
      {
        label: 'Column Name',
        sortableColumn: true,
      },
      {
        label: 'Data Type',
        sortableColumn: true,
      },
      {
        label: ' isPrimary',
        sortableColumn: true,
      },
      {
        label: ' isUnique',
        sortableColumn: true,
      },
      { label: 'Column Profile' },
    ]
  }, [])
  const leftTableColumns: DataTableColumn[] = useMemo(() => {
    return [{ label: 'Table Name', sortableColumn: true }]
  }, [])

  // useEffect(() => {
  //   if (notebookResponse?.message) {
  //     setNotebookString(notebookResponse?.message)
  //   }
  // }, [notebookResponse])

  useEffect(()=>{
    if(dataSourceTables.length && selectedTableId){
      // console.log(dataSourceTables)
      // console.log(selectedTableId)
      const tableRow = dataSourceTables.find((dst)=> dst.id === selectedTableId)
      // console.log(tableRow)
      if(tableRow && tableRow.profileStatus){
        setDeltaKeySelectionOption(true)
      }else{
        setDeltaKeySelectionOption(false)
      }
  }
  },[selectedTableId])
  useEffect(() => {
    if (advanceTableScanFlag) {
      getDataTableProfilingData()
    }
  }, [advanceTableScanFlag])

  useEffect(() => {
    // if (!selectedTableId) {
    //   setNotebookString('')
    // }

    if (selectedTableId) {
      getDataColumns(selectedTableId, searchText)
    }
  }, [selectedTableId, searchText, databricksTableScanFlag])

  useEffect(()=>{
    if(tableProfileData && dataColumns.length){
      const uniqueColumns = Array.from(new Set(dataColumns.map((item) => item.dataColumnName)))
      setColumnData(uniqueColumns)
      // console.log(tableProfileData)
      const deltaKeyArray:DeltaKey[] = tableProfileData['Delta Key'] as DeltaKey[]
      // console.log(deltaKeyArray)
      // console.log(deltaKeyArray.length)
      setDeltaKeyData(deltaKeyArray)
      if(deltaKeyArray.length && deltaKeyArray[0]['Column Name'] != null){
        // console.log("Inside the if of the use effect")
        setDeltaKeyValue(deltaKeyArray[0]['Column Name'] as string)
      }else{
        setDeltaKeyValue(null)
      }
      
    }
  },[tableProfiling, selectedTableId, dataColumns])

  const dataTableProfilingData = useMemo(() => {
    if (!tableProfiling.length) return

    return tableProfiling[0]
  }, [selectedTableId, tableProfiling])

  const tableProfileData = useMemo(() => {
    if (!dataTableProfilingData) return
    try {
      // console.log(dataTableProfilingData)
      setProfilingDataId(dataTableProfilingData.id)
      return JSON.parse(dataTableProfilingData.profilingData) as ProfilingDataType
    } catch (error) {
      console.log(error)
    }
  }, [dataTableProfilingData])

  // console.log({ dataTableProfilingData }, { tableProfileData })
  // useEffect(() => {
  //   if (selectedTableId) {
  //     getDataTableProfilingData(selectedTableId)
  //   }
  // }, [selectedTableId])
  // console.log({ selectedTableId })
  // useEffect(() => {
  //   if (dataSourceTables.length && !searchText) {
  //     const rows = dataSourceTables.map((dt) => ({
  //       id: `${dt.id}`,
  //       values: [dt.name],
  //     })) as DataTableRow[]
  //     setLeftTable(rows)
  //   }
  //   if (!dataSourceTables.length) {
  //     setLeftTable([])
  //     setRightTable([])
  //   }
  // }, [dataSourceTables, searchText, selectedTableId])

  // const leftRows = useMemo(() => {
  //   if (dataSourceTables.length) {
  //     return dataSourceTables.map((dt) => ({
  //       id: `${dt.id}`,
  //       values: [dt.name],
  //     })) as DataTableRow[]
  //   }
  //   return []
  // }, [dataSourceTables])

  useEffect(() => {
    if (dataSourceTables.length) {
      setLeftTable(dataSourceTables)
    } else {
      setLeftTable([])
    }
  }, [dataSourceTables, dataSourceId])

  useEffect(() => {
    if (selectedTableId && dataSourceTables.length) {
      const columnList = dataColumns.map((cl) => ({
        id: `${cl.id}`,
        values: [cl.id, cl.dataColumnName, cl.datatype, `${cl.isPrimary}`, cl.isUnique, 'VIEW'] as string[],
      }))
      setRightTable(columnList)
    } else {
      setRightTable([])
    }
  }, [selectedTableId, dataColumns, searchText, dataSourceTables])

  // useEffect(() => {
  //   if (!dataSourceTables.length) {
  //     setSelectedTableID(undefined)
  //     setLeftTable([])
  //     setRightTable([])
  //   }
  // }, [dataSourceTables])

  const refreshRightTable = (sid: unknown) => {
    // setNotebookString('')
    const tblId = Number(sid)
    // getDataTableProfilingData(tblId)
    getDataColumns(tblId)
    updateTableId(tblId)
  }

  const handleFilterSelect = (id: typeof filterApplied) => {
    // const filtedToUpdate = filterApplied.includes(id as string)
    //   ? filterApplied.filter((ft) => ft !== id)
    //   : [...filterApplied, id]
    setFilterApplied(id)
    switch (id) {
      case 'profiled':
        setLeftTable(dataSourceTables.filter((dt) => dt.profileStatus && dt.profileStatus))
        setFilterAnchor(null)
        break
      case 'unprofiled':
        setLeftTable(dataSourceTables.filter((dt) => !dt.profileStatus))
        setFilterAnchor(null)
        break
      case 'all':
        setLeftTable(dataSourceTables)
        setFilterAnchor(null)
        break
    }
    // setFilterApplied(id as typeof filterApplied)
    // if (id === 'profiled') {
    //   setLeftTable(dataSourceTables.filter((dt) => dt.profileStatus && dt.profileStatus))
    // }
    // if (id === 'unprofiled') {
    //   setLeftTable(dataSourceTables.filter((dt) => !dt.profileStatus))
    // } else {
    //   setLeftTable(dataSourceTables)
    // }

    // // setFilterApplied(filtedToUpdate as string[])
    // setFilterAnchor(null)
  }

  const sortedLeftTables = useMemo(() => {
    return leftTable.sort(sortTableFn)
  }, [leftTable, isTableAsc])

  function onButtonClick(rowId: string, actionType: ShadowTableAction): void {
    switch (actionType) {
      case ShadowTableAction.Viewable:
        // console.log({ actionType }, rowId)
        getDataColumnProfilingData(Number(rowId))
        setOpenColumnProfiling(true)
    }
  }

  const columnProfilingData = useMemo(() => {
    if (!tableColumnProfiling.length) return
    return tableColumnProfiling[0]
  }, [tableColumnProfiling])

  const colProfile = useMemo(() => {
    if (!columnProfilingData) return
    try {
      return JSON.parse(columnProfilingData.profilingData) as ColumnProfilingType
    } catch (error) { }
  }, [columnProfilingData])

  const handleSelectToScan = (selectDataTable: DataTable) => {
    const toSetTables = tablesToScan.some((tb) => tb.id === selectDataTable.id)
      ? tablesToScan.filter((tb) => tb.id != selectDataTable.id)
      : [...tablesToScan, selectDataTable]

    updateTablesToScan(toSetTables)
  }

  const toggleTableState = async (table: DataTable, active: boolean) => {
    const success = await confirmUpdateTable({
      id: table.id,
      name: table.name,
      containerDirectory: table.containerDirectory,
      dataSystemId: table.dataSystemId,
      description: table.description,
      profileStatus: table.profileStatus,
      mappingStatus: table.mappingStatus,
      isActive: active,
    })
    if (success) {
      setLeftTable(sortedLeftTables.map((tb) => (tb.id === table.id ? { ...tb, isActive: active } : tb)))
    }
  }
  // console.log({ colProfile })

  const scrollableContainer = {
    maxHeight: '60vh',
    overflowY: 'auto',
  }
  const cardScrollableContainer = {
    maxHeight: '40vh',
    overflowY: 'auto',
  }
  const handleViewProfileInfo = (vt: ViewType) => {
    if (vt !== profileCardView) {
      setProfileCardView(vt)
    }
  }

  const handleSortTables = (sortType: boolean) => {
    setIsTableAsc(sortType)

    const srtTables = leftTable.sort((dt1, dt2) => (dt1.name > dt2.name ? 1 : -1))
    setLeftTable(srtTables)
  }

  const handleSelect = async (event: any, selectedOption: any) => {
    if (tableProfileData && profilingDataId) {
      // console.log(selectedOption)
      const updatedTableProfileData: ProfilingDataType = { ...tableProfileData };
      // console.log({updatedTableProfileData})
      let deltaKeyArray:DeltaKey[] = updatedTableProfileData['Delta Key'] as DeltaKey[]
      if(deltaKeyArray.length  && selectedTableId && dataPodId){
        deltaKeyArray[0]['Column Name']= selectedOption
        // console.log('Updated Table Profile Data:', updatedTableProfileData)
        const body:UpdateDataProfilingData ={
          id:profilingDataId,
          dataTableId:selectedTableId,
          profilingData:JSON.stringify(updatedTableProfileData)

        }
        const updateFlag = await updateDataTableProfilingData(body, dataPodId)
        if(updateFlag){
          setStatusMessage('Updated the delta key')
          setShowResultStatus(true)
          setSeverity('success')
          setDeltaKeyValue(selectedOption)
        }else{
          setStatusMessage('Failed to update the delta key')
          setShowResultStatus(true)
          setSeverity('error')
        }
      }else{
        setDeltaKeyValue(null)
      }
    }
  }
  return (
    <>
    <ResultStatus
      severtiy={severity}
      showStatus={showResultStatus}
      closeStatus={setShowResultStatus}
      alertMessage={statusMessage}
    />
    <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={14} spacing={2}>
      <Grid item xs={3}>
        <LucidWidePopUp
          showCloseIcon
          openPopUp={openColumnProfiling}
          headingText={'Column Profile Information'}
          closePopup={setOpenColumnProfiling}
        //cancelText="Close"
        >
          <Grid item xs={15} sx={{ marginBottom: 12 }}>
            <Grid item xs={12} container sx={{ marginBottom: 2 }}>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12} />
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Table Name" />
                </Grid>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Column Name" />
                </Grid>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Data Type" />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2"> {tableProfileData?.['Table Name']} </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2"> {colProfile?.['Column Name']} </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2"> {colProfile?.['Data Type']} </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} />
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Rows" />
                </Grid>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Max Value" />
                </Grid>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Min Value" />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2"> {colProfile?.Rows} </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2"> {colProfile?.['Max Value']} </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2"> {colProfile?.['Min Value']} </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} />
              </Grid>
            </Grid>
            <Grid item container xs={12} direction="row" spacing={0.5}>
              <Grid item xs={11}>
                <HeadingLabel headingText="Sample Distribution Set" />
              </Grid>
              <Grid item xs={1}>
                <ToggleView viewType={profileCardView} handleToggle={handleViewProfileInfo} />
              </Grid>
            </Grid>
            <Grid item xs={12} />
            {/* <Card sx={{ backgroundColor: 'white', width: '100%' }}>
                <Grid item container sx={{ ...scrollableContainer, marginLeft: 4, marginTop: 2, marginBottom: 2 }}>
                  {colProfile?.Distribution && (
                    <>
                      {Object.keys(colProfile.Distribution).map((ky, ix) => (
                        <Grid item key={ix + ky} container xs={4} spacing={2} justifyContent={'left'}>
                          <Grid item xs={6}>
                            <Typography variant="body2">{ky}: </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="subtitle1" gutterBottom>
                              {colProfile.Distribution ? JSON.stringify(colProfile.Distribution[ky]) ?? '' : ''}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
              </Card> */}
            <Card
              sx={{
                ...cardScrollableContainer,
                backgroundColor: '#f2f2f2',
                marginLeft: 4,
                marginTop: 1,
                marginBottom: 4,
                width: '96%',
                height: '120%',
              }}
            >
              {profileCardView === 'Grid' && (
                <Grid item sx={{ ...cardScrollableContainer }}>
                  {colProfile?.Distribution && (
                    <BarChart
                      xAxis={[
                        {
                          scaleType: 'band',
                          data: Object.keys(colProfile.Distribution),
                          tickSize: 25,
                        },
                      ]}
                      series={[{ data: Object.values(colProfile.Distribution).map((value) => Number(value)) }]}
                      width={900}
                      height={300}
                      margin={{ top: 20, right: 10, bottom: 50, left: 40 }}
                      borderRadius={4}
                    />
                  )}
                </Grid>
              )}
              {profileCardView === 'Table' && (
                <Grid item container sx={{ ...cardScrollableContainer, marginLeft: 4, marginTop: 2, marginBottom: 2 }}>
                  {colProfile?.Distribution && (
                    <>
                      {Object.keys(colProfile.Distribution).map((key, distIndex) => (
                        <Grid
                          key={distIndex + key}
                          item
                          container
                          xs={4}
                          spacing={2}
                          justifyContent={'left'}
                          sx={{ ...scrollableContainer }}
                        >
                          <Grid item xs={6}>
                            <Typography variant="body2">{key}: </Typography>
                          </Grid>
                          <Grid item xs={3}>
                            <Typography variant="subtitle1" gutterBottom>
                              {colProfile.Distribution ? JSON.stringify(colProfile.Distribution[key]) ?? '' : ''}
                            </Typography>
                          </Grid>
                        </Grid>
                      ))}
                    </>
                  )}
                </Grid>
              )}
            </Card>
          </Grid>
        </LucidWidePopUp>
        <Card sx={{ backgroundColor: 'white' }}>
          <CardHeader
            disableTypography
            sx={{ backgroundColor: 'grey', height: 40 }}
            title={
              <>
                <Grid item xs={12} container alignItems="baseline" spacing={1}>
                  <Grid item xs={6}>
                    <Typography variant="body2" gutterBottom>
                      {'List of Tables'}
                    </Typography>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton
                      size="small"
                      title={`sort ${isTableAsc ? 'desc' : 'asc'}`}
                      onClick={() => handleSortTables(!isTableAsc)}
                    >
                      {isTableAsc ? (
                        <ArrowDownwardOutlined fontSize="small" />
                      ) : (
                        <ArrowUpwardOutlined fontSize="small" />
                      )}
                    </IconButton>
                  </Grid>
                  <Grid item xs={1}>
                    <IconButton title="Filter" onClick={({ currentTarget }) => setFilterAnchor(currentTarget)}>
                      <FilterAltOutlinedIcon fontSize="small" />
                    </IconButton>
                    <Menu
                      id="basic-menu"
                      anchorEl={filterAnchor}
                      open={Boolean(filterAnchor)}
                      onClose={handleFilterSelect}
                      MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        'aria-multiselectable': 'true',
                      }}
                    >
                      {['profiled', 'unprofiled', 'all'].map((itm) => (
                        <MenuItem
                          selected={filterApplied === itm}
                          key={itm}
                          onClick={() => handleFilterSelect(itm as typeof filterApplied)}
                        >
                          {itm}
                        </MenuItem>
                      ))}
                    </Menu>
                  </Grid>
                </Grid>
              </>
            }
          />
          <Divider />
          <List sx={{ width: '100%', maxWidth: 360, marginTop: -1 }} dense>
            {leftTable.map((dt, ix) => (
              <ListItem key={ix}>
                <Checkbox
                  sx={{
                    color: 'green',
                    '&.Mui-checked': {
                      color: green[600],
                    },
                  }}
                  disabled={!dt.isActive}
                  checked={tablesToScan.some((ts) => ts.id === dt.id)}
                  onChange={() => handleSelectToScan(dt)}
                  inputProps={{ 'aria-label': 'controlled' }}
                />
                <ListItemButton
                  sx={{ color: selectedTableId === dt.id ? 'primary.main' : 'inherit', p: 1 }}
                  onClick={() => refreshRightTable(dt.id)}
                >
                  {dt.name.length > 25 ? (
                    <Tooltip title={dt.name} placement="top-start">
                      <Typography variant="body2">{dt.name.slice(0, 20) + '...'}</Typography>
                    </Tooltip>
                  ) : (
                    <Typography variant="body2">{dt.name}</Typography>
                  )}
                  {/* <Typography variant="body2">{dt.name}</Typography> */}
                </ListItemButton>

                {/* <ListItemText
                  disableTypography
                  primary={
                    dt.name.length > 20 ? (
                      <Tooltip title={dt.name} placement="top-start">
                        <Typography variant="body2">{dt.name.slice(0, 20) + '...'}</Typography>
                      </Tooltip>
                    ) : (
                      <Typography sx={{backgroundColor: selectedTableId==}} variant="body2">{dt.name}</Typography>
                    )
                  }
                /> */}
                {dt.profileStatus && <CheckCircleIcon fontSize="small" color="primary" titleAccess="Data Profiled" />}
                <IconButton
                  size="small"
                  title={dt.isActive ? 'isActive' : 'InActive'}
                  onClick={() => {
                    toggleTableState(dt, !dt.isActive)
                  }}
                >
                  {dt.isActive ? <ToggleOnIcon color="success" /> : <ToggleOffIcon />}
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Card>
        {/* <ShadowTable
          rows={leftRows}
          columns={leftTableColumns}
          selectedRow={`${selectedTableId ?? 0}`}
          selectRow={(id) => refreshRightTable(id)}
        /> */}
      </Grid>
      <Grid item container xs={9}>
        <>
          <Grid item xs={12} sx={{ marginBottom: 12 }}>
            <Grid item xs={10} sx={{ marginBottom: 2 }}>
              <Typography sx={{ fontSize: 18 }} color="primary">
                Table Profile Information
              </Typography>
            </Grid>
            <Grid item xs={12} container sx={{ marginBottom: 2 }}>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="TableName" />
                </Grid>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Format" />
                </Grid>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Primary Key" />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2"> {tableProfileData?.['Table Name']} </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2"> {tableProfileData?.Format} </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography variant="body2"> {tableProfileData?.['Primary Key']} </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} />
              </Grid>
              <Grid item container xs={12} spacing={1}></Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Size in Bytes" />
                </Grid>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Rows" />
                </Grid>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Delta Key" />
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                  <Typography variant="body2"> {tableProfileData?.['Size in Bytes']} </Typography>
                </Grid>

                <Grid item xs={4}>
                  <Typography variant="body2"> {tableProfileData?.Rows} </Typography>
                </Grid>
                {showDeltaKeySelectionOption && (<Grid item xs={4}>
                  {/* <Typography variant="body2"> {tableProfileData?.['Delta Key']['Column Name']} </Typography> */}
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    options={columnData}
                    getOptionLabel={(option) => option || ''}
                    sx={{ width: '45%' }}
                    renderInput={(params) => <TextField {...params} />}
                    ListboxProps={{
                      style: { maxHeight: '100%', overflow: 'auto' },
                    }}
                    value={deltaKeyValue}
                    onChange={(event, selectedOption) => { handleSelect(event, selectedOption); }}
                  />
                </Grid>)}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Max Date" />
                </Grid>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Min Date" />
                </Grid>
                <Grid item xs={4}>
                  <LucidTextLabel labelText="Null Values" />
                </Grid>
              </Grid>
              {deltaKeyData?.length && (<Grid item container xs={12} spacing={1}>
                <Grid item xs={4}>
                  {/* <Typography variant="body2"> {tableProfileData?.['Delta Key']['Max Date']} </Typography> */}
                  <Typography variant="body2"> {deltaKeyData[0]['Max Date']} </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <Typography variant="body2"> {tableProfileData?.['Delta Key']['Min Date']} </Typography> */}
                  <Typography variant="body2"> {deltaKeyData[0]['Min Date']} </Typography>
                </Grid>
                <Grid item xs={4}>
                  {/* <Typography variant="body2"> {tableProfileData?.['Delta Key']['Null Values']} </Typography> */}
                  <Typography variant="body2"> {deltaKeyData[0]['Null Values']} </Typography>
                </Grid>
                <Grid item xs={12} />
                <Grid item xs={12} />
              </Grid>)}
            </Grid>

            {/* <Grid item xs={12} sx={{ mb: 2 }}>
            <LucidJupyter
              noteBookString={notebookString}
              headingText={'Verify and adjust generated spark code'}
              updateNoteBookString={setNotebookString}
            />
          </Grid> */}
            <Grid xs={15} />
            <ShadowTable
              rows={rightTable}
              columns={rightTableColumns}
              tableActionParams={{
                onButtonClick: onButtonClick,
                actions: [ShadowTableAction.Viewable],
              }}
            />
          </Grid>
        </>
      </Grid>
    </Grid>
    </>
  )
}
