import React from 'react'
import { Button } from '@mui/material'
import {BaseButtonProps} from './BaseButtonProps'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'

export const PreviousPage: React.FC<BaseButtonProps> = ({pageName, onClick}: BaseButtonProps) => {
  return (
    <Button variant="contained" startIcon={<ArrowBackIcon fontSize="small" />} title={pageName} onClick={onClick} > {pageName} </Button>
  )
}

