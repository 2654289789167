import {
  GenAICoreEntityAttribute,
  GenAIEditEntityMapping,
  GenAIEnrichedCoreEntityAttribute,
  GenAIGetNewEntityMapping,
  GenAIReconcilationBody,
  GenAIRelationsBody,
  GenAISchemaCompatibilty,
  GenAISchemaCompatibiltyAttribute,
  generateGenAISubjectAreasBody,
} from '../businessObjects'
import { sendGenApiRequest } from '../requestManager/sendGenApiRequest'
const genAIPath = {
  getGenAiReconcilation: 'EntityReconciliation',
  getGenAiDataFlow: 'DataFlow',
  getGenAIRelations: 'GenerateRelations',
  getCoreEntityAttributes: 'coreEntityAttributes',
  getEnrichedCoreEntityAttributes: 'enrichedCoreEntityAttributes',
  generateGenAISubjectAreas: 'GenerateSubjectArea',
  editEntityMapping: 'EditEntityMapping',
  newMappings: 'NewGenerateMappings',
  schemaCompatibility: 'SchemaCompatibility',
  schemaCompatibilityAttribute: 'SchemaCompatibilityAttribute',
}

export const getGenAiReconcilation = async (token: string, dataPodId: string, promptInput?: string) => {
  const body: GenAIReconcilationBody = {
    dataPodId: dataPodId,
    promptInput: promptInput,
  }
  const endPoint = genAIPath.getGenAiReconcilation
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const getGenAiDataFlow = async (token: string, dataPodId: string) => {
  const body: GenAIReconcilationBody = {
    dataPodId: dataPodId,
  }
  const endPoint = genAIPath.getGenAiDataFlow
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const generateGenAISubjectAreas = async (token: string, dataPodId: string) => {
  const body: generateGenAISubjectAreasBody = {
    dataPodId: dataPodId,
  }
  const endPoint = genAIPath.generateGenAISubjectAreas
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const getGenAiRelations = async (token: string, dataPodId: string, coreEntityId: number) => {
  const body: GenAIRelationsBody = {
    dataPodId: dataPodId,
    dataCoreEntityId: coreEntityId,
  }
  const endPoint = genAIPath.getGenAIRelations
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const getCoreEntityAttributes = async (token: string, dataPodId: string, coreEntityId: number) => {
  const body: GenAICoreEntityAttribute = {
    dataPodId: dataPodId,
    coreEntityId: coreEntityId,
  }
  const endPoint = genAIPath.getCoreEntityAttributes
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const getEnrichedCoreEntityAttributes = async (
  token: string,
  dataPodId: string,
  enrichedEntityName: string,
  entityType: string,
  description: string,
  canonicalEntities: number[],
) => {
  const body: GenAIEnrichedCoreEntityAttribute = {
    dataPodId: dataPodId,
    enrichedEntityName: enrichedEntityName,
    entityType: entityType,
    description: description,
    canonicalEntities: canonicalEntities,
  }
  const endPoint = genAIPath.getEnrichedCoreEntityAttributes
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const entityServices = { getGenAiReconcilation, generateGenAISubjectAreas }

export const getEditEntityMapping = async (
  token: string,
  dataPodId: string,
  dataSystemId: number,
  dataTableId: number,
  dataEntityId: number,
) => {
  const body: GenAIEditEntityMapping = {
    dataPodId: dataPodId,
    dataSystemId: dataSystemId,
    dataTableId: dataTableId,
    dataEntityId: dataEntityId,
  }
  const endPoint = genAIPath.editEntityMapping
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}
export const NewEntityMapping = async (
  token: string,
  dataPodId: string,
  dataSystemId: number,
  dataTableId: number,
  dataEntityName: string,
  dataEntityDescription: string,
) => {
  const body: GenAIGetNewEntityMapping = {
    dataPodId: dataPodId,
    dataSystemId: dataSystemId,
    dataTableId: dataTableId,
    dataEntityName: dataEntityName,
    dataEntityDescription: dataEntityDescription,
  }
  const endPoint = genAIPath.newMappings
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const SchemaCompatibilty = async (token: string, dataPodId: string, dataEntityId: number) => {
  const body: GenAISchemaCompatibilty = {
    dataPodId: dataPodId,
    dataEntityId: dataEntityId,
  }
  const endPoint = genAIPath.schemaCompatibility
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const SchemaCompatibiltyAttribute = async (
  token: string,
  dataPodId: string,
  dataEntityId: number,
  attributeId: number,
  flag: string,
  dataType: string,
) => {
  const body: GenAISchemaCompatibiltyAttribute = {
    dataPodId: dataPodId,
    dataEntityId: dataEntityId,
    attributeId: attributeId,
    flag: flag,
    dataType: dataType,
  }
  const endPoint = genAIPath.schemaCompatibilityAttribute
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}
