import { sendGenApiRequest } from '../requestManager/sendGenApiRequest'
const genAIPath = {
  businessKey: 'BusinessKey',
  attributeDataTpyes: 'AttributeDataTypes',
}

const generateBusiniessKey = async <T>(token: string, body: T) => {
  const endPoint = genAIPath.businessKey
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}

const generateAttributeDataTypes = async <T>(token: string, body: T) => {
  const endPoint = genAIPath.attributeDataTpyes
  return await sendGenApiRequest(endPoint, 'POST', token, body)
}

export const genDatapodServices = { generateBusiniessKey, generateAttributeDataTypes }
