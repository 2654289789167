import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetQueryString } from '../../hooks/queryStringHook'
import {
  Card as MUICard,
  Typography,
  Grid,
  Skeleton,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { useGetEntitiesByAnalyticsGroup } from '../../hooks/analyticsGroupHooks'
import { DataTableRow } from '../../ui-components/DataTable/DataTableTypes'
import ShadowTable from '../../ui-components/ShadowTable/ShadowTable'

type ViewResultsParams = {
  dataPodId: string
}

const analyticsGroupEntityColumns = [
  {
    label: 'Entity ID',
  },
  {
    label: 'Entity Name',
  },
  {
    label: 'Description',
  },
]

type GroupEntityProps = {
  group_Id?: number
}

const GroupEntities = ({group_Id}: GroupEntityProps) => {
  const groupId = useGetQueryString('groupId')
  const measureId = useGetQueryString('measureId')
  const { dataPodId } = useParams<ViewResultsParams>()

  const [entityExpanded, setEntityExpanded] = useState<boolean>(false)
  const { entitiesLoading, analyticsGroupEntitiesList } = useGetEntitiesByAnalyticsGroup(dataPodId, group_Id ?? groupId)
  const [selectedEntityRows, setSelectEntityGroup] = useState<DataTableRow[]>([])

  useEffect(() => {
    if (analyticsGroupEntitiesList?.result) {
      const tRows = analyticsGroupEntitiesList?.result?.map((ent: any) => ({
        id: `${ent.dataEntityId}`,
        values: [ent.dataEntityId, ent.dataEntityName, ent.dataEntityDescription],
      })) as DataTableRow[]
      setSelectEntityGroup(tRows)
    }
  }, [analyticsGroupEntitiesList])

  return (
    <>
      {entitiesLoading ? (
        <>
          <Skeleton variant="rectangular" height={50} sx={{ p: 1 }} />
        </>
      ) : (
        <Accordion expanded={entityExpanded} onChange={() => setEntityExpanded(!entityExpanded)}>
          <AccordionSummary id={`entity-header`} expandIcon={<ExpandMoreIcon />}>
            <Typography variant="h4" textAlign={'left'} width={'100%'}>
              List of Analytics Entities
            </Typography>
          </AccordionSummary>
          <AccordionDetails>
            <ShadowTable rows={selectedEntityRows} columns={analyticsGroupEntityColumns} />
          </AccordionDetails>
        </Accordion>
      )}
    </>
  )
}

export default GroupEntities
