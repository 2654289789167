
import { LucidAccount, UserAccount } from '../businessObjects'

import { ServerLucidAccountParams } from '../hooks/lucidAccountHooks'

import { sendRequest } from '../requestManager/sendRequest'

const lucidAccountPath = {
  createAccount: 'Account/addNewAccount',
  getAllAccountsByAccountId: 'Account/GetById/',
  getAllAccountsByTenantID: 'Account/GetAccountByTenantId',
  updateAccount: 'Account/Update',
}

const createAccount = async (token: string, account: LucidAccount) => {

  const endPoint = lucidAccountPath.createAccount
  return sendRequest(endPoint, 'POST', token, account)
}
const updateAccount = async (token: string, lucidAccount: ServerLucidAccountParams) => {

  const endPoint = lucidAccountPath.updateAccount
  return sendRequest(endPoint, 'PUT', token, lucidAccount)
}
const getAllAccountsByAccountId = async (token: string, accountId: string) => {
  const endPoint =lucidAccountPath.getAllAccountsByAccountId + `${accountId}`

  return sendRequest(endPoint, 'GET', token)
}
const getAllAccountsByTenantID = async (token: string, tenenatId: string) => {
  const endPoint =  lucidAccountPath.getAllAccountsByTenantID + `?tenenatId=${tenenatId}`
  return sendRequest(endPoint, 'GET', token)
}

export const accountServices = {
  createAccount,
  updateAccount,
  getAllAccountsByAccountId,
  getAllAccountsByTenantID,
}
