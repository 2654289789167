import { Badge, Box, CssBaseline, Grid, IconButton, InputAdornment, TextField, Toolbar, styled } from '@mui/material'
import { useState } from 'react'
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar'

import { drawerWidth } from '../Drawer/LucidDataDrawer'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HelpOutlineOutlinedIcon from '@mui/icons-material/HelpOutlineOutlined'
import { AccountInfo } from '../AccountInfo'
import CancelIcon from '@mui/icons-material/Cancel'
// import NotificationsIcon from '@mui/icons-material/Notifications'
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { LucidBreadCrumbs } from './LucidBreadCrumbs'
import React from 'react'
import { NotificationPopper } from './NotificationPopper'

interface AppBarProps extends MuiAppBarProps {
  open?: boolean
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}))

export const LucidAppBar = ({ children, open }: AppBarProps) => {
  const anchorRef = React.useRef(null)
  const [searchText, setSearchText] = useState<string>('')
  const [isOpen, setIsOpen] = React.useState(false)
  const [shouldNotify, setShouldNotify] = React.useState(false)

  const handleSearch = (textInput: string): void => {
    setSearchText(textInput)
  }

  const handleToggle = () => {
    setIsOpen((prevOpen) => !prevOpen)
    setShouldNotify(false)
  }

  return (
    <>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{ width: `calc(100% - ${drawerWidth}px)`, boxShadow: 0, zIndex: 1 }}
        color="transparent"
      >
        {/* will be replaced with Navigation BreadCrumbs */}
        <Toolbar>
          <LucidBreadCrumbs />

          <Box sx={{ flexGrow: 1 }} />
          <Grid item container xs={4} md={3}>
            <TextField
              placeholder="Search"
              value={searchText}
              onChange={({ target }) => handleSearch(target.value)}
              color="primary"
              margin="dense"
              size="small"
              type="search"
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchOutlinedIcon />
                  </InputAdornment>
                ),
                endAdornment: searchText ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setSearchText('')}>
                      <CancelIcon fontSize="small" />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <></>
                ),
              }}
            />
          </Grid>

          {/* Notification */}
          <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
            <IconButton
              color={'inherit'}
              ref={anchorRef}
              aria-label="Notifications"
              aria-controls={isOpen ? 'menu-list-grow' : undefined}
              aria-haspopup="true"
              onClick={handleToggle}
            >
              <Badge color={'secondary'} variant={shouldNotify && !isOpen ? 'dot' : 'standard'}>
                <NotificationsNoneIcon />
              </Badge>
            </IconButton>
            <NotificationPopper
              handleToggle={handleToggle}
              isOpen={isOpen}
              shouldNotify={shouldNotify}
              setShouldNotify={setShouldNotify}
              anchorRef={anchorRef}
            />
            <IconButton color="inherit">
              <HelpOutlineOutlinedIcon />
            </IconButton>
            <AccountInfo />
          </Box>

          {children}
        </Toolbar>
      </AppBar>
    </>
  )
}
