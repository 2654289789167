import { sendRequest } from '../requestManager/sendRequest'

const rolesPath = {
    getRoles: 'Permissions/roles',
    getUsers: 'Permissions/users',
    getUsersByRole: 'Permissions/role/users',
    getUsersByDatapodId: 'Permissions/role/usersByDatapod',
    assignRole: 'Permissions/assignrole',
    editUserRole: 'Permissions/editUserRole',
    deleteUserRole: 'Permissions/deleteUserRole',
}

const getRoles = async (token: string) => {
    const endPoint = rolesPath.getRoles
    return sendRequest(endPoint, 'GET', token)
}

const getUsers = async (token: string) => {
    const endPoint = rolesPath.getUsers
    return sendRequest(endPoint, 'GET', token)
}

const getUsersByRole = async (token: string, roleId: number) => {
    const endPoint = rolesPath.getUsersByRole + `?roleId=${roleId}`
    return sendRequest(endPoint, 'GET', token)
}

const getUsersByDatapodId = async (token: string, datapodId: string) => {
    const endPoint = rolesPath.getUsersByDatapodId + `?datapodId=${datapodId}`
    return sendRequest(endPoint, 'GET', token)
}

const assignRoles = async (token: string, userId: number, roleId: number, datapodId: string) => {
    const endPoint = rolesPath.assignRole + `?userId=${userId}&roleId=${roleId}&datapodId=${datapodId}`
    return sendRequest(endPoint, 'POST', token)
}

const editUserRole = async (token: string, userId: number, roleId: number, datapodId: string) => {
    const endPoint = rolesPath.editUserRole + `?userId=${userId}&roleId=${roleId}&datapodId=${datapodId}`
    return sendRequest(endPoint, 'PUT', token)
}

const deleteUserRole = async (token: string, userId: number, roleId: number, datapodId: string) => {
    const endPoint = rolesPath.deleteUserRole + `?userId=${userId}&roleId=${roleId}&datapodId=${datapodId}`
    return sendRequest(endPoint, 'DELETE', token)
}


export const rolesServices = {
    getRoles,
    getUsers,
    getUsersByRole,
    getUsersByDatapodId,
    assignRoles,
    editUserRole,
    deleteUserRole,
}