import { UserAccount } from 'businessObjects'
import { sendRequest } from '../requestManager/sendRequest'

const userPath = {
  addUser: 'User/addNewUser',
  getUserByMailId: 'User/getUserByMailId',
  getUsersByTenantId: 'User/getUsersByTenantId',
}

const addUser = async (token: string, user: UserAccount) => {
  const endPoint = userPath.addUser
  return sendRequest(endPoint, 'POST', token, user)
}

const getUserByMailId = async (token: string, mailId: string) => {
  const endPoint = userPath.getUserByMailId + `?mailId=${mailId}`
  return sendRequest(endPoint, 'GET', token)
}

const getUsersByTenantId = async (token: string, tenantId: string) => {
  const endPoint = userPath.getUsersByTenantId + `?tenantId=${tenantId}`
  return sendRequest(endPoint, 'GET', token)
}

export const userServices = {
  addUser,
  getUserByMailId,
  getUsersByTenantId,
}
