import CodeMirror from '@uiw/react-codemirror'
import { json } from '@codemirror/lang-json'
import { lintGutter } from '@codemirror/lint'
import { EditorView } from '@codemirror/view'
import { useEffect, useMemo, useState } from 'react'
import { Button, CircularProgress, FormHelperText, Grid, MenuItem, Select, Typography } from '@mui/material'
import { AddOutlined, CancelOutlined } from '@mui/icons-material'
import {
  ServiceConnectionType,
  useAddServiceConnection,
  useGetServiceConnectionConfigs,
  useGetServiceConnectionTypes,
  useGetServiceConnections,
  usePostServiceConnection,
  useUpateServiceConnection,
} from '../../../hooks/serviceConnectionHooks'
import { isValidEntry, isValidDescription } from '../../../utils/constants'
import ResultStatus from '../../../ui-components/ResultStatus'
import AnimatedLoader from '../../../ui-components/AnimatedLoader'
import ServiceNameWithIcon from '../../../ui-components/ServiceNameWithIcon'
import LucidDataPodDebounceSearch from '../../../ui-components/LucidDataPodDebounceSearch'
import TextFormInput from 'ui-components/TextForm/TextFormInput'
import { HeadingLabel, LucidTextLabel } from 'ui-components/LucidTextLabel'
import { UpdateServiceConnection, NewServiceConnection } from '../../../businessObjects'
import { PreviousPage } from 'ui-components/Button/PreviousPage'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import ToggleEditor, { ToggleEditorType } from 'ui-components/ToggleView/ToggleEditor'


const options = {
  autoFocus: true,
  lineNumbers: true,
  lineWrapping: true,
  autocompletion: true,
  highlightActiveLine: true,
  highlightSelectionMatches: true,
  mode: {
    name: 'javascript',
    json: true,
    statementIndent: 2,
  },
}

type DataService = {
  id: number
  serviceName: string
  serviceDescription: string
  isActive: boolean
  serviceConnectionTypeId: string
  jsonConfiguration: string
}

type Props = {
  selectedConnection?: NewServiceConnection
  closeAddForm: (podId?: string) => void
  dataPodId?: string
  serviceConnectionId?: number
  handleService?: (args?: number) => void
  isAddNewPodStep?: boolean
}

const AddServiceConnection = ({
  handleService,
  dataPodId,
  selectedConnection,
  serviceConnectionId,
  closeAddForm,
  isAddNewPodStep,
}: Props) => {
  const isEdit = !!serviceConnectionId
  const isView = !!serviceConnectionId
  const hideDataPodOption = isAddNewPodStep

  // const [addedSuccess, setAddedSuccess] = useState<boolean>(false)

  const [connectionName, setConnectionName] = useState<string>('')
  const [connectionType, setConnectionType] = useState<string>('')
  const [serviceType, setServiceType] = useState<string>('')
  const [toggleEditor, setToggleEditor] = useState<ToggleEditorType>('Code')
  const [serviceConfig, setServiceConfig] = useState<string>('')
  const [podServiceConfig, setPodServiceConfig] = useState<{ [key: string]: string }>({})
  const [serviceConfigObject, setServiceConfigObject] = useState<{ [key: string]: string }>({})
  const [podId, setPodId] = useState<string>()
  const { serviceConnectionTypes, isTypeLoading } = useGetServiceConnectionTypes()
  const { serviceConnTypeConfigs, isTypeConnLoading } = useGetServiceConnectionConfigs(connectionType)
  const { addServiceConnectionFn, isAddingConnection } = useAddServiceConnection(podId)
  const { updateServiceConnectionFn, isUpdatingConnection } = useUpateServiceConnection(podId)
  const navigate = useNavigate()
  const [invalidJson, setInvalidJson] = useState<boolean>(false)

  useEffect(() => {
    if (dataPodId) {
      setPodId(dataPodId)
    }
  }, [dataPodId])
  // const { dataServices, connectionTypes = [], getServiceType, isDataServiceLoading } = useGetServiceConnections()
  // const { postError, postComplete, addServiceConnection, updateServiceConnection } = usePostServiceConnection(dataPod)

  // const body = {
  //   id: serviceConnectionId || 0,
  //   serviceConnectionJsonTemplate: JSON.stringify(serviceConfigObject),
  //   serviceConnectionTypeId: connectionType,
  //   serviceTypeId: serviceType,
  //   locationInfo: '/data/services/json',
  //   connectionName: connectionName,
  //   connectionType: connectionType,
  //   action: selectedConnection?.id ? 'edit' : 'new',
  // }

  // console.log({ selectedConnection })

  // const handleEditorChange = (json: any) => {
  //   try {
  //     setServiceConfig(json)
  //     JSON.parse(json)
  //     handleService && handleService(body)
  //     setErrorState(false)
  //   } catch (e) {
  //     setErrorState(true)
  //   }
  // }

  // useEffect(() => {
  //   if (postError) {
  //     //Add/Edit - handling error message.
  //     setErrorState(true)
  //   }
  // }, [postError])

  useEffect(() => {
    if (selectedConnection && !connectionType) {
      // console.log({ selectedConnection })
      setConnectionName(selectedConnection?.connectionName)
      // setDataPod(selectedConnection?.dataPodId)
      setConnectionType(selectedConnection?.serviceConnectionTypeId)
      setServiceType(selectedConnection.serviceTypeId.toString())
      setServiceConfig(selectedConnection.serviceConnectionJsonTemplate)
      try {
        const servConfig = JSON.parse(selectedConnection.serviceConnectionJsonTemplate)
        // console.log(servConfig)
        if (servConfig) {
          setPodServiceConfig(servConfig)
          setServiceConfigObject(servConfig)
        }
      } catch (error) {
        console.log({ error })
        setInvalidJson(true)
      }
    }
  }, [selectedConnection])

  const isLoading = useMemo(() => {
    return isAddingConnection || isUpdatingConnection || isTypeConnLoading || isTypeLoading
  }, [isAddingConnection, isUpdatingConnection, isTypeConnLoading, isTypeLoading])

  //this will wait for load connection type and data pods at edit/view case
  // useEffect(() => {
  //   if (selectedConnection?.dataPodId && selectedConnection?.action) {
  //     //on view/edit once all pre-selection done
  //     setServiceType(selectedConnection?.serviceTypeId?.toString())
  //     // setServiceConfig(selectedConnection?.serviceConnectionJsonTemplate)
  //   }
  // }, [dataServices?.length])

  // useEffect(() => {
  //   if (postComplete?.dataPodId) {
  //     //Edit - allowing modify n times.
  //     if (isEdit) {
  //       setAddedSuccess(true)
  //     } else {
  //       //Add - Once added successfully navigated to List page with podId filter
  //       closeAddForm(dataPod)
  //     }
  //   }
  // }, [postComplete])

  // useEffect(() => {
  //   if (handleService && body) {
  //     // handleService(body)
  //   }
  // }, [connectionName, serviceConfig, connectionType, serviceType])

  // const handleSave = () => {
  //   if (isEdit) {
  //     body.id = selectedConnection.id
  //     updateServiceConnection(body)
  //   } else {
  //     addServiceConnection(body)
  //   }
  // }

  const handleConnectionType = (val: string) => {
    setConnectionType(val)
    setServiceType('')
    setPodServiceConfig({})
    setServiceConfigObject({})
    setServiceConfig('')
  }

  const handleServiceType = (value: string) => {
    setServiceType(value)
    const jsonConfiguration = serviceConnTypeConfigs.find((s) => s.id === parseInt(value))?.jsonConfiguration
    // handleEditorChange(jsonConfiguration)
    if (jsonConfiguration) {
      setServiceConfig(jsonConfiguration)
      try {
        const servConfig = JSON.parse(jsonConfiguration)
        // console.log(servConfig)
        if (servConfig) {
          
          setPodServiceConfig(servConfig)

          // initialize key-values  to preserve the serviceConnect object and save in the database.
          // Preserving serviceConnection object is essential for  update. the keys will be retained even if values doesn't exist or set.
          const initConf = Object.keys(servConfig).reduce((acc, cr) => {
            return {...acc, [cr]: ""}
          },{})

          setServiceConfigObject(initConf)
        }
      } catch (error) {
        console.log({ error })
      }
    }
  }

  const handleAddClose = () => {
    setConnectionName('')
    setConnectionType('')
    setServiceType('')
    // setServiceConfig('')
    closeAddForm(podId)
  }

  const handleServiceConfig = (configKey: string, configValue: string) => {
    setServiceConfigObject((prv) => ({ ...prv, [configKey]: configValue }))
  }

  // console.log({ selectedConnection })

  const confirmConnection = async () => {
    const serviceConnParams: NewServiceConnection = {
      serviceConnectionJsonTemplate: toggleEditor === 'Code'? serviceConfig  :JSON.stringify(serviceConfigObject),
      serviceConnectionTypeId: connectionType,
      serviceTypeId: Number(serviceType),
      locationInfo: selectedConnection?.locationInfo ? selectedConnection.locationInfo : '/data/services/json',
      connectionName: connectionName,
      connectionType: connectionType,
    }
    console.log({serviceConnParams})
    if (serviceConnectionId) {
      const updatedId = await updateServiceConnectionFn({
        id: serviceConnectionId,
        ...serviceConnParams,
      } as UpdateServiceConnection)
      handleService && handleService(updatedId)
    } else {
      const newConnectId = await addServiceConnectionFn(serviceConnParams)
      handleService && handleService(newConnectId)
    }
  }

  const configLabels = useMemo(() => {
    const keyLabels = Object.keys(podServiceConfig)
    if (!keyLabels.length) return []
    return keyLabels
  }, [podServiceConfig])

  const configPlaceHolders = useMemo(() => {
    const placeHolders = Object.values(podServiceConfig)
    if (!placeHolders.length) return []
    return placeHolders
  }, [podServiceConfig])

  const handleEditor = (configChanges: string) => {
    setServiceConfig(configChanges)
    setInvalidJson(false)
    try {
      const jsonObj = JSON.parse(configChanges)
      
      setServiceConfigObject(jsonObj)
   } catch (error) {
      setInvalidJson(true)
      console.log('Incomple Json')
   }
  }

  const handleToggle = (toggle: ToggleEditorType) => {
    if( toggle ==='Code') {
    //  console.log({serviceConfig})
      const jsonTemp = JSON.stringify(serviceConfigObject)
     
      setServiceConfig(jsonTemp)
      
    } else {
      try {
        const jsonObj = JSON.parse(serviceConfig)
         
        setServiceConfigObject(jsonObj)
       } catch (error) {
          setInvalidJson(true)
          console.log('Incomple Json')
       }
    }
    setToggleEditor(toggle)
  }

  return (
    <>
      <>
        {/* <ResultStatus
          severtiy="success"
          showStatus={addedSuccess}
          closeStatus={() => setAddedSuccess(false)}
          alertMessage={`SUCCESS: ${isEdit ? `Updated` : `Created`}  Service: ${connectionName} !`}
        /> */}
        {/* <ResultStatus
          showStatus={errorState}
          alertMessage={postError?.message || 'Please fill required fields or invalid entries'}
          severtiy="error"
          closeStatus={() => setErrorState(false)}
        /> */}
      </>
      <Grid item xs={3} mb={3}>
           <PreviousPage  onClick={() => navigate(generatePath("/Settings/ServiceConnection"))} pageName={"Back"} />
        </Grid>
      <AnimatedLoader height="50%" width="40%" loading={isLoading} />
      <Grid container item xs={12}>
        <Grid item xs={12} justifyContent={'space-between'} alignItems={'center'}>
          <Grid item xs={6}>
            <Typography variant="h1">{isView ? `View` : isEdit ? `Update` : `Create`} Service Connection</Typography>
          </Grid>
          <Grid item xs={15} container sx={{ m: 1, p: 1 }} />
        </Grid>
        {!hideDataPodOption && (
          <Grid item xs={12} sx={{ lineHeight: 3 }}>
            <Typography component="span" variant="subtitle1">
              Data Pod Name
            </Typography>

            <LucidDataPodDebounceSearch
              defaultVal={podId}
              selectPod={setPodId}
              size="small"
              disabled={isEdit || isView}
            />
          </Grid>
        )}

        {podId && (
          <>
            {/* <Grid item xs={14} container alignContent="baseline" justifyItems="baseline" spacing={1}>
              <Grid item xs={5}>
                <TextFormInput
                  value={connectionName}
                  columnSize={11}
                  labelText=" Connection Name"
                  changeHandle={(connName) => setConnectionName(connName)}
                  validateInput={!isValidEntry(connectionName)}
                  validateErrorText={!isValidEntry(connectionName) ? 'Invalid Connection Name' : ''}
                />
               
              </Grid>
            </Grid> */}
            <Grid item xs={14} container alignContent="baseline" justifyItems="baseline" spacing={4}>
              <Grid item xs={5}>
                <LucidTextLabel labelText="Connection Type" />
                <Select
                  fullWidth
                  labelId="select-service-type-label"
                  id="service-typet"
                  value={connectionType || ''}
                  sx={{ height: '40px' }}
                  disabled={isEdit || isView || isLoading}
                  // endAdornment={isLoading && <CircularProgress color="info" size={60} />}
                  onChange={({ target }) => handleConnectionType(target?.value)}
                  required
                >
                  {serviceConnectionTypes.map((srvc) => (
                    <MenuItem key={srvc?.id} value={srvc?.id}>
                      {srvc?.serviceConnectionType1}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={5}>
                <LucidTextLabel labelText="Service Type" />

                <Select
                  fullWidth
                  labelId="select-service-type-label"
                  id="service-type"
                  value={serviceType || ''}
                  sx={{ height: '40px' }}
                  disabled={isEdit || isView || isLoading}
                  onChange={({ target }) => handleServiceType(target?.value)}
                  required
                >
                  {serviceConnTypeConfigs.map((srvc: DataService) => (
                    <MenuItem key={srvc?.id} value={srvc?.id?.toString()}>
                      <ServiceNameWithIcon serviceName={srvc?.serviceName} />
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
              <Grid item xs={5}>
                <TextFormInput
                  value={connectionName}
                  // columnSize={11}
                  labelText=" Connection Name"
                  changeHandle={(connName) => setConnectionName(connName)}
                  validateInput={!isValidEntry(connectionName)}
                  validateErrorText={!isValidEntry(connectionName) ? 'Invalid Connection Name' : ''}
                />
              </Grid>
              <Grid item xs={1} container alignItems="center"  >
                <ToggleEditor viewType={toggleEditor} disabledForm={invalidJson} handleToggle={handleToggle} />
              </Grid>
            </Grid>
          </>
        )}

        {serviceType && (
          <Grid item xs={14} sx={{ ml: 3, pl: 3 }} container alignContent="center" justifyItems="baseline" spacing={2}>
            <Grid item xs={16} />
            <Grid item xs={4} />
            <Grid item xs={5}>
              <HeadingLabel headingText={'Service Configuaration'} />
            </Grid>
            <Grid item xs={16} />
              {toggleEditor === 'Form' ? configLabels.map((pk, ix) => (
                <Grid key={pk} item xs={5.5}>
                  <TextFormInput
                    labelPostion="left"
                    columnSize={11}
                    value={serviceConfigObject[pk] || ''}
                    required
                    labelText={pk}
                    multiline={
                      configPlaceHolders.length &&
                      typeof configPlaceHolders[ix] == 'string' &&
                      configPlaceHolders[ix].includes('PRIVATE')
                        ? 4
                        : undefined
                    }
                    // validateInput={!serviceConfigObject[pk]}
                    placeholderText={configPlaceHolders[ix]}
                    changeHandle={(confval) => handleServiceConfig(pk, confval)}
                  />
                </Grid>
              )): 
                <Grid  item xs={10}>
                  <CodeMirror
                  {...options}
                  editable={!isView || isEdit}
                  value={serviceConfig}
                  basicSetup={options}
                  onChange={(value) => handleEditor(value)}
                  extensions={[EditorView.lineWrapping, json(), lintGutter()]}
                /> 
                {invalidJson && <FormHelperText error > <Typography variant="h4">Invalid JSON Format</Typography> </FormHelperText>}
                </Grid>
            }

            <Grid item xs={12} container alignContent="flex-end" spacing={1}>
              <Grid item xs={15} />

              <Grid item xs={2.5}>
                <Button
                  fullWidth
                  disabled={!connectionName || !connectionType || !serviceType || invalidJson}
                  variant="contained"
                  onClick={confirmConnection}
                >
                  {serviceConnectionId ? 'Update Connection' : 'Add Connection'}
                </Button>
              </Grid>
              <Grid item xs={2.5}>
                <Button
                  fullWidth
                  color="secondary"
                  onClick={() => closeAddForm(podId)}
                  variant="outlined"
                  startIcon={<CancelOutlined />}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}
      </Grid>
    </>
  )
}

export default AddServiceConnection
