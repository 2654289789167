import { Button, Grid, IconButton, Typography } from '@mui/material'
import MailOutlinedIcon from '@mui/icons-material/MailOutlined'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'

import { LucidAccount, UserAccount } from 'businessObjects'
import { HeadingLabel, LucidTextLabel } from 'ui-components/LucidTextLabel'
import { useAddUser } from 'hooks/userHooks'
import { useCreateLucidAccount } from 'hooks/lucidAccountHooks'
import { useGetEnterprisePlans, useGetUserPlans } from 'hooks/subscriptionHooks'
import { useLucidAuthContext } from 'LucidAuthContext/LucidAuthContext'
import { useNavigate } from 'react-router-dom'
import EmailAddress from './EmailAddress'
import { useState } from 'react'
import ActionPage from 'ui-components/ActionPage'
import ResultStatus from 'ui-components/ResultStatus'

type Props = {
  onNext: () => void
  onBack: () => void
  lucidAccount: LucidAccount
  setLucidAccount: React.Dispatch<React.SetStateAction<LucidAccount>>
  userAccount: UserAccount
  setUserAccount: React.Dispatch<React.SetStateAction<UserAccount>>
  setActiveStep: React.Dispatch<React.SetStateAction<number>>
}

const Confirmation = ({
  onNext,
  onBack,
  lucidAccount,
  setLucidAccount,
  userAccount,
  setUserAccount,
  setActiveStep,
}: Props) => {
  const { addUser } = useAddUser()
  const [submitLucidAccount, createError] = useCreateLucidAccount()
  const { updatePreferredAccountId, setLucidUserId, accountId } = useLucidAuthContext()

  const { enterprisePlans } = useGetEnterprisePlans()
  const { userPlans } = useGetUserPlans()

  const [successMessage, setSuccessMessage] = useState<string>()
  const [successState, setSuccessState] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const onConfirm = async () => {
    if (lucidAccount && userAccount) {
      const addedUser = await addUser(userAccount)

      if (!addedUser) {
        setErrorState(true)
        setErrorMessage("Failed to add user.")
        return
      } else {
        setSuccessState(true)
        setSuccessMessage("User added successfully.")
      }

      if (!accountId) {
        const addedLucidAcnt = await submitLucidAccount({ ...lucidAccount, registeredUserId: addedUser.id })

        if (createError) {
          setErrorState(true)
          setErrorMessage("Failed to add account.")
          return
        } else {
          setSuccessState(true)
          setSuccessMessage("Account added successfully.")
        }

        if (!addedLucidAcnt || !addedLucidAcnt?.accountId || !lucidAccount.planId) {
          return
        }

        updatePreferredAccountId(addedLucidAcnt.accountId)
      }

      setLucidUserId(addedUser.id)
    }

    onNext()
  }

  return (
    <Grid
    xs={12}
    sx={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      height: '100%',
      gap: 5,
    }}
    item
  >
      {successMessage && (
        <ResultStatus
          severtiy="success"
          showStatus={successState}
          closeStatus={setSuccessState}
          alertMessage={successMessage}
        />
      )}
      {errorMessage && (
        <ResultStatus
          severtiy="error"
          showStatus={errorState}
          closeStatus={setErrorState}
          alertMessage={errorMessage}
        />
      )}
     
        <Grid item>
          <MailOutlinedIcon color="primary" />
        </Grid>
        <Grid item>
          <Typography variant="h2" gutterBottom>
            Review Form
          </Typography>
          <Typography variant="body1" gutterBottom>
            Review and submit information.
          </Typography>
        </Grid>

        <Grid item xs={8} container spacing={2} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {/* Email Address */}
          <Grid item container sx={{ gap: 2 }}>
            <Grid item xs={10} container sx={{ justifyContent: 'space-between' }} alignItems={'baseline'}>
              <HeadingLabel headingText={'Email Address'} />
              {/* <IconButton aria-label="edit" size="small">
                <ModeEditOutlineIcon color="primary" fontSize="small"/>
              </IconButton> */}
            </Grid>
            <Grid item xs={10} sx={{ gap: 5, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Grid item xs={5}>
                <LucidTextLabel labelText="Work Email Address" />
                <Typography variant="body2" color={'secondary'}>
                  {userAccount.email}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <LucidTextLabel labelText="Authenticator Provider" />
                <Typography variant="body2" color={'secondary'}>
                  Azure Entra Id
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Account Details */}
          {!accountId && (
            <>
              <Grid item container sx={{ gap: 2 }}>
                <Grid item xs={10} container sx={{ justifyContent: 'space-between' }} alignItems={'baseline'}>
                  <HeadingLabel headingText={'Account Details'} />
                  <IconButton aria-label="edit" size="small" onClick={() => setActiveStep(1)}>
                    <ModeEditOutlineIcon color="primary" fontSize="small" />
                  </IconButton>
                </Grid>
                <Grid item xs={10} sx={{ gap: 5, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                  <Grid item xs={5}>
                    <LucidTextLabel labelText="Account Name" />
                    <Typography variant="body2" color={'secondary'}>
                      {lucidAccount.accountName}
                    </Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <LucidTextLabel labelText="Organization Id" />
                    <Typography variant="body2" color={'secondary'}>
                      {lucidAccount.accountTenantId}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid item xs={10} sx={{ gap: 5, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
                  <Grid item xs={5}>
                    <LucidTextLabel labelText="Account Plan" />
                    <Typography variant="body2" color={'secondary'}>
                      {enterprisePlans && enterprisePlans.find(plan => plan.id == lucidAccount.planId)?.name}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}

          {/* User Details */}
          <Grid item container sx={{ gap: 2 }}>
            <Grid item xs={10} container sx={{ justifyContent: 'space-between' }} alignItems={'baseline'}>
              <HeadingLabel headingText={'User Details'} />
              <IconButton aria-label="edit" size="small" onClick={() => (accountId ? setActiveStep(0) : setActiveStep(2))}>
                <ModeEditOutlineIcon
                  color="primary"
                  fontSize="small"
                />
              </IconButton>
            </Grid>
            <Grid item xs={10} sx={{ gap: 5, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Grid item xs={5}>
                <LucidTextLabel labelText="First Name" />
                <Typography variant="body2" color={'secondary'}>
                  {userAccount.fullName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={10} sx={{ gap: 5, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Grid item xs={5}>
                <LucidTextLabel labelText="Country Code" />
                <Typography variant="body2" color={'secondary'}>
                  {userAccount.countryCode}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <LucidTextLabel labelText="Phone number" />
                <Typography variant="body2" color={'secondary'}>
                  {userAccount.phoneNumber}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={10} sx={{ gap: 5, display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}>
              <Grid item xs={5}>
                <LucidTextLabel labelText="Country" />
                <Typography variant="body2" color={'secondary'}>
                  {userAccount.country}
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <LucidTextLabel labelText="User plan" />
                <Typography variant="body2" color={'secondary'}>
                  {userPlans && userPlans.find(plan => plan.id == userAccount.planId)?.name}
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          {/* Plan Details */}
          {/* <Grid item container sx={{gap: 2}}>
            <Grid item xs={10} container sx={{justifyContent: 'space-between'}} alignItems={'baseline'}>
                <HeadingLabel headingText={'Plan Details'} />
                <IconButton aria-label="edit" size="small">
                  <ModeEditOutlineIcon color="primary" fontSize="small"/>
                </IconButton>
            </Grid>
            <Grid item xs={10} sx={{gap: 5, display: 'flex', flexDirection:'row', justifyContent: 'flex-start'}}>
              <Grid item xs={5}>
                <LucidTextLabel labelText="Plan name" />
                <Typography variant="body2" color={'secondary'}>
                  bhimeub.com
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <LucidTextLabel labelText="Plan price" />
                <Typography variant="body2" color={'secondary'}>
                  Azure Entra Id
                </Typography>
              </Grid>
            </Grid>
          </Grid> */}
        </Grid>
        <Grid item sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={onBack} variant="outlined">
            Back
          </Button>
          <Button type="submit" variant="contained" onClick={onConfirm} color="primary" sx={{ ml: 2 }}>
            Save and Continue
          </Button>
        </Grid>
      </Grid>
  )
}

export default Confirmation
