import { useState } from "react";

import { Box, Button, Card, CardContent, CardHeader, FormLabel, Grid, List, ListItem, ListItemText, Theme, Typography, makeStyles } from "@mui/material";
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter';

import TextFormInput from "ui-components/TextForm/TextFormInput";
import { EnterprisePlans, LucidAccount, UserAccount } from "businessObjects";
import { useGetEnterprisePlans } from "hooks/subscriptionHooks";
import { theme } from "styles/theme";
import { environment } from "utils/environment";

type Props = {
  onNext: () => void
  onBack: () => void
  lucidAccount: LucidAccount
  setLucidAccount: React.Dispatch<React.SetStateAction<LucidAccount>>
  userAccount: UserAccount
  setUserAccount: React.Dispatch<React.SetStateAction<UserAccount>>
}

const AccountDetails = ({ onNext, onBack, lucidAccount, setLucidAccount, userAccount, setUserAccount }: Props) => {

  const { enterprisePlans } = environment.isEnterpriseApp ? { enterprisePlans: []} : useGetEnterprisePlans()
  const [selectedPlan, setSelectedPlan] = useState<number | undefined>(lucidAccount?.planId);

  const handleSelectPlan = (planId: number) => {
    setSelectedPlan(planId);
    setLucidAccount({...lucidAccount, planId})
  };

  const isFormValid = lucidAccount.accountName && lucidAccount.accountTenantId && (environment.isEnterpriseApp ? true : selectedPlan);

  return (
    <>
        <Box sx={{ height: '100%',  width: '100%', display: 'flex', flexDirection: 'column', gap: 5 }}>
          <BusinessCenterIcon color="primary"/>

          <Box>
            <Typography variant="h2" gutterBottom>
              Account Details
            </Typography>
            <Typography variant="body1" gutterBottom>
              Enter your account information to get access to Lucid Data Hub.
            </Typography>
          </Box>

          <form onSubmit={onNext} style={{height: '100%', width: '100%'}}>
            <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  width: '30%',
                  gap: 3,
                }}
              >
                <TextFormInput 
                  fullWidth 
                  required 
                  labelText="Account (Organization) Name" 
                  value={lucidAccount.accountName?.toString() || ''} 
                  // validateErrorText={!formData.organizationName ? 'This field is required' : ''}
                  // changeHandle={(value) => setFormData({...formData, organizationName: value})} 
                  changeHandle={(value) => setLucidAccount({...lucidAccount, accountName: value})} 
                  // validateInput={!formData.organizationName} 
                />
                <TextFormInput 
                  fullWidth
                  required 
                  disabled={true}
                  labelText="Organization Id" 
                  value={lucidAccount.accountTenantId.toString()} 
                  // validateErrorText={!formData.organizationId ? 'This field is required' : ''}
                  changeHandle={(value) => {}} 
                  // validateInput={!formData.organizationId} 
                />
              </Box>

              <Box sx={{ display: 'flex', justifyContent: 'space-evenly' , width: '100%'}}>
                {/* {enterprisePlans.length && (
                  <>
                    {enterprisePlans.map((plan) => (
                      <Card key={'accountPlanId_'+plan.id} sx={{ minWidth: 275, marginBottom: 2 }}>
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {plan.name}
                          </Typography>
                          <Typography variant="body1" color="text.secondary" sx={{ whiteSpace: 'pre-line' }} gutterBottom>
                            {plan.description}
                          </Typography>
                          <List dense>
                            <ListItem>
                              <ListItemText primary={`Price: $${plan.price.toFixed(2)}`} />
                            </ListItem>
                          </List>
                          <Button
                            variant={selectedPlan === plan.id ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => handleSelectPlan(plan.id)}
                            sx={{ mt: 2 }}
                          >
                            {selectedPlan === plan.id ? 'Selected' : 'Select'}
                          </Button>
                        </CardContent>
                      </Card>
                      )
                    )}
                  </>
                )} */}
                <Grid container spacing={3}>
                  {enterprisePlans.map((plan) => (
                    <Grid item xs={12} md={4} key={'accountPlanId_' + plan.id}>
                      <Card
                        sx={{
                          backgroundColor: theme.palette.background.paper,
                          padding: theme.spacing(4),
                          borderRadius: theme.shape.borderRadius,
                          minWidth: 275,
                          marginBottom: theme.spacing(2),
                          // minHeight: 350,
                          justifyContent: 'space-around'
                        }}
                      >
                        <CardHeader title={plan.name} />
                        <CardContent>
                          <Typography variant="body1" color="text.secondary" sx={{ whiteSpace: 'pre-line' }} gutterBottom>
                            {plan.description}
                          </Typography>
                          <List dense>
                            <ListItem>
                              <ListItemText primary={`Price: $${plan.price.toFixed(2)}`} />
                            </ListItem>
                          </List>
                          <Button
                            variant={selectedPlan === plan.id ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => handleSelectPlan(plan.id)}
                            sx={{ mt: 2, ...(selectedPlan === plan.id && { backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText }) }}
                            fullWidth
                          >
                            {selectedPlan === plan.id ? 'Selected' : 'Select'}
                          </Button>
                        </CardContent>
                      </Card>
                    </Grid>
                  ))}
                  {(!enterprisePlans.length && !environment.isEnterpriseApp) && (
                    <Typography variant="body1" color="text.secondary">
                      No plans available.
                    </Typography>
                  )}
                </Grid>
              </Box>

              <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                {/* <Button onClick={onBack} variant="outlined">
                  Back
                </Button> */}
                <Button type="submit" variant="contained" disabled={!isFormValid} color="primary" sx={{ ml: 2 }}>
                  Save and Continue
                </Button>
              </Box>

            </Box>
          </form>
        </Box>
    </>
  )
}

export default AccountDetails
