import { NewAttribute } from '../businessObjects'

import { sendRequest } from '../requestManager/sendRequest'

const dataAttributesPath = {
  addDataAttribute: 'DataAttributes/addnew',
  getCustomDataAttributeMap: 'DataAttributes/getCustomAttributeMap',
  updateDataAttribute: 'DataAttributes/UpdateAttributeName',
  getDataAttributesById: 'DataAttributes/GetById',
  getDataAttributesByEntityId: 'DataAttributes/GetByEntityId',
  getDataAttributesMapping: 'DataAttributes/getMapping',
  deleteAttributeMap: 'DataAttributes/deleteAttributeMap',
  deleteAttribute: 'DataAttributes/deleteAttribute',
  saveNoteNotebook: 'DataAttributes/CustomAttributeNB',
  updateNB: 'DataAttributes/UpdateCustomAttributeNB',
  getNB: 'DataAttributes/GetCustomAttributeNB',
  getAttributeByName: 'DataAttributes/GetByAttributeName',
}

export const createDataAttribute = async (token: string, dataAttribute: NewAttribute, datapodId: string) => {
  const endPoint = dataAttributesPath.addDataAttribute + `?datapodID=${datapodId}`
  return await sendRequest(endPoint, 'POST', token, dataAttribute)
}

export const updateDataAttribute = async <T>(token: string, params: T, datapodId: string) => {
  const endPoint = dataAttributesPath.updateDataAttribute + `?datapodID=${datapodId}`
  return await sendRequest(endPoint, 'PUT', token, params)
}

const getDataAttributesById = async (token: string, id: number) => {
  const endPoint = dataAttributesPath.getDataAttributesById + `?id=${id}`
  return await sendRequest(endPoint, 'GET', token)
}
export const getDataAttributesByEntityId = async (token: string, entityId: number) => {
  const endPoint = dataAttributesPath.getDataAttributesByEntityId + `?entityId=${entityId}`
  return await sendRequest(endPoint, 'GET', token)
}

export const getDataAttributesByMultipleEntityId = async (token: string, entityId: number[]) => {
  const queryString = entityId.map((id, index) => `${index === 0 ? '?entityId=' : '&entityId='}${id}`).join('');
  const endPoint = dataAttributesPath.getDataAttributesByEntityId + queryString
  return await sendRequest(endPoint, 'GET', token)
}

export const getDataAttributesMapping = async (
  token: string,
  dataAttributeID?: number,
  datapodId?:string,
  dataTableID?:number,
  records?: number,
  pageNumber?: number,
) => {
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  let url = dataAttributesPath.getDataAttributesMapping
  if (dataAttributeID) {
    url += `?dataAttributeID=${dataAttributeID}`
  }
  if(datapodId && dataTableID){
    url+=`?datapodID=${datapodId}&dataTableID=${dataTableID}`
  }
  const endPoint = paginationParams ? url + paginationParams : url
  return await sendRequest(endPoint, 'GET', token)
}

const deleteAttributeMapId = async (attributeMapId: number, token: string) => {
  const deleteAttributeMapUrl = dataAttributesPath.deleteAttributeMap + `?DataAttributeMapId=${attributeMapId}`
  return sendRequest(deleteAttributeMapUrl, 'DELETE', token)
}

const deleteAttributeId = async (attributeId: number, token: string) => {
  const body = [attributeId]
  const deleteAttributeIdUrl = dataAttributesPath.deleteAttribute
  return sendRequest(deleteAttributeIdUrl, 'DELETE', token, body)
}

const saveCustomAttributeNB = async (
  token: string,
  datapodId: string,
  customAttributeId: number,
  notebookString: string,
  commitMessage: string,
) => {
  const inputParams = {
    datapodID: datapodId,
    customattributeID: customAttributeId,
    notebookToRun: notebookString,
    commitMessage,
  }
  return sendRequest(dataAttributesPath.saveNoteNotebook, 'POST', token, inputParams)
}

const updateCustributeNB = async (
  token: string,
  datapodId: string,
  customAttributeId: number,
  notebookString: string,
  commitMessage: string,
) => {
  const inputParams = {
    datapodID: datapodId,
    customattributeID: customAttributeId,
    notebookToRun: notebookString,
    commitMessage,
  }
  return sendRequest(dataAttributesPath.updateNB, 'PUT', token, inputParams)
}

const getDataAttributeNotebook = async (token: string, datapodID: string, customAttributeID: number) => {
  const endPoint = dataAttributesPath.getNB + `?datapodId=${datapodID} &customAttributeID=${customAttributeID}`
  return await sendRequest(endPoint, 'GET', token)
}

const getCustomAttributeMap = async (
  token: string,
  datapodId: string,
  dataAttributeId: number,
  dataTableID?: number,
  dataEntityId?: number,
  dataColumnId?: number,
  dataEntityMapId?: number,
) => {
  let endPoint =
    dataAttributesPath.getCustomDataAttributeMap + `?dataPodId=${datapodId} &dataAttributeId=${dataAttributeId}`
  if (dataTableID) {
    endPoint += `&dataTableId=${dataTableID}`
  }
  if (dataEntityId) {
    endPoint += `&dataEntityId=${dataEntityId}`
  }
  if (dataColumnId) {
    endPoint += `dataColumnId=${dataColumnId}`
  }
  if (dataEntityMapId) {
    endPoint += `dataEntityMapId=${dataEntityMapId}`
  }

  return await sendRequest(endPoint, 'GET', token)
}
export const getDataAttributeName = async (token: string, datapodId: string, attributeName: string, entityId: number) => {
  const endPoint =
    dataAttributesPath.getAttributeByName +
    `?dataPodId=${datapodId} &DataAttributeName=${attributeName} &DataEntityId=${entityId}`
  return await sendRequest(endPoint, 'GET', token)
}

export const dataAttributesServices = {
  createDataAttribute,
  getDataAttributesById,
  getDataAttributesByEntityId,
  updateDataAttribute,
  deleteAttributeMapId,
  deleteAttributeId,
  saveCustomAttributeNB,
  updateCustributeNB,
  getDataAttributeNotebook,
  getCustomAttributeMap,
  getDataAttributeName,
}
