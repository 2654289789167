import { Box, Button, Grid, IconButton, Typography } from '@mui/material'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import React, { useEffect, useMemo, useState } from 'react'
import { DataPodInfo, DataSystem, DatapodServiceConnection, Industry, SubjectArea } from '../../businessObjects'
import { DataTableColumn, DataTableRow } from '../../ui-components/DataTable/DataTableTypes'
import ServiceNameWithIcon from '../../ui-components/ServiceNameWithIcon'
import DataTable from '../../ui-components/DataTable/DataTable'
import { generatePath, useNavigate } from 'react-router-dom'
import { useGetDataPodTypes } from '../../hooks/datapodHooks'
import { useGetDataSystemTypes } from '../../hooks/dataSystemHooks'
import { useQueryServiceConnections } from '../../hooks/serviceConnectionHooks'
import { HeadingLabel, LucidTextLabel } from 'ui-components/LucidTextLabel'
import { SendOutlined } from '@mui/icons-material'
import { ROUTES } from 'Routes/constants'

type Props = {
  subjectAreas: SubjectArea[]
  selectedIndustry?: Industry
  dataPodDetails: DataPodInfo
  dataSystems: DataSystem[]
  goToStep: (stepNumber: number) => void
  dataPodId?: string
  connections: DatapodServiceConnection[]
}

export const DataContextSummary = ({
  dataPodId,
  goToStep,
  dataPodDetails,
  subjectAreas,
  dataSystems,
  selectedIndustry,
  connections,
}: Props) => {
  // const [subjectAreaRows, setSubjectAreaRows] = useState<DataTableRow[]>([])
  // const [dataSystemRows, setDataSystemRows] = useState<DataTableRow[]>([])
  const navigate = useNavigate()
  //service-connection by datapodId
  // const { connections } = useQueryServiceConnections(dataPodId)
  // const [serviceConnections, setServiceConnections] = useState<DataTableRow[]>([])
  const dataServiceColumns: DataTableColumn[] = useMemo(() => {
    return [
      {
        label: 'Id',
      },
      {
        label: 'Connection Name',
      },
      {
        label: 'Connection Type',
      },
      {
        label: 'Service Name',
      },
    ]
  }, [])
  // console.log({ connections })
  // useEffect(() => {
  //   const tRows = connections?.map((conn: any) => ({
  //     id: `${conn.id}`,
  //     values: [
  //       conn.id,
  //       conn.connectionName,
  //       conn.connectionType,
  //       // eslint-disable-next-line react/jsx-key
  //       <ServiceNameWithIcon serviceName={conn.serviceTypeName} />,
  //     ],
  //   })) as DataTableRow[]
  //   setServiceConnections(tRows)
  // }, [connections])

  const serviceConnectionRows = useMemo(() => {
    if (!connections.length) return []
    return connections.map((conn: any) => ({
      id: `${conn.id}`,
      values: [
        conn.id,
        conn.connectionName,
        conn.connectionType,
        // eslint-disable-next-line react/jsx-key
        <ServiceNameWithIcon serviceName={conn.serviceTypeName} />,
      ],
    })) as DataTableRow[]
  }, [connections])

  const { allDatapodTypes } = useGetDataPodTypes()

  const { allDataSystemTypes } = useGetDataSystemTypes()

  const systemTypes = useMemo(() => {
    if (!allDataSystemTypes.length) return []
    return allDataSystemTypes
  }, [allDataSystemTypes])

  const podTypeInfo = useMemo(() => {
    return allDatapodTypes.find((pt) => pt.id === dataPodDetails.datapodType)?.dataPodType1 ?? ''
  }, [dataPodDetails.datapodType, allDatapodTypes.length])

  const subjectTableColumns: DataTableColumn[] = [
    {
      label: 'ID',
    },
    {
      label: 'Data Domain Name',
    },
    { label: 'Description' },
  ]
  const dataSystemColumns: DataTableColumn[] = [
    { label: 'Data Domain ID' },
    { label: 'Data Domain Name' },
    { label: 'Data Source ID' },
    { label: 'Data Source' },
    { label: 'Description' },
    { label: 'Data Source Type' },
    { label: 'Service Connection Name' },
  ]

  const subjectAreaRows = useMemo(() => {
    if (!subjectAreas.length) return []
    return subjectAreas.map((sa) => ({
      id: `${sa.id}`,
      values: [sa.id, sa.subjectAreaName, sa.subjectAreaDesc],
    })) as DataTableRow[]
  }, [subjectAreas])

  // useEffect(() => {
  // if (subjectAreas.length) {
  //   const tSARows = subjectAreas.map((sa) => ({
  //     id: `${sa.id}`,
  //     values: [sa.id, sa.subjectAreaName, sa.subjectAreaDesc],
  //   })) as DataTableRow[]
  //   setSubjectAreaRows(tSARows)
  // }
  // if (dataSystems.length && subjectAreas.length) {
  //   const tRows = dataSystems.map((sa) => ({
  //     id: `${sa.id}`,
  //     values: [
  //       sa.subjectAreaId,
  //       subjectAreas.find((sb) => sb.id === sa.subjectAreaId)?.subjectAreaName ?? '',
  //       sa.id,
  //       sa.dataSystemName,
  //       sa.dataSystemDesc,
  //       systemTypes.find((st) => st.id === sa.dataSystemType)?.dataSystemType1 ?? '',
  //       sa.serviceConnectionName,
  //     ],
  //   })) as DataTableRow[]
  //   setDataSystemRows(tRows)
  // }
  // }, [subjectAreas, dataSystems, systemTypes])

  const dataSystemRows = useMemo(() => {
    if (!subjectAreas.length || !dataSystems.length) return []
    return dataSystems.map((sa) => ({
      id: `${sa.id}`,
      values: [
        sa.subjectAreaId,
        subjectAreas.find((sb) => sb.id === sa.subjectAreaId)?.subjectAreaName ?? '',
        sa.id,
        sa.dataSystemName,
        sa.dataSystemDesc,
        systemTypes.find((st) => st.id === sa.dataSystemType)?.dataSystemType1 ?? '',
        sa.serviceConnectionName,
      ],
    })) as DataTableRow[]
  }, [systemTypes, dataSystems, subjectAreas])

  return (
    <Box sx={{ ml: '2rem', mt: '3rem', mr: '2rem' }}>
      <Grid container item sx={{display: 'flex', justifyContent: 'flex-end'}}>
        <Button 
          variant="contained"
          // fullWidth
          // disabled={}
          onClick={() => navigate(generatePath(ROUTES.DatapodsManageAccess, {datapodId: dataPodId}))}
          >
          Manage Access
        </Button>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={16} />
        <Grid item xs={8} container spacing={2}>
          <Grid item xs={10} container alignItems={'baseline'}>
            <Grid item xs={3}>
              <HeadingLabel headingText={'Data Pod details'} />
            </Grid>
            <Grid item xs={0}>
              <IconButton aria-label="edit" size="small" onClick={() => goToStep(0)}>
                <ModeEditOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={4}>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={8}>
                <LucidTextLabel labelText="Data Pod Name" />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color={'secondary'}>
                  {dataPodDetails.name}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={8}>
                <LucidTextLabel labelText="DataPod Description" />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color={'secondary'}>
                  {dataPodDetails.description}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={8}>
                <LucidTextLabel labelText="Data Pod Id" />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color={'secondary'}>
                  {dataPodId}
                </Typography>
              </Grid>
            </Grid>

            <Grid item xs={12} container spacing={1}>
              <Grid item xs={8}>
                <LucidTextLabel labelText="Industry" />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color={'secondary'}>
                  {selectedIndustry?.industryName ?? dataPodDetails.industryName}
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={8}>
                <LucidTextLabel labelText="Datapod Type" />
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color={'secondary'}>
                  {podTypeInfo}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={8} />
        </Grid>

        <Grid item xs={10} container alignItems={'baseline'}>
          <Grid item xs={3}>
            <HeadingLabel headingText={'Data Domains'} />
          </Grid>
          <Grid item xs={0}>
            <IconButton aria-label="edit" size="small" onClick={() => goToStep(1)}>
              <ModeEditOutlineIcon />
            </IconButton>
          </Grid>

          <Grid item xs={12}>
            <DataTable columns={subjectTableColumns} rows={subjectAreaRows} />
          </Grid>
        </Grid>
        <Grid item xs={16} />
        <Grid item xs={12} container>
          <Grid item xs={10} alignItems={'baseline'} container>
            <Grid item xs={3}>
              <HeadingLabel headingText={'Service Connection Details'} />
            </Grid>
            <Grid item xs={2}>
              <IconButton aria-label="edit" size="small" onClick={() => goToStep(2)}>
                <ModeEditOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <DataTable columns={dataServiceColumns} rows={serviceConnectionRows} />
          </Grid>
        </Grid>
        <Grid item xs={16} />
        <Grid item xs={12} container>
          <Grid item xs={10} alignItems={'baseline'} container>
            <Grid item xs={3}>
              <HeadingLabel headingText={'Data Source Details'} />
            </Grid>
            <Grid item xs={2}>
              <IconButton aria-label="edit" size="small" onClick={() => goToStep(3)}>
                <ModeEditOutlineIcon />
              </IconButton>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <DataTable columns={dataSystemColumns} rows={dataSystemRows} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}
