import { DataQualityCodeRequest, DataQualityNBRequest, DataQualityRule } from '../businessObjects'
import { sendGenApiRequest } from '../requestManager/sendGenApiRequest'
import { sendRequest } from '../requestManager/sendRequest'

const DATAQUALITY_PATH = {
  getActiveRules: 'DataQuality/GetActiveRules',
  getDataQualityRules: 'DataQuality/GetDataQualityRules',
  getDataQualityRuleById: 'DataQuality/GetDataQualityRule',
  addDataQualityRule: 'DataQuality/AddDataQualityRule',
  updateDataQualityRule: 'DataQuality/UpdateDataQualityRule',
  deleteDataQualityRule: 'DataQuality/DeleteDataQualityRule',

  getDataQualityNB: 'DataQuality/GetDataQualityNB',
  addDataQualityNB: 'DataQuality/AddDataQualityNB',
  updateDataQualityNB: 'DataQuality/UpdateDataQualityNB',

  getDataQulaityCode: 'DataQuality',
  getDataQualityRecommendations: 'DataQuality/GetDataQualityRecommendations',
}

const getActiveRules = (token: string) => {
  const url = DATAQUALITY_PATH.getActiveRules

  return sendRequest(url, 'GET', token)
}

const getDataQualityRules = (token: string, entityId: number) => {
  const url = DATAQUALITY_PATH.getDataQualityRules + `?entityId=${entityId}`

  return sendRequest(url, 'GET', token)
}

const getDataQualityRuleById = (token: string, ruleId: number) => {
  const url = DATAQUALITY_PATH.getDataQualityRuleById + `?ruleId=${ruleId}`

  return sendRequest(url, 'GET', token)
}

const addDataQualityRule = (token: string, payload: DataQualityRule) => {
  const url = DATAQUALITY_PATH.addDataQualityRule

  return sendRequest(url, 'POST', token, payload)
}

const updateDataQualityRule = (token: string, payload: DataQualityRule) => {
  const url = DATAQUALITY_PATH.updateDataQualityRule

  return sendRequest(url, 'PUT', token, payload)
}

const deleteDataQualityRule = (token: string, ruleId: number) => {
  const url = DATAQUALITY_PATH.deleteDataQualityRule + `?ruleId=${ruleId}`

  return sendRequest(url, 'DELETE', token)
}

const getDataQualityNB = (token: string, dataPodId: string, ruleId: number) => {
  const url = DATAQUALITY_PATH.getDataQualityNB + `?datapodId=${dataPodId}&dataQualityRuleId=${ruleId}`

  return sendRequest(url, 'GET', token)
}

const addDataQualityNB = (token: string, payload: DataQualityNBRequest) => {
  const url = DATAQUALITY_PATH.addDataQualityNB

  return sendRequest(url, 'POST', token, payload)
}

const updateDataQualityNB = (token: string, payload: DataQualityNBRequest) => {
  const url = DATAQUALITY_PATH.updateDataQualityNB

  return sendRequest(url, 'PUT', token, payload)
}

const getDataQualtiyCode = (payload: DataQualityCodeRequest, token?: string) => {
  return sendGenApiRequest(DATAQUALITY_PATH.getDataQulaityCode, 'POST', token ?? 'Lucid123', payload)
}

const getDataQualityRecommendations = (token: string, datapodId: string, entityId: number, attributeId?: number) => {
  let url = DATAQUALITY_PATH.getDataQualityRecommendations + `?datapodId=${datapodId}&dataEntityID=${entityId}`
  if (attributeId) {
    url += `&dataAttributeID=${attributeId}`
  }

  return sendRequest(url, 'GET', token)
}

export const dataQualityServices = {
  getActiveRules,
  getDataQualityRules,
  getDataQualityRuleById,
  addDataQualityRule,
  updateDataQualityRule,
  deleteDataQualityRule,
  getDataQualityNB,
  addDataQualityNB,
  updateDataQualityNB,
  getDataQualtiyCode,
  getDataQualityRecommendations,
}
