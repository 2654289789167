import { useEffect, useState } from "react";
import { AxiosError } from "axios";

import { UserAccount } from "businessObjects";
import { useLucidAuthContext } from "LucidAuthContext/LucidAuthContext";
import { userServices } from "apiServices/user";

export const useAddUser = () : {
  addUser: (user: UserAccount) => Promise<UserAccount | undefined>
} => {
  const {getBearerToken} = useLucidAuthContext(); 

  const addUser  = async (user: UserAccount): Promise<UserAccount | undefined> => {
    try {
      const bearerToken = await getBearerToken();
      if (bearerToken ) {
          const {data, status, headers} = await userServices.addUser(bearerToken, user);
          
          if(status == 200) {
            return data;
          }
      }
    } catch (error) {
        const err = error as AxiosError;
        console.log(err.message);
    }
  }

  return {addUser}
}

export const useGetUsersByTenantId = () => {
  const {getBearerToken} = useLucidAuthContext(); 

  const getUsersByTenantId  = async (tenantId: string): Promise<UserAccount[]> => {
      try {
          const bearerToken = await getBearerToken();
          if (bearerToken && tenantId) {
              const {data, status, headers} = await userServices.getUsersByTenantId(bearerToken, tenantId);
              
              if(status == 200) {
                  return data;
              }
          }
      } catch (error) {
          const err = error as AxiosError;
          console.log(err.message);
          return []
      }

      return [];
  }

  return { getUsersByTenantId };
}

// export const useAddUser = () : {
//   addUser: (user: UserAccount) => Promise<UserAccount | undefined>
// } => {
//   const {getBearerToken} = useLucidAuthContext(); 

//   const addUser  = async (user: UserAccount): Promise<UserAccount | undefined> => {
//     try {
//       const bearerToken = await getBearerToken();
//       if (bearerToken ) {
//           const {data, status, headers} = await userServices.addUser(bearerToken, user);
          
//           if(status == 200) {
//             return data;
//           }
//       }
//     } catch (error) {
//         const err = error as AxiosError;
//         console.log(err.message);
//     }
//   }

//   return {addUser}
// }