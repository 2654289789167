import React, { useEffect, useMemo, useState } from 'react'
import ActionPage from '../../../ui-components/ActionPage'
import { Button, Card, CardContent, Grid, IconButton, TextField, Typography } from '@mui/material'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'
import { PipelineSchedule } from '../../../businessObjects'
import {
  useAddPipelineSchedule,
  useGetPipelineSchedules,
  useUpdatePipelineSchedule,
} from '../../../hooks/dataEngineeringHooks'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import CronScheduler, { getCronHumanReadable } from '../../../ui-components/JobScheduler/CronScheduler'
import { AddOutlined } from '@mui/icons-material'
import { isValidDescription, isValidEntry } from '../../../utils/constants'
import { CronFrequency, defCron } from '../../../ui-components/JobScheduler/SchedulerConstants'

type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
const initPipelineSchedule = {
  frequency: defCron,
  timeZone: '',
  name: '',
  description: '',
}
const DataEngineeringManageSchedules = () => {
  const { dataPodId } = useParams<ViewResultsParams>()
  //const [pipelineScheduleList, setPipelineScheduleList] = useState<PipelineSchedule[]>([])
  const [newPipelineSchedule, setNewPipelineSchedule] = useState<PipelineSchedule>(initPipelineSchedule)
  const [pipelineSchedule, setPipelineSchedule] = useState<PipelineSchedule>(initPipelineSchedule)
  // const [pipelineScheduleRows, setPipelineScheduleRows] = useState<DataTableRow[]>([])
  const [enableAddSchedule, setEnableAddSchedule] = useState<boolean>(false)
  const [enableUpdateSchedule, setEnableUpdateSchedule] = useState<boolean>(false)
  const [pipelineCron, setPipelineCron] = useState<CronFrequency>('None')
  const [displayEditWarning, setDisplayEditWarning] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>()

  const { pipelineSchedules, refreshData } = useGetPipelineSchedules()
  const { addPipelineSchedule } = useAddPipelineSchedule()
  const { updatePipelineSchedule } = useUpdatePipelineSchedule()

  const navigate = useNavigate()
  const onClickHome = () => {
    const DEpipelineSchedulePage = generatePath(ROUTES.DataEngineeringPipelineSchedules, { dataPodId: dataPodId })
    navigate(DEpipelineSchedulePage)
  }

  const pipelineScheduleColumns = useMemo(() => {
    return [
      {
        label: 'Schedule Name',
        sortableColumn: true,
      },
      {
        label: 'Schedule Description',
        sortableColumn: true,
      },
      {
        label: 'Frequency',
      },
      {
        label: 'Timezone',
        sortableColumn: true,
      },
      {
        label: 'Start time',
      },
      {
        label: 'End time',
      },
      {
        label: 'Edit',
      },
    ]
  }, [])

  const pipelineScheduleInfo = useMemo(() => {
    if (!pipelineSchedules.length) return []
    return pipelineSchedules
  }, [pipelineSchedules])

  const filterNullString = (nullString?: string) => {
    return nullString && nullString != 'null' ? nullString : ''
  }

  const tableRows = useMemo(() => {
    if(!pipelineSchedules.length) return []
    return pipelineSchedules.map((pschedule) => ({
      id: String(pschedule.id),
      values: [
        pschedule.name,
        pschedule.description,
        getCronHumanReadable(pschedule.frequency, { tz: pschedule.timeZone })?.humanReadTxt ?? '',
        pschedule.timeZone,
        filterNullString(pschedule.startdate) + '' + filterNullString(pschedule.starttime),
        filterNullString(pschedule.enddate) + '' + filterNullString(pschedule.enddate),
        'EDIT',
      ],
    })) as DataTableRow[]
  },[pipelineSchedules])

  // useEffect(() => {
  //   if (pipelineSchedules?.length && !pipelineScheduleRows.length) {
  //     setPipelineScheduleRows(
  //       pipelineSchedules.map((pschedule) => ({
  //         id: String(pschedule.id),
  //         values: [
  //           pschedule.name,
  //           pschedule.description,
  //           getCronHumanReadable(pschedule.frequency, { tz: pschedule.timeZone })?.humanReadTxt ?? '',
  //           pschedule.timeZone,
  //           filterNullString(pschedule.startdate) + '' + filterNullString(pschedule.starttime),
  //           filterNullString(pschedule.enddate) + '' + filterNullString(pschedule.enddate),
  //           'EDIT',
  //         ],
  //       })),
  //     )
  //   }
  // }, [pipelineSchedules])

  const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
    const tbID = Number(rowId)
    const selPipelineSched = pipelineScheduleInfo.find((r) => r.id === tbID)
    console.log({ selPipelineSched })

    if (selPipelineSched) {
      setPipelineCron(
        getCronHumanReadable(selPipelineSched.frequency, { tz: selPipelineSched.timeZone })?.cronFr ?? 'None',
      )
      setPipelineSchedule(selPipelineSched)
      if (actionType === ShadowTableAction.Editable) {
        setDisplayEditWarning(true)
        setEnableUpdateSchedule(true)
      }
    }
  }

  const confirmAdd = async () => {
    if (newPipelineSchedule?.name) {
      const createdPlSched = await addPipelineSchedule({
        name: newPipelineSchedule.name,
        description: newPipelineSchedule.description,
        frequency: newPipelineSchedule.frequency,
        startdate: newPipelineSchedule.startdate,
        starttime: newPipelineSchedule.starttime,
        timeZone: newPipelineSchedule.timeZone,
      })
      if (createdPlSched?.id) {
        refreshData()
        setEnableAddSchedule(false)
        setNewPipelineSchedule(initPipelineSchedule)
      }
    }
  }
  const confirmUpdate = async () => {
    if (pipelineSchedule?.id) {
      const updatedPlSched = await updatePipelineSchedule({
        ...pipelineSchedule,
      })
      if (updatedPlSched?.id) {
        refreshData()
        setEnableUpdateSchedule(false)
        setPipelineSchedule(initPipelineSchedule)
      }
    }
  }

  const disableUpdate = useMemo(() => {
    return !(isValidEntry(pipelineSchedule?.name) && isValidDescription(pipelineSchedule?.description))
  }, [pipelineSchedule?.name, pipelineSchedule?.description, pipelineSchedule?.frequency])

  const disableSave = useMemo(() => {
    return !(isValidEntry(newPipelineSchedule?.name) && isValidDescription(newPipelineSchedule?.description))
  }, [newPipelineSchedule?.name, newPipelineSchedule?.description, newPipelineSchedule?.frequency])

  const saveCronDetails = (freq: CronFrequency, pipelineSchedule: PipelineSchedule) => {
    console.log({ freq }, { pipelineSchedule })
    setPipelineCron(freq)
    setNewPipelineSchedule((prv) => ({
      ...prv,
      ...pipelineSchedule,
    }))
  }

  const updateCronDetails = (freq: CronFrequency, pplSchedule: PipelineSchedule) => {
    setPipelineCron(freq)
    setPipelineSchedule(pplSchedule)
  }

  const onClose = () => {
    setNewPipelineSchedule(initPipelineSchedule)
    setPipelineSchedule(initPipelineSchedule)

    setEnableAddSchedule(false)
    setEnableUpdateSchedule(false)
  }

  return (
    <ActionPage>
      <LucidPopUp
        showCloseIcon
        openPopUp={enableAddSchedule}
        closePopup={onClose}
        confirmText="Save"
        disabledConfirm={disableSave}
        onConfirm={confirmAdd}
        headingText={
          <Typography variant="h4" gutterBottom color="primary">
            Add Pipeline Schedule
          </Typography>
        }
      >
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={10} container spacing={1}>
            <Grid item xs={10}>
              <Typography variant="body2" gutterBottom>
                Schedule Name
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                id="schedule-name-id"
                placeholder="pipeline schedule name"
                value={newPipelineSchedule?.name ?? ''}
                size="small"
                fullWidth
                onChange={({ target }) => setNewPipelineSchedule((prv) => ({ ...prv, name: target.value }))}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={10} container spacing={1}>
            <Grid item xs={10}>
              <Typography variant="body2" gutterBottom>
                Schedule Description
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                id="schedule-desc-id"
                placeholder="pipeline schedule decription"
                value={newPipelineSchedule?.description ?? ''}
                size="small"
                fullWidth
                rows={2}
                multiline
                onChange={({ target }) => setNewPipelineSchedule((prv) => ({ ...prv, description: target.value }))}
              />
            </Grid>
          </Grid>
          <Grid item xs={10} container>
            <CronScheduler
              cronFrequency={pipelineCron}
              pipelineSchedule={newPipelineSchedule}
              updatePipelineSchedule={saveCronDetails}
            />
          </Grid>
        </Grid>
      </LucidPopUp>
      <LucidPopUp
        showCloseIcon
        openPopUp={enableUpdateSchedule}
        closePopup={onClose}
        confirmText="Update"
        onConfirm={confirmUpdate}
        disabledConfirm={disableUpdate}
        headingText={'Update Pipeline Schedule'}
      >
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={10} container spacing={1}>
            <Grid item xs={10}>
              <Typography variant="body2" gutterBottom>
                Schedule Name
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                id="schedule-name-id"
                placeholder="pipeline schedule name"
                value={pipelineSchedule?.name ?? ''}
                size="small"
                fullWidth
                onChange={({ target }) => setPipelineSchedule((prv) => ({ ...prv, name: target.value }))}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} />
          <Grid item xs={10} container spacing={1}>
            <Grid item xs={10}>
              <Typography variant="body2" gutterBottom>
                Schedule Description
              </Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                variant="outlined"
                id="schedule-desc-id"
                placeholder="pipeline schedule decription"
                value={pipelineSchedule?.description ?? ''}
                size="small"
                fullWidth
                rows={2}
                multiline
                onChange={({ target }) => setPipelineSchedule((prv) => ({ ...prv, description: target.value }))}
              />
            </Grid>
          </Grid>
          <Grid item xs={10} container>
            <CronScheduler
              cronFrequency={pipelineCron ?? 'None'}
              pipelineSchedule={pipelineSchedule}
              updatePipelineSchedule={updateCronDetails}
            />
          </Grid>
        </Grid>
      </LucidPopUp>
      <Card sx={{ backgroundColor: 'white', boxShadow: 3, borderRadius: 3 }}>
        <CardContent>
          <Grid item container spacing={1} alignItems={'baseline'} sx={{ pb: 4 }}>
            <Grid item xs={1}>
              <IconButton onClick={onClickHome}>
                <HomeOutlinedIcon />
              </IconButton>
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={6}>
              <Typography align="center" variant="h3" color="primary" gutterBottom>
                Manage Pipeline Schedules
              </Typography>
            </Grid>
          </Grid>
          <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={14} spacing={2}>
            <Grid item xs={10} />
            <Grid item xs={2}>
              <Button
                variant="contained"
                size="small"
                onClick={() => setEnableAddSchedule(true)}
                fullWidth
                startIcon={<AddOutlined />}
              >
                Add Pipeline Schedule
              </Button>
            </Grid>
            <Grid item xs={14} />
            <Grid item container xs={10}>
              <ShadowTable
                rows={tableRows}
                columns={pipelineScheduleColumns}
                tableActionParams={{
                  onButtonClick: onButtonClick,
                  actions: [ShadowTableAction.Editable],
                }}
              />
            </Grid>
          </Grid>
        </CardContent>
      </Card>
    </ActionPage>
  )
}

export default DataEngineeringManageSchedules
