import {
  Box,
  Grid,
  IconButton,
  Typography,
  Card,
  MenuItem,
  TextField,
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardHeader,
  InputAdornment,
  Button,
  FormControl,
  Select,
  OutlinedInput,
  Chip,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'

import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'

import { useGetProfileAttributes, useGetProfileEntity } from '../../../hooks/dataProfilingHooks'

import { useGetAllDataSystemsByPodId, useGetDataSystemByDataSystemId } from '../../../hooks/dataSystemHooks'
import { useGetSubjectArea } from '../../../hooks/subjectAreaHooks'

import { AppProfileEntity, Entity, NewEntity, CoreEntity, EntityReconData } from '../../../businessObjects'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'

import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import { green } from '@mui/material/colors'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import {
  useAddEntityMap,
  useAddNewEntity,
  useGetEntity,
  useGetCoreEntity,
  useUpdateEntityMap,
  useUpdateEntityName,
} from '../../../hooks/entityHooks'
import CancelIcon from '@mui/icons-material/Cancel'
import { Add, AddOutlined, SaveOutlined, TrySharp } from '@mui/icons-material'
import { useGetRelationData } from '../../../hooks/applicationHooks'
import { useGetDataAttributesByEntity, useGetDataReconcilationByEntityId } from '../../../hooks/dataAttrbutesHooks'
import ReactFlow from '../../../ReactFlow/ReactFlow'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import Spinner from 'react-spinner-material'
import CustomEnrichedModel from './CustomEnrichedModel'
import { ProgressBar } from '../../../ui-components/ProgressBar'
import AnimatedLoader from '../../../ui-components/AnimatedLoader'
import { PreviousPage } from 'ui-components/Button/PreviousPage'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
}

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
export const DataModelingEnterpriseModels = (props: Props) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  // let datapodID: string = 'F269DB96-4FD9-4AB5-A180-26843B292C24'
  const [selectedSourceID, setSelectedSourceID] = useState<number>()
  const [tableView, setTableView] = useState<boolean>(true)
  const [cardView, setCardView] = useState<boolean>(false)
  const [entityData, setEntityData] = useState<any[]>([])
  const [coreEntityData, setCoreEntities] = useState<CoreEntity[]>([])
  const [selectedEntity, setSelectedEntity] = useState<Entity[]>([])
  const [selectedCoreEntity, setSelectedCoreEntity] = useState<CoreEntity[]>([])
  const [openEnrichedModel, setOpenEnrichedModel] = useState<boolean>(false)

  const [dataSystems] = useGetAllDataSystemsByPodId({}, dataPodId)
  const { dataSystemInfo } = useGetDataSystemByDataSystemId(selectedSourceID)
  //const { subjectAreaInfo } = useGetSubjectArea(dataSystemInfo?.subjectAreaId)

  const { profileEntities } = useGetProfileEntity(dataPodId, selectedSourceID, undefined, undefined, undefined)

  const [showReactFlow, setShowreactFlow] = useState<boolean>(false)
  const [showLoading, setShowLoading] = useState<boolean>(false)
  const [nodes, setNodes] = useState<any[]>([])
  const [edges, setEdges] = useState<any[]>([])
  const [filteredNodes, setFilteredNodes] = useState<any[]>([])
  const [filteredEdges, setFilteredEdges] = useState<any[]>([])
  const [entities] = useGetEntity({}, dataPodId)
  const [coreEntities] = useGetCoreEntity(dataPodId)
  const [relations] = useGetRelationData({}, dataPodId)
  const entityReconData = useGetDataReconcilationByEntityId(dataPodId)
  const [childEntityData, setChildEntityData] = useState<EntityReconData[]>([])
  const [entityKeyValue, setEntityKeyValue] = useState<any[]>([])
  const getAttribute = useGetDataAttributesByEntity()
  let nodeData: any = []
  useEffect(() => {
    if (dataSystemID && !selectedSourceID) {
      setSelectedSourceID(Number(dataSystemID))
    }
  }, [dataSystemID])
  // useEffect(() => {
  //   if (entities.length) {
  //     setEntityData(entities)
  //   }
  // }, [entities])
  useEffect(() => {
    if (coreEntities.length && !coreEntityData.length) {
      setCoreEntities(coreEntities)
      // console.log(coreEntities)
      setSelectedCoreEntity([coreEntities[0]])
    }
  }, [coreEntities])
  useEffect(() => {
    if (entityReconData.reconData.length) {
      setChildEntityData(entityReconData.reconData)
    }
  }, [entityReconData.reconData])
  // const reconDataFiltered = useMemo(() => {
  //   if (reconData.length) {
  //     const reconTemp = reconData.filter((rd, ix) => {
  //       return reconData.findIndex((cr) => cr.dataCoreEntityId === rd.dataCoreEntityId) === ix
  //     })
  //     const newData = reconTemp.map((cp) => ({
  //       dataCoreEntityId: cp.dataCoreEntityId,

  //       parentName: cp.parent,

  //       childrenData: reconData
  //         .filter((rd) => rd.dataCoreEntityId === cp.dataCoreEntityId)
  //         .map((cx) => ({ dataSystemEntityID: cx.dataSystemEntityId, child: cx.child })),
  //     }))
  //     return newData
  //   }
  // }, [reconData])
  // // console.log({reconDataFiltered})
  useEffect(() => {
    if (childEntityData?.length && selectedCoreEntity.length) {
      let selectedEntityData: any = []
      selectedEntityData = childEntityData.filter((cd) => {
        return selectedCoreEntity.some((se) => {
          return se.id == cd.dataCoreEntityId
        })
      })
      // selectedCoreEntity.forEach((coreEntity) => {
      //   childEntityData.some((ced) => {
      //     if (ced.dataCoreEntityId == coreEntity.id) {
      //       selectedEntityData.push(ced)
      //     }
      //   })
      // })
      selectedEntityData && setEntityData(selectedEntityData)
    }
  }, [selectedCoreEntity.length, childEntityData])

  useEffect(() => {
    const idData: any = []
    if (entityData.length) {
      entityData.forEach((entity: any) => {
        idData.push({
          id: entity.dataSystemEntityId,
          entityName: entity.child,
        })
      })
      setEntityKeyValue(idData)
    }else{
      // console.log("Inside else")
      setShowLoading(false)
    }

  }, [entityData.length])
  var entityIds: any = []
  if (entityKeyValue.length) {
    // console.log(entityKeyValue)
    // console.log("Inside the entity key value")
    entityIds = entityKeyValue
  }

  // if (entityIds?.length) {

  const processEntities = async () => {
    // console.log("inside process entities")
    setShowLoading(true)
    for (const entity of entityIds) {
      try {
        const attributeData = await getAttribute(entity.id)
        if (attributeData) {
          const attributeValues = attributeData.map((attributeFrag) => ({
            attributeId: attributeFrag.id,
            attributeName: attributeFrag.dataAttributeName,
            type: attributeFrag.dataAttributeDataType,
          }))
          nodeData.push({
            entityName: entity.entityName,
            entityId: entity.id,
            attributes: attributeValues,
          })
        }
      } catch (error: any) {
        if (error.response && error.response.status === 404) {
          console.error('Attribute data not found for entity:', entity.id)
          nodeData.push({
            entityName: entity.entityName,
            entityId: entity.id,
            attributes: [],
          })
          setShowLoading(false)
        } else {
          console.error('An error occurred:', error)
          setShowLoading(false)
        }
      }
    }
    if (nodeData.length > 0) {
      setNodes(nodeData)
      // console.log("Inside node data length if")
      // console.log(relations)
      const edgeData: any = []
      if (relations) {
        // console.log("Inside relations")
        relations.forEach((relation) => {
          edgeData.push({
            table: relation.dataTableName,
            tableId: relation.dataTableId,
            database: relation.database,
            id: relation.id,
            leftEntityId: relation.leftEntityId,
            leftEntity: relation.leftEntityName,
            leftAttributeId: relation.leftAttributeId,
            leftAttributeName: relation.leftAttributeName,
            rightEntityId: relation.rightEntityId,
            rightEntity: relation.rightEntityName,
            rightAttributeId: relation.rightAttributeId,
            rightAttributeName: relation.rightAttributeName,
            cardinality: relation.cardinality,
          })
        })
      }
      if (edgeData.length) {
        setEdges(edgeData)
        setShowLoading(false)
      }
    }
  }
  // if (!nodes.length) {
  //   processEntities()
  // }
  // }
  useEffect(() => {
    if(entityKeyValue.length && relations?.length){
      processEntities()
    }else{
      setShowLoading(false)
    }
  }, [entityKeyValue.length, relations])
  useEffect(() => {
    if (edges.length && selectedCoreEntity.length && entityData.length) {
      const filteredEdgesData: any = []

      edges.forEach((edge) => {
        if (
          entityData.some(
            (se) => se.dataSystemEntityId == edge.leftEntityId || se.dataSystemEntityId == edge.rightEntityId,
          )
        ) {
          filteredEdgesData.push(edge)
        }
      })
      if (filteredEdgesData.length) {
        // check for the edge data also in the if condition
        setFilteredEdges(filteredEdgesData)
        setShowreactFlow(true)
      }
    }else{
      // console.log("Inside the else")
      setShowLoading(false)
    }
  }, [selectedCoreEntity?.length, edges, entityData?.length, selectedCoreEntity, entityData, edges?.length])

  const allProfileEntities = useMemo(() => {
    if (!profileEntities?.length) return []
    return profileEntities.sort((p1, p2) => (p1.dataTableName > p2.dataTableName ? 1 : -1))
  }, [profileEntities?.length])

  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  const dataSources = useMemo(() => {
    if (!dataSystems.length) return []
    return dataSystems
  }, [dataSystems])

  const toggleView = (toggle: boolean) => {
    setCardView(!toggle)
    setTableView(toggle)
  }

  const AddEnrichedModel = () => {
    // setOpenEnrichedModel(true)
    const customEnrichedModelRoute = generatePath(ROUTES.CustomEnrichedModel, {
      dataPodId: dataPodId,
      // dataSystemID: selRow.dataSystemID,
      // entityID: selRow.dataEntityID,
    })
    navigate(customEnrichedModelRoute)
  }

  const showEnrichedModel = () => {
    // setOpenEnrichedModel(true)
    const viewEnrichedModelRoute = generatePath(ROUTES.DataModelingEnterpriseEnrichedTables, {
      dataPodId: dataPodId,
      // dataSystemID: selRow.dataSystemID,
      // entityID: selRow.dataEntityID,
    })
    navigate(viewEnrichedModelRoute)
  }
  // const closeEnrichedModel = () => {
  //   setOpenEnrichedModel(false)
  // }

  // const rightTableColumns = useMemo(() => {
  //   return [
  //     {
  //       label: 'Entity Name',
  //     },
  //     {
  //       label: 'Entity Description',
  //     },
  //     {
  //       label: 'Entity Type',
  //     },

  //     {
  //       label: 'Action',
  //     },
  //   ]
  // }, [])

  // const coreEntityRows = useMemo(() => {
  //   if (!coreEntities.length) return []
  //   return coreEntities
  //     .filter((cr) => ['reference', 'transaction'].includes(cr.entityType))
  //     .map((ce) => ({
  //       id: String(ce.id),
  //       values: [ce.dataEntityName, ce.description, ce.entityType, 'VIEW'],
  //     }))
  // }, [coreEntities])

  // const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
  //   if (actionType === ShadowTableAction.Viewable) {
  //     //console.log({ rowId })
  //     const attributesRoute = generatePath(ROUTES.EditCustomEnrichedModel, {
  //       dataPodId: dataPodId,
  //       customEntityId: rowId,
  //     })
  //     navigate(attributesRoute)
  //   }
  // }

  return (
    <ActionPage>
      {/* <CustomEnrichedModel open={openEnrichedModel} close={closeEnrichedModel} dataPodId={dataPodId ?? ''} /> */}
      <Grid item xs={3} mb={3}>
           <PreviousPage  onClick={() => navigate(generatePath(ROUTES.DataModelingEntityRelationships, { dataPodId: dataPodId }))} pageName={"Back"} />
        </Grid>
      <Box style={cardContainer}>
        <Grid container spacing={2}>
          <Grid item container spacing={2}>
            {/* <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={6}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data Pod Name
                      </Typography>
                    </Grid>
                    <Grid item />

                    <Grid item xs={5}>
                      <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                        Data pod ID
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={15} />

                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.name}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.id}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid> */}
            <Grid item xs={12} />
            <Grid item xs={5}>
              <Typography gutterBottom>Please select Entites </Typography>

              <Select
                fullWidth
                id="outlined-select-dataSource"
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                variant="outlined"
                value={selectedCoreEntity.map((se) => se.id)}
                size="small"
                label={'Select Core Entity'}
                onChange={({ target }) => {
                  const selEntity = coreEntityData.filter((ed) => (target.value as number[]).includes(ed.id))
                  setSelectedCoreEntity(selEntity)
                }}
                multiple
                MenuProps={MenuProps}
                renderValue={() => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedCoreEntity.map((sce) => (
                      <Chip key={sce.id} label={sce.dataEntityName} />
                    ))}
                  </Box>
                )}
              >
                {coreEntityData.map((d) => (
                  <MenuItem key={d.id} value={d.id}>
                    <Checkbox checked={selectedCoreEntity.some((sc) => sc.id === d.id)} />
                    <ListItemText primary={d.dataEntityName} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid item xs={1} />
            <Grid item xs={3}>
              <Button
                startIcon={<AddOutlined fontSize="small" />}
                variant="contained"
                onClick={AddEnrichedModel}
                fullWidth
              >
                Add Enriched Entities
              </Button>
            </Grid>
            <Grid item xs={3}>
              <Button
                startIcon={<VisibilityOutlinedIcon fontSize="small" />}
                variant="contained"
                onClick={showEnrichedModel}
                fullWidth
              >
                View Enriched Entities
              </Button>
            </Grid>
          </Grid>
          <Grid item xs={15} />
          <Grid item xs />
          {/* <Grid item xs={12}>
           will be moved to new page once customEntity is ready
            <ShadowTable
              rows={coreEntityRows}
              columns={rightTableColumns}
              tableActionParams={{
                onButtonClick: onButtonClick,
                actions: [ShadowTableAction.Viewable],
              }}
            />
          </Grid> */}
          <Grid item xs={12} container>
            <Grid container spacing={2}>
              {showReactFlow && <ReactFlow nodeData={nodes} edgeData={filteredEdges} />}
              {!showReactFlow&& !showLoading && !entityKeyValue.length &&
              <>
              <Grid xs={10} justifyContent={'center'}>
                <Typography variant='h3' justifyContent={'center'}>
                  No Data
                </Typography>
                </Grid>
              </>
              }
              {/* <ProgressBar loading={!showReactFlow} /> */}
              <AnimatedLoader height='50%' width='40%' loading={(showLoading)}/>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}

// type DataMappingTableProps = {
//   dataSourceTables: AppProfileEntity[]
// }

// export const DataMappingTable = ({ dataSourceTables }: DataMappingTableProps) => {
//   const [selectedTableIDs, setSelectedTableID] = useState<number[]>([])
//   const [dsTables, setDSTables] = useState<AppProfileEntity[]>([])
//   const [showPopUp, setShowPop] = useState<boolean>(false)
//   const [selectedRow, setSelectedRow] = useState<AppProfileEntity>()
//   const [newEntity, setNewEntity] = useState<Entity>()
//   const [contextPromptText, setContextPromptText] = useState<string>('')
//   const [isNewEntity, setIsNewEntity] = useState<boolean>(false)

//   const navigate = useNavigate()

//   const { saveNewEntity } = useAddNewEntity(selectedRow?.datapodId ?? '')

//   const [updateEntityName, newEntityName] = useUpdateEntityName()

//   const [updateNewMap, newMap] = useUpdateEntityMap()

//   useEffect(() => {
//     if (newEntityName && newMap) {
//       setShowPop(false)
//     }
//   }, [newMap, newEntityName])
//   //const [leftTable, setLeftTable] = useState<DataTableRow[]>([])
//   const [rightTable, setRightTable] = useState<DataTableRow[]>([])

//   const rightTableColumns = useMemo(() => {
//     return [
//       {
//         label: 'Table Name',
//       },
//       {
//         label: 'Entity Name',
//       },
//       {
//         label: 'Entity Description',
//       },
//       {
//         label: 'Fine Tune',
//       },
//       {
//         label: 'Attribute Mapping',
//       },
//     ]
//   }, [])
//   // const leftTableColumns = useMemo(() => {
//   //   return [{ label: 'List Of Tables' }]
//   // }, [])

//   const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
//     const tbID = Number(rowId)
//     const selRow = dsTables.find((r) => Number(r.dataTableID) === tbID)
//     //console.log({ selRow })
//     setSelectedRow(selRow)
//     // console.log({ rowId })
//     if (actionType === ShadowTableAction.Editable) {
//       const selRow = dsTables.find((r) => Number(r.dataTableID) === tbID)
//       //console.log({ selRow })
//       setSelectedRow(selRow)
//       setShowPop(true)
//     }
//     if (actionType === ShadowTableAction.Viewable && selRow) {
//       const attributesRoute = generatePath(ROUTES.DataMappingAttribues, {
//         dataPodId: selRow?.datapodId,
//         dataSystemID: selRow.dataSystemID,
//         entityID: selRow.dataEntityID,
//       })
//       navigate(attributesRoute)
//     }
//   }

//   const getTableColumns = (id: number) => {
//     const tbID = Number(id)
//     const tbIds = selectedTableIDs.includes(tbID)
//       ? selectedTableIDs.filter((tid) => tid != tbID)
//       : [...selectedTableIDs, tbID]

//     setSelectedTableID(tbIds)
//   }
//   useEffect(() => {
//     if (dataSourceTables.length) {
//       setDSTables(dataSourceTables)
//       const rtRows = dataSourceTables
//         .sort((t1, t2) => (t1.dataTableName > t2.dataTableName ? 1 : -1))
//         .map((dt) => ({
//           id: `${dt.dataTableID}`,
//           values: [dt.dataTableName, dt.dataEntityName, 'Desc', 'EDIT', 'VIEW'],
//         })) as DataTableRow[]
//       setRightTable(rtRows)
//     }
//   }, [dataSourceTables])

//   const updatedRightTable = useMemo(() => {
//     if (!selectedTableIDs.length) {
//       return rightTable
//     }
//     return rightTable.filter((rt) => selectedTableIDs.includes(Number(rt.id)))
//   }, [rightTable, selectedTableIDs])

//   const closePopup = (close: boolean) => {
//     setShowPop(close)
//     setSelectedRow(undefined)
//     setNewEntity(undefined)
//     setIsNewEntity(false)
//   }

//   const onChangeNewEntity = (name: string, value: string) => {
//     setNewEntity((prv) => ({ ...prv, [name]: value } as Entity))
//   }

//   const updateEntity = async () => {
//     if (selectedRow) {
//       const { dataEntityID, dataEntityName } = selectedRow
//       // await updateEntityName({
//       //   id: dataEntityID,
//       //   name: dataEntityName,
//       // })
//     }
//   }
//   const saveNewEntityMap = async () => {
//     if (newEntity) {
//       const { description, dataEntityName, coreEntity } = newEntity
//       const nEntity: NewEntity = {
//         dataEntityName,
//         description,
//         profileName: 'client App',
//         coreEntity,
//         dataLakeZone: 2,
//       }
//       const savedEntityRes = await saveNewEntity(nEntity)
//       if (savedEntityRes) {
//         setNewEntity(undefined)
//         setIsNewEntity(false)
//         setSelectedRow(
//           (prv) =>
//             ({
//               ...prv,
//               dataEntityID: savedEntityRes.id,
//               description: savedEntityRes.description,
//               dataEntityName: savedEntityRes.dataEntityName,
//             } as AppProfileEntity),
//         )
//         const tmp = dsTables.find((d) => selectedRow?.dataEntityID) as AppProfileEntity
//         setDSTables((prv) => [
//           ...prv,
//           {
//             ...tmp,
//             dataEntityID: savedEntityRes.id,
//             description: savedEntityRes.description,
//             dataEntityName: savedEntityRes.dataEntityName,
//           },
//         ])
//       }
//     }
//   }

//   const saveNewMap = async () => {
//     if (contextPromptText && selectedRow) {
//       const { mapId, dataEntityID, dataTableID } = selectedRow
//       await updateNewMap({
//         id: mapId,
//         guidedPromt: contextPromptText,
//         dataTableId: Number(dataTableID),
//         dataEntityId: dataEntityID,
//         profileName: 'client Application',
//       })
//     }
//   }

//   const onSubmit = () => {
//     !isNewEntity && updateEntity()
//     saveNewMap()
//   }

//   const onChange = (name: string, value: string) => {
//     setSelectedRow(
//       (prev) =>
//         ({
//           ...prev,
//           [name]: value,
//         } as AppProfileEntity),
//     )
//     setIsNewEntity(false)
//     setNewEntity(undefined)
//   }

//   return (
//     <Grid item container xs={16} md={12} spacing={3}>
//       <Grid item xs={3} md={3}>
//         <LucidPopUp
//           openPopUp={showPopUp}
//           onConfirm={onSubmit}
//           closePopup={closePopup}
//           headingText={<Typography variant="h4">Edit and Fine-Tune </Typography>}
//           confirmText="Save"
//           showCloseIcon
//         >
//           <Grid container justifyContent={'center'} alignItems={'stretch'} spacing={3}>
//             <Grid item xs={9}>
//               <Typography color={'text.primary'}>
//                 <span style={{ color: 'red' }}>*</span> Indicates a required field
//               </Typography>
//             </Grid>
//             <Grid item xs={9}>
//               <Typography color={'text.primary'}>
//                 <span style={{ color: 'red' }}>*</span> Table Description
//               </Typography>
//             </Grid>
//             <Grid item xs={9}>
//               <TextField
//                 fullWidth
//                 id="outlined-select-industry"
//                 placeholder="Table Description"
//                 color="secondary"
//                 size="small"
//                 rows={2}
//                 multiline
//                 value={selectedRow?.dataTableDesc ?? ''}
//                 onChange={({ target }) => onChange('dataSystemDesc', target.value)}
//               />
//             </Grid>
//             <Grid item xs={9}>
//               <Typography color={'text.primary'}>
//                 <span style={{ color: 'red' }}>*</span> Entity Name
//               </Typography>
//             </Grid>
//             <Grid item container xs={9}>
//               <Grid item xs={8}>
//                 <TextField
//                   fullWidth
//                   id="outlined-basic"
//                   placeholder="Enter Name"
//                   variant="outlined"
//                   color="secondary"
//                   size="small"
//                   select
//                   onChange={({ target }) => onChange('dataEntityID', target.value)}
//                   value={selectedRow?.dataEntityID ?? ''}
//                 >
//                   {dsTables.map((ds) => (
//                     <MenuItem key={ds.dataEntityID} value={ds.dataEntityID}>
//                       {ds.dataEntityName}
//                     </MenuItem>
//                   ))}
//                 </TextField>
//               </Grid>
//               <Grid item xs={4}>
//                 <Button onClick={() => setIsNewEntity(true)} startIcon={<Add />}>
//                   New Entity
//                 </Button>
//               </Grid>
//             </Grid>
//             {isNewEntity && (
//               <Grid item xs={9} container spacing={1}>
//                 <Grid item xs={8}>
//                   <TextField
//                     fullWidth
//                     id="outlined-basic"
//                     placeholder="New Entity Name"
//                     variant="outlined"
//                     color="secondary"
//                     size="small"
//                     onChange={({ target }) => onChangeNewEntity('dataEntityName', target.value)}
//                     value={newEntity?.dataEntityName ?? ''}
//                   />
//                 </Grid>

//                 <Grid item xs={8}>
//                   <TextField
//                     fullWidth
//                     id="outlined-basic"
//                     placeholder="Enter description"
//                     variant="outlined"
//                     color="secondary"
//                     size="small"
//                     onChange={({ target }) => onChangeNewEntity('description', target.value)}
//                     value={newEntity?.description ?? ''}
//                   />
//                 </Grid>
//                 <Grid item>
//                   <Button variant="contained" onClick={saveNewEntityMap} startIcon={<SaveOutlined />}>
//                     Save Entity
//                   </Button>
//                 </Grid>
//               </Grid>
//             )}
//             <Grid item xs={9}>
//               <Typography color={'text.primary'}>
//                 <span style={{ color: 'red' }}>*</span> Entity Description
//               </Typography>
//             </Grid>
//             <Grid item xs={9}>
//               <TextField
//                 fullWidth
//                 id="outlined-basic"
//                 placeholder="Enter Name"
//                 variant="outlined"
//                 color="secondary"
//                 size="small"
//                 onChange={({ target }) => onChange('description', target.value)}
//                 value={selectedRow?.description ?? ''}
//               />
//             </Grid>
//             <Grid item xs={9}>
//               <Typography color={'text.primary'}>
//                 <span style={{ color: 'red' }}>*</span> Fine Tune
//               </Typography>
//             </Grid>
//             <Grid item xs={9}>
//               <TextField
//                 fullWidth
//                 id="outlined-basic"
//                 helperText="Please provide a detailed context why you are making this change, so that the LLM model will remember this context for future processing"
//                 placeholder={'send a Prompt'}
//                 variant="outlined"
//                 color="secondary"
//                 rows={3}
//                 multiline
//                 value={contextPromptText}
//                 onChange={({ target }) => setContextPromptText(target.value)}
//               />
//             </Grid>
//           </Grid>
//         </LucidPopUp>
//         <Card sx={{ backgroundColor: 'white' }}>
//           <CardHeader
//             disableTypography
//             sx={{ backgroundColor: 'grey' }}
//             title={<Typography sx={{ fontSize: 15, align: 'right' }}>{'List Of Tables'}</Typography>}
//           />
//           <Divider />
//           <List sx={{ width: '100%', maxWidth: 360, marginTop: -2 }} dense>
//             {dataSourceTables.map((p, ix) => (
//               <ListItem key={ix}>
//                 <ListItemButton onClick={() => getTableColumns(p.dataTableID)}>
//                   <ListItemIcon>
//                     <Checkbox
//                       sx={{
//                         color: 'green',
//                         '&.Mui-checked': {
//                           color: green[600],
//                         },
//                       }}
//                       edge="start"
//                       tabIndex={-1}
//                       checked={selectedTableIDs.includes(Number(p.dataTableID))}
//                       disableRipple
//                     />
//                   </ListItemIcon>
//                   <ListItemText primary={p.dataTableName} />
//                 </ListItemButton>
//               </ListItem>
//             ))}
//           </List>
//         </Card>
//       </Grid>

//       <Grid item container xs={8}>
//         <ShadowTable
//           rows={updatedRightTable}
//           columns={rightTableColumns}
//           tableActionParams={{
//             onButtonClick: onButtonClick,
//             actions: [ShadowTableAction.Editable],
//           }}
//         />
//       </Grid>
//     </Grid>
//   )
// }
