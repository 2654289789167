import { useEffect, useState } from 'react'
import { serviceConnections } from '../apiServices/serviceConnections'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import {
  ConnectionType,
  DatapodServiceConnection,
  NewServiceConnection,
  ServiceConnectionConfig,
  UpdateServiceConnection,
} from 'businessObjects'
import { HttpStatusCode } from 'axios'

type FlatObject = { [key: string]: any }

export type ServiceConnectionType = {
  connectionName: string
  connectionType: string
  dataPodId: string
  id: number
  locationInfo: string
  serviceConnectionJsonTemplate: string
  serviceConnectionTypeId: string
  serviceTypeId: number
  //referring for edit|view
  action?: string
  //if any errors during service creation/update/delete using this prop for error msg
  errorMsg?: string
  date?: number
}

export const useQueryServiceConnections = (
  podId?: string,
  serviceConnectionTypeID?: string,
): {
  getServiceConnectionsByPodId: (datapodId: string) => Promise<void>
  connections: ServiceConnectionType[]
  error: Error | undefined
  connectionsLoading: boolean
} => {
  const [error, setError] = useState<Error>()
  const [connectionsLoading, setConnectionsLoading] = useState<boolean>(false)
  const [connections, setServiceConnections] = useState<ServiceConnectionType[]>([])
  const { getBearerToken } = useLucidAuthContext()

  const getServiceConnectionsByPodId = async (dataPodId: string): Promise<void> => {
    setConnectionsLoading(true)
    try {
      if (dataPodId) {
        const bearerToken = await getBearerToken()
        if (bearerToken) {
          const { data, status } = await serviceConnections.getServiceConnection(
            bearerToken,
            dataPodId,
            serviceConnectionTypeID || '',
          )
          if (status == 200) {
            setServiceConnections(data)
            setConnectionsLoading(false)
          }
        }
      }
    } catch (error: any) {
      setError(error)
      setConnectionsLoading(false)
    }
  }

  useEffect(() => {
    if (podId) {
      getServiceConnectionsByPodId(podId)
    }
  }, [podId])

  return { getServiceConnectionsByPodId, connections, error, connectionsLoading }
}

export const useGetServiceConnections = (
  dataPodId?: string,
): {
  dataServices: any
  dataLakeResults: any
  dataLakeZoneResults: any
  connectionTypes: FlatObject | undefined
  getServiceType: (type: string) => void
  getDataLake: (datapodId: string) => void
  getDataLakeZones: () => void
  isDataServiceLoading: boolean
  error: Error | undefined
} => {
  const [error, setError] = useState<Error>()
  const [isDataServiceLoading, setDataServiceLoading] = useState<boolean>(false)
  const [connectionTypes, setConnectionTypes] = useState<any>([])
  const [dataServices, setDataServices] = useState<any>()
  const [dataLakeResults, setDataLakeResults] = useState<any>()
  const [dataLakeZoneResults, setDataLakeZoneResults] = useState<any>()

  const { getBearerToken } = useLucidAuthContext()

  const getServiceConnectionTypes = async (): Promise<void> => {
    try {
      setDataServiceLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await serviceConnections.getServiceConnectionTypes(bearerToken)
        if (status == 200) {
          setConnectionTypes(data)
        } else {
          setError(data)
        }
        setDataServiceLoading(false)
      }
    } catch (error: any) {
      setDataServiceLoading(false)
      setError(error)
    }
  }

  const getServiceType = async (type: string): Promise<void> => {
    try {
      setDataServiceLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && type) {
        const { data, status } = await serviceConnections.getServiceType(bearerToken, type)
        if (status == 200) {
          setDataServices(data)
        } else {
          setError(data)
        }
        setDataServiceLoading(false)
      }
    } catch (error: any) {
      setDataServiceLoading(false)
      setError(error)
    }
  }

  const getDataLake = async (dataPodId: string): Promise<void> => {
    try {
      setDataServiceLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await serviceConnections.getDataLakeResults(bearerToken, dataPodId)
        if (status == 200) {
          setDataLakeResults(data)
        } else {
          setError(data)
        }
        setDataServiceLoading(false)
      }
    } catch (error: any) {
      setDataServiceLoading(false)
      setError(error)
    }
  }

  const getDataLakeZones = async (): Promise<void> => {
    try {
      setDataServiceLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await serviceConnections.getDataLakeZoneResults(bearerToken)
        if (status == 200) {
          setDataLakeZoneResults(data)
        } else {
          setError(data)
        }
        setDataServiceLoading(false)
      }
    } catch (error: any) {
      setDataServiceLoading(false)
      setError(error)
    }
  }

  useEffect(() => {
    if (dataPodId) {
      getDataLake(dataPodId)
    }
    getServiceConnectionTypes()
    getDataLakeZones()
  }, [])

  return {
    dataServices,
    dataLakeResults,
    dataLakeZoneResults,
    connectionTypes,
    getServiceType,
    getDataLake,
    getDataLakeZones,
    isDataServiceLoading,
    error,
  }
}

export const usePostServiceConnection = (
  dataPodId: string,
): {
  postComplete: ServiceConnectionType | undefined
  addServiceConnection: (body: FlatObject) => Promise<void>
  updateServiceConnection: (body: FlatObject) => Promise<void>
  deleteServiceConnection: (connectionId: number) => Promise<void>
  postError: FlatObject | undefined
  deleteStatus: FlatObject | undefined
} => {
  const [deleteStatus, setDeleteStatus] = useState<FlatObject | undefined>(undefined)
  const [postComplete, setPostComplete] = useState<ServiceConnectionType | undefined>(undefined)
  const [postError, setPostError] = useState<FlatObject | undefined>()
  const { getBearerToken } = useLucidAuthContext()

  const addServiceConnection = async (body: any): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await serviceConnections.addServiceConnection(bearerToken, dataPodId, body)
        if (status == 200) {
          setPostComplete({ ...data, date: Date.now() })
        } else {
          setPostError({ message: data?.toString(), date: Date.now() })
        }
      }
    } catch (error: any) {
      setPostError({
        message: error?.response?.data?.title || error?.response?.data || error?.message,
        date: Date.now(),
      })
    }
  }

  const updateServiceConnection = async (body: any): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await serviceConnections.updateServiceConnection(bearerToken, dataPodId, body)
        if (status == 200) {
          setPostComplete({ ...data, date: Date.now() })
        } else {
          setPostError({ message: data?.toString(), date: Date.now() })
        }
      }
    } catch (error: any) {
      setPostError({
        message: error?.response?.data?.title || error?.response?.data || error?.message,
        date: Date.now(),
      })
    }
  }

  const deleteServiceConnection = async (connectionId: number): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && connectionId) {
        const { data, status } = await serviceConnections.deleteServiceConnection(bearerToken, connectionId)
        if (status == 204) {
          setDeleteStatus({ status, date: Date.now() })
        } else {
          setPostError({ message: data?.toString(), date: Date.now() })
        }
      }
    } catch (error: any) {
      setPostError({
        message: error?.response?.data?.title || error?.response?.data || error?.message,
        date: Date.now(),
      })
    }
  }

  return {
    postComplete,
    addServiceConnection,
    updateServiceConnection,
    deleteServiceConnection,
    postError,
    deleteStatus,
  }
}

// call this hook after call serviceConnectionTypes
export const useGetServiceConnectionConfigs = (typeId?: string) => {
  const [serviceConnTypeConfigs, setServiceConnTypeConfigs] = useState<ServiceConnectionConfig[]>([])
  const [connTypeConfigError, setconnTypeConfigError] = useState<boolean>(false)
  const [isTypeConnLoading, setIsTypeConnLoading] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()
  const getServiceConfigs = async (): Promise<void> => {
    try {
      setIsTypeConnLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && typeId) {
        const { data, status } = await serviceConnections.getServiceType(bearerToken, typeId)
        if (status === HttpStatusCode.Ok && data) {
          setIsTypeConnLoading(false)
          setServiceConnTypeConfigs(data as ServiceConnectionConfig[])
        }
      }
    } catch (er) {
      console.log({ er })
      setIsTypeConnLoading(false)
      setconnTypeConfigError(true)
    }
  }
  useEffect(() => {
    if (typeId) {
      getServiceConfigs()
    }
  }, [typeId])

  return { serviceConnTypeConfigs, isTypeConnLoading, connTypeConfigError, refetchConnTypeConfigs: getServiceConfigs }
}

export const useGetServiceConnectionTypes = () => {
  const [serviceConnectionTypes, setServiceConnectionTypes] = useState<ConnectionType[]>([])
  const [connectionTypeError, setConnectionTypeError] = useState<boolean>(false)
  const [isTypeLoading, setIsTypeLoading] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()
  const getServiceType = async (): Promise<void> => {
    try {
      setIsTypeLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        const { data, status } = await serviceConnections.getServiceConnectionTypes(bearerToken)
        if (status === HttpStatusCode.Ok && data) {
          setIsTypeLoading(false)
          setServiceConnectionTypes(data as ConnectionType[])
        }
      }
    } catch (er) {
      console.log({ er })
      setIsTypeLoading(false)
      setConnectionTypeError(true)
    }
  }
  useEffect(() => {
    if (!serviceConnectionTypes.length) {
      getServiceType()
    }
  }, [])

  return { serviceConnectionTypes, isTypeLoading, connectionTypeError, refetchConnTypes: getServiceType }
}

export const useGetDatapodServiceConnections = (datapodId?: string, connectionTypeId?: string) => {
  const [dataPodServiceConnections, setDataPodServiceConnections] = useState<DatapodServiceConnection[]>([])
  const [connectionsError, setConnectionsError] = useState<boolean>(false)
  const [isConnectionsLoading, setIsConnectionsLoading] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()
  const getPodConnections = async (): Promise<void> => {
    try {
      setIsConnectionsLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && connectionTypeId && datapodId) {
        const { data, status } = await serviceConnections.getServiceConnection(bearerToken, datapodId, connectionTypeId)
        if (status === HttpStatusCode.Ok && data) {
          setIsConnectionsLoading(false)
          setDataPodServiceConnections(data as DatapodServiceConnection[])
        }
      }
    } catch (er) {
      console.log({ er })
      setIsConnectionsLoading(false)
      setConnectionsError(true)
    }
  }
  useEffect(() => {
    if (datapodId && connectionTypeId) {
      getPodConnections()
    }
  }, [datapodId, connectionTypeId])

  return {
    dataPodServiceConnections,
    isConnectionsLoading,
    connectionsError,
    refetchServiceConnections: getPodConnections,
  }
}

export const useDeleteServiceConnection = () => {
  const { getBearerToken } = useLucidAuthContext()

  const deleteConnFn = async (connectionId: number) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && connectionId) {
        const { status } = await serviceConnections.deleteServiceConnection(bearerToken, connectionId)
        return status === HttpStatusCode.NoContent
      }
    } catch (error) {
      console.log(error)
    }
  }
  return { deleteConnFn }
}

export const useAddServiceConnection = (datapodId?: string) => {
  const [isAddingConnection, setAddingConnection] = useState(false)
  const [addConnectionError, setAddConnectionError] = useState(false)
  const { getBearerToken } = useLucidAuthContext()

  const addServiceConnectionFn = async (newConn: NewServiceConnection) => {
    try {
      setAddingConnection(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId) {
        const { status, data } = await serviceConnections.addServiceConnection(bearerToken, datapodId, newConn)
        if (status === HttpStatusCode.Ok && data) {
          const resp = data as DatapodServiceConnection
          setAddingConnection(false)
          return resp.id
        }
      }
    } catch (error) {
      setAddConnectionError(true)
      setAddingConnection(false)
    }
  }
  return { addServiceConnectionFn, isAddingConnection, addConnectionError }
}

export const useUpateServiceConnection = (datapodId?: string) => {
  const [isUpdatingConnection, setIsUpdatingConnection] = useState(false)
  const [updateConnectionError, setUpdateConnectionError] = useState(false)
  const { getBearerToken } = useLucidAuthContext()

  const updateServiceConnectionFn = async (newConn: UpdateServiceConnection) => {
    try {
      setIsUpdatingConnection(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId) {
        const { status, data } = await serviceConnections.updateServiceConnection(bearerToken, datapodId, newConn)
        if (status === HttpStatusCode.Ok && data) {
          const resp = data as DatapodServiceConnection
          setIsUpdatingConnection(false)
          return resp.id
        }
      }
    } catch (error) {
      setUpdateConnectionError(true)
      setIsUpdatingConnection(false)
    }
  }
  return { updateServiceConnectionFn, isUpdatingConnection, updateConnectionError }
}

export const useGetServiceConnectionsByDataPod = (dataPodId?: string, serviceConnectionTypeID?: string) => {
  const [connError, setConnError] = useState<boolean>()
  const [connectionsLoading, setConnectionsLoading] = useState<boolean>(false)
  const [connections, setServiceConnections] = useState<DatapodServiceConnection[]>([])
  const { getBearerToken } = useLucidAuthContext()

  const getServiceConnections = async (): Promise<void> => {
    setConnectionsLoading(true)
    try {
      if (dataPodId) {
        const bearerToken = await getBearerToken()
        if (bearerToken) {
          const { data, status } = await serviceConnections.getServiceConnection(
            bearerToken,
            dataPodId,
            serviceConnectionTypeID || '',
          )
          if (status == 200) {
            setServiceConnections(data)
            setConnectionsLoading(false)
          }
        }
      }
    } catch (er) {
      setConnError(true)
      setConnectionsLoading(false)
    }
  }

  useEffect(() => {
    if (dataPodId) {
      getServiceConnections()
    }
  }, [dataPodId, serviceConnectionTypeID])

  return { connections, connError, getServiceConnections, connectionsLoading }
}
