import {
  Box,
  Grid,
  IconButton,
  Typography,
  Card,
  TextField,
  Divider,
  Checkbox,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  CardHeader,
  InputAdornment,
  Button,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  SelectChangeEvent,
  Container,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  Chip,
  OutlinedInput,
  Collapse,
  Autocomplete
} from '@mui/material'
import { Dispatch, useEffect, useMemo, useState } from 'react'

import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { green } from '@mui/material/colors'

import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import CancelIcon from '@mui/icons-material/Cancel'
import { ROUTES } from '../../../Routes/constants'
import ActionPage from '../../../ui-components/ActionPage'
import { cardContainer } from '../../../styles/globalStyles'
import {
  AddDataHubEntityMap,
  AttributeProfile,
  DataQualityRule,
  Entity,
  EntityMapping,
  FabricRunNewJobRequest,
  getDataHubResponse,
  ResultStatusSeverity,
  RunNewJobParams,
  RunStatusResponse,
  ServiceConnections,
  UpdateAttributeData,
} from '../../../businessObjects'
import {
  useGetAllEntitiesByDataPodId,
  useGetEntity,
  useGetEntityMapping,
  useGetEntityMappingByEntityIdAndPodId,
} from '../../../hooks/entityHooks'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import { useGenerateBusinessKey } from '../../../hooks/generateDatapodHooks'
import {
  useGetDataAttributes,
  useGetDataAttributesByEntityId,
  useGetDataAttributesMapping,
  useDeleteAttributeId,
  useUpdateDataAttribute,
} from '../../../hooks/dataAttrbutesHooks'
import { useGetAllDataSystemsByPodId, useGetDataSystemTypes } from '../../../hooks/dataSystemHooks'
import { useNotebookToQueryString } from '../../../hooks/notebookHooks'
import { useGetQueryString } from '../../../hooks/queryStringHook'
import { LucidJupyter } from '../../../ui-components/JupyterComponents/LucidJupyter'
import { usePostDatabricksJobRunNew } from '../../../hooks/databricksHooks'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { filterActiveRunIds, initiateActiveRunId } from '../../../features/notification/notificationSlice'
import { useGetServiceConnection } from '../../../hooks/dataEngineeringHooks'
import { useDeleteDataQualityRule, useGetDataQualityRules } from '../../../hooks/dataQualityHooks'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import { ArrowDownwardOutlined, ArrowUpwardOutlined } from '@mui/icons-material'
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined'
import {
  useGetDataColumnProfilingByAttributeId,
  useGetDataTableProfilingByTableId,
} from 'hooks/generativeIntegartionHooks'
import { ColumnProfilingCanType, ProfilingDataType } from '../profilingDataTypes'
import { useUpdateEntityMapFunc } from 'hooks/entityHooks'
import LucidWidePopUp from 'ui-components/LucidWidePopUp'
import { HeadingLabel, LucidTextLabel } from 'ui-components/LucidTextLabel'
import { GenBusinessKey } from 'businessObjects'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import React from 'react'
import Link from '@mui/material/Link'
import { serviceConnections } from 'apiServices/serviceConnections'
import { useSchemaComapatibilty, useSchemaComapatibiltyAtrribute } from 'hooks/genAIApiHooks'
import LoadingButton from '@mui/lab/LoadingButton'
import { sortAny } from 'utils/sortUtils'
import KeyIcon from '@mui/icons-material/Key'
import SettingsIcon from '@mui/icons-material/Settings'
import ResultStatus from 'ui-components/ResultStatus'
import { useAddDataHubEntityMap, useGetAllDataHubs, useGetDataHubEntityMaps, useGetEntityAttributes } from 'hooks/dataHubHooks'
import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp'
import { useGetRunNewJobPayload, useRunNewJob } from 'hooks/executionHooks'
type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
export const CanonicalModel = (props: Props) => {
  const { dataPodId, dataSystemID } = useParams<ViewResultsParams>()
  const typeId = useGetQueryString('typeId')
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const [selectedSourceID, setSelectedSourceID] = useState<number>()
  // const [tableView, setTableView] = useState<boolean>(true)
  // const [cardView, setCardView] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  // const [dataSystems] = useGetAllDataSystemsByPodId({}, dataPodId)
  const [entities] = useGetEntity({}, dataPodId, undefined, typeId && typeId ? Number(typeId) : undefined, true)
  const [filteredEntities, setFilteredEntities] = useState<Entity[]>(entities)
  // const [refetchEntities, setRefetchEntities] = useState<boolean>(false)
  const [hubEntities, setHubEntities] = useState<Entity[]>([])
  const [fetchAllEntity, entity] = useGetAllEntitiesByDataPodId()
  // const {fetchEntity} = useGetEntity;

  useEffect(() => {
    if (dataSystemID && !selectedSourceID) {
      setSelectedSourceID(Number(dataSystemID))
    }
  }, [dataSystemID])
  useEffect(() => {
    if (!entity.length && dataPodId?.length) {
      fetchAllEntity({}, dataPodId, undefined, undefined)
    } else if (entity.length) {
      const filteredHubEntities = entity.filter(entity =>
        entity.dataHubMapId !== null && entity.dataHubCloneTypeId !== null
      );
      setHubEntities(filteredHubEntities);
    }
  }, [entity, dataPodId])

  useEffect(() => {
    if (searchText.trim() === '') {
      setFilteredEntities(entities)
    } else {
      setFilteredEntities(
        entities?.filter((entity) => entity.dataEntityName.toLowerCase().includes(searchText.trim().toLowerCase())),
      )
    }
  }, [searchText])

  const allEntities = useMemo(() => {
    if (searchText.trim() === '') {
      return entities.sort((p1, p2) => (p1.dataEntityName > p2.dataEntityName ? 1 : -1))
    }
    return filteredEntities.sort((p1, p2) => (p1.dataEntityName > p2.dataEntityName ? 1 : -1))
  }, [entities?.length, filteredEntities?.length])

  const navigate = useNavigate()
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  // const confirmDeleteAttributeId = async (attributeId: number) => {
  //   if (attributeId) {
  //     const deleteMapSuccess = await deleteAttributeId(attributeId)
  //     if (deleteMapSuccess) {
  //       console.log('Success')
  //       // const filteredRows = tableRows?.filter((r) => r.id !== String(selectedAttributeRow?.id))
  //       // refetch()
  //       // setTableRows(filteredRows)
  //       // setDeleteSuccess(true)
  //       // closeDeleteWarning()
  //     }
  //   }
  // }

  return (
    <ActionPage>
      {/* <Grid item container spacing={2}>
        <Grid item xs={2}>
          <IconButton onClick={onClickHome}>
            <HomeOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid> */}
      <Box style={cardContainer}>
        {/* <Grid container spacing={2}>
          <Grid item container spacing={2}>
            <Grid item xs={12}>
              <Box sx={{ mt: 2 }}>
                <Grid container spacing={4} alignItems={'center'}>
                  <Grid item container xs={15} spacing={1}>
                    <Grid item xs={6}>
                      <Typography component="span" variant="subtitle1">
                        Data Pod Name
                      </Typography>
                    </Grid>
                    <Grid item />
 
                    <Grid item xs={5}>
                      <Typography component="span" variant="subtitle1">
                        Data Pod ID
                      </Typography>
                    </Grid>
                    <Grid item xs={12} md={15} />
 
                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.name}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={1} />
                      <Grid item xs={5}>
                        <TextField
                          fullWidth
                          id="outlined-select-dataSource"
                          variant="outlined"
                          value={dataPodInfo?.id}
                          required
                          size="small"
                          disabled
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            <Grid item xs={12} />
          </Grid> */}
        {/* </Grid> */}
        <Grid item container spacing={0}>
          <Grid item xs={12} container alignItems={'center'}>
            <Grid item xs={3}>
              <TextField
                placeholder="Search"
                value={searchText}
                onChange={({ target }) => setSearchText(target.value)}
                color="primary"
                margin="dense"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!searchText ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setSearchText('')}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Grid item container xs={14}>
            <DataModelEntityAttibuteProps dataEntities={allEntities} dataPodId={dataPodId} hubEntities={hubEntities} typeId={typeId} />
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}

type DataModelEntityAttibutePropsProps = {
  dataEntities: Entity[]
  dataPodId: string | undefined
  hubEntities: Entity[]
  typeId: string | null
}

const DataModelEntityAttibuteProps = ({ dataEntities, dataPodId, hubEntities, typeId }: DataModelEntityAttibutePropsProps) => {
  const navigate = useNavigate()

  const [selectedEntityID, setSelectedEntityID] = useState<number | undefined>(dataEntities[0]?.id)
  const [hubEntityDatapod, setHubEntityDatapod] = useState<any>()
  const [hubEntity, setHubEntity] = useState<boolean>(false)
  // const [dataEntityTypes, setDataEntityType] = useState<number>(dataEntities[0]?.dataEntityType)
  const [entityMappings] = useGetEntityMapping({}, hubEntity? hubEntityDatapod?.dataEntityId : selectedEntityID)
  //const [entityMappingsByTable] = useGetEntityMapping({})
  const [fetchDataAttributes, dataAttributes, loading, createError] = useGetDataAttributes(false)
  const [selectedEntityInfo, setSelectedEntityInfo] = useState<Entity>()
  const [selectedEntityMappings, setSelectedEntityMappings] = useState<EntityMapping>()
  // const [showPopup, setShowPopup] = useState<boolean>(false)
  // const [fetchAttributesMappingData, attributesMappingData] = useGetDataAttributesMapping()
  const [notebookString, setNotebookString] = useState<string>()
  const [isTableAsc, setIsTableAsc] = useState<boolean>(true)
  const [isTableHubAsc, setIsTableHubAsc] = useState<boolean>(true)
  const [filterAnchor, setFilterAnchor] = useState<null | HTMLElement>(null)
  const [filterApplied, setFilterApplied] = useState<'In Canonical' | 'Not In Canonical' | 'all'>('all')
  const [viewTableProfiling, setViewTableProfiling] = useState<boolean>(false)
  const [openColumnProfiling, setOpenColumnProfiling] = useState<boolean>(false)
  const { fetchEntityMapping } = useGetEntityMappingByEntityIdAndPodId(dataPodId)
  // const [deleteAttributeIdStatus, setDeleteAttributeIdStatus] = useState<boolean>(true)
  const [dataHubsPopUp, setDataHubsPopUp] = useState<boolean>(false)
  const [fetchDataHubs, dataHubs] = useGetAllDataHubs()

  const { attributeProfiles, refetchAttributes } = useGetDataAttributesByEntityId(selectedEntityID)

  const { loadCanonicalNotebookStringByEntityId, notebookResponse } = useNotebookToQueryString(dataPodId)
  const [leftTableEntities, setLeftTableEntities] = useState<Entity[]>([])
  const [entitiesIdsToLoad, setEntityIdsToLoad] = useState<number[]>([])
  const [selectedTableId, setSelectedTableId] = useState<number>()
  const [allDataSystems] = useGetAllDataSystemsByPodId({}, dataPodId)
  const { allDataSystemTypes } = useGetDataSystemTypes()
  const { deleteAttributeId } = useDeleteAttributeId()
  const [canonicalLoadType, setCanonicalLoadType] = useState<{ [key: number]: string }>({})

  const [runId, setRunId] = useState<number | string>()
  const [fetchAllEntity, entity] = useGetAllEntitiesByDataPodId()
  // const { tableProfiling } = useGetDataTableProfiling(dataPodId, selectedTableId)

  const { getDataTableProfilingData } = useGetDataTableProfilingByTableId(dataPodId)
  const [tablesProfilingInfo, setTablesProfilingInfo] = useState<ProfilingDataType[]>([])
  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const activeFabricRunStatus = useAppSelector((state: RootState) => state.notification.fabricRunStatus)
  const dispatch = useAppDispatch()
  const { getConnections } = useGetServiceConnection()
  const { generateBusinessKey } = useGenerateBusinessKey()
  const { fetchSchemaComapatibiltyAtrribute } = useSchemaComapatibiltyAtrribute()
  const { fetchSchemaComapatibilty } = useSchemaComapatibilty()
  const { updateEntityMapFunc } = useUpdateEntityMapFunc()
  const [updateAttribFunc] = useUpdateDataAttribute()
  const [defaultDropDownOptions, setDefaultDropDownOptions] = useState<any>()
  const [DropDownSelectionFlag, setDropDownSelectionFlag] = useState<boolean>(false)
  const [bkLoading, setBKLoading] = useState<boolean>(false)
  const [schemaCompat, setSchemaCompat] = useState<boolean>(false)
  const [dataCompat, setDataCompat] = useState<boolean>(false)
  const [dataLakeLoad, setDataLakeLoad] = useState<boolean>(false)
  const [loadToWarehouse, setLoadToWarehouse] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState(null)
  const [showPopUp, setShowPop] = useState<boolean>(false)
  const [info, setInfo] = useState<boolean>(false)
  const [severity, setSeverity] = useState<ResultStatusSeverity>('info')
  const [resultStatusMessage, setResultStatusMessage] = useState<string>(`Info: Select Entity to perform the action`)
  const [isOpen, setIsOpen] = useState(true);
  const [isHubOpen, setIsHubOpen] = useState(true);
  const [userSelectedDataHub, setuserSelectedDataHub] = useState<getDataHubResponse>()
  const [submitNewDataHubEntityMap] = useAddDataHubEntityMap()
  const [getDataHubEntityAttributes] = useGetEntityAttributes()
  const [dataHubAttributes, setDataHubAttributes] = useState<DataTableRow[]>()
  const [dataHubCustomAttributes, setDataHubCustomAttributes] = useState<DataTableRow[]>()
  const [getDataHubEntities] = useGetDataHubEntityMaps()

  const {runNewJob} = useRunNewJob()
  const { getRunNewJobPayload } = useGetRunNewJobPayload();

  const sortTableFn = (t1: Entity, t2: Entity) => {
    if (t1.dataEntityName < t2.dataEntityName) {
      return isTableAsc ? -1 : 1
    }
    if (t1.dataEntityName > t2.dataEntityName) {
      return isTableAsc ? 1 : -1
    }
    return 0
  }
  const sortTableHubFn = (t1: Entity, t2: Entity) => {
    if (t1.dataEntityName < t2.dataEntityName) {
      return isTableHubAsc ? -1 : 1
    }
    if (t1.dataEntityName > t2.dataEntityName) {
      return isTableHubAsc ? 1 : -1
    }
    return 0
  }

  const { getDataColumnProfilingByAttributeId, tableColumnProfiling } = useGetDataColumnProfilingByAttributeId()

  const entityMappingTableColumns = useMemo(() => {
    return [
      {
        label: 'Data System Name',
        sortableColumn: true,
      },
      {
        label: 'Data System Type',
        sortableColumn: true,
      },

      {
        label: 'Table Name',
        sortableColumn: true,
      },
      // {
      //   label: 'Table Profile',
      //   sortableColumn: true,
      // },
    ]
  }, [])

  const dataPodDataSystems = useMemo(() => {
    if (allDataSystems.length) return allDataSystems
    return allDataSystems
  }, [allDataSystems])

  const dataSystemTypes = useMemo(() => {
    if (allDataSystemTypes.length) return allDataSystemTypes
    return allDataSystemTypes
  }, [allDataSystemTypes])

  const generatedTableColumns = useMemo(() => {
    if(hubEntity){
      return [
        { label: 'Attribute Name', sortableColumn: true },
        // { label: 'Description', sortableColumn: true },
        { label: 'Attribute Type', sortableColumn: true },
        { label: 'Data Type', sortableColumn: true },
        { label: 'Data Classification', sortableColumn: true },
        { label: 'isBusinessKey', sortableColumn: true },
        { label: 'Column Profile' },
      ]
    }else{
      return [
        { label: 'Attribute Name', sortableColumn: true },
        // { label: 'Description', sortableColumn: true },
        { label: 'Attribute Type', sortableColumn: true },
        { label: 'Data Type', sortableColumn: true },
        { label: 'Data Classification', sortableColumn: true },
        { label: 'isBusinessKey', sortableColumn: true },
        { label: 'Column Profile' },
        { label: 'Edit Business Key' },
      ]
    }
  }, [hubEntity])

  const cusomtAttribTableColumns = useMemo(() => {
    return [
      { label: 'Attribute Name', sortableColumn: true },
      { label: 'Attribute Description', sortableColumn: true },
      { label: 'Attribute Type', sortableColumn: true },
      { label: 'Data Type', sortableColumn: true },
      { label: 'Data Classification', sortableColumn: true },
      { label: 'isBusinessKey', sortableColumn: true },
      { label: 'View Details' },
      { label: 'Delete' },
    ]
  }, [])
  const cusomtAttribTableColumnsHub = useMemo(() => {
    return [
      { label: 'Attribute Name', sortableColumn: true },
      { label: 'Attribute Description', sortableColumn: true },
      { label: 'Attribute Type', sortableColumn: true },
      { label: 'Data Type', sortableColumn: true },
      { label: 'Data Classification', sortableColumn: true },
      { label: 'isBusinessKey', sortableColumn: true },
    ]
  }, [])

  // const attributesModalColumns = useMemo(() => {
  //   return [
  //     { label: 'Data System Name' },
  //     { label: 'Data Table Name' },
  //     { label: 'Column Name' },
  //     { label: 'Transformer Text' },
  //     { label: 'Transformer Query' },
  //     { label: 'Add/Edit Transformer' },
  //   ]
  // }, [])

  useEffect(() => {
    if (notebookResponse?.message) {
      // console.log('comes here')
      setNotebookString(notebookResponse?.message)
    }
  }, [notebookResponse])

  const sortedEntites = useMemo(() => {
    if (!dataEntities.length) return []
    // if (!entity.length) return []
    if (entity.length) {
      return entity
    } else {
      return dataEntities
    }
  }, [dataEntities, entity])

  useEffect(() => {
    if (sortedEntites.length > 0) {
      setLeftTableEntities(sortedEntites)
      if (selectedEntityID) {
        if (!sortedEntites.some((item) => item.id === selectedEntityID)) {
          const firstEntityId = sortedEntites[0]?.id
          setSelectedEntityID(firstEntityId)
        }
      } else {
        const firstEntityId = sortedEntites[0]?.id
        setSelectedEntityID(firstEntityId)
      }
    } else {
      setSelectedEntityID(undefined)
    }
  }, [sortedEntites])

  useEffect(() => {
    if (!dataHubs?.length) {
      fetchDataHubs()
    }
  }, [])

  useEffect(() => {
    if (selectedEntityID) {
      const selectedEntity = sortedEntites.find((entity) => entity.id === selectedEntityID)
      const selectedHubEntity = hubEntities.find((entity) => entity.id === selectedEntityID)
      if (!selectedEntity && selectedHubEntity) {
        setSelectedEntityInfo(selectedHubEntity)
      } else {
        setSelectedEntityInfo(selectedEntity)
        setDataHubAttributes(undefined)
        setDataHubCustomAttributes(undefined)
      }
      if (selectedEntity) {
        setHubEntity(false)
        setHubEntityDatapod(undefined)
        fetchDataAttributes(selectedEntityID)
      }
      if (selectedHubEntity) {
        setHubEntity(true)
        getDataHubAttributes(selectedEntityID, selectedHubEntity)
      }
      loadCanonicalNotebookStringByEntityId(selectedEntityID)
    } else {
      setHubEntity(false)
      setHubEntityDatapod(undefined)
      setSelectedEntityInfo(undefined)
    }
  }, [selectedEntityID])

  useEffect(() => {
    if (activeRunIds && runId) {
      const temp = activeRunIds.find((element) => element.runId === runId)
      if (temp && temp.state.resultState === 'SUCCESS') {
        // console.log({ temp })
        if (temp.runName?.includes('LDH_CanonicalLoad') && selectedEntityID && dataPodId) {
          setDataLakeLoad(false)
          // fetchAllEntity({}, dataPodId, undefined, undefined)
          fetchAllEntity({}, dataPodId, undefined, typeId && typeId ? Number(typeId) : undefined, undefined, undefined, true)
          setEntityIdsToLoad([])
        } else if (temp.runName?.includes('LDH_DataCompatbilityCheck') && selectedEntityID && dataPodId) {
          setDataCompat(false)
          setEntityIdsToLoad([])
        } else if (temp.runName?.includes('LDH_LoadToDataWarehouse') && selectedEntityID && dataPodId) {
          setLoadToWarehouse(false)
          setEntityIdsToLoad([])
        }
      }
    }
  }, [activeRunIds])
  
  useEffect(() => {
    if (activeFabricRunStatus && runId) {
      const temp = activeFabricRunStatus.find((element) => element.id === runId)
      if (temp && temp.status === 'Completed') {
        // console.log({ temp })
        if (temp.jobDetails?.triggerType === 'Data Lake Load' && selectedEntityID && dataPodId) {
          setDataLakeLoad(false)
          // fetchAllEntity({}, dataPodId, undefined, undefined)
          fetchAllEntity({}, dataPodId, undefined, typeId && typeId ? Number(typeId) : undefined, undefined, undefined, true)
          setEntityIdsToLoad([])
        } else if (temp.jobDetails?.triggerType === 'Data Compatibility Check' && selectedEntityID && dataPodId) {
          setDataCompat(false)
          setEntityIdsToLoad([])
        } else if (temp.jobDetails?.triggerType === 'Data Warehouse Load' && selectedEntityID && dataPodId) {
          setLoadToWarehouse(false)
          setEntityIdsToLoad([])
        }
      }
    }
  }, [activeFabricRunStatus])

  // const updatedDataAttributesTable = useMemo(() => {
  //   if (selectedEntityID) {
  //     const attributesRow = dataAttributes?.map((dt) => ({
  //       id: `${dt.id}`,
  //       values: [
  //         dt.dataAttributeName,
  //         dt.description,
  //         dt.dataAttributeType == '2' ? 'Target' : 'Source',
  //         dt.dataAttributeDataType,
  //         dt.dataClassification,
  //         dt.isBusinessKey ? 'True' : 'False',
  //         'VIEW',
  //       ],
  //     })) as DataTableRow[]
  //     return attributesRow
  //   } else return []
  // }, [dataAttributes, selectedEntityID])
  const getDataHubAttributes = async (selectedEntId: number, hubEntityInfo: Entity) => {
    if (dataPodId?.length && selectedEntId) {
      const resp = await getDataHubEntityAttributes(dataPodId, selectedEntId)
      if (resp) {
        // setDataHubAttributes(resp)
        const typeMapping: { [key: string]: string } = {
          string: "String",
          integer: "Integer",
          double: "Double",
          date: "Date",
          boolean: "Boolean",
          binary: "Binary",
          timestamp: "Timestamp",
          long: "Long",
        };
        // setDefaultDropDownOptions(tempArray)
        const tempTableRows = resp.filter((attr: any) => attr.attributeDataType !== 'CustomAttribute').map((attr: any) => ({
          id: `${attr.id}`,
          values: [
            attr.dataAttributeName,
            // ad.description,
            attr.attributeDataType,
            attr.dataAttributeDataType,
            //ad.dataAttributeDataType,
            // 'DROPDOWN',
            attr.dataClassification,
            attr.isBusinessKey ? 'True' : 'False',
            'VIEW',
          ],
        }))
        setDataHubAttributes(tempTableRows)
        const tempTableRowsHubCustom = resp.filter((attr: any) => attr.attributeDataType === 'CustomAttribute').map((attr: any) => ({
          id: `${attr.id}`,
          values: [
            attr.dataAttributeName,
            attr.description,
            attr.attributeDataType,
            attr.dataAttributeDataType,
            attr.dataClassification,
            attr.isBusinessKey ? 'True' : 'False',
          ],
        }))
        setDataHubCustomAttributes(tempTableRowsHubCustom)
        if (hubEntityInfo && hubEntityInfo.dataHubMapId) {
          const dataHubEnts = await getDataHubEntities(undefined, hubEntityInfo.dataHubMapId)
          if (dataHubEnts) {
            setHubEntityDatapod(dataHubEnts[0])
          }
        }
      } else {
        console.log("some error while getting the attributes")
      }
    }
  }
  const generatedAttributes = useMemo(() => {
    if (!attributeProfiles.length) return []
    // console.log("Inside the use memo", {attributeProfiles})
    const tempArray = attributeProfiles.map((ap) => ({
      [ap.id]: { defaultValue: ap.dataAttributeDataType },
    }))
    // console.log(tempArray)
    setDefaultDropDownOptions(tempArray)
    return attributeProfiles
      .filter((ad) => ad.dataAttributeType !== 'CustomAttribute')
      .map((ad) => ({
        id: `${ad.id}`,
        values: [
          ad.dataAttributeName,
          // ad.description,
          ad.dataAttributeType,
          //ad.dataAttributeDataType,
          'DROPDOWN',
          ad.dataClassification,
          ad.isBusinessKey ? 'True' : 'False',
          'VIEW',
          ad.isBusinessKey ? 'TOGGLE ON' : 'TOGGLE OFF',
        ],
      }))
  }, [attributeProfiles, selectedEntityID])

  const compositeAttributes = useMemo(() => {
    if (!attributeProfiles.length) return []
    return attributeProfiles
      .filter((ad) => ad.dataAttributeType === 'CustomAttribute')
      .map((ad) => ({
        id: `${ad.id}`,
        values: [
          ad.dataAttributeName,
          ad.description,
          ad.dataAttributeType,
          ad.dataAttributeDataType,
          ad.dataClassification,
          ad.isBusinessKey ? 'True' : 'False',
          'EDIT',
          'DELETE',
        ],
      }))
  }, [attributeProfiles, selectedEntityID])

  const updatedEntityMappingTable = useMemo(() => {
    if (selectedEntityID) {
      const entityMappingRows = entityMappings.map((dt) => {
        const dataSystemInfo = dataPodDataSystems.find((ds) => ds.id === dt.dataSystemId)
        const dataSystemType = dataSystemTypes.find((dst) => dst.id === dataSystemInfo?.dataSystemType)
        return {
          id: `${dt.id}`,
          values: [dt.dataSystemName, dataSystemType? dataSystemType?.dataSystemType1 : "Source", dt.dataTableName],
        }
      }) as DataTableRow[]
      return entityMappingRows
    } else return []
  }, [entityMappings[0]?.id, selectedEntityID])

  const selectedEntitySourceInfo = useMemo(() => {
    if (selectedEntityID) {
      return entityMappings.find((emp) => emp.dataEntityId === selectedEntityID)
    }
  }, [entityMappings, selectedEntityID])

  const onCompositeButtonClick = async (rowId: string, actionType: ShadowTableAction) => {
    if (actionType === ShadowTableAction.Deletable) {
      const deleteMapSuccess = await deleteAttributeId(parseInt(rowId))
      if (deleteMapSuccess && selectedEntityID) {
        refetchAttributes()
      }
    }
    if (actionType === ShadowTableAction.Editable) {
      const dataAttributeDetailsRoute = generatePath(ROUTES.DataModelingCanonicalModelEditCustomAttribute, {
        dataPodId: dataPodId,
        entityID: selectedEntityID,
        // tableID: selectedEntitySourceInfo?.dataTableId,
        // dataSystemID: selectedEntitySourceInfo?.dataSystemId,
        dataAttributeID: rowId,
      })
      navigate(dataAttributeDetailsRoute)
      if (selectedEntityID) loadCanonicalNotebookStringByEntityId(selectedEntityID)
    }
  }

  const onClickCustomAttribute = () => {
    const dataAttributeDetailsEditRoute = generatePath(ROUTES.DataModelingCanonicalModelAddAttribute, {
      dataPodId: dataPodId,
      entityID: selectedEntityID,
      // tableID: selectedEntitySourceInfo?.dataTableId,
      // dataSystemID: selectedEntitySourceInfo?.dataSystemId,
    })
    navigate(dataAttributeDetailsEditRoute)
  }

  const runComptabilityNewJob = async () => {
    if(dataPodId && entitiesIdsToLoad.length) {
      const requestBody = {
        i_data_pod_id: dataPodId || '',
        i_data_entity_id: JSON.stringify(entitiesIdsToLoad),
      };
      
      const jobParams = {
        name: entitiesIdsToLoad
          .map((id) => {
            const dataEntity = dataEntities.find((de) => de.id === id);
            return dataEntity ? dataEntity.dataEntityName : null;
          })
          .filter((name) => name !== null)
          .join(', '),
        route: generatePath(ROUTES.DataModelingCanonicalModel, {
          dataPodId: dataPodId,
        }),
      };
  
      try {
        const payload = await getRunNewJobPayload(dataPodId, requestBody, 'dataCompatibility_Job_Id');
        
        if(payload) {
          const runId = await runNewJob(activeRunIds, jobParams, 'Data Compatibility Check', payload);
          
          if(runId){
            setRunId(runId)
          }
        }
      } catch (error: any) {
        setInfo(true)
        setSeverity('error')
        setResultStatusMessage(error.message)
      }
    }
  };

  const runDatawarehouseLoadJob = async () => {
    if(dataPodId && entitiesIdsToLoad.length) {
      const requestBody = {
        i_data_pod_id: dataPodId || '',
        i_data_entity_id: JSON.stringify(entitiesIdsToLoad),
      };
      
      const jobParams = {
        name: entitiesIdsToLoad
          .map((id) => {
            const dataEntity = dataEntities.find((de) => de.id === id);
            return dataEntity ? dataEntity.dataEntityName : null;
          })
          .filter((name) => name !== null)
          .join(', '),
        route: generatePath(ROUTES.DataModelingCanonicalModel, {
          dataPodId: dataPodId,
        }),
      };
  
      try {
        const payload = await getRunNewJobPayload(dataPodId, requestBody, 'dataWarehouse_Job_Id');
        
        if(payload) {
          const runId = await runNewJob(activeRunIds, jobParams, 'Data Warehouse Load', payload);
          
          if(runId){
            setRunId(runId)
          }
        }
      } catch (error: any) {
        setInfo(true)
        setSeverity('error')
        setResultStatusMessage(error.message)
      }
    }
  };

  const handleRunNewJob = async () => {
    if(dataPodId && entitiesIdsToLoad.length) {
      const requestBody = {
        i_data_pod_id: dataPodId || '',
        i_data_entity_id: JSON.stringify(entitiesIdsToLoad),
      };
      
      const jobParams = {
        name: entitiesIdsToLoad
          .map((id) => {
            const dataEntity = dataEntities.find((de) => de.id === id);
            return dataEntity ? dataEntity.dataEntityName : null;
          })
          .filter((name) => name !== null)
          .join(', '),
        route: generatePath(ROUTES.DataModelingCanonicalModel, {
          dataPodId: dataPodId,
        }),
      };

      try {
        const payload = await getRunNewJobPayload(dataPodId, requestBody, 'dataCanonical_Job_Id');
        
        if(payload) {
          const runId = await runNewJob(activeRunIds, jobParams, 'Data Lake Load', payload);
          
          if(runId){
            setRunId(runId)
          }
        }
      } catch (error: any) {
        setInfo(true)
        setSeverity('error')
        setResultStatusMessage(error.message)
      }
    }
  };

  const { dataQualityRules, isDataQualityRulesLoading, isDataQualityRulesError } =
    useGetDataQualityRules(selectedEntityID)
  const { isDeleteRuleSuccess, isDeleteRuleError, deleteDataQualityRule } = useDeleteDataQualityRule()

  const [rules, setRules] = useState<DataQualityRule[]>([])

  useEffect(() => {
    setRules(dataQualityRules)
  }, [dataQualityRules])

  const dataQualityColumnNames = useMemo(() => {
    return [{ label: 'Name', sortableColumn: true }, { label: 'View Details' }, { label: 'Delete' }]
  }, [])

  const dataQualityRowValues = useMemo(() => {
    if (!rules.length) return []
    return rules.map((rule) => ({
      id: `${rule.id}`,
      values: [rule.name, 'EDIT', 'DELETE'],
    }))
  }, [rules, selectedEntityID])

  const onDataQualityRuleSelect = async (rowId: string, actionType: ShadowTableAction) => {
    if (actionType === ShadowTableAction.Deletable) {
      const isDeleted: boolean = await deleteDataQualityRule(parseInt(rowId))
      if (isDeleted && selectedEntityID) {
        const filteredRules = rules.filter((rule) => rule.id !== parseInt(rowId))

        setRules(filteredRules)
      }
    }
    if (actionType === ShadowTableAction.Editable) {
      const dataQualityEditRoute = generatePath(ROUTES.DataModelingCanonicalModelEditDataQuality, {
        dataPodId: dataPodId,
        entityID: selectedEntityID,
        // tableID: selectedEntitySourceInfo?.dataTableId,
        // dataSystemID: selectedEntitySourceInfo?.dataSystemId,
        dataQualtiyID: rowId,
      })
      navigate(dataQualityEditRoute)
      // if(selectedEntityID) loadCanonicalNotebookStringByEntityId(selectedEntityID)
    }
  }

  const onClickDataQuality = () => {
    const dataQualityAddRoute = generatePath(ROUTES.DataModelingCanonicalModelAddDataQuality, {
      dataPodId: dataPodId,
      entityID: selectedEntityID,
      // tableID: selectedEntitySourceInfo?.dataTableId,
      // dataSystemID: selectedEntitySourceInfo?.dataSystemId,
    })
    navigate(dataQualityAddRoute, { state: {} })
  }

  const handleFilterSelect = (id: typeof filterApplied) => {
    setFilterApplied(id)
    switch (id) {
      case 'In Canonical':
        const canonicalLoaded = sortedEntites.filter((dt) => dt.canonicalLoad && dt.canonicalLoad)
        // console.log({ canonicalLoaded })
        setLeftTableEntities(canonicalLoaded)
        setFilterAnchor(null)
        break
      case 'Not In Canonical':
        const canonicalUnLoaded = sortedEntites.filter((dt) => !dt.canonicalLoad)
        // console.log({ canonicalUnLoaded })
        setLeftTableEntities(canonicalUnLoaded)
        setFilterAnchor(null)
        break
      case 'all':
        setLeftTableEntities(sortedEntites)
        setFilterAnchor(null)
        break
    }
  }

  const sortedEntities = useMemo(() => {
    return leftTableEntities.sort(sortTableFn)
  }, [leftTableEntities, isTableAsc])

  const sortedHubEntities = useMemo(()=>{
   return hubEntities.sort(sortTableHubFn) 
  },[hubEntities, isTableHubAsc]) 

  const handleEntityToLoad = (eId: number) => {
    const entIds = entitiesIdsToLoad.includes(eId)
      ? entitiesIdsToLoad.filter((ed) => ed != eId)
      : [...entitiesIdsToLoad, eId]
    setEntityIdsToLoad(entIds)
  }

  function onTableProfButtonClick(rowId: string, actionType: ShadowTableAction): void {
    switch (actionType) {
      case ShadowTableAction.Viewable:
        // console.log({ actionType }, rowId)

        setSelectedTableId(entityMappings.find((em) => em.id === Number(rowId))?.dataTableId)
        setViewTableProfiling(true)
    }
  }

  const onTableProfCanonicalButtonClick = async () => {
    let prevTableProfile: ProfilingDataType[] = []
    for (let i = 0; i < entityMappings.length; i++) {
      const { dataTableId } = entityMappings[i]
      const tableData = await getDataTableProfilingData(dataTableId)
      if (tableData) {
        try {
          const profilingInfo = JSON.parse(tableData[0].profilingData) as ProfilingDataType
          setCanonicalLoadType((prevState) => ({
            ...prevState,
            [i]: tableData[0].maptype,
          }))
          prevTableProfile = [
            ...prevTableProfile,
            {
              ...profilingInfo,
              entityMapId: entityMappings[i].id,
              dataTableId: dataTableId,
              dataEntityId: entityMappings[i].dataEntityId,
              maptype: tableData[0].maptype,
            },
          ]
        } catch (error) {
          console.log({ error })
        }
      } else {
        console.log('error fetching profiling data for tableId: ', dataTableId)
      }
    }
    setTablesProfilingInfo(prevTableProfile)

    setViewTableProfiling(true)
  }

  const onClickGenerateKey = async () => {
    if (dataPodId && entitiesIdsToLoad.length) {
      setBKLoading(true)
      let allSuccess: number[] = []
      for (let i = 0; i < entitiesIdsToLoad.length; i++) {
        const genKeyBody: GenBusinessKey = {
          dataPodId: dataPodId,
          entityId: entitiesIdsToLoad[i],
        }
        const isSuccess = await generateBusinessKey(genKeyBody)
        if (isSuccess) {
          allSuccess = [...allSuccess, entitiesIdsToLoad[i]]
        }
      }
      if (allSuccess.length == entitiesIdsToLoad.length) {
        setEntityIdsToLoad([])
        setBKLoading(false)
        refetchAttributes()
      } else {
        setBKLoading(false)
      }
    }
  }

  const onClickSchemaCompatbility = async () => {
    if (dataPodId && entitiesIdsToLoad.length) {
      setSchemaCompat(true)
      for (let i = 0; i < entitiesIdsToLoad.length; i++) {
        const isSuccess = await fetchSchemaComapatibilty(dataPodId, entitiesIdsToLoad[i])
        if (isSuccess) {
          setSchemaCompat(false)
          refetchAttributes()
        }
      }
    }
  }

  // function onButtonAttributeClick(rowId: String, actionType: ShadowTableAction): void {
  //   switch (actionType) {
  //     case ShadowTableAction.Viewable:
  //       // console.log({ actionType }, rowId)
  //       getDataColumnProfilingByAttributeId(Number(rowId))
  //       setOpenColumnProfiling(true)
  //   }
  // }

  async function onButtonAttributeClick(rowId: String, actionType: ShadowTableAction, newValue?: string) {
    switch (actionType) {
      case ShadowTableAction.Viewable:
        let columProfileRoute: string
        if (hubEntity) {
          columProfileRoute = generatePath(ROUTES.DataModelingCanonicalColumnProfileInfo, {
            dataPodId: hubEntityDatapod.dataPodId,
            dataAttributeID: Number(rowId),
          })
        } else {
          columProfileRoute = generatePath(ROUTES.DataModelingCanonicalColumnProfileInfo, {
            dataPodId: dataPodId,
            dataAttributeID: Number(rowId),
          })
        }
        navigate(columProfileRoute, { state: { attrId: Number(rowId), dp: dataPodId } })
        break

      case ShadowTableAction.Toggle:
        // console.log('Inside the toggle', newValue)
        const attribute = attributeProfiles.find((at) => at.id === Number(rowId))
        if (attribute && dataPodId && !newValue?.length) {
          const params: UpdateAttributeData = {
            id: attribute?.id ?? 0,
            name: attribute?.dataAttributeName ?? '',
            description: attribute?.description ?? '',
            dataAttributeDataType: attribute?.dataAttributeDataType ?? '',
            dataClassification: attribute?.dataClassification ?? '',
            isBusinessKey: !attribute?.isBusinessKey,
            coreEntity: false,
            entityType: '',
          }
          const updatedAttibutes = await updateAttribFunc(params, dataPodId)
          if (updatedAttibutes) {
            refetchAttributes()
            // fetchAllEntity({}, dataPodId, undefined, undefined)
            fetchAllEntity({}, dataPodId, undefined, typeId && typeId ? Number(typeId) : undefined, undefined, undefined, true)
          }
        }
        break
    }
  }

  const onEditDropDownSelectionClick = async (rowId: String, actionType: ShadowTableAction, newValue?: string) => {
    switch (actionType) {
      case ShadowTableAction.DropDownSelection:
        if (newValue) {
          // console.log(newValue)
          // setDropDownSelectionFlag(true)
          const attributeResp = attributeProfiles.find((at) => at.id === Number(rowId))
          if (attributeResp && dataPodId) {
            const params: UpdateAttributeData = {
              id: attributeResp?.id ?? 0,
              name: attributeResp?.dataAttributeName ?? '',
              description: attributeResp?.description ?? '',
              dataAttributeDataType: newValue,
              dataClassification: attributeResp?.dataClassification ?? '',
              isBusinessKey: attributeResp?.isBusinessKey,
              coreEntity: false,
              entityType: '',
            }
            const updatedAttibutes = await updateAttribFunc(params, dataPodId)
            if (updatedAttibutes) {
              fetchSchemaComapatibiltyAtrribute(dataPodId, attributeResp.dataEntityId, attributeResp.id, 'No', newValue)
              refetchAttributes()
            }
          }
        }

        break
    }
  }
  const columnProfilingData = useMemo(() => {
    if (!tableColumnProfiling.length) return []
    return tableColumnProfiling
  }, [tableColumnProfiling])

  // const colProfile = useMemo(() => {
  //   if (!columnProfilingData) return
  //   try {
  //     return JSON.parse(columnProfilingData.profilingData) as ColumnProfilingType
  //   } catch (error) {}
  // }, [columnProfilingData])

  const colProfile: ColumnProfilingCanType[] = useMemo(() => {
    if (!columnProfilingData) return []

    try {
      const parsedProfiles = columnProfilingData.map((item) => {
        return JSON.parse(item.profilingData) as ColumnProfilingCanType
      })
      // console.log(parsedProfiles)
      return parsedProfiles
    } catch (error) {
      console.error('Error parsing column profiling data:', error)
      return []
    }
  }, [columnProfilingData])

  const scrollableContainer = {
    maxHeight: '30vh',
    overflowY: 'auto',
  }

  const handleLoadType = async (index: number, selectedProfile: ProfilingDataType, selectedType: string) => {
    if (selectedProfile.entityMapId && selectedProfile.dataEntityId && selectedProfile.dataTableId) {
      const upMapBody = {
        id: selectedProfile.entityMapId,
        dataTableId: selectedProfile.dataTableId,
        dataEntityId: selectedProfile.dataEntityId,
        profileName: '',
        guidedPromt: '',
        mapType: selectedType,
      }
      const resp = await updateEntityMapFunc(upMapBody)
      if (resp) {
        setCanonicalLoadType((prevState) => ({
          ...prevState,
          [index]: selectedType,
        }))
      }
    }
  }

  const onCloseTableProfiling = (close: boolean) => {
    setViewTableProfiling(close)
    setTablesProfilingInfo([])
  }
  const onClosePushDataHub = (close: boolean) => {
    setDataHubsPopUp(close)
    setuserSelectedDataHub(undefined)
    // setFetchAction(false)
    // setTablesProfilingInfo([])
  }

  const onPreviewButtonClick = async () => {
    let columProfileRoute: string
    if (hubEntity) {
      columProfileRoute = generatePath(ROUTES.DataModelingCanonicalDataPreview, {
        dataPodId: hubEntityDatapod.dataPodId,
        dataEntityID: hubEntityDatapod.dataEntityId,
        hubEntity: true
      })
    } else {
      columProfileRoute = generatePath(ROUTES.DataModelingCanonicalDataPreview, {
        dataPodId: dataPodId,
        dataEntityID: selectedEntityID,
        hubEntity: false
      })
    }
    navigate(columProfileRoute, { state: { entId: selectedEntityID, dp: dataPodId } })
  }

  const pushOrFetchHubEntity = async () => {
    if (userSelectedDataHub && selectedEntityID && dataPodId && selectedEntityInfo) {
      const body: AddDataHubEntityMap[] = [{
        dataHubId: userSelectedDataHub.id,
        dataEntityId: selectedEntityID,
        dataPodId: dataPodId
      }]
      const resp = await submitNewDataHubEntityMap(body)
      if (resp && resp.flag) {
        setDataHubsPopUp(false)
        setInfo(true)
        setSeverity('success')
        setResultStatusMessage('Pushed ' + selectedEntityInfo.dataEntityName + ' to ' + userSelectedDataHub.dataHubName)
      } else {
        setInfo(true)
        setSeverity('error')
        setResultStatusMessage('Error while pushing ' + selectedEntityInfo.dataEntityName + ' to ' + userSelectedDataHub.dataHubName)
      }
    }

  }

  const handleSubmit = (value: string) => {
    if (entitiesIdsToLoad.length) {
      setSelectedValue(value)
      // console.log(value)
      handleClose()
      switch (value) {
        case 'Generate Business Key':
          onClickGenerateKey()
          setSelectedValue('')
          break
        // case 'Check Schema Compatibility':
        //   onClickSchemaCompatbility()
        //   break
        case 'Check Data Compatibility':
          onClickSchemaCompatbility()
          runComptabilityNewJob()
          setSelectedValue('')
          break
        case 'Data Lake Load':
          handleRunNewJob()
          setSelectedValue('')
          break
        case 'Data Warehouse Load':
          runDatawarehouseLoadJob()
          setSelectedValue('')
          break
        case 'Push to hub':
          setDataHubsPopUp(true)
          setSelectedValue('')
          break
        case 'Fetch from hub':
          const fetchEntityRoute = generatePath(ROUTES.DataModelingCanonicalModelFetchEntity,
            { dataPodId: dataPodId }
          )
          navigate(fetchEntityRoute)
          setSelectedValue('')
          break
      }
    } else if (value === 'Fetch from hub') {
      const fetchEntityRoute = generatePath(ROUTES.DataModelingCanonicalModelFetchEntity,
        { dataPodId: dataPodId }
      )
      navigate(fetchEntityRoute)
      setSelectedValue('')
    } else {
      setInfo(true)
      setShowPop(true)
      setSelectedValue('')
    }
  }

  const canonicalModelOptions = [
    { value: 'Generate Business Key', label: 'Generate Business Key', icon: '' },
    //{ value: 'Check Schema Compatibility', label: 'Check Schema Compatibility', icon: <KeyIcon fontSize="small" /> },
    { value: 'Check Data Compatibility', label: 'Check Data Compatibility', icon: '' },
    { value: 'Data Lake Load', label: 'Data Lake Load', icon: '' },
    { value: 'Data Warehouse Load', label: 'Data Warehouse Load', icon: '' },
    { value: 'Push to hub', label: 'Push Entity To Hub' },
    { value: 'Fetch from hub', label: 'Fetch Entity From Hub' }
  ]

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleOnChangeDataHub = async (key: string, value: string) => {
    const dataHub = dataHubs?.find((dh) => dh.id === value)
    setuserSelectedDataHub(dataHub)
  }

  return (
    <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={14} spacing={2}>
      <ResultStatus
        severtiy={severity}
        showStatus={info}
        closeStatus={setInfo}
        alertMessage={resultStatusMessage}
      />
      <LucidPopUp
        showCloseIcon
        openPopUp={dataHubsPopUp}
        headingText={'Push Entity to Data Hub'}
        closePopup={onClosePushDataHub}
        onConfirm={pushOrFetchHubEntity}
        confirmText='Push'
      >
        <Grid container>
          <Grid item xs={12}>
            <Typography color="text.primary">
              <span style={{ color: 'red' }}>*</span> Entity Name
            </Typography>
            <TextField
              fullWidth
              id="current-table-id"
              placeholder="Entity Name"
              variant="outlined"
              color="secondary"
              size="small"
              value={selectedEntityInfo?.dataEntityName ?? ''}
              // onChange={({ target }) => handleOnChangeDataHub('attributeName', target.value)}
              disabled
            >
            </TextField>
          </Grid>
          <Grid item container xs={12} display={'flex'} flexDirection={'column'} alignContent={'left'}>
            <Grid item xs={12} mt={2}>
              <Typography color="text.primary">
                <span style={{ color: 'red' }}>*</span> Data Hub  Name
              </Typography>
              <TextField
                fullWidth
                id="current-table-id"
                placeholder="Data Hub Name"
                variant="outlined"
                color="secondary"
                size="small"
                value={userSelectedDataHub?.id ?? ''}
                onChange={({ target }) => handleOnChangeDataHub('attributeName', target.value)}
                select
              >
                {dataHubs?.map((dh) => (
                  <MenuItem key={dh.id} value={dh.id}>
                    {dh.dataHubName}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
          {userSelectedDataHub && (
            <>
              <Grid item xs={12} mt={2}>
                <Typography color="text.primary">
                  Data Hub  Description
                </Typography>
                <TextField
                  fullWidth
                  id="current-table-id"
                  placeholder="Data Hub Name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  value={userSelectedDataHub?.dataHubDesc ?? ''}
                  disabled
                >
                  {userSelectedDataHub?.dataHubDesc}
                </TextField>
              </Grid>
              <Grid item xs={12} mt={2}>
                <Typography color="text.primary">
                  Data Hub  Created By
                </Typography>
                <TextField
                  fullWidth
                  id="current-table-id"
                  placeholder="Data Hub Name"
                  variant="outlined"
                  color="secondary"
                  size="small"
                  value={userSelectedDataHub?.createdBy ?? ''}
                  disabled
                >
                  {userSelectedDataHub?.dataHubDesc}
                </TextField>
              </Grid>
            </>)}
        </Grid>
      </LucidPopUp>
      <LucidWidePopUp
        showCloseIcon
        openPopUp={viewTableProfiling}
        headingText={'Table Profile Information'}
        closePopup={onCloseTableProfiling}
      //cancelText="Close"
      >
        <Grid container>
          {/* <Grid item xs={10} sx={{ marginBottom: 2 }}>
            <Typography sx={{ fontSize: 18 }} color="primary">
              Table Profile Information
            </Typography>
          </Grid> */}
          {tablesProfilingInfo.map((dataTableProfilingData, ix) => (
            <Container key={ix} sx={scrollableContainer}>
              <Grid item xs={12} sx={{ marginBottom: 12 }}>
                <Grid item xs={12} container sx={{ marginBottom: 2 }}>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <LucidTextLabel labelText="TableName" />
                    </Grid>
                    <Grid item xs={3}>
                      <LucidTextLabel labelText="Format" />
                    </Grid>
                    <Grid item xs={3}>
                      <LucidTextLabel labelText="Primary Key" />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2"> {dataTableProfilingData?.['Table Name']} </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2"> {dataTableProfilingData?.Format} </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2"> {dataTableProfilingData?.['Primary Key']} </Typography>
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} />
                  </Grid>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <LucidTextLabel labelText="Size in Bytes" />
                    </Grid>
                    <Grid item xs={3}>
                      <LucidTextLabel labelText="Rows" />
                    </Grid>
                    <Grid item xs={3}>
                      <LucidTextLabel labelText="Delta Key" />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2"> {dataTableProfilingData?.['Size in Bytes']} </Typography>
                    </Grid>

                    <Grid item xs={3}>
                      <Typography variant="body2"> {dataTableProfilingData?.Rows} </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {dataTableProfilingData?.['Delta Key']['Column Name']
                          ? dataTableProfilingData?.['Delta Key']['Column Name']
                          : 'Null'}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <LucidTextLabel labelText="Max Date" />
                    </Grid>
                    <Grid item xs={3}>
                      <LucidTextLabel labelText="Min Date" />
                    </Grid>
                    <Grid item xs={3}>
                      <LucidTextLabel labelText="Null Values" />
                    </Grid>
                  </Grid>
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {dataTableProfilingData?.['Delta Key']['Max Date']
                          ? dataTableProfilingData?.['Delta Key']['Max Date']
                          : 'Null'}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {dataTableProfilingData?.['Delta Key']['Min Date']
                          ? dataTableProfilingData?.['Delta Key']['Min Date']
                          : 'Null'}
                      </Typography>
                    </Grid>
                    <Grid item xs={3}>
                      <Typography variant="body2">
                        {dataTableProfilingData?.['Delta Key']['Null Values']
                          ? dataTableProfilingData?.['Delta Key']['Null Values']
                          : 'Null'}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12} />
                    {tablesProfilingInfo.length > 1  && (
                      <Grid item container xs={9} spacing={1}>
                        <FormControl>
                          <FormLabel id="demo-controlled-radio-buttons-group">{'Canonical Load Type'}</FormLabel>
                          <RadioGroup
                            row
                            aria-labelledby="demo-controlled-radio-buttons-group"
                            name="controlled-radio-buttons-group"
                            key={ix}
                            value={canonicalLoadType[ix] || ''}
                            onChange={({ target }) => handleLoadType(ix, dataTableProfilingData, target.value)}
                          >
                            <FormControlLabel value={'Append'} control={<Radio />} label={'Append'} disabled={hubEntity}/>
                            <FormControlLabel value={'Merge'} control={<Radio />} label={'Merge'} disabled={hubEntity} />
                          </RadioGroup>
                        </FormControl>
                      </Grid>
                    )}
                    <Grid item xs={12} />
                    <Divider variant="middle" flexItem />
                    <Grid item xs={12} />
                  </Grid>
                </Grid>
              </Grid>
            </Container>
          ))}
        </Grid>
      </LucidWidePopUp>
      <LucidWidePopUp
        openPopUp={openColumnProfiling}
        headingText={'Column Profile Information'}
        closePopup={setOpenColumnProfiling}
        cancelText="Close"
      >
        <Grid item xs={15} sx={{ ...scrollableContainer, marginLeft: 4, marginTop: 2, marginBottom: 12 }}>
          <Grid item xs={12} container sx={{ marginBottom: 2 }}>
            {colProfile.map((profile, profileIndex) => (
              <Card sx={{ backgroundColor: 'white', width: '100%' }}>
                <Grid key={profileIndex} item xs={12} container spacing={1}>
                  {/* Dynamic header labels for each column profile */}
                  {Object.keys(profile).map((key, index) => {
                    switch (key) {
                      case 'Column Name':
                      case 'Data Type':
                      case 'Rows':
                      case 'Set Type':
                      case 'Max Value':
                      case 'Min Value':
                        return (
                          <Grid key={index} item xs={4}>
                            <LucidTextLabel labelText={key} />
                            <Typography variant="body2">{profile[key as keyof ColumnProfilingCanType]}</Typography>
                          </Grid>
                        )
                      default:
                        return null // Handle other keys or skip them
                    }
                  })}
                  <Grid item xs={12} />
                  <Grid item xs={12} />
                  <Grid item container xs={12} spacing={1}>
                    <Grid item xs={12} />
                    <Grid item xs={12} />
                  </Grid>
                  <Grid item container xs={15} direction="row" spacing={0.5}>
                    <Grid item xs={12}>
                      <HeadingLabel headingText="Sample Distribution Set" />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item container sx={{ ...scrollableContainer, marginLeft: 4, marginTop: 2, marginBottom: 2 }}>
                      {profile.Distribution && (
                        <>
                          {Object.keys(profile.Distribution).map((key, distIndex) => (
                            <Grid key={distIndex + key} item container xs={4} spacing={2} justifyContent={'left'}>
                              <Grid item xs={6}>
                                <Typography variant="body2">{key}: </Typography>
                              </Grid>
                              <Grid item xs={3}>
                                <Typography variant="subtitle1" gutterBottom>
                                  {profile.Distribution ? JSON.stringify(profile.Distribution[key]) ?? '' : ''}
                                </Typography>
                              </Grid>
                            </Grid>
                          ))}
                        </>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </Card>
            ))}
          </Grid>
        </Grid>
      </LucidWidePopUp>
      <Grid
        item
        container
        display={'flex'}
        flexDirection={'row'}
        xs={12}
        justifyContent={'space-evenly'}
        alignContent={'space-evenly'}
        ml={'30%'}
      >
        <Grid item xs={9} />
        <div>
          <IconButton
            aria-label="more"
            id="long-button"
            color="primary"
            aria-controls={anchorEl ? 'long-menu' : undefined}
            aria-haspopup="true"
            onClick={handleClick}
            disabled={hubEntity}
          >
            <SettingsIcon />
          </IconButton>
          <Menu id="long-menu" anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
            {canonicalModelOptions.map((option) => (
              <MenuItem
                key={option.value}
                onClick={() => handleSubmit(option.value)}
                selected={option.value === selectedValue}
              >
                {option.label}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <Grid item xs={2}>
          <Button variant="contained" onClick={onPreviewButtonClick}>
            Data Preview
          </Button>
        </Grid>
      </Grid>
      {/* <Grid
        item
        container
        display={'flex'}
        flexDirection={'row'}
        xs={12}
        justifyContent={'space-evenly'}
        alignContent={'space-evenly'}
        ml={'30%'}
      > */}

      {/* <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}> */}
      {/* <Grid item xs={2}>
          <LoadingButton loading={bkLoading} variant="contained" onClick={onClickGenerateKey}>
            Generate Business Key
          </LoadingButton>
        </Grid> */}
      {/* </Grid> */}
      {/* <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}> */}
      {/* <Grid item xs={3}>
          <LoadingButton loading={schemaCompat} variant="contained" onClick={onClickSchemaCompatbility}>
            Check Schema Compatibility
          </LoadingButton>
        </Grid> */}
      {/* </Grid> */}
      {/* <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}> */}
      {/* <Grid item xs={2}>
          <LoadingButton loading={dataCompat} variant="contained" onClick={runComptabilityNewJob}>
            Check Data Compatibility
          </LoadingButton>
        </Grid> */}
      {/* </Grid> */}
      {/* <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}> */}
      {/* <Grid item xs={1.5}>
          <LoadingButton loading={dataLakeLoad} variant="contained" onClick={runNewJob}>
            Data Lake Load
          </LoadingButton>
        </Grid> */}
      {/* </Grid> */}
      {/* <Grid item xs={2} sx={{ display: 'flex', justifyContent: 'flex-start', marginBottom: 2 }}> */}
      {/* <Grid item xs={2}>
          <LoadingButton variant="contained" onClick={runDatawarehouseLoadJob} loading={loadToWarehouse}>
            Data Warehouse load
          </LoadingButton>
        </Grid>  */}
      {/* </Grid> */}
      <Grid item xs={3.5}>
        <Grid display={'flex'} flexDirection={'column'} justifyContent={'center'} item xs={12}>
          <Grid item xs={12}>
            <Card sx={{ backgroundColor: 'white', marginLeft: 0 }}>
              <CardHeader
                disableTypography
                sx={{ backgroundColor: 'grey' }}
                title={
                  <Grid item xs={12} container alignItems="baseline" spacing={1}>
                    <Grid item xs={8}>
                      <Typography variant="body2" sx={{ fontSize: 16 }} gutterBottom>
                        {'List of Entites'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="small"
                        title={`sort ${isTableAsc ? 'asc' : 'desc'}`}
                        onClick={() => setIsTableAsc(!isTableAsc)}
                      >
                        {isTableAsc ? <ArrowDownwardOutlined fontSize="small" /> : <ArrowUpwardOutlined fontSize="small" />}
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      <IconButton title="Filter" onClick={({ currentTarget }) => setFilterAnchor(currentTarget)}>
                        <FilterAltOutlinedIcon fontSize="small" />
                      </IconButton>
                      <Menu
                        id="basic-menu"
                        anchorEl={filterAnchor}
                        open={Boolean(filterAnchor)}
                        onClose={handleFilterSelect}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                          'aria-multiselectable': 'true',
                        }}
                      >
                        {['In Canonical', 'Not In Canonical', 'all'].map((itm) => (
                          <MenuItem
                            selected={filterApplied === itm}
                            key={itm}
                            onClick={() => handleFilterSelect(itm as typeof filterApplied)}
                          >
                            {itm}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Grid>
                  </Grid>
                }
              />
              <Divider />
              <Grid item display={'flex'} justifyContent={'flex-end'} flexDirection={'row'}>
                <IconButton onClick={() => setIsOpen(!isOpen)}>
                  {isOpen ? <><Typography>Collapse</Typography><ArrowUpwardOutlined fontSize="small" /></> :
                    <><Typography>Expand</Typography><ArrowDownwardOutlined fontSize="small" /></>}
                </IconButton>
              </Grid>
              <Collapse in={isOpen} timeout="auto" unmountOnExit>
                <List dense>
                  {sortedEntities.map((p, ix) => (
                    <ListItem
                      key={ix}
                      sx={{
                        color: selectedEntityID === p.id ? 'blue' : 'inherit',
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: 'green',
                          '&.Mui-checked': {
                            color: green[600],
                          },
                        }}
                        edge="start"
                        tabIndex={-1}
                        checked={entitiesIdsToLoad.includes(p.id)}
                        onChange={() => handleEntityToLoad(p.id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        // disabled={p.canonicalLoad}
                        disableRipple
                      />
                      <ListItemButton
                        onClick={() => {
                          setNotebookString('')
                          setSelectedEntityID(p.id)
                        }}
                      >
                        <ListItemText primary={p.dataEntityName} />
                      </ListItemButton>
                      {!!p.hasBuisnessKey ? (
                        <></>
                      ) : (
                        <ErrorOutlineIcon fontSize="small" color="warning" titleAccess="No Business Key" />
                      )}
                      {!!p.canonicalLoad ? (
                        <CheckCircleIcon fontSize="small" color="primary" titleAccess="Loaded to Canonical" />
                      ) : (
                        <></>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Card>
          </Grid>
          <Grid item xs={12} sx={{ mt: isOpen ? 1 : 2 }}>
            <Card sx={{ backgroundColor: 'white', marginLeft: 0 }}>
              <CardHeader
                disableTypography
                sx={{ backgroundColor: 'grey' }}
                title={
                  <Grid item xs={12} container alignItems="baseline" spacing={1}>
                    <Grid item xs={8}>
                      <Typography variant="body2" sx={{ fontSize: 16 }} gutterBottom>
                        {'List of Hub Fetched Entites'}
                      </Typography>
                    </Grid>
                    <Grid item>
                      <IconButton
                        size="small"
                        title={`sort ${isTableHubAsc ? 'asc' : 'desc'}`}
                        onClick={() => setIsTableHubAsc(!isTableHubAsc)}
                      >
                        {isTableAsc ? <ArrowDownwardOutlined fontSize="small" /> : <ArrowUpwardOutlined fontSize="small" />}
                      </IconButton>
                    </Grid>
                    <Grid item xs={1}>
                      {/* <IconButton title="Filter" onClick={({ currentTarget }) => setFilterAnchor(currentTarget)}>
                        <FilterAltOutlinedIcon fontSize="small" />
                      </IconButton> */}
                      <Menu
                        id="basic-menu"
                        anchorEl={filterAnchor}
                        open={Boolean(filterAnchor)}
                        onClose={handleFilterSelect}
                        MenuListProps={{
                          'aria-labelledby': 'basic-button',
                          'aria-multiselectable': 'true',
                        }}
                      >
                        {['In Canonical', 'Not In Canonical', 'all'].map((itm) => (
                          <MenuItem
                            selected={filterApplied === itm}
                            key={itm}
                            onClick={() => handleFilterSelect(itm as typeof filterApplied)}
                          >
                            {itm}
                          </MenuItem>
                        ))}
                      </Menu>
                    </Grid>
                  </Grid>
                }
              />
              <Divider />
              <Grid item display={'flex'} justifyContent={'flex-end'} flexDirection={'row'}>
                <IconButton onClick={() => setIsHubOpen(!isHubOpen)}>
                  {isHubOpen ? <><Typography>Collapse</Typography><ArrowUpwardOutlined fontSize="small" /></> :
                    <><Typography>Expand</Typography><ArrowDownwardOutlined fontSize="small" /></>}
                </IconButton>
              </Grid>
              <Collapse in={isHubOpen} timeout="auto" unmountOnExit>
                <List dense>
                  {sortedHubEntities.map((p, ix) => (
                    <ListItem
                      key={ix}
                      sx={{
                        color: selectedEntityID === p.id ? 'blue' : 'inherit',
                      }}
                    >
                      <Checkbox
                        sx={{
                          color: 'green',
                          '&.Mui-checked': {
                            color: green[600],
                          },
                        }}
                        edge="start"
                        tabIndex={-1}
                        checked={entitiesIdsToLoad.includes(p.id)}
                        onChange={() => handleEntityToLoad(p.id)}
                        inputProps={{ 'aria-label': 'controlled' }}
                        // disabled={p.canonicalLoad}
                        disableRipple
                      />
                      <ListItemButton
                        onClick={() => {
                          setNotebookString('')
                          setSelectedEntityID(p.id)
                        }}
                      >
                        <ListItemText primary={p.dataEntityName} />
                      </ListItemButton>
                      {!!p.hasBuisnessKey ? (
                        <></>
                      ) : (
                        <ErrorOutlineIcon fontSize="small" color="warning" titleAccess="No Business Key" />
                      )}
                      {!!p.canonicalLoad ? (
                        <CheckCircleIcon fontSize="small" color="primary" titleAccess="Loaded to Canonical" />
                      ) : (
                        <></>
                      )}
                    </ListItem>
                  ))}
                </List>
              </Collapse>
            </Card>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={8.5} spacing={1}>
        <Grid item xs={12} sx={{ marginBottom: 4 }}>
          <Grid item xs={10} sx={{ marginBottom: 2 }}>
            <Typography sx={{ fontSize: 18 }} color="primary">
              Selected Entity Details
            </Typography>
          </Grid>
          <Grid item xs={12} container sx={{ marginBottom: 2 }}>
            <Grid item xs={4}>
              <Typography variant="body1" textAlign={'left'}>
                Entity Id
              </Typography>
              <Typography color="text.secondary" textAlign={'left'}>
                {selectedEntityInfo?.id}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" textAlign={'left'}>
                Entity Name
              </Typography>
              <Typography color="text.secondary" textAlign={'left'}>
                {selectedEntityInfo?.dataEntityName}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="body1" textAlign={'left'}>
                Entity Type
              </Typography>
              <Typography color="text.secondary" textAlign={'left'}>
                {selectedEntityInfo?.entityType}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container sx={{ marginBottom: 2 }}>
            {/* <Grid item xs={4}>
              <Typography variant="body1" textAlign={'left'}>
                Is Core
              </Typography>
              <Typography color="text.secondary" textAlign={'left'}>
                {selectedEntityInfo?.coreEntity}
              </Typography>
            </Grid> */}
            <Grid item xs={12}>
              <Typography variant="body1" textAlign={'left'}>
                Entity Description
              </Typography>
              <Typography color="text.secondary" textAlign={'left'}>
                {selectedEntityInfo?.description}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} container sx={{ marginBottom: 4 }} spacing={1}>
          <Grid item xs={6}>
            <Typography variant="h6" gutterBottom color="primary">
              Entity's Source Tables
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <Link component="button" variant="h6" onClick={onTableProfCanonicalButtonClick}>
              Table Profile and Canonical Load Type
            </Link>
          </Grid>

          <ShadowTable
            rows={updatedEntityMappingTable}
            columns={entityMappingTableColumns}
          // tableActionParams={{
          //   onButtonClick: onTableProfButtonClick
          //   actions: [ShadowTableAction.Viewable],
          // }}
          />
          {/* <ShadowTable
              rows={generatedAttributes}
              columns={generatedTableColumns}
              tableActionParams={{
                onButtonClick: onButtonAttributeClick,
                actions: [ShadowTableAction.Viewable],
              }}
            /> */}
        </Grid>

        <Grid item xs={12} container sx={{ marginBottom: 4 }} spacing={1}>
          <Grid item xs={10} container alignItems="flex-end">
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom color="primary">
                Source Attributes
              </Typography>
            </Grid>
            <Grid item xs={4} />
          </Grid>
          <Grid item xs={15} />
          <ShadowTable
            rows={dataHubAttributes?.length ? dataHubAttributes : generatedAttributes}
            columns={generatedTableColumns}
            dropDownOptions={['String', 'Integer', 'Double', 'Date', 'Boolean', 'Binary', 'Timestamp', 'Long']}
            dropDownDefaultValues={defaultDropDownOptions}
            // defaultValue={generatedAttributes.}
            tableActionParams={{
              onButtonClick: onButtonAttributeClick,
              onEditDropDownSelectionClick: onEditDropDownSelectionClick,
              actions: [ShadowTableAction.Viewable, ShadowTableAction.Toggle, ShadowTableAction.DropDownSelection],
            }}
          />
        </Grid>
        <Grid item xs={12} container display={'flex'} justifyContent={'space-between'} sx={{ mb: 2 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Custom Attributes
          </Typography>
          <Button variant="contained" color="primary" onClick={onClickCustomAttribute} disabled={hubEntity}>
            Add Custom Attribute
          </Button>
        </Grid>

        <Grid item xs={12} sx={{ mb: 2 }} />
        <ShadowTable
          rows={dataHubCustomAttributes?.length ? dataHubCustomAttributes : compositeAttributes}
          columns={dataHubCustomAttributes?.length ? cusomtAttribTableColumnsHub : cusomtAttribTableColumns}
          tableActionParams={{
            onButtonClick: onCompositeButtonClick,
            actions: [ShadowTableAction.Viewable, ShadowTableAction.Deletable],
          }}
        />

        <Grid item xs={12} container display={'flex'} justifyContent={'space-between'} sx={{ mt: 4, mb: 2 }}>
          <Typography variant="h6" gutterBottom color="primary">
            Data Quality
          </Typography>
          <Button variant="contained" color="primary" onClick={onClickDataQuality} disabled={hubEntity}>
            Add Data Quality
          </Button>
        </Grid>

        <Grid item xs={12} />
        <ShadowTable
          rows={dataQualityRowValues}
          columns={dataQualityColumnNames}
          tableActionParams={{
            onButtonClick: onDataQualityRuleSelect,
            actions: [ShadowTableAction.Viewable, ShadowTableAction.Deletable],
          }}
        />
        <Grid item xs={12} container sx={{ mt: 2 }} spacing={1}>
          {/* <LucidJupyter
              noteBookString={notebookString}
              headingText={'Canonical Load - Verify and adjust generated spark code'}
              updateNoteBookString={setNotebookString}
            /> */}
        </Grid>
      </Grid>
    </Grid>
  )
}
