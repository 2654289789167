import { useEffect, useState } from 'react'
import { useNavigate, useParams, generatePath } from 'react-router-dom'
import { ROUTES } from '../../Routes/constants'
import DataPodDetailRibbon from '../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import ShadowTable, { ShadowTableAction } from '../../ui-components/ShadowTable/ShadowTable'
import ActionPage from '../../ui-components/ActionPage'
import {
  CardHeader,
  Button,
  Card,
  CardContent,
  Card as MUICard,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  Skeleton,
  IconButton,
} from '@mui/material'
import LoadingButton from '@mui/lab/LoadingButton'
import { AnalyticsMeasure, AnalyticsGroup } from '../../businessObjects'
import { AddOutlined, Delete, SendOutlined } from '@mui/icons-material'
import { useGetDataPodInfo } from '../../hooks/datapodHooks'
import { useDeleteAnalyticsGroup, useGetAnalyticGroups } from '../../hooks/analyticsGroupHooks'
import { useDeleteAllAnalyticsMeasure, useDeleteAnalyticsMeasure, useGetAnalyticMeasure, useMeasureNotebook } from '../../hooks/analyticsMeasureHooks'
import { DataTableRow } from '../../ui-components/DataTable/DataTableTypes'
import { ProgressBar } from '../../ui-components/ProgressBar'
import GroupEntities from './GroupEntities'
import { useGetQueryString } from '../../hooks/queryStringHook'
import { group } from 'console'
import ResultStatus from '../../ui-components/ResultStatus'
import AnimatedLoader from '../../ui-components/AnimatedLoader'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import { DeletePopUp } from 'pages/DataProcess/DataMapping/DataMappingPopUps'

type ViewResultsParams = {
  dataPodId: string
}

const analyticsMeasureColumns = [
  {
    label: 'Measure ID',
  },
  {
    label: 'Measure Name',
  },
  {
    label: 'Measure Description',
  },
  { label: 'View Details' },
  { label: 'Delete' }
]

type deleteType= 'group' | 'allMeasure'|  'measure' | ''

const AnalyticsGroupResults = () => {
  const groupId = useGetQueryString('groupId')
  const { dataPodId } = useParams<ViewResultsParams>()
  // const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const { analyticsGroupLoading, analyticsGroupList, getAnalyticsGroup} = useGetAnalyticGroups(dataPodId)
  const [selectedAnalyticGroup, setSelectAnalyticGroup] = useState<AnalyticsGroup>()
  const { getAnalyticsMeasure, analyticsMeasureList, analyticsMeasureLoading } = useGetAnalyticMeasure(dataPodId)
  const { generateMeasureKPI, addMeasureNotebookLoading, measureNotebookResponse, noteBookError } =
    useMeasureNotebook(dataPodId)

  const [selectedMeasureRows, setSelectMeasureRows] = useState<DataTableRow[]>([])
  const [errorState, setErrorState] = useState<boolean>(false)
  const [groupDeleteLoading, setGroupDeleteLoading] = useState<boolean>(false)
  const [allDeleteLoading, setAllDeleteLoading] = useState<boolean>(false)
  // const [measureDeleteLoading, setMeasureDeleteLoading] = useState<boolean>(false)
  const [showDeletePopUp,setShowDeletePopUp] = useState<boolean>(false)
  const {deleteAnalyticsGroup} = useDeleteAnalyticsGroup()
  const [itemToDeleteString, setItemToDeleteString] = useState<string | undefined>(undefined)
  const [deleteType, setDeleteType] = useState<deleteType>()
  const [analyticsMeasureId, setAnalyticsMeasureId] = useState<string | undefined>(undefined)
  const navigate = useNavigate()
  const {deleteMeasure} = useDeleteAnalyticsMeasure()
  const {deleteAllMeasure} = useDeleteAllAnalyticsMeasure()
  const [disableDelete, setDisableDelete] = useState<boolean>(false)

  const selectAnalyticGroup = (aGroup: AnalyticsGroup) => {
    if (aGroup?.id) {
      setSelectMeasureRows([])
      setSelectAnalyticGroup(aGroup)
      getAnalyticsMeasure(aGroup.id)
    }
  }
  useEffect(() => {
    //Once generate measure KP success, refreshing measures list table
    if (groupId && analyticsGroupList.length) {
      // const preSelectedGroup = analyticsGroupList.find((g) => g.id === parseInt(groupId);
      selectAnalyticGroup(analyticsGroupList.find((g) => g.id === parseInt(groupId)) as any)
    }
  }, [groupId, analyticsGroupList])

  const onAddMeasure = () => {
    let measureDetailPage = generatePath(ROUTES.AddDataAnalyticsMeasuresResults, {
      dataPodId: dataPodId,
    })
    if (selectedAnalyticGroup) {
      measureDetailPage = measureDetailPage + '?groupId=' + selectedAnalyticGroup.id
    }
    navigate(measureDetailPage)
  }

  useEffect(() => {
    if (noteBookError) {
      setErrorState(true)
    }
  }, [noteBookError])

  useEffect(() => {
    //Once generate measure KP success, refreshing measures list table
    if (measureNotebookResponse && selectedAnalyticGroup?.id) {
      getAnalyticsMeasure(selectedAnalyticGroup.id)
    }
  }, [measureNotebookResponse])

  useEffect(() => {
    if (analyticsMeasureList?.length) {
      const tMsrRows = analyticsMeasureList.map((msr: AnalyticsMeasure) => ({
        id: `${msr.analyticsMeasureId}`,
        values: [msr.analyticsMeasureId, msr.analyticsMeasureName, msr.analyticsMeasureDescription, 'VIEW','DELETE'],
      })) as DataTableRow[]
      setSelectMeasureRows(tMsrRows)
      setDisableDelete(false)
    }else{
      setSelectMeasureRows([])
      setDisableDelete(true)
    }
  }, [analyticsMeasureList, analyticsMeasureList.length])

  const onButtonClick = (measureId: string, actionType: ShadowTableAction) => {
    if (actionType === ShadowTableAction.Viewable) {
      let measureDetailPage = generatePath(ROUTES.DataAnalyticsMeasuresResults, {
        dataPodId: dataPodId,
      })
      if (selectedAnalyticGroup?.id && measureId) {
        measureDetailPage =
          measureDetailPage + '?groupId=' + selectedAnalyticGroup.id + '&measureId=' + Number(measureId)
      }
      navigate(measureDetailPage)
    }
    if(actionType === ShadowTableAction.Deletable){
      setAnalyticsMeasureId(measureId)
      setShowDeletePopUp(true)
      const row = selectedMeasureRows.find((smr)=> smr.id === measureId)
      setItemToDeleteString(row?.values[1])
      setDeleteType('measure')
    }
  }

  const generateGroupMeasure = () => {
    if (dataPodId && selectedAnalyticGroup?.id) {
      generateMeasureKPI(selectedAnalyticGroup?.id)
    }
  }

  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }

  const handleDeleteClick = (sag:AnalyticsGroup,type:deleteType) =>{
    if(type === 'allMeasure'){
      setItemToDeleteString('')
    }else{
      setItemToDeleteString(sag.analyticsGroupName)
    }
    setDeleteType(type)
    setShowDeletePopUp(true)
  }
  const handleDeletePopUp = async (groupId:string)=>{
    const gId:number = Number(groupId)
    if(deleteType == 'group'){
      handleAnalyticsGroup(groupId)
    }else if(deleteType == 'allMeasure'){
      handleAllMeasureDelete(gId)
    }else if (deleteType =='measure' && analyticsMeasureId){
      // console.log("Inside the measure type")
      handleMeasureDelete(analyticsMeasureId, gId)
    }
  }
  const handleAnalyticsGroup = async (groupId:string) =>{
    setGroupDeleteLoading(true)
    const resp = await deleteAnalyticsGroup(Number(groupId))
    if(resp){
      setGroupDeleteLoading(false)
      setShowDeletePopUp(false)
      setGroupDeleteLoading(false)
    }else{
      // setTimeout(() => setGroupDeleteLoading(false),10000)
    }
    getAnalyticsGroup()
    setDeleteType('')
  }
  const handleMeasureDelete = async (measureId:string, groupId:number) =>{
    // setMeasureDeleteLoading(true)
    let numArr: number[] = [Number(measureId)];
    const flag = await deleteMeasure(numArr)
    if(flag){
      // setMeasureDeleteLoading(false)
      setShowDeletePopUp(false)
      getAnalyticsMeasure(groupId)
    }else{
      // setTimeout(() => setGroupDeleteLoading(false),10000)
    }
    setDeleteType('')
  }
  const closeGroupDeletePopUp=()=>{
    setShowDeletePopUp(false)
  }
  const handleAllMeasureDelete = async(groupId:number) =>{
    setAllDeleteLoading(true)
    const uniqueMeasureIds = Array.from(new Set(selectedMeasureRows.map((item) => Number(item.id))))
    const allDeleteFlag = await deleteAllMeasure(uniqueMeasureIds)
    if(allDeleteFlag){
      setAllDeleteLoading(false)
      setShowDeletePopUp(false)
      getAnalyticsMeasure(groupId)
    }else{
      // setTimeout(() => setGroupDeleteLoading(false),3000)
    }
    setDeleteType('')
  }
  return (
    <>
    <ActionPage>
      {noteBookError?.message && (
        <ResultStatus
          showStatus={errorState}
          alertMessage={noteBookError?.message}
          severtiy="error"
          closeStatus={() => setErrorState(false)}
        />
      )}
      {/* <ProgressBar loading={addMeasureNotebookLoading} /> */}
      {/*<AnimatedLoader height='50%' width='40%' loading={addMeasureNotebookLoading}/>*/}
      <CardHeader
        disableTypography
        title={
          <Typography variant="h4" gutterBottom color="primary">
            {'Analytics Groups'}
          </Typography>
        }
      />
      {/* <Grid item xs={0.5}>
        <IconButton onClick={onClickHome}>
          <HomeOutlinedIcon />
        </IconButton>
      </Grid> */}

      {/* <CardContent>
        <DataPodDetailRibbon
          dataPodId={dataPodId}
          dataPodName={dataPodInfo?.name}
          dataPodIndustryName={dataPodInfo?.industryName}
        />
      </CardContent> */}

      <Grid item container justifyContent={'center'} alignItems={'stretch'} xs={14} spacing={2}>
        <Grid item xs={3}>
          <Card sx={{ backgroundColor: 'white' }}>
            <CardHeader
              disableTypography
              sx={{ backgroundColor: 'grey' }}
              title={<Typography sx={{ fontSize: 15, align: 'right' }}>{'List Of Analytics Groups'}</Typography>}
            />

            {analyticsGroupLoading ? (
              <>
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
                <Skeleton variant="text" height={30} />
              </>
            ) : (
              <List dense sx={{ p: 0 }}>
                {analyticsGroupList.length > 0 ? (
                  analyticsGroupList?.map((p: AnalyticsGroup, index: number) => (
                    <ListItem key={p.id + index} sx={{ p: 0 }}>
                      <ListItemButton
                        onClick={() => selectAnalyticGroup(p)}
                        selected={selectedAnalyticGroup?.id === p.id}
                      >
                        <ListItemText primary={p.analyticsGroupName} />
                      </ListItemButton>
                    </ListItem>
                  ))
                ) : (
                  <Typography sx={{ p: 1, textAlign: 'center' }}>No Data</Typography>
                )}
              </List>
            )}
          </Card>
        </Grid>

        <Grid item xs={9} sx={{ p: 2 }}>
          {selectedAnalyticGroup && (
            <Card sx={{ p: 1, mb: 1 }}>
              <Grid item xs={12} container sx={{ marginBottom: 2 }}>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                    Analytics Group ID
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} textAlign={'left'}>
                    {selectedAnalyticGroup?.id}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                    Group Name
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} textAlign={'left'}>
                    {selectedAnalyticGroup?.analyticsGroupName}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                    Group Description
                  </Typography>
                  <Typography sx={{ fontSize: 14 }} textAlign={'left'}>
                    {selectedAnalyticGroup?.analyticsGroupDescription}
                  </Typography>
                </Grid>
                <Grid item xs={3}>
                  <Typography sx={{ fontSize: 14 }} variant="h3" textAlign={'left'}>
                    Delete Analytics Group
                  </Typography>
                  <LoadingButton
                      loading={groupDeleteLoading}
                      onClick={()=> handleDeleteClick(selectedAnalyticGroup,'group')}
                      color='primary'
                      variant='contained'
                      startIcon={<Delete fontSize="small" />}                  
                  >
                    Delete Analytics Group
                  </LoadingButton>
                </Grid>
              </Grid>
            </Card>
          )}

          <Grid item xs={12} sx={{ mb: 2 }}>
            <GroupEntities group_Id={selectedAnalyticGroup?.id} />
          </Grid>

          {selectedMeasureRows && (
            <>
              <Grid item xs={12} 
                justifyContent={'space-between'} 
                display={'flex'} 
                sx={{ mb: 2 }} 
                alignItems={'center'}
              >
                <Grid item xs={6}>
                  <Typography sx={{ fontSize: 16 }} color="primary">
                    List of Analytics Measures
                  </Typography>
                </Grid>
                <Grid item xs={6} justifyContent={'end'} display={'flex'}>
                  {!analyticsMeasureLoading && selectedAnalyticGroup?.id && !analyticsMeasureList.length && (
                    <LoadingButton
                      variant="outlined"
                      color="primary"
                      startIcon={<SendOutlined />}
                      onClick={generateGroupMeasure}
                      loading={addMeasureNotebookLoading}
                      sx={{ mr: 2 }}
                    >
                      Generate Measures (KPI)
                    </LoadingButton>
                  )}
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onAddMeasure}
                    startIcon={<AddOutlined />}
                    disabled={!selectedAnalyticGroup?.id}
                  >
                    Add New Measure
                  </Button>
                  { selectedAnalyticGroup && (
                    <LoadingButton
                      loading={allDeleteLoading}
                      onClick={()=> handleDeleteClick(selectedAnalyticGroup,'allMeasure')}
                      color='primary'
                      variant='contained'
                      startIcon={<Delete fontSize="small" />} 
                      style={{marginLeft:'2px'}}
                      disabled={disableDelete}                 
                  >
                    Delete All Analytics Measures
                  </LoadingButton>
                  )}
                </Grid>
              </Grid>
              {analyticsMeasureLoading ? (
                <>
                  <Skeleton variant="rectangular" height={40} />
                  <Skeleton variant="rectangular" height={40} />
                </>
              ) : (
                <ShadowTable
                  rows={selectedMeasureRows}
                  columns={analyticsMeasureColumns}
                  tableActionParams={{
                    onButtonClick: onButtonClick,
                    actions: [
                      ShadowTableAction.Viewable,
                      ShadowTableAction.Deletable
                    ],
                  }}
                />
              )}
            </>
          )}
        </Grid>
      </Grid>
    </ActionPage>
    <DeletePopUp
        showDeleteWarning={showDeletePopUp}
        confirmDeleteMap={()=> handleDeletePopUp(String(selectedAnalyticGroup?.id))}
        closeDeleteWarning={closeGroupDeletePopUp}
        itemToDelete={itemToDeleteString}
      />
    </>
  )
}

export default AnalyticsGroupResults
