import { useEffect, useState } from 'react'
import {
  Button,
  Card,
  CardContent,
  Grid,
  List,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'

import saveAs from 'file-saver'

import { GetSubjectAreaTableDataBody, SubjectArea, SubjectAreaTable } from '../../businessObjects'

import LucidWidePopUp from '../../ui-components/LucidWidePopUp'
import AnimatedLoader from '../../ui-components/AnimatedLoader'
import ShadowTable from '../../ui-components/ShadowTable/ShadowTable'
import { DataTableColumn, DataTableRow } from '../../ui-components/ExtendedShadowTable'

import { useGetSubjectAreaTableData, useGetSubjectAreaTables } from '../../hooks/generateSampleDataHooks'
import ResultStatus from '../../ui-components/ResultStatus'
import { LucidTextLabel } from 'ui-components/LucidTextLabel'
import TextFormInput from 'ui-components/TextForm/TextFormInput'

type Props = {
  dataPodId: string
  subjectAreaList: SubjectArea[]
  subjectAreaId: number | undefined
  showDetailsPopup: boolean
  setShowDetailsPopup: React.Dispatch<React.SetStateAction<boolean>>
}

export const DataContextGenerateSampleData = ({
  dataPodId,
  subjectAreaList,
  subjectAreaId,
  showDetailsPopup,
  setShowDetailsPopup,
}: Props) => {
  const { fetchTables } = useGetSubjectAreaTables()
  const { fetchTableData } = useGetSubjectAreaTableData()

  const [subjectAreaTables, setSubjectAreaTables] = useState<SubjectAreaTable[]>()
  const [selectedTable, setSelectedTable] = useState<SubjectAreaTable>()
  const [selectedTableColumns, setSelectedTableColumns] = useState<DataTableColumn[]>([])
  const [isLoadingTables, setIsLoadingTables] = useState<boolean>(false)
  const [isLoadingTableData, setIsLoadingTableData] = useState<boolean>(false)
  const [isErrorLoadingTables, setIsErrorLoadingTables] = useState<boolean>(false)
  const [isErrorLoadingTableData, setIsErrorLoadingTableData] = useState<boolean>(false)
  const [tableDataPromptText, setTableDataPromptText] = useState<string>('')
  const [inputTableName, setInputTableName] = useState<string>('')
  const [inputNumOfRecords, setInputNumOfRecords] = useState<number>(20)

  useEffect(() => {
    //If pop up closed handling state variables, else loading tables for respective Subject Area
    if (!showDetailsPopup) {
      setSubjectAreaTables(undefined)
      setSelectedTable(undefined)
    } else {
      getTables(Number(subjectAreaId))
    }
  }, [showDetailsPopup])

  useEffect(() => {
    setSubjectAreaTables(undefined)
    setSelectedTable(undefined)
  }, [subjectAreaId])

  useEffect(() => {
    const tableColumns: DataTableColumn[] = []

    //For a selected table, mapping like DataTableColumns to pass into ShadowTable
    if (selectedTable) {
      selectedTable.Columns.forEach((colName) => {
        tableColumns.push({ label: colName })
      })
    }

    setSelectedTableColumns(tableColumns)
  }, [selectedTable])

  //Fetch tables for respective Subject Area
  const getTables = async (id: number) => {
    setIsLoadingTables(true)

    const result = await fetchTables(dataPodId, id, '')

    if (result) {
      setSubjectAreaTables(result.data)
    } else {
      setIsErrorLoadingTables(true)
      setShowDetailsPopup(false)
    }
    setIsLoadingTables(false)
  }

  const addSubjectAreaTable = async (tableName: string) => {
    const table = subjectAreaTables?.find((table) => (table.table_name === tableName))

    if(table) {
      setInputTableName('')
      return
    }

    const newTable: SubjectAreaTable = {
      table_name: tableName,
      Columns: [],
      values: [],
    }

    setSubjectAreaTables(subjectAreaTables ? [...subjectAreaTables, newTable] : [newTable])
    setInputTableName('')
  }

  const generateSampleData = async () => {
    const payload = getPayloadForSampleData()

    setIsLoadingTableData(true)
    const result = await fetchTableData(payload)

    if (result) {
      const [columns, values] = changeStructureOfData(result)

      const tRows = values.map((row: any) => ({
        id: 0,
        values: Object.values(row),
      })) as DataTableRow[]

      const tableWithValues = { ...selectedTable, Columns: columns, values: tRows } as SubjectAreaTable
      console.log(subjectAreaTables?.map((table) => (table.table_name === selectedTable?.table_name ? tableWithValues : table)))
      console.log(tableWithValues)

      setSelectedTable(tableWithValues)
      setSubjectAreaTables((tables) => {
        return tables?.map((table) => (table.table_name === selectedTable?.table_name ? tableWithValues : table))
      })
    } else {
      setIsErrorLoadingTableData(true)
    }

    setIsLoadingTableData(false)
  }

  // Input: {id: [1,2], name: [John, Jane]}
  // Output: [{id: 1, name: John}, {id: 2, name: Jane}]
  const changeStructureOfData = (input: any) => {
    const columns: DataTableColumn[] = []

    const values: any = input.data.reduce((acc: any, column: any) => {
      columns.push(column.columnName)

      column.values.forEach((value: any, index: string | number) => {
        if (!acc[index]) {
          acc[index] = {}
        }
        acc[index][column.columnName] = value
      })
      return acc
    }, [])

    return [columns, values]
  }

  const getPayloadForSampleData = () => {
    const subjectArea = subjectAreaList.find((sa) => sa.id === subjectAreaId)
    const payload: GetSubjectAreaTableDataBody = {
      dataPodId,
      GenTableAPIoutput: {
        context: {
          industry: subjectArea?.industryName || '',
          subject_area_name: subjectArea?.subjectAreaName || '',
          subject_area_description: subjectArea?.subjectAreaDesc || '',
        },
        data: {
          table_name: selectedTable?.table_name || '',
          Columns: selectedTable?.Columns || [],
        },
      },
      promptInput: tableDataPromptText,
      numberOfRecords: inputNumOfRecords,
    }

    return payload
  }

  const convertToCSV = () => {
    const header = selectedTable?.Columns.join(',')
    const csv = [header]

    selectedTable?.values?.forEach((row: DataTableRow) => {
      let csv_row = ''

      row.values.forEach((value) => {
        csv_row += `"${value}",`
      })
      csv.push(csv_row)
    })

    return csv.join('\n')
  }

  const saveAsCSV = () => {
    if (!selectedTable?.values) return

    const csvData = convertToCSV()

    const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8' })
    saveAs(blob, `${selectedTable?.table_name}.csv`)
  }

  return (
    <>
      <ResultStatus
        severtiy="error"
        showStatus={isErrorLoadingTables}
        closeStatus={setIsErrorLoadingTables}
        alertMessage={`Failed to load tables.`}
      />

      <LucidWidePopUp
        showCloseIcon
        closePopup={setShowDetailsPopup}
        openPopUp={showDetailsPopup}
        headingText={'Generate Sample Data Details'}
        confirmText=""
      >
        <ResultStatus
          severtiy="error"
          showStatus={isErrorLoadingTableData}
          closeStatus={setIsErrorLoadingTableData}
          alertMessage={`Failed to load "${selectedTable?.table_name}" table data.`}
        />

        <AnimatedLoader height="50%" width="50%" loading={isLoadingTables} />
        {!isLoadingTables && (
          <>
            <Grid container direction="row" justifyContent="flex-start" spacing={1}>
              <Grid container item direction="column" justifyContent="space-around" xs={3} md={3} spacing={1}>
                <Grid item container sx={{ display: 'flex', flexDirection: 'column'}}>
                  <Grid item>
                    <TextFormInput
                      fullWidth
                      labelText="Table Name"
                      value={inputTableName}
                      changeHandle={(value) => setInputTableName(value)}
                    />
                  </Grid>
                  <Grid item sx={{ mt: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <Button
                      variant="contained"
                      disabled={!inputTableName}
                      onClick={() => addSubjectAreaTable(inputTableName)}
                    >
                      Add
                    </Button>
                  </Grid>
                </Grid>
                <Grid item>
                  <Typography variant="h5" gutterBottom align="left">
                    Table Names:
                  </Typography>
                  <List
                    component="nav"
                    aria-label="secondary mailbox folder"
                    sx={{ maxHeight: '33vh', overflow: 'auto' }}
                  >
                    {subjectAreaTables &&
                      subjectAreaTables.map((table, index) => (
                        <Card
                          key={index}
                          sx={{
                            marginBottom: 1,
                            boxShadow: 2,
                            borderRadius: 1,
                          }}
                        >
                          <ListItemButton
                            key={index}
                            selected={selectedTable?.table_name === table.table_name}
                            onClick={() => {
                              setSelectedTable(table)
                              setTableDataPromptText('')
                            }}
                          >
                            <CardContent sx={{ padding: '3px' }}>
                              <ListItemText primary={table.table_name} />
                            </CardContent>
                          </ListItemButton>
                        </Card>
                      ))}
                  </List>
                </Grid>
                <Grid item mt={2} justifyContent={'center'}>
                  {/* <Typography color={'text.primary'}>Sample Data Size *</Typography> */}
                  <TextField
                    // style={{ width: '50%' }}
                    fullWidth
                    id="outlined-basic"
                    label="Enter data size (20-100)"
                    variant="outlined"
                    color="secondary"
                    size="small"
                    type="number"
                    value={inputNumOfRecords}
                    onChange={({ target }) => {
                      const value = parseInt(target.value)
                      if (value >= 20 && value <= 100) {
                        setInputNumOfRecords(value)
                      }
                    }}
                    // required
                    inputProps={{ step: 10, min: 20, max: 100 }}
                  />
                </Grid>
                <Grid item>
                  <TextFormInput
                    fullWidth
                    labelText="Prompt text"
                    multiline={3}
                    value={tableDataPromptText}
                    changeHandle={(value) => setTableDataPromptText(value)}
                  />
                </Grid>
                {/* <Grid container item mb={2} direction="row" justifyContent="flex-end" spacing={2}>
                  <Grid item>
                    <Button disabled={selectedTable ? false : true} onClick={generateSampleData} variant="contained">
                      Generate sample data
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button disabled={selectedTable?.values ? false : true} onClick={saveAsCSV} variant="contained">
                      Save as CSV
                    </Button>
                  </Grid>
                </Grid> */}
              </Grid>
              <Grid item container xs={9} md={9} sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>

                <Grid container item sx={{ maxHeight: '65vh', overflow: 'auto' }}>
                  <ShadowTable
                    rows={selectedTable?.values || []}
                    columns={selectedTableColumns}
                    isDataLoading={isLoadingTableData}
                    />
                </Grid>
                <Grid container item sx={{ mb: 2, justifyContent: "flex-end"}} spacing={2}>
                  <Grid item>
                    <Button disabled={selectedTable ? false : true} onClick={generateSampleData} variant="contained">
                      Generate sample data
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button disabled={selectedTable?.values ? false : true} onClick={saveAsCSV} variant="contained">
                      Save as CSV
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </LucidWidePopUp>
    </>
  )
}
