import { sendRequest } from '../requestManager/sendRequest'

const serviceConnectionPath = {
  getServiceConnection: 'DataEngineering/GetServiceConnection',
  getServiceConnectionTypes: 'DataEngineering/GetServiceConnectionType',
  AddServiceConnection: 'DataEngineering/AddServiceConnection',
  UpdateServiceConnection: 'DataEngineering/UpdateServiceConnection',
  deleteServiceConnection: 'DataEngineering/deleteServiceConnection',
  getDataLake: 'DataEngineering/GetDataLake',
  getDataLakeZone: 'Entities/getDatalakeZone',
  getServiceType: 'DataEngineering/GetServiceType',
}

const getServiceConnection = async (token: string, dataPodId: string, serviceConnectionTypeID?: string) => {
  let endPoint = serviceConnectionPath.getServiceConnection + `?dataPodId= ${dataPodId}`
  if (serviceConnectionTypeID) {
    endPoint += '&serviceConnectionTypeID=' + serviceConnectionTypeID
  }
  return sendRequest(endPoint, 'GET', token)
}

const getServiceConnectionTypes = async (token: string) => {
  return sendRequest(serviceConnectionPath.getServiceConnectionTypes, 'GET', token)
}

const getServiceType = async (token: string, typeID: string) => {
  return sendRequest(serviceConnectionPath.getServiceType + '?serviceConnectionTypeID=' + typeID, 'GET', token)
}

const getDataLakeResults = async (token: string, dataPodId: string) => {
  return sendRequest(serviceConnectionPath.getDataLake + '?dataPodId=' + dataPodId, 'GET', token)
}

const getDataLakeZoneResults = async (token: string) => {
  return sendRequest(serviceConnectionPath.getDataLakeZone, 'GET', token)
}

const addServiceConnection = async (token: string, dataPodId: string, body: any) => {
  return sendRequest(serviceConnectionPath.AddServiceConnection + '?dataPodId=' + dataPodId, 'POST', token, body)
}

const updateServiceConnection = async <T>(token: string, dataPodId: string, body: T) => {
  return sendRequest(serviceConnectionPath.UpdateServiceConnection + '?dataPodId=' + dataPodId, 'PUT', token, body)
}

const deleteServiceConnection = async (token: string, connectionId: number) => {
  return sendRequest(serviceConnectionPath.deleteServiceConnection + '?id=' + connectionId, 'DELETE', token)
}

export const serviceConnections = {
  getServiceConnection,
  getServiceConnectionTypes,
  getServiceType,
  addServiceConnection,
  updateServiceConnection,
  deleteServiceConnection,
  getDataLakeResults,
  getDataLakeZoneResults,
}
