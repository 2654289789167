import { useEffect, useState } from 'react'
import {
  AttributeProfile,
  DataAttribute,
  DataAttributeBody,
  EntityReconData,
  NewAttribute,
  NewAttributeMap,
  UpdateAttributeData,
  UpdateEntityReconBody,
  attributeMappingDataBody,
} from '../businessObjects'
import {
  createDataAttribute,
  dataAttributesServices,
  getDataAttributesByEntityId,
  getDataAttributesByMultipleEntityId,
  getDataAttributesMapping,
  updateDataAttribute,
  getDataAttributeName
} from '../apiServices/dataAttributes'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'
import { applicationServices } from '../apiServices/application'
import { AxiosError } from 'axios'

type dataPodId = string

type UpdateAttribFunc = (params: UpdateAttributeData, datapodId: dataPodId) => Promise<AttributeProfile | undefined>

type Id = number
type EntityId = number
type AttributeParams = Id | EntityId
type GetDataAttributes = (params: AttributeParams) => Promise<void>
type UpdateEntityRecon = (params: UpdateEntityReconBody) => Promise<void>

type UpdateAttributeResult = {
  exist: boolean;
  dataAttribute?: DataAttribute;
};

export const useAddNewDataAttribute = () => {
  //const [newDataAttribute, setNewDataAttribute] = useState<AttributeProfile>()
  //const [loading, setLoading] = useState<boolean>(false)
  //const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const submitNewAttribute = async (params: NewAttribute, datapodId: dataPodId): Promise<DataAttribute | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId) {
        const { data, status } = await createDataAttribute(bearerToken, params, datapodId)
        // console.log(data)
        if (status == 200) {
          //console.log(status)
          return data
          //setLoading(false)
        }
      }
    }catch (error) {
      // setCreateError(true)
      // setLoading(false)
      console.log(error)
    }
  }
  return [submitNewAttribute]
}

export const useUpdateDataAttribute = (): [UpdateAttribFunc] => {
  //const [newDataAttribute, setNewDataAttribute] = useState<AttributeProfile>()
  //const [loading, setLoading] = useState<boolean>(false)
  //const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const submitAtrribute = async (
    params: UpdateAttributeData,
    datapodId: dataPodId,
  ): Promise<AttributeProfile | undefined> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && datapodId) {
      const { data, status } = await updateDataAttribute(bearerToken, params, datapodId)
      // console.log(data)
      if (status == 200) {
        //console.log(status)
        return data as AttributeProfile
        //setLoading(false)
      }
    }
  }
  return [submitAtrribute]
}

export const useGetDataAttributes = (
  byId: boolean,
): [GetDataAttributes, AttributeProfile[] | undefined, boolean | undefined, boolean | undefined] => {
  const [dataAttributes, setDataAttributes] = useState<AttributeProfile[]>([])
  const [loading, setLoading] = useState<boolean>(false)
  const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const fetchDataAttributes = async (params: AttributeParams): Promise<void> => {
    setLoading(true)
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken) {
        if (byId) {
          const { data, status } = await dataAttributesServices.getDataAttributesById(bearerToken, params)
          if (status == 200) {
            setDataAttributes(data)
            setLoading(false)
          }
        } else {
          const { data, status } = await getDataAttributesByEntityId(bearerToken, params)
          if (status == 200) {
            setDataAttributes(data)
            setLoading(false)
          }
        }
      }
    } catch (error) {
      setCreateError(true)
      setLoading(false)
    }
  }
  return [fetchDataAttributes, dataAttributes, loading, createError]
}
export const useGetDataAttributesByEntityId = (
  entityId?: number,
): { attributeProfiles: DataAttribute[]; refetchAttributes: () => void } => {
  const [dataAttributes, setDataAttributes] = useState<DataAttribute[]>([])
  const { getBearerToken } = useLucidAuthContext()
  const fetchDataAttributes = async (): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && entityId) {
        const { data, status } = await getDataAttributesByEntityId(bearerToken, entityId)
        if (status == 200) {
          //console.log(status)
          setDataAttributes(data)
        }
      }
    } catch (err) {
      console.log({ err })
    }
  }
  useEffect(() => {
    fetchDataAttributes()
  }, [entityId])
  return { attributeProfiles: dataAttributes, refetchAttributes: fetchDataAttributes }
}

export const useGetDataAttributesByEntity = () => {
  const { getBearerToken } = useLucidAuthContext()
  const fetchDataAttributes = async (params: AttributeParams): Promise<AttributeProfile[] | undefined> => {
    const bearerToken = await getBearerToken()
    if (bearerToken) {
      const { data, status } = await getDataAttributesByEntityId(bearerToken, params)
      if (status == 200) {
        return data as AttributeProfile[]
      }
    }
  }
  return fetchDataAttributes
}

export const useDeleteAttributeMap = (): { deleteAttributeMapId: (mapId: number) => Promise<Boolean | undefined> } => {
  // deleteAttributeId: (mapId: number) => Promise<Boolean | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  const deleteAttributeMapId = async (mapId: number): Promise<Boolean | undefined> => {
    const token = await getBearerToken()
    if (token) {
      try {
        const { status } = await dataAttributesServices.deleteAttributeMapId(mapId, token)
        console.log({ status })
        return status == 204
      } catch (error) {
        const { message } = error as AxiosError
        console.log({ message })
      }
    }
  }
  return { deleteAttributeMapId }
}

export const useDeleteAttributeId = (): { deleteAttributeId: (mapId: number) => Promise<Boolean | undefined> } => {
  // deleteAttributeId: (mapId: number) => Promise<Boolean | undefined> } => {
  const { getBearerToken } = useLucidAuthContext()
  // const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
  const deleteAttributeId = async (mapId: number): Promise<Boolean | undefined> => {
    const token = await getBearerToken()
    if (token && mapId) {
      console.log('mapId is ', mapId)
      try {
        const { status } = await dataAttributesServices.deleteAttributeId(mapId, token)
        console.log({ status })
        return status == 204
      } catch (error) {
        const { message } = error as AxiosError
        console.log({ message })
      }
    }
  }
  return { deleteAttributeId }
}

// export const useDeleteAttributeId = (): { deleteAttributeId: (mapId: number) => Promise<Boolean | undefined> } => {
//   const { getBearerToken } = useLucidAuthContext()
//   const deleteAttributeId = async (mapId: number): Promise<Boolean | undefined> => {
//     console.log('mapId is ',mapId)
//     try {
//       const token = await getBearerToken()
//       if (token && mapId) {
//         const { status } = await dataAttributesServices.deleteAttributeId(mapId, token)
//         console.log({ status })
//         return status == 204
//       }
//     } catch (error) {
//       const { message } = error as AxiosError
//       console.log({ message })
//     }
//     //}
//     //return true;
//   }
//   return { deleteAttributeId }
// }

export const useGetDataReconcilationByEntityId = (
  dataPodId?: string,
  entityId?: number,
  dataSystemEntityId?: number,
): { reconData: EntityReconData[] ,reconDataLoaded: EntityReconData[]; refetchAttributes: () => void } => {
  const [data, setData] = useState<EntityReconData[]>([])
  const { getBearerToken } = useLucidAuthContext()
  const fetchDataAttributes = async (): Promise<void> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && dataPodId) {
      const { data, status } = await applicationServices.getEntityReconciliation(
        bearerToken,
        dataPodId,
        entityId,
        dataSystemEntityId,
      )
      if (status == 200) {
        //console.log(status)
        setData(data)
      }
    }
  }
  useEffect(() => {
    fetchDataAttributes()
  }, [entityId, dataPodId])
  return { reconData:data, reconDataLoaded: data, refetchAttributes: fetchDataAttributes }
}

export const useGetDataAttributesMapping = (): [
  fetchAttributesMappingData: (dataAttributeID?: number | undefined) => Promise<void>,
  attributesMappingData?: attributeMappingDataBody[],
] => {
  const [attributesMappingData, setAttributesMappingData] = useState<attributeMappingDataBody[]>([])
  const { getBearerToken } = useLucidAuthContext()
  const fetchAttributesMappingData = async (dataAttributeID?: number): Promise<void> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && dataAttributeID) {
      const { data, status } = await getDataAttributesMapping(bearerToken, dataAttributeID)
      if (status == 200) {
        //console.log(status)
        setAttributesMappingData(data)
      }
    }
  }
  return [fetchAttributesMappingData, attributesMappingData]
}

export const useGetDataAttributesMappingByTableId = (): [
  fetchAttributesMappingData: (
    datapodId: string | undefined,
    dataTableId: number | undefined,
    dataAttributeID?: number | undefined,
  ) => Promise<void>,
  attributesMappingData?: attributeMappingDataBody[],
] => {
  const [attributesMappingData, setAttributesMappingData] = useState<attributeMappingDataBody[]>([])
  const { getBearerToken } = useLucidAuthContext()
  const fetchAttributesMappingData = async (
    datapodId: string | undefined,
    dataTableId: number | undefined,
    dataAttributeID?: number,
  ): Promise<void> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && datapodId && dataTableId) {
      const { data, status } = await getDataAttributesMapping(bearerToken, undefined, datapodId, dataTableId)
      if (status == 200) {
        //console.log(status)
        setAttributesMappingData(data)
      }
    }
  }
  return [fetchAttributesMappingData, attributesMappingData]
}

export const useUpdateEntityRecon = (datapodId?: string): [UpdateEntityRecon, EntityReconData | undefined] => {
  const [updatedEntityRecon, setUpdatedEntityRecon] = useState<EntityReconData>()
  const { getBearerToken } = useLucidAuthContext()
  const updateEntityRecon = async (params: UpdateEntityReconBody): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && datapodId && params.id && params.dataCoreEntityId && params.dataSystemEntityId) {
        const { data, status } = await applicationServices.updateEntityReconciliation(bearerToken, datapodId, params)
        if (status == 200) {
          setUpdatedEntityRecon(data)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }
  return [updateEntityRecon, updatedEntityRecon]
}

export const useSaveCustomAttribeNotebook = (datapodID?: string) => {
  const { getBearerToken } = useLucidAuthContext()
  const saveNoteBook = async (notebookString: string, customAttribId: number, commitMessage: string) => {
    try {
      const token = await getBearerToken()
      if (token && datapodID) {
        const { status } = await dataAttributesServices.saveCustomAttributeNB(
          token,
          datapodID,
          customAttribId,
          notebookString,
          commitMessage,
        )
        return status === 200
      }
    } catch (error) {
      console.log({ error })
    }
  }
  return { saveNoteBook }
}

export const useUpdateCustomAttribeNotebook = (datapodID?: string) => {
  const { getBearerToken } = useLucidAuthContext()

  const updateAttributeNoteBook = async (notebookString: string, customAttribId: number, commitMessage: string) => {
    try {
      const token = await getBearerToken()
      if (token && datapodID && customAttribId) {
        console.log({ notebookString })
        const { status } = await dataAttributesServices.updateCustributeNB(
          token,
          datapodID,
          customAttribId,
          notebookString,
          commitMessage,
        )
        return status === 200
      }
    } catch (error) {
      console.log({ error })
    }
  }
  return { updateAttributeNoteBook }
}

export const useGetCustomAttribeNotebook = (
  datapodID?: string,
  customAttributeId?: number,
): {
  noteBookString?: string
} => {
  const [noteBookString, setNoteBookString] = useState<string>()
  const { getBearerToken } = useLucidAuthContext()

  const getNoteBook = async () => {
    try {
      const token = await getBearerToken()
      if (token && datapodID && customAttributeId) {
        const { status, data } = await dataAttributesServices.getDataAttributeNotebook(
          token,
          datapodID,
          customAttributeId,
        )
        if (status === 200) {
          setNoteBookString(data)
        }
      }
    } catch (error) {
      console.log({ error })
    }
  }
  useEffect(() => {
    getNoteBook()
  }, [customAttributeId])

  return { noteBookString }
}

export const useGetDataAttributeByID = (dataAttributeId?: number) => {
  const [dataAttributes, setDataAttributes] = useState<AttributeProfile[]>()

  const { getBearerToken } = useLucidAuthContext()
  const fetchDataAttributes = async (): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataAttributeId) {
        const { data, status } = await dataAttributesServices.getDataAttributesById(bearerToken, dataAttributeId)
        if (status == 200) {
          setDataAttributes(data)
        }
      }
    } catch (err) {
      console.log({ err })
    }
  }
  useEffect(() => {
    fetchDataAttributes()
  }, [dataAttributeId])
  return { dataAttributes }
}

export const useGetCustomDataAttributeByID = (dataPodId?: string, dataAttributeId?: number) => {
  const [dataAttributes, setDataAttributes] = useState<AttributeProfile[]>()
  const [childAttributes, setChildAttributes] = useState<AttributeProfile[]>([])
  const [childAttributesMaps, setChildAttributesMaps] = useState<NewAttributeMap[]>([])

  const { getBearerToken } = useLucidAuthContext()
  const fetchDataAttributes = async (): Promise<void> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataAttributeId) {
        let customAtr: AttributeProfile[] = []
        const { data: foundCustom, status: foundAttrib } = await dataAttributesServices.getDataAttributesById(
          bearerToken,
          dataAttributeId,
        )
        if (foundAttrib == 200) {
          customAtr = foundCustom
        }
        if (dataPodId) {
          const { data: customAttribMap, status: foundMaps } = await dataAttributesServices.getCustomAttributeMap(
            bearerToken,
            dataPodId,
            dataAttributeId,
          )
          let totalAttribs = 0
          if (foundMaps == 200) {
            const cusAttMaps: NewAttributeMap[] = customAttribMap
            totalAttribs = cusAttMaps.length
            let allMaps: AttributeProfile[] = []
            for (let i = 0; i < cusAttMaps.length; i++) {
              const sourceAttrId = cusAttMaps[i].dataSourceAttributeId
              if (sourceAttrId) {
                const { data: customAttrbChild, status: foundAttribchild } =
                  await dataAttributesServices.getDataAttributesById(bearerToken, sourceAttrId)

                if (foundAttribchild == 200) {
                  allMaps = [...allMaps, ...customAttrbChild]
                }
              }
            }
            if (allMaps.length === totalAttribs && customAtr.length) {
              setDataAttributes(customAtr)
              setChildAttributes(allMaps)
              setChildAttributesMaps(cusAttMaps)
            }
          }
        }
      }
    } catch (err) {
      console.log({ err })
    }
  }
  useEffect(() => {
    if (dataPodId && dataAttributeId) {
      fetchDataAttributes()
    }
  }, [dataAttributeId])
  return { dataAttributes, childAttributes, childAttributesMaps }
}

export const useUpdateAttributeDetails = (dataPodId?: string) => {
  //const [newDataAttribute, setNewDataAttribute] = useState<AttributeProfile>()
  //const [loading, setLoading] = useState<boolean>(false)
  //const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const updateAttibuteFunc = async (params: UpdateAttributeData): Promise<AttributeProfile | undefined> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && dataPodId) {
      const { data, status } = await updateDataAttribute(bearerToken, params, dataPodId)
      // console.log(data)
      if (status == 200) {
        //console.log(status)
        return data as AttributeProfile
        //setLoading(false)
      }
    }
  }
  return { updateAttibuteFunc }
}

export const useGetDataAttributesByMultipleEntityId = (): {
  attributeProfiles: DataAttribute[]
  refetchAttributes: (entityId: number[]) => Promise<DataAttribute[] | undefined>
} => {
  const [dataAttributes, setDataAttributes] = useState<DataAttribute[]>([])
  const { getBearerToken } = useLucidAuthContext()

  const fetchDataAttributes = async (entityId?: number[]): Promise<DataAttribute[] | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && entityId) {
        const { data, status } = await getDataAttributesByMultipleEntityId(bearerToken, entityId)
        if (status === 200) {
          return data
        }
      }
    } catch (err) {
      console.log({ err })
    }
  }
  return { attributeProfiles: dataAttributes, refetchAttributes: fetchDataAttributes }
}

export const useGetDataAttributesBySelectedEntityId = (entityIds: number[]) => {
  const [dataAttributes, setDataAttributes] = useState<DataAttribute[]>([])
  const [loadingAttributes, setLoadingAttributes] = useState<boolean>(false)
  const { getBearerToken } = useLucidAuthContext()

  const getDataAttributes = async () => {
    try {
      setLoadingAttributes(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && entityIds) {
        const { data, status } = await getDataAttributesByMultipleEntityId(bearerToken, entityIds)
        if (status === 200) {
          setDataAttributes(data)
          // return data
        }
        setLoadingAttributes(false)
      }
    } catch (err) {
      setLoadingAttributes(false)
      console.log({ err })
    }
  }

  return { dataAttributes, loadingAttributes, getDataAttributes }
}

export const useGetAttributeByName = () => {
  //const [newDataAttribute, setNewDataAttribute] = useState<AttributeProfile>()
  //const [loading, setLoading] = useState<boolean>(false)
  //const [createError, setCreateError] = useState<boolean>()
  const { getBearerToken } = useLucidAuthContext()
  const dataAttributeNameValidation = async (dataPodId?: string , attributeName?:string, entityId?:number): Promise<UpdateAttributeResult | undefined> => {
    const bearerToken = await getBearerToken()
    if (bearerToken && dataPodId && entityId && attributeName) {
      const { data, status } = await getDataAttributeName(bearerToken,dataPodId,attributeName,entityId)
      if (status == 200 && data) {
        if(data.message=="No Records found"){
          return {exist:false, dataAttribute:data.dataAttributes}
        }
        else{
          return {exist:true,dataAttribute:data.dataAttributes[0] as DataAttribute}
        }
      }
    }
  }
  return { dataAttributeNameValidation }
}