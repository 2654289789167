import { Grid, Typography, Box } from '@mui/material'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'
import DataProcessCard from '../DataProcessCard'
import { useGetSessionPodInfo } from '../../../hooks/getSessionPodInfo'

type ResultColor = 'success' | 'error' | 'primary' | 'secondary' | 'info' | 'warning'
export const DataModeling = () => {
  const dataPod = useGetSessionPodInfo()
  return (
    <ActionPage>
      {/* <Box>
        <Grid item xs={12}>
          <Typography gutterBottom>Data Mapping</Typography>
        </Grid>
      </Box> */}
      <Box style={cardContainer}>
        <Grid container spacing={2}>
          <Grid container spacing={1}>
            <Grid item xs={12} />
            <Grid item xs={15} container>
              <Grid item xs={2}>
                <Typography variant="h4" gutterBottom color="primary">
                  {dataPod?.datapodType == 2 ? 'Generative Integrations' : 'Data Modeling'}
                </Typography>
              </Grid>
              <Grid item sx={{ paddingLeft: 2 }} xs={10} container spacing={1}>
                <Grid item xs={3} md={5.7}>
                  <DataProcessCard
                    headingText={dataPod?.datapodType == 2 ? 'Source Canonical Model' : 'Canonical Model'}
                    type={dataPod?.datapodType == 2 ? 'Source Canonical Model' : 'Canonical Model'}
                    labelType={'DataPod'}
                    dataPodId={dataPod?.id}
                    subLabel={dataPod?.name}
                  />
                </Grid>
                <Grid item xs={3} md={5.7}>
                  <DataProcessCard
                    headingText={'Data Quality Recommendations'}
                    type={'Data Quality Recommendations'}
                    labelType={'DataPod'}
                    dataPodId={dataPod?.id}
                    subLabel={dataPod?.name}
                  />
                </Grid>
                <Grid item xs={3} md={5.7}>
                  <DataProcessCard
                    headingText={dataPod?.datapodType == 2 ? 'Source to Target Mapping' : 'Entity Reconciliation'}
                    type={
                      dataPod?.datapodType == 2 ? 'Generative Integration Source To Target' : 'Entity Reconciliation'
                    }
                    dataPodId={dataPod?.id}
                    labelType={'DataPod'}
                    subLabel={dataPod?.name}
                  />
                </Grid>

                {dataPod?.datapodType == 2 ? (
                  <Grid item xs={3} md={5.7}>
                    <DataProcessCard
                      headingText="Target Canonical Model"
                      type={dataPod?.datapodType == 2 ? 'Target Canonical Model' : 'Canonical Model'}
                      labelType="DataPod"
                      dataPodId={dataPod.id}
                      subLabel={dataPod.name}
                    />
                  </Grid>
                ) : (
                  <></>
                )}
                {dataPod?.datapodType != 2 ? (
                  <>
                    <Grid item xs={3} md={5.7}>
                      <DataProcessCard
                        headingText={'Discover Relationships'}
                        type="Generate Entity Relationships"
                        dataPodId={dataPod?.id}
                        labelType={'DataPod'}
                        subLabel={dataPod?.name}
                      />
                    </Grid>
                    <Grid item xs={3} md={5.7}>
                      <DataProcessCard
                        headingText="Enriched Data Model"
                        type="Generate Enterprise Data Models"
                        dataPodId={dataPod?.id}
                        labelType={'DataPod'}
                        subLabel={dataPod?.name}
                      />
                    </Grid>
                  </>
                ) : (
                  <></>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}
