import { useState, useEffect } from 'react'
import { AnalyticsGroup } from '../businessObjects'
import { analyticsGroupServices } from '../apiServices/analyticsGroup'
import { useLucidAuthContext } from '../LucidAuthContext/LucidAuthContext'

type FlatObject = { [key: string]: any }

export const useGetAnalyticGroups = (
  dataPodId?: string,
): {
  getAnalyticsGroup: () => void
  analyticsGroupList: AnalyticsGroup[]
  getEntitiesByAnalyticsGroup: (groupId: number) => void
  analyticsGroupEntitiesList: any
  analyticsGroupLoading: boolean
  entitiesLoading: boolean
  groupError: FlatObject | undefined
} => {
  const [groupError, setGroupError] = useState<FlatObject | undefined>()
  const [analyticsGroupLoading, setAnalyticsGroupLoading] = useState<boolean>(false)
  const [analyticsGroupList, setAnalyticsGroupList] = useState<AnalyticsGroup[]>([])
  const [entitiesLoading, setEntitiesLoading] = useState<boolean>(false)
  const [analyticsGroupEntitiesList, setAnalyticsGroupEntitiesList] = useState<any>()

  const { getBearerToken } = useLucidAuthContext()

  const getAnalyticsGroup = async (): Promise<void> => {
    try {
      setAnalyticsGroupLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await analyticsGroupServices.getAnalyticsGroup(bearerToken, dataPodId)
        if (status == 200) {
          setAnalyticsGroupList(data)
        } else {
          setGroupError({ time: Date.now(), message: data?.toSring() })
        }
        setAnalyticsGroupLoading(false)
      }
    } catch (error: any) {
      setAnalyticsGroupLoading(false)
      setGroupError({ time: Date.now(), message: error.message })
    }
  }

  const getEntitiesByAnalyticsGroup = async (analyticsGroupID: number): Promise<void> => {
    try {
      setEntitiesLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && analyticsGroupID) {
        const { data, status } = await analyticsGroupServices.getEntitiesByAnalyticsGroup(
          bearerToken,
          dataPodId,
          analyticsGroupID,
        )
        if (status == 200) {
          setAnalyticsGroupEntitiesList(data)
        } else {
          setGroupError({ time: Date.now(), message: data?.toSring() })
        }
        setEntitiesLoading(false)
      }
    } catch (error: any) {
      setEntitiesLoading(false)
      setGroupError({ time: Date.now(), message: error.message })
    }
  }

  useEffect(() => {
    if (dataPodId) {
      getAnalyticsGroup()
    }
  }, [])

  return {
    getAnalyticsGroup,
    analyticsGroupList,
    getEntitiesByAnalyticsGroup,
    analyticsGroupEntitiesList,
    analyticsGroupLoading,
    entitiesLoading,
    groupError,
  }
}

export const useGetEntitiesByAnalyticsGroup = (
  dataPodId?: string,
  groupId?: any,
): {
  getEntitiesByAnalyticsGroup: () => void
  analyticsGroupEntitiesList: any
  entitiesLoading: boolean
  error: Error | undefined
} => {
  const [error, setError] = useState<Error>()
  const [entitiesLoading, setEntitiesLoading] = useState<boolean>(false)
  const [analyticsGroupEntitiesList, setAnalyticsGroupEntitiesList] = useState<any>()

  const { getBearerToken } = useLucidAuthContext()

  const getEntitiesByAnalyticsGroup = async (): Promise<void> => {
    try {
      setEntitiesLoading(true)
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId) {
        const { data, status } = await analyticsGroupServices.getEntitiesByAnalyticsGroup(
          bearerToken,
          dataPodId,
          groupId,
        )
        if (status == 200) {
          setAnalyticsGroupEntitiesList(data)
        } else {
          setError(data)
        }
        setEntitiesLoading(false)
      }
    } catch (error: any) {
      setEntitiesLoading(false)
      setError(error)
    }
  }

  useEffect(() => {
    if (dataPodId) {
      getEntitiesByAnalyticsGroup()
    }
  }, [dataPodId, groupId])

  return { getEntitiesByAnalyticsGroup, analyticsGroupEntitiesList, entitiesLoading, error }
}

export const useDeleteAnalyticsGroup = (
): {
  deleteAnalyticsGroup: (groupId:number) => Promise<boolean| void>
} => {
  const { getBearerToken } = useLucidAuthContext()
  const deleteAnalyticsGroup = async (groupId:number): Promise<boolean | void> => {
    try{
      const bearerToken = await getBearerToken()
      if(bearerToken && groupId){
        const { data, status } = await analyticsGroupServices.deleteAnalyticsGroup(
          bearerToken,
          groupId,
        )
        if(status == 204){
          return true
        }else{
          return false
        }
      }
    }catch (error: any){
      console.log(error)
      return false
    }
  }
  return { deleteAnalyticsGroup }
}