import { Box, Button, Card, CardActions, CardContent, CardHeader, ClickAwayListener, Grow, IconButton, Paper, Popper, Typography } from "@mui/material"
import CancelPresentationOutlinedIcon from '@mui/icons-material/CancelPresentationOutlined'

import { useEffect, useState } from "react";

import { useGetDatabricksRunStatus } from "../../hooks/databricksHooks";

import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { addActiveRunIds, addFabricRunStatus } from "../../features/notification/notificationSlice";
import { ApiStatus, FabricJobStatusRequest, FabricJobStatusResponse, GenAIApiStatus, RunIdStatus, RunStatusResponse } from "../../businessObjects";
import { useGetFabricJobStatus } from "hooks/fabricHooks";


type Props = {
  handleToggle: () => void;
  isOpen: boolean;
  shouldNotify: boolean;
  setShouldNotify: React.Dispatch<React.SetStateAction<boolean>>;
  anchorRef: React.MutableRefObject<null>
}

const isGenAIApiStatus = (notification: any): notification is GenAIApiStatus => {
  return "GenAIApiStatusId" in notification;
};

const isRunStatusResponse = (notification: any): notification is RunStatusResponse => {
  return "runId" in notification && "state" in notification;
};

const isFabricRunStatus = (notification: any): notification is FabricJobStatusResponse => {
  return "workspaceId" in notification && "status" in notification;
};

export const NotificationPopper = ({ handleToggle, isOpen, anchorRef, shouldNotify, setShouldNotify }: Props) => {
  const [runStatus, setRunStatus] = useState<RunStatusResponse[]>([]);

  const [notifications, setNotifications] = useState<(GenAIApiStatus | RunStatusResponse | FabricJobStatusResponse)[]>([]);

  const { fetchRunStatus } = useGetDatabricksRunStatus();

  const { fetchFabricJobStatus } = useGetFabricJobStatus();

  const activeRunIds = useAppSelector((state) => state.notification.activeRunIds)
  const fabricRunStatus = useAppSelector((state) => state.notification.fabricRunStatus)
  const genAIApiStatus = useAppSelector((state) => state.notification.genAIApiStatus)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (activeRunIds?.length > 0 || genAIApiStatus?.length > 0 || fabricRunStatus?.length > 0) {
      console.log("triggering fetchRunStatus and fetchFabricJobStatus")
      setNotifications(prevArray => {
        const newSet = new Set([...activeRunIds, ...fabricRunStatus, ...genAIApiStatus])
        return Array.from(newSet)
      })

      if (!isOpen)
        setShouldNotify(true)

      let interval = setInterval(async () => {
        Promise.allSettled([getRunStatus(), getFabricJobStatus()])
          .then(results => {
            results.forEach(result => {
              if (result.status === 'fulfilled') {
                // console.log('Success:', result.value);
              } else {
                console.error('Failed:', result.reason);
              }
            });
          });
      }, 10000);

      return () => {
        clearInterval(interval);
      };
    }
    else {
      setShouldNotify(false)
    }
  }, [activeRunIds, genAIApiStatus, fabricRunStatus])

  const getRunStatus = async () => {
    //Filter the run status of failed or success. So, we don't need status of them again.
    const filteredRunStatus = activeRunIds.filter(run => {
      const status = run.state.resultState
      return status !== 'FAILED' && status !== 'SUCCESS';
    })

    const payload: RunIdStatus[] = filteredRunStatus.map(run => {
      return {
        runId: run.runId,
        status: run.state.lifeCycleState,
        url: run.params.url,
        token: run.params.token
      }
    })

    if (payload.length) {
      const result = await fetchRunStatus(payload.filter((param)=>param.runId!=undefined));

      if (result) {
        dispatch(addActiveRunIds([...result]))
      }
    }
  }

  const getFabricJobStatus = async () => {
    //Filter the run status of failed or success. So, we don't need status of them again.
    const filteredFabricJobStatus = fabricRunStatus.filter(run => {
      return (run.status ==='InProgress' || run.status === 'NotStarted');
    })

    const payload: FabricJobStatusRequest[] = filteredFabricJobStatus.map(job => {
      return {
        workspaceId: job.workspaceId,
        itemId: job.itemId,
        JobInstanceId: job.id,
        jobType: job.jobType,
        token: job.token
      }
    })

    if (payload.length) {
      const result = await fetchFabricJobStatus(payload);

      if (result) {
        dispatch(addFabricRunStatus([...result]))
      }
    }
  }

  const renderByType = (notification: RunStatusResponse | GenAIApiStatus | FabricJobStatusResponse, index: number) => {
    if(isGenAIApiStatus(notification)) {
      const element = notification
      return (
        <Card
          key={index}
          sx={{
            marginBottom: 1,
            boxShadow: 4,
            borderRadius: 1,
          }}
        >
          <CardHeader
            title={`${element.title}`}
          />
          <CardContent>
            <Typography variant="h6">
              {`${element.description}`}
            </Typography>
            <Typography variant="h6">
              {`Status: ${element.status}`}
            </Typography>
            {/* <Typography variant="body1">
              {`API URL: ${element.apiUrl}`}
            </Typography>
            <Typography variant="body1">
              {`Redirect URL: ${element.redirectUrl}`}
            </Typography> */}
          </CardContent>
        </Card> )
    } else if(isRunStatusResponse(notification)) {
      const element = notification
      return (
        <Card
          key={index}
          sx={{
            marginBottom: 1,
            boxShadow: 4,
            borderRadius: 1,
          }}
        >
          <CardHeader
            title={`JOB ${element.state.resultState == "LOADING" ? element.state.lifeCycleState : element.state.resultState}`}
          />
          <CardContent>
            <Typography variant="h6">
              {`Job Type: ${element.jobDetails?.triggerType}`}
            </Typography>
            <Typography variant="h6">
              {`Name: ${element.jobDetails?.parameters.name}`}
            </Typography>
            <Typography variant="body1">
              {`Run ID: ${element.runId}`}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: (element.state.resultState==='SUCCESS' || element.state.resultState==='FAILED')? 'space-between':'flex-end',
            }}>
            {element.runPageUrl && (
              <> 
              {(element.state.resultState==='SUCCESS' || element.state.resultState==='FAILED')&& 
                <Button
                  variant="contained"
                  href={element.jobDetails?.parameters.route ?? '/'}
                  rel="noopener noreferrer"
                  disabled={(element.state.resultState !== "SUCCESS" && element.state.resultState !== "FAILED")}
                >
                  Go To Page
                </Button>}
                <Button
                  variant="contained"
                  href={element.runPageUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO JOB
                </Button>
              </>
            )}
          </CardActions>
        </Card> )
    } else if(isFabricRunStatus(notification)) {
      const element = notification
      return (
        <Card
          key={index}
          sx={{
            marginBottom: 1,
            boxShadow: 4,
            borderRadius: 1,
          }}
        >
          <CardHeader
            title={`JOB ${element.status}`}
          />
          <CardContent>
            <Typography variant="h6">
              {`Job Type: ${element.jobDetails?.triggerType}`}
            </Typography>
            <Typography variant="h6">
              {`Name: ${element.jobDetails?.parameters.name}`}
            </Typography>
            <Typography variant="body1">
              {`Run ID: ${element.id}`}
            </Typography>
          </CardContent>
          <CardActions
            sx={{
              display: 'flex',
              justifyContent: !(element.status ==='InProgress' || element.status === 'NotStarted') ? 'space-between' : 'flex-end',
            }}>
            {/* {element.runPageUrl && ( */}
              <> 
              {!(element.status ==='InProgress' || element.status === 'NotStarted') && 
                <Button
                  variant="contained"
                  href={element.jobDetails?.parameters.route ?? '/'}
                  rel="noopener noreferrer"
                  disabled={(element.status ==='InProgress' || element.status === 'NotStarted')}
                >
                  Go To Page
                </Button>}
                <Button
                  variant="contained"
                  href={"https://app.fabric.microsoft.com/monitoringhub?experience=data-warehouse"}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  GO TO JOB
                </Button>
              </>
            {/* )} */}
          </CardActions>
        </Card> )
    }
  }

  return (
    <Popper
      open={isOpen}
      anchorEl={anchorRef.current}
      transition
      disablePortal
      placement="bottom"
    >
      {({ TransitionProps }) => (
        <Grow {...TransitionProps} style={{ transformOrigin: 'center top' }}>
          <Paper id="menu-list-grow"
            elevation={4}
            style={{
              height: '100vh',
              overflowY: 'auto',
              minWidth: '22vw'
            }}>
            <ClickAwayListener onClickAway={handleToggle}>
              <>
                <Box style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', padding: '8px' }}>
                  <Typography variant="h2" align="left">
                    Notifications
                  </Typography>
                  <IconButton onClick={handleToggle}>
                    <CancelPresentationOutlinedIcon />
                  </IconButton>
                </Box>

                <Button onClick={() => setNotifications([])}>Clear</Button>

                <Box style={{ padding: '8px' }}>
                  {notifications.map((element, index) => (
                    renderByType(element, index)
                  ))}
                </Box>
              </>
            </ClickAwayListener>
          </Paper>
        </Grow>
      )}
    </Popper>
  )
}