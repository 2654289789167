import { CreateJobParams, FabricJobStatusRequest, FabricNotebookCreateRequest, FabricRunNewJobRequest, RunIdStatus, RunNewJobParams } from '../businessObjects'
import { sendRequest } from '../requestManager/sendRequest'

const fabricPath = {
  getJobStatus: 'Fabric/jobs/run/status/get',
  postRunNewJob: 'Fabric/jobs/status/run-new',
  postCreateAndRun: 'Fabric/notebook/createAndRun',
}

export const getJobStatus = async (token: string, jobStatusPayload: FabricJobStatusRequest[]) => {
  const url = fabricPath.getJobStatus
  return await sendRequest(url, 'POST', token, jobStatusPayload)
}

export const postRunNewJob = async (token: string, params: FabricRunNewJobRequest) => {
  const url = fabricPath.postRunNewJob
  return await sendRequest(url, 'POST', token, params)
}

export const postCreateAndRun = async (token: string, params: FabricNotebookCreateRequest) => {
    const url = fabricPath.postCreateAndRun
    return await sendRequest(url, 'POST', token, params);
}

export const fabricServices = {
  getJobStatus,
  postRunNewJob,
  postCreateAndRun
}