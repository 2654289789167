import { Configuration, VnetConfig } from "businessObjects"
import { useLucidAuthContext } from "LucidAuthContext/LucidAuthContext"
import { configurationServices } from "../apiServices/lucidConfiguration"
import { vnetConfigServices } from "apiServices/vnetConfiguration"


export const useGetVnetConfiguration = () => {
  
  const { getBearerToken } = useLucidAuthContext()

  const getVnetConfiguration = async (accountId: string): Promise<VnetConfig | undefined> => {
    const token = await getBearerToken()

    if (token && accountId) {
      try {
        const { data, status } = await vnetConfigServices.getVnetConfiguration(token, accountId);

        if (status === 200) 
          return data;
        
      } catch (error) {
        console.error('Error fetching VNET configuration:', error);
      }
    } 
  }
  return { getVnetConfiguration }
}

export const useAddVnetConfiguration = () => {
  const { getBearerToken } = useLucidAuthContext();

  const addVnetConfiguration = async (config: VnetConfig): Promise<VnetConfig | null> => {
    const token = await getBearerToken();

    if (token) {
      try {
          const { data, status } = await vnetConfigServices.addVnetConfiguration(token, config);

          if(status == 200) {
              return data;
          }
      } catch (error) {
        console.error('Error adding Lucid configuration:', error);
      }
    }

    return null;
  };

  return { addVnetConfiguration };
};

// export const useDeleteConfiguration = (): { deleteConfiguration: (id: number) => Promise<boolean> } => {
//     const { getBearerToken } = useLucidAuthContext();
  
//     const deleteConfiguration = async (id: number): Promise<boolean> => {
//       const token = await getBearerToken();
  
//       if (token) {
//         try {
//             const { status } = await configurationServices.deleteConfiguration(token, id);
            
//             if(status == 204)
//                 return true;

//         } catch (error) {
//           console.error('Error deleting Lucid configuration:', error);
//         }
//       }

//       return false;
//     };
  
//     return { deleteConfiguration };
// };
  
export const useUpdateVnetConfiguration = () => {
    const { getBearerToken } = useLucidAuthContext();
  
    const updateVnetConfiguration = async (params: VnetConfig): Promise<VnetConfig | null> => {
      const token = await getBearerToken();
  
      if (token) {
        try {
          const {data, status} = await vnetConfigServices.updateVnetConfiguration(token, params);
          
          if(status == 200)
            return data;

        } catch (error) {
          console.error('Error updating Lucid configuration:', error);
        }
      }

      return null;
    };
  
    return { updateVnetConfiguration };
  };
    