
import { sendRequest } from '../requestManager/sendRequest'

import axios, { AxiosRequestConfig } from 'axios'
import { environment } from '../utils/environment'
import {RunAnalyticsGroupProfileBody} from '../businessObjects'
import { getAxiosRequestConfig } from './apiserviceHelpers'

const analyticsGroupPath = {
    AddAnalyticsGroup: 'AnalyticsGroup/AddAnalyticsGroup',
    UpdateAnalyticsGroup: 'AnalyticsGroup/UpdateAnalyticsGroup',
    deleteAnalyticsGroup: 'AnalyticsGroup/deleteAnalyticsGroup',

    AddAnalyticsGroupMap: 'AnalyticsGroup/AddAnalyticsGroupMap',
    UpdateAnalyticsGroupMap : 'AnalyticsGroup/UpdateAnalyticsGroupMap',

    getAnalyticsGroup: 'AnalyticsGroup/getAnalyticsGroup',
    getEntitiesByAnalyticsGroup: 'AnalyticsGroup/getEntitiesByAnalyticsGroup',
    getMapping: 'AnalyticsGroup/getMapping'
}

const addAnalyticsGroup = async (token: string, dataPodId: string, body: any) => {
    return sendRequest(analyticsGroupPath.AddAnalyticsGroup + '?dataPodId=' + dataPodId, 'POST', token, body)
}

const updateAnalyticsGroup = async (token: string, dataPodId: string, body: any) => {
    return sendRequest(analyticsGroupPath.UpdateAnalyticsGroup + '?dataPodId=' + dataPodId, 'PUT', token, body)
}

const deleteAnalyticsGroup = async (token: string, analyticsGroupID: number) => {
    return sendRequest(analyticsGroupPath.deleteAnalyticsGroup + '?analyticsGroupID=' + analyticsGroupID, 'DELETE', token)
}

const addAnalyticsGroupMap = async (token: string, dataPodId: string, body: any) => {
    return sendRequest(analyticsGroupPath.AddAnalyticsGroupMap + '?dataPodId=' + dataPodId, 'POST', token, body)
}

const updateAnalyticsGroupMap = async (token: string, dataPodId: string, body: any) => {
    return sendRequest(analyticsGroupPath.UpdateAnalyticsGroupMap + '?dataPodId=' + dataPodId, 'PUT', token, body)
}

const getAnalyticsGroup = async (token: string, dataPodId: string, analyticsGroupID?: number, analyticsGroupName?: string, RecordsPerPage?: number, PageNumber?: number) => {
    const paginationParams = RecordsPerPage && PageNumber ? `&RecordsPerPage=${RecordsPerPage}&PageNumber=${PageNumber}` : null
    let url = analyticsGroupPath.getAnalyticsGroup;
    if (dataPodId) {
        url += `?datapodID=${dataPodId}`;
    }
    
    if (analyticsGroupID) {
        url += `&analyticsGroupID=${analyticsGroupID}`;
    }
    
    if (analyticsGroupName) {
        url += `&analyticsGroupName=${analyticsGroupName}`;
    }  

    if(paginationParams) {
        url + paginationParams
    }
    return sendRequest(url, 'GET', token)
}

const getEntitiesByAnalyticsGroup = async (token: string, dataPodId: string, analyticsGroupID?: number, analyticsGroupName?: string, searchText?: string, RecordsPerPage?: number, PageNumber?: number) => {
    const paginationParams = RecordsPerPage && PageNumber ? `&RecordsPerPage=${RecordsPerPage}&PageNumber=${PageNumber}` : null
    let url = analyticsGroupPath.getEntitiesByAnalyticsGroup;
    if (dataPodId) {
        url += `?datapodID=${dataPodId}`;
    }
    
    if (analyticsGroupID) {
        url += `&analyticsGroupID=${analyticsGroupID}`;
    }
    
    if (analyticsGroupName) {
        url += `&analyticsGroupName=${analyticsGroupName}`;
    } 
    
    if (searchText) {
        url += `&searchText=${searchText}`;
    }
    
    if(paginationParams) {
        url + paginationParams
    }

    return sendRequest(url, 'GET', token)
}

const getMapping = async (token: string, dataPodId: string, anlyticsGroupMapID?: number, analyticsgroupID?: number, dataentityID?: number, analyticsGroupName?: string, dataEntityName?: string, RecordsPerPage?: number, PageNumber?: number) => {
    const paginationParams = RecordsPerPage && PageNumber ? `&RecordsPerPage=${RecordsPerPage}&PageNumber=${PageNumber}` : null
    let url = analyticsGroupPath.getMapping;
    if (dataPodId) {
        url += `?datapodID=${dataPodId}`;
    } else if (anlyticsGroupMapID) {
        url += `&anlyticsGroupMapID=${anlyticsGroupMapID}`;
    }  else if (analyticsgroupID) {
        url += `&analyticsgroupID=${analyticsgroupID}`;
    }  else if (dataentityID) {
        url += `&dataentityID=${dataentityID}`;
    }  else if (analyticsGroupName) {
        url += `&analyticsGroupName=${analyticsGroupName}`;
    }  else if (dataEntityName) {
        url += `&dataEntityName=${dataEntityName}`;
    }
    
    if(paginationParams) {
        url + paginationParams
    }

    return sendRequest(url, 'GET', token)
}



export const analyticsGroupServices = {
    addAnalyticsGroup,
    updateAnalyticsGroup,
    deleteAnalyticsGroup,
    addAnalyticsGroupMap,
    updateAnalyticsGroupMap,
    getAnalyticsGroup,
    getEntitiesByAnalyticsGroup,
    getMapping
}
  