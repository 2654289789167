import { Box, Grid, IconButton, Typography, MenuItem, TextField, InputAdornment, Button } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'

import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'

import {
  useGetProfileAttibuteOutput,
  useGetProfileAttributesByEntityId,
  useGetProfileEntitites,
  useGetProfileEntity,
} from '../../../hooks/dataProfilingHooks'

import {
  AppProfileAttribute,
  AttributeProfile,
  DataAttributeBody,
  NewAttribute,
  NewAttributeMapBody,
  UpdateAttributeData,
  UpdateAttributeMapBody,
} from '../../../businessObjects'

import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import { green } from '@mui/material/colors'
import CancelIcon from '@mui/icons-material/Cancel'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'

import {
  useAddNewDataAttribute,
  useDeleteAttributeMap,
  useUpdateDataAttribute,
} from '../../../hooks/dataAttrbutesHooks'
import { useAddNewAttributeMap, useUpdateAttributeProfileMap } from '../../../hooks/attributeProfileHooks'
import { ArrowBack } from '@mui/icons-material'
import ResultSnackBar from '../../../ui-components/AppBar/ResultSnackBar'
import ResultStatus from '../../../ui-components/ResultStatus'
import { ProgressBar } from '../../../ui-components/ProgressBar'
import {
  DataMappingAttributesAddCustomAttribute,
  DataMappingAttributesEditPopUp,
  DeletePopUp,
} from './DataMappingPopUps'
import AnimatedLoader from '../../../ui-components/AnimatedLoader'
import { PreviousPage } from 'ui-components/Button/PreviousPage'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
  entityID: string
  tableID: string
}
export const DataMappingAttributes = (props: Props) => {
  const { dataPodId, dataSystemID, entityID, tableID } = useParams<ViewResultsParams>()
  const [selectedSourceID, setSelectedSourceID] = useState<number>()
  const [entityAttributes, setEntityAttributes] = useState<AppProfileAttribute[]>([])
  const [freeSearch, setFreeSearch] = useState<string>('')
  const [selectedAttributeRow, setSelectedAttributeRow] = useState<AttributeProfile>()
  const [showPopUp, setShowPop] = useState<boolean>(false)
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const [attributeMapId, setAttributeMapId] = useState<number>()
  const [deleteSuccess, setDeleteSuccess] = useState<boolean>(false)
  const [editWarning, setEditWarning] = useState<boolean>(false)
  const [tableRows, setTableRows] = useState<DataTableRow[]>()
  const [addAttributePopUp, setAddAttributePopUp] = useState<boolean>(false)
  const [attributeUpdated, setAttributeUpdated] = useState<boolean>(false)
  //const [attributeProfilesList, setattributeProfilesList] = useState<AttributeProfile[]>([])
  const [isNewAttr, setIsNewAtrr] = useState<boolean>(false)
  //const [processingData, setProcessingData] = useState<boolean>(false)
  const [editOptions, setEditOptions] = useState<'editContents' | 'editMapping' | 'newAttribute'>('editContents')
  const [newAttibute, setNewAttribute] = useState<NewAttribute>()
  const [selectAttributeId, setSelectAttributeId] = useState<number>()

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const {
    profileAttributes,
    refetch,
    loadingProfileAttributes: loadingAttrbibutes,
  } = useGetProfileAttibuteOutput(dataPodId, selectedSourceID, Number(entityID), Number(tableID), freeSearch, {
    recordsPerPage: 1000,
    pageNumber: 1,
  })
  const { profileEntities, refetchEntities } = useGetProfileEntitites(
    dataPodId,
    selectedSourceID ? Number(selectedSourceID) : undefined,
    undefined,
    {
      recordsPerPage: 1000,
      pageNumber: 1,
    },
    undefined,
  )
  const [newAttributeFunc] = useAddNewDataAttribute()
  const [updateAttribFunc] = useUpdateDataAttribute()

  const [updateAttrMapFunc] = useUpdateAttributeProfileMap()

  const { deleteAttributeMapId } = useDeleteAttributeMap()

  const [addAttributeMapFunc, newAttributeMap, loading, createError] = useAddNewAttributeMap()

  useEffect(() => {
    if (dataSystemID && !selectedSourceID) {
      setSelectedSourceID(Number(dataSystemID))
      //console.log(dataSystemID)
    }
  }, [dataSystemID])

  // useEffect(() => {
  //   if (!profileAttributes) {
  //     setProcessingData(true)
  //   }
  // }, [profileAttributes])

  // const loadingAttrbibutes = useMemo(() => {
  //   return !profileAttributes?.length
  // }, [profileAttributes, freeSearch])

  useEffect(() => {
    if (profileAttributes) {
      //console.log(attributes[0])
      //setProcessingData(false)
      const sortedAttribs = profileAttributes.sort((p1, p2) => (p1.dataAttributeName > p2.dataAttributeName ? 1 : -1))
      setEntityAttributes(sortedAttribs)
      //console.log({ sortedAttribs })
      const tableRowsData = sortedAttribs.map((at) => ({
        id: String(at.dataAttributeMapID),
        values: [
          at.dataColumnName,
          at.dataAttributeName,
          at.dataAttributeDescription,
          at.dataClassification,
          //`${at.isBusinessKey ?? false}`,
          at.dataAttributeDataType,
          'EDIT',
          'DELETE',
        ],
      }))

      setTableRows(tableRowsData)
    }
  }, [freeSearch, entityID, profileAttributes])

  const selectedEntityInfo = useMemo(() => {
    if (!profileEntities) return
    return entityID
      ? profileEntities?.find((pa) => pa.dataEntityID === Number(entityID) && pa.dataTableID === Number(tableID))
      : undefined
  }, [profileEntities])

  const navigate = useNavigate()
  const onClickHome = () => {
    const dataMappingResulPage = generatePath(ROUTES.DataMappingResults, { dataPodId: dataPodId, dataSystemID })
    navigate(dataMappingResulPage)
  }

  // const toggleView = (toggle: boolean) => {
  //   setCardView(!toggle)
  //   setTableView(toggle)
  // }

  const tableColumns = useMemo(() => {
    return [
      {
        label: 'Column Name',
        sortableColumn: true,
      },
      {
        label: 'Attribute Name',
        sortableColumn: true,
      },

      {
        label: 'Attribute Description',
        sortableColumn: true,
      },
      {
        label: 'Classification',
        sortableColumn: true,
      },
      // {
      //   label: 'isBussinessKey',
      //   sortableColumn: true,
      // },
      {
        label: 'Data Type',
      },
      {
        label: 'EDIT',
      },
      {
        label: 'DELETE',
      },
    ]
  }, [])

  const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
    const tbID = Number(rowId)
    // const selAttr = attributeProfilesList.find((ap) => ap.id === tbID)
    const selRow = entityAttributes.find((r) => r.dataAttributeMapID === tbID)

    if (selRow) {
      setSelectedAttributeRow({
        id: selRow?.dataAttributeID ?? 0,
        dataAttributeMapID: selRow?.dataAttributeMapID ?? 0,
        dataEntityId: selRow?.dataEntityID ?? 0,
        entityName: selRow?.dataEntityName ?? '',
        dataAttributeName: selRow?.dataAttributeName ?? '',
        dataColumnName: selRow?.dataColumnName ?? '',
        dataPodId: selRow?.datapodId ?? '',
        dataAttributeDataType: selRow?.dataAttributeDataType ?? '',
        dataClassification: selRow?.dataClassification ?? '',
        description: selRow?.dataAttributeDescription ?? '',
        isBusinessKey: selRow?.isBusinessKey ?? false,
      })
      // console.log({ rowId })
      if (actionType === ShadowTableAction.Editable) {
        setEditWarning(true)
        setShowPop(true)
      }
      if (actionType === ShadowTableAction.Deletable) {
        setAttributeMapId(selRow?.dataAttributeMapID)
        setShowDeleteWarning(true)
      }
    }
  }

  const closePopup = (close: boolean) => {
    setShowPop(close)
    setSelectedAttributeRow(undefined)
    setNewAttribute(undefined)
    setEditOptions('editContents')
  }

  const closeAddAttributePopUp = (close: boolean) => {
    setAddAttributePopUp(close)
    setSelectedAttributeRow(undefined)
    setNewAttribute(undefined)
    refetch()
  }

  const onChange = (name: string, value: unknown) => {
    setNewAttribute(undefined)
    setIsNewAtrr(false)
    setSelectedAttributeRow(
      (prv) =>
        ({
          ...prv,
          [name]: value,
        } as AttributeProfile),
    )
  }
  const changeBusinessKey = (value: boolean) => {
    setNewAttribute(undefined)
    setIsNewAtrr(false)
    setSelectedAttributeRow(
      (prv) =>
        ({
          ...prv,
          isBusinessKey: value,
        } as AttributeProfile),
    )
  }

  const onChangeNewAttribute = (name: string, value: string) => {
    setNewAttribute((prv) => ({ ...prv, [name]: value } as NewAttribute))
  }

  const saveNewAttribute = async () => {
    if (dataPodId && newAttibute) {
      const newAttribVal: NewAttribute = {
        dataAttributeName: newAttibute?.dataAttributeName ?? '',
        dataEntityId: entityID ? Number(entityID) : 0,
        description: newAttibute?.description ?? '',
        isBusinessKey: Boolean(newAttibute.isBusinessKey),
        dataClassification: newAttibute.dataClassification ?? '',
        dataAttributeDataType: newAttibute?.dataAttributeDataType ?? '',
        dataAttributeType: 'simple',
      }
      const res = await newAttributeFunc(newAttribVal, dataPodId)

      const toUpdate = entityAttributes.find((ea) => ea.dataAttributeID === selectedAttributeRow?.id)

      if (res && toUpdate) {
        //setattributeProfilesList([...attributeProfiles, res])
        const upd: UpdateAttributeMapBody = {
          id: toUpdate?.dataAttributeMapID,
          dataTableId: toUpdate?.dataTableID,
          dataEntityId: res.dataEntityId,
          dataColumnId: toUpdate?.dataColumnId,
          dataAttributeId: res?.id,
          dataEntityMapId: toUpdate?.dataEntityMapId,
        }
        const updatedMapRes = await updateAttrMapFunc(upd)

        if (updatedMapRes && selectedAttributeRow) {
          const updatedRowValues = tableRows?.find((tr) => tr.id === String(selectedAttributeRow?.dataAttributeMapID))
          //console.log({ updatedRowValues })
          if (updatedRowValues) {
            const filteredRows = tableRows?.map((tr) =>
              tr.id === String(selectedAttributeRow?.dataAttributeMapID)
                ? {
                    ...tr,
                    values: [
                      tr.values[0],
                      newAttibute.dataAttributeName,
                      newAttibute.description,
                      newAttibute.dataClassification,
                      // String(selectedAttributeRow?.isBusinessKey),
                      newAttibute.dataAttributeDataType,
                      'EDIT',
                      'DELETE',
                    ],
                  }
                : tr,
            )
            setTableRows(filteredRows)
            refetch()
            setAttributeUpdated(true)
            //setProcessingData(false)
          }
        }
      } else if (!res) {
        setErrorState(true)
        setErrorMessage('Failed to add new Attribute.')
      }
    }
  }

  // const addAttributePopUpSubmit = async (dataColumnId: number | undefined) => {
  //   if (dataPodId && newAttibute) {
  //     if (
  //       newAttibute.dataAttributeDataType &&
  //       newAttibute.description &&
  //       newAttibute.dataClassification &&
  //       newAttibute.dataAttributeDataType &&
  //       newAttibute.dataAttributeDataType.trim() !== '' &&
  //       newAttibute.description.trim() !== '' &&
  //       newAttibute.dataClassification.trim() !== '' &&
  //       newAttibute.dataAttributeDataType.trim() !== ''
  //     ) {
  //       const newAttribVal: DataAttributeBody = {
  //         dataAttributeName: newAttibute?.dataAttributeName ?? '',
  //         dataEntityId: entityID ? Number(entityID) : 0,
  //         description: newAttibute?.description ?? '',
  //         isBusinessKey: newAttibute.isBusinessKey ?? false,
  //         dataClassification: newAttibute.dataClassification ?? '',
  //         dataAttributeDataType: newAttibute?.dataAttributeDataType ?? '',
  //       }
  //       const res = await newAttributeFunc(newAttribVal, dataPodId)
  //       if (selectedEntityInfo) {
  //         const newAttributeMapBody: NewAttributeMapBody = {
  //           dataTableId: selectedEntityInfo?.dataTableID,
  //           dataEntityId: entityID ? Number(entityID) : 0,
  //           dataColumnId: dataColumnId ?? -1,
  //           dataAttributeId: res?.id ?? 0,
  //           dataEntityMapId: selectedEntityInfo?.mapId,
  //           runid: 0,
  //         }
  //         const addMapSuccess = await addAttributeMapFunc(newAttributeMapBody, dataPodId)
  //         if (addMapSuccess && addMapSuccess) {
  //           return addMapSuccess
  //         }
  //       }
  //     }
  //   }
  // }

  const onSubmit = async () => {
    // console.log({ editOptions })
    //setProcessingData(true)
    if (tableRows) {
      if (editOptions == 'editContents' && dataPodId) {
        const params: UpdateAttributeData = {
          id: selectedAttributeRow?.id ?? 0,
          name: selectedAttributeRow?.dataAttributeName ?? '',
          description: selectedAttributeRow?.description ?? '',
          dataAttributeDataType: selectedAttributeRow?.dataAttributeDataType ?? '',
          dataClassification: selectedAttributeRow?.dataClassification ?? '',
          isBusinessKey: String(selectedAttributeRow?.isBusinessKey) == 'true' ? true : false,
          coreEntity: false,
          entityType: '',
        }
        const updatedAttibutes = await updateAttribFunc(params, dataPodId)
        if (updatedAttibutes) {
          const {
            dataAttributeName,
            description,
            dataColumnName,
            dataClassification,
            isBusinessKey,
            dataAttributeDataType,
          } = updatedAttibutes
          // console.log({ updatedAttibutes })
          const updateRows = tableRows?.map((tr) =>
            tr.id !== String(updatedAttibutes.id)
              ? tr
              : {
                  ...tr,
                  values: [
                    selectedAttributeRow?.dataColumnName ?? dataColumnName,
                    dataAttributeName,
                    description,
                    dataClassification,
                    isBusinessKey ? 'true' : 'false',
                    dataAttributeDataType,
                    'EDIT',
                    'DELETE',
                  ],
                },
          )
          setTableRows(updateRows)
          setAttributeUpdated(true)
          //setProcessingData(false)
        }
      }
      if (editOptions == 'editMapping' && selectedAttributeRow?.dataAttributeMapID && selectAttributeId) {
        const { dataAttributeMapID } = selectedAttributeRow
        const toUpdate = entityAttributes.find((ea) => ea.dataAttributeMapID === dataAttributeMapID)

        const upd: UpdateAttributeMapBody = {
          id: toUpdate?.dataAttributeMapID ?? 0,
          dataTableId: toUpdate?.dataTableID ?? 0,
          dataEntityId: toUpdate?.dataEntityID ?? 0,
          dataColumnId: toUpdate?.dataColumnId ?? 0,
          dataAttributeId: selectAttributeId ?? 0,
          dataEntityMapId: toUpdate?.dataEntityMapId ?? 0,
        }

        const updatedAttrMap = await updateAttrMapFunc(upd)
        if (updatedAttrMap) {
          //console.log({ updatedAttrMap })
          const updatedRowValues = tableRows.find((tr) => tr.id === String(upd.id))
          //console.log({ updatedRowValues })
          if (updatedRowValues) {
            const filteredRows = tableRows
              //.filter((tr) => tr.id !== String(toUpdate?.dataAttributeID))
              .map((tr) => (tr.id === String(dataAttributeMapID) ? { ...tr, values: updatedRowValues.values } : tr))

            setTableRows(filteredRows)
            //setProcessingData(false)
          }
        }
      }
      if (editOptions == 'newAttribute') {
        saveNewAttribute()
        // refetch()
        // refetchAttributes()
      }
    }
    refetch()
    closePopup(!showPopUp)
  }

  const closeDeleteWarning = () => {
    setAttributeMapId(undefined)
    setShowDeleteWarning(false)
  }

  const confirmDeleteAttributeMap = async () => {
    //setProcessingData(true)
    if (attributeMapId) {
      const deleteMapSuccess = await deleteAttributeMapId(attributeMapId)
      if (deleteMapSuccess && selectedAttributeRow?.id) {
        const filteredRows = tableRows?.filter((r) => r.id !== String(selectedAttributeRow?.dataAttributeMapID))
        refetch()
        setTableRows(filteredRows)
        setDeleteSuccess(true)
        closeDeleteWarning()
      }
    }
  }

  // const onClickCustomAttribute = () => {
  //   const customAttrPage = generatePath(ROUTES.DataMappingCustomAttribue, {
  //     dataPodId,
  //     dataSystemID,
  //     entityID,
  //     tableID,
  //   })
  //   navigate(customAttrPage)
  // }

  return (
    <ActionPage>
      {/* <ProgressBar loading={loadingAttrbibutes} /> */}
      {/* <AnimatedLoader height="50%" width="40%" loading={loadingAttrbibutes} /> */}
      <ResultStatus
        severtiy="success"
        showStatus={deleteSuccess}
        closeStatus={setDeleteSuccess}
        alertMessage={`SUCCESS: Deleted Attribute Map ${selectedAttributeRow?.dataAttributeName}`}
      />
      <ResultStatus
        severtiy="warning"
        showStatus={editWarning}
        closeStatus={setEditWarning}
        alertMessage={`Warning: Editing Attribute "${selectedAttributeRow?.dataAttributeName}" will impact corresponding dependencies.`}
      />
      <ResultStatus severtiy="error" showStatus={errorState} closeStatus={setErrorState} alertMessage={errorMessage} />
      <DeletePopUp
        showDeleteWarning={showDeleteWarning}
        confirmDeleteMap={confirmDeleteAttributeMap}
        closeDeleteWarning={closeDeleteWarning}
        itemToDelete={selectedAttributeRow?.dataAttributeName}
      />
      <DataMappingAttributesEditPopUp
        showPopUp={showPopUp}
        onSubmit={onSubmit}
        closePopup={closePopup}
        editOptions={editOptions}
        setEditOptions={setEditOptions}
        selectedAttributeRow={selectedAttributeRow}
        onChange={onChange}
        changeBusinessKey={changeBusinessKey}
        setSelectAttributeId={setSelectAttributeId}
        newAttibute={newAttibute}
        onChangeNewAttribute={onChangeNewAttribute}
        attributeUpdated={attributeUpdated}
        setAttributeUpdated={setAttributeUpdated}
      />
      <Grid item container spacing={1}>
        <Grid item xs={2}>
          <Button
            variant="outlined"
            onClick={onClickHome}
            title="Back to Data Mapping"
            startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
          >
            Back
          </Button>
        </Grid>
      </Grid>
      <Box style={cardContainer}>
        <Box sx={{ mt: 0 }}>
          <Grid container spacing={2}>
            <Grid item container xs={14} spacing={1}>
              <Grid item xs={4}>
                <Typography component="span" variant="subtitle1">
                  Table Name
                </Typography>
              </Grid>
              <Grid item xs={1} />

              <Grid item xs={4}>
                <Typography component="span" variant="subtitle1">
                  Entity Name
                </Typography>
              </Grid>
            </Grid>

            <Grid item container xs={14} spacing={1}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={selectedEntityInfo?.dataTableName ?? ''}
                  required
                  size="small"
                  disabled
                  //onChange={({ target }) => setSelectedSourceID(Number(target.value))}
                />
              </Grid>
              <Grid item xs={1} />
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={selectedEntityInfo?.dataEntityName ?? ''}
                  required
                  size="small"
                  disabled
                />
              </Grid>
            </Grid>

            {/* <Grid item container xs={14} spacing={1}>
              <Grid item xs={10}>
                <Typography color={'text.primary'}>
                  <span style={{ color: 'red' }}>*</span> Fine-Tune
                </Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  fullWidth
                  id="outlined-basic"
                  variant="outlined"
                  color="secondary"
                  rows={3}
                  multiline
                  helperText="Please provide a detailed context why you are making this change, so that the LLM model will remember this context for future processing"
                  placeholder={'send a Prompt'}
                  value={contextPromptText}
                  onChange={({ target }) => setContextPromptText(target.value)}
                />
              </Grid>
            </Grid> */}
          </Grid>
        </Box>

        <Grid item xs={12} />

        <Grid item container spacing={2}>
          <Grid item xs={12} />
          <Grid item xs={12} spacing={6} container direction={'row'} alignItems="center">
            {/* <Grid item xs={7} /> */}
            <Grid item xs={3}>
              <TextField
                placeholder="Search"
                //value={searchText}
                onChange={({ target }) => setFreeSearch(target.value)}
                color="primary"
                value={freeSearch}
                margin="dense"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  endAdornment: !!freeSearch ? (
                    <InputAdornment position="end">
                      <IconButton onClick={() => setFreeSearch('')}>
                        <CancelIcon fontSize="small" />
                      </IconButton>
                    </InputAdornment>
                  ) : (
                    <></>
                  ),
                }}
              />
            </Grid>
            {/* <Grid item xs={3}>
              <Button variant="contained" color="primary" sx={{ marginLeft: 4 }} onClick={onClickCustomAttribute}>
                Add Custom Attribute
              </Button>
            </Grid> */}
          </Grid>
          <Grid item md={12} />

          <Grid item xs={14} justifyContent={'center'} alignItems={'stretch'} container>
            <ShadowTable
              rows={tableRows ?? []}
              columns={tableColumns}
              tableActionParams={{
                onButtonClick: onButtonClick,
                actions: [ShadowTableAction.Editable],
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </ActionPage>
  )
}
