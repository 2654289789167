import { Button, Card, CardActions, CardContent, CardHeader, Grid, List, ListItem, ListItemText, Typography, styled } from '@mui/material'
import { LucidAccount, UserAccount } from 'businessObjects'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'

import { useGetEnterprisePlans, useGetUserPlans } from 'hooks/subscriptionHooks'
import { useEffect, useState } from 'react'
import { theme } from 'styles/theme'
import { environment } from 'utils/environment'

const PlanCard = styled(Card)(({ theme }) => ({
  textAlign: 'center',
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const Price = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: '2rem',
  margin: theme.spacing(2, 0),
}));

const OldPrice = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.disabled,
  textDecoration: 'line-through',
}));

const EarlyBird = styled(Typography)(({ theme }) => ({
  color: theme.palette.success.main,
  fontSize: '0.8rem',
}));

type Props = {
  onNext: () => void
  onBack: () => void
  lucidAccount: LucidAccount
  setLucidAccount: React.Dispatch<React.SetStateAction<LucidAccount>>
  userAccount: UserAccount
  setUserAccount: React.Dispatch<React.SetStateAction<UserAccount>>
}

const PricingTiers = ({ onNext, onBack, lucidAccount, setLucidAccount, userAccount, setUserAccount }: Props) => {
  const { userPlans } = useGetUserPlans()
  const [selectedPlan, setSelectedPlan] = useState<number | undefined>(userAccount?.planId)

  const handleSelectPlan = (planId: number) => {
    setSelectedPlan(planId)
    setUserAccount({ ...userAccount, planId })
  }

  if(environment.isEnterpriseApp) {
    onNext()
  }

  return (
    <>
      <Grid
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          height: '100%',
          gap: 5,
        }}
        item
      >
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            height: '100%',
            gap: 5,
          }}
        >
          <Grid item>
            <BusinessCenterIcon color="primary" />
          </Grid>
          <Grid item>
            <Typography variant="h2" gutterBottom>
              User Plans
            </Typography>
            <Typography variant="body1" gutterBottom>
              Select a user plan to proceed.
            </Typography>
          </Grid>

          <Grid item sx={{ display: 'flex', mt: 5, justifyContent: 'space-evenly' }}>
            {userPlans.length > 0 && (
              <>
                {userPlans.map((plan) => (
                  <Grid item xs={12} md={4} ml={2} key={'userPlanId_' + plan.id}>
                      <PlanCard>
                        <CardContent>
                          <Typography variant="h6" gutterBottom>
                            {plan.name}
                          </Typography>
                          <Typography variant="body2" color="textSecondary">
                            {plan.description}
                          </Typography>
                          {/* <OldPrice>$49</OldPrice> */}
                          <Price>${plan.price}</Price>
                          {/* <Typography variant="body2">Free forever!</Typography> */}
                          {/* <EarlyBird>
                             🐦 Early bird special (25% off).
                          </EarlyBird> */}
                        </CardContent>
                        <CardActions>
                          <Button
                            variant={selectedPlan === plan.id ? 'contained' : 'outlined'}
                            color="primary"
                            onClick={() => handleSelectPlan(plan.id)}
                            sx={{ mt: 2 }}
                            // fullWidth
                          >
                            {selectedPlan === plan.id ? 'Selected' : 'Select'}
                          </Button>
                        </CardActions>
                      </PlanCard>
                    </Grid>
                ))}
              </>
            )}
            {!userPlans.length && (
              <Typography variant="body1" color="text.secondary">
                No plans available.
              </Typography>
            )}
            {/* {userPlans.length && (
            <>
              {userPlans.map((plan) => 
                <Card key={'userPlanId_'+plan.id} sx={{ minWidth: 275, marginBottom: 2 }}>
                  <CardContent>
                    <Typography variant="h6" gutterBottom>
                      {plan.name}
                    </Typography>
                    <Typography variant="body1" color="text.secondary" sx={{ whiteSpace: 'pre-line' }} gutterBottom>
                      {plan.description}
                    </Typography>
                    <List dense>
                      <ListItem>
                        <ListItemText primary={`Price: $${plan.price.toFixed(2)}`} />
                      </ListItem>
                    </List>
                    <Button
                      variant={selectedPlan === plan.id ? 'contained' : 'outlined'}
                      color="primary"
                      onClick={() => handleSelectPlan(plan.id)}
                      sx={{ mt: 2 }}
                    >
                      {selectedPlan === plan.id ? 'Selected' : 'Select'}
                    </Button>
                  </CardContent>
                </Card>
              )}
            </>
          )} */}
          </Grid>
        </Grid>

        <Grid item sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
          <Button onClick={onBack} variant="outlined">
            Back
          </Button>
          <Button
            type="submit"
            variant="contained"
            disabled={!selectedPlan}
            onClick={onNext}
            color="primary"
            sx={{ ml: 2 }}
          >
            Save and Continue
          </Button>
        </Grid>
      </Grid>
    </>
  )
}

export default PricingTiers


// import React, { useState } from 'react';
// import { Box, Button, Typography, Card, CardContent, CardActions } from '@mui/material';
// import { styled } from '@mui/material/styles';
// import { LucidAccount, UserAccount } from 'businessObjects';
// import { useGetUserPlans } from 'hooks/subscriptionHooks';

// type Props = {
//   onNext: () => void
//   onBack: () => void
//   lucidAccount: LucidAccount
//   setLucidAccount: React.Dispatch<React.SetStateAction<LucidAccount>>
//   userAccount: UserAccount
//   setUserAccount: React.Dispatch<React.SetStateAction<UserAccount>>
// }

// const PlanCard = styled(Card)(({ theme }) => ({
//   textAlign: 'center',
//   border: `1px solid ${theme.palette.divider}`,
//   borderRadius: theme.shape.borderRadius,
// }));

// const Price = styled(Typography)(({ theme }) => ({
//   color: theme.palette.primary.main,
//   fontSize: '2rem',
//   margin: theme.spacing(2, 0),
// }));

// const OldPrice = styled(Typography)(({ theme }) => ({
//   color: theme.palette.text.disabled,
//   textDecoration: 'line-through',
// }));

// const EarlyBird = styled(Typography)(({ theme }) => ({
//   color: theme.palette.success.main,
//   fontSize: '0.8rem',
// }));

// const PricingTiers = ({ onNext, onBack, lucidAccount, setLucidAccount, userAccount, setUserAccount }: Props) => {
//   const { userPlans } = useGetUserPlans()
//   const [selectedPlan, setSelectedPlan] = useState<number | undefined>(userAccount?.planId)

//   const handleSelectPlan = (planId: number) => {
//     setSelectedPlan(planId)
//     setUserAccount({ ...userAccount, planId })
//   }
  
//   return (
//     <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
//       <Box display="flex" justifyContent="space-around" width="100%" maxWidth={1200}>
//         {/* Community Plan */}
//         <PlanCard>
//           <CardContent>
//             <Typography variant="h6" gutterBottom>
//               Community
//             </Typography>
//             <Typography variant="body2" color="textSecondary">
//               Get started with the industry-standard React UI library, MIT-licensed.
//             </Typography>
//             <Price>$0</Price>
//             <Typography variant="body2">Free forever!</Typography>
//           </CardContent>
//           <CardActions>
//             <Button variant="contained" color="primary" fullWidth>
//               Get started
//             </Button>
//           </CardActions>
//         </PlanCard>
//       </Box>
//     </Box>
//     // <Box display="flex" justifyContent="center" alignItems="center" mt={5}>
//     //   <Box display="flex" justifyContent="space-around" width="100%" maxWidth={1200}>
//     //     {/* Community Plan */}
//     //     <PlanCard>
//     //       <CardContent>
//     //         <Typography variant="h6" gutterBottom>
//     //           Community
//     //         </Typography>
//     //         <Typography variant="body2" color="textSecondary">
//     //           Get started with the industry-standard React UI library, MIT-licensed.
//     //         </Typography>
//     //         <Price>$0</Price>
//     //         <Typography variant="body2">Free forever!</Typography>
//     //       </CardContent>
//     //       <CardActions>
//     //         <Button variant="contained" color="primary" fullWidth>
//     //           Get started
//     //         </Button>
//     //       </CardActions>
//     //     </PlanCard>

//     //     {/* Pro Plan */}
//     //     <PlanCard>
//     //       <CardContent>
//     //         <Typography variant="h6" gutterBottom>
//     //           Pro
//     //         </Typography>
//     //         <Typography variant="body2" color="textSecondary">
//     //           Best for professional developers building enterprise or data-rich applications.
//     //         </Typography>
//     //         <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
//     //           <Typography variant="body2" color="textSecondary">
//     //             Perpetual
//     //           </Typography>
//     //           <Box mx={1}>
//     //             <Typography variant="body2" color="textSecondary">
//     //               /
//     //             </Typography>
//     //           </Box>
//     //           <Typography variant="body2" color="textSecondary">
//     //             Annual
//     //           </Typography>
//     //         </Box>
//     //         <Price>$15</Price>
//     //         <Typography variant="body2">
//     //           Billed annually at $180/dev. No additional fee beyond 10 devs.
//     //         </Typography>
//     //       </CardContent>
//     //       <CardActions>
//     //         <Button variant="contained" color="primary" fullWidth>
//     //           Buy now
//     //         </Button>
//     //       </CardActions>
//     //     </PlanCard>

//     //     {/* Premium Plan */}
//     //     <PlanCard>
//     //       <CardContent>
//     //         <Typography variant="h6" gutterBottom>
//     //           Premium
//     //         </Typography>
//     //         <Typography variant="body2" color="textSecondary">
//     //           The most advanced features for data-rich applications, as well as the highest priority for support.
//     //         </Typography>
//     //         <Box display="flex" justifyContent="center" alignItems="center" mt={1}>
//     //           <Typography variant="body2" color="textSecondary">
//     //             Perpetual
//     //           </Typography>
//     //           <Box mx={1}>
//     //             <Typography variant="body2" color="textSecondary">
//     //               /
//     //             </Typography>
//     //           </Box>
//     //           <Typography variant="body2" color="textSecondary">
//     //             Annual
//     //           </Typography>
//     //         </Box>
//     //         <OldPrice>$49</OldPrice>
//     //         <Price>$37</Price>
//     //         <Typography variant="body2">
//     //           Billed annually at $444/dev.
//     //         </Typography>
//     //         <EarlyBird>
//     //           🐦 Early bird special (25% off).
//     //         </EarlyBird>
//     //       </CardContent>
//     //       <CardActions>
//     //         <Button variant="contained" color="primary" fullWidth>
//     //           Buy now
//     //         </Button>
//     //       </CardActions>
//     //     </PlanCard>
//     //   </Box>
//     // </Box>
//   );
// };

// export default PricingTiers;
