import React, { useState, useEffect } from 'react'
import { TextField, Button, Typography, Container, Grid } from '@mui/material'
import { useLucidAuthContext } from 'LucidAuthContext/LucidAuthContext'
import {
  useAddVnetConfiguration,
  useGetVnetConfiguration,
  useUpdateVnetConfiguration,
} from 'hooks/vnetConfiguraitonHooks'
import { VnetConfig } from 'businessObjects'
import TextFormInput from 'ui-components/TextForm/TextFormInput'
import ActionPage from 'ui-components/ActionPage'
import ResultStatus from 'ui-components/ResultStatus'
import { PreviousPage } from 'ui-components/Button/PreviousPage'
import { generatePath, useNavigate } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'

const VnetConfiguration = () => {
  const { accountId } = useLucidAuthContext()
  const { getVnetConfiguration } = useGetVnetConfiguration()
  const { updateVnetConfiguration } = useUpdateVnetConfiguration()
  const { addVnetConfiguration } = useAddVnetConfiguration()

  const [successMessage, setSuccessMessage] = useState<string>()
  const [successState, setSuccessState] = useState<boolean>(false)

  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)
  const navigate = useNavigate()


  const [formData, setFormData] = useState<VnetConfig>({
    id: 0,
    accountId: accountId || '',
    subscriptionId: '',
    resourceGroupName: '',
    vnetname: '',
    vnetaddress: '',
  })

  useEffect(() => {
    const fetchData = async () => {
      if (accountId) {
        const config = await getVnetConfiguration(accountId)

        if (config) {
          setFormData({ ...config })
        } else {
          setErrorState(true)
          setErrorMessage('Failed to get configuration.')
        }
      }
    }
    fetchData()
  }, [accountId])

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    const apiCall = formData.id ? updateVnetConfiguration : addVnetConfiguration

    const response = await apiCall(formData)

    if (formData.id && response) {
      setSuccessState(true)
      setSuccessMessage('Updated Successfully.')
    } else if (!formData.id && response) {
      setFormData(response)
      setSuccessState(true)
      setSuccessMessage('Added Successfully.')
    } else if (formData.id && !response) {
      setErrorState(true)
      setErrorMessage('Failed to update configuration.')
    } else if (!formData.id && !response) {
      setErrorState(true)
      setErrorMessage('Failed to add configuration.')
    }
  }

  return (
    <ActionPage>
      {successMessage && (
        <ResultStatus
          severtiy="success"
          showStatus={successState}
          closeStatus={setSuccessState}
          alertMessage={successMessage}
        />
      )}
      {errorMessage && (
        <ResultStatus
          severtiy="error"
          showStatus={errorState}
          closeStatus={setErrorState}
          alertMessage={errorMessage}
        />
      )}
      <Grid item xs={3} mb={3}>
        <PreviousPage onClick={() => navigate(generatePath(ROUTES.Settings))} pageName={'Back'} />
      </Grid>
      <Container>
        <Typography variant="h4" gutterBottom>
          {formData.id ? 'Edit VNet' : 'Add VNet'}
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              {/* Subscription ID */}
              <TextFormInput
                fullWidth
                required
                labelText="Subscription ID"
                value={formData.subscriptionId}
                // validateErrorText={!formData.organizationName ? 'This field is required' : ''}
                changeHandle={(value) => setFormData({ ...formData, subscriptionId: value })}
              />
            </Grid>
            <Grid item xs={12}>
              {/* ResourceGroup Name */}
              <TextFormInput
                fullWidth
                required
                labelText="ResourceGroup Name"
                value={formData.resourceGroupName}
                // validateErrorText={!formData.organizationName ? 'This field is required' : ''}
                changeHandle={(value) => setFormData({ ...formData, resourceGroupName: value })}
              />
            </Grid>
            <Grid item xs={12}>
              {/* VNET Name */}
              <TextFormInput
                fullWidth
                required
                labelText="VNET Name"
                value={formData.vnetname}
                // validateErrorText={!formData.organizationName ? 'This field is required' : ''}
                changeHandle={(value) => setFormData({ ...formData, vnetname: value })}
              />
            </Grid>
            <Grid item xs={12}>
              {/* VNET Address */}
              <TextFormInput
                fullWidth
                required
                labelText="VNET Address"
                value={formData.vnetaddress}
                // validateErrorText={!formData.organizationName ? 'This field is required' : ''}
                changeHandle={(value) => setFormData({ ...formData, vnetaddress: value })}
              />
            </Grid>
          </Grid>
          <Button type="submit" variant="contained" color="primary" style={{ marginTop: '20px' }}>
            {formData.id ? 'Update' : 'Save'}
          </Button>
        </form>
      </Container>
    </ActionPage>
  )
}

export default VnetConfiguration
