import { DataTableBody } from '../businessObjects'

import { getAxiosRequestConfig } from './apiserviceHelpers'
import { sendRequest } from '../requestManager/sendRequest'

const dataTablePath = {
  createDataTable: 'DataTable/addnew',
  getAllDataTables: 'DataTable/Get',
  getDataTablesByName: 'DataTable/GetByName',
  getDataTablesById: 'DataTable/GetById',
  updateDataTable: 'DataTable/Update',
}

export const createDataTable = async (token: string, dataAttribute: DataTableBody, datapodId: string) => {
  return sendRequest(dataTablePath.createDataTable + `?datapodID=${datapodId}`, 'POST', token, dataAttribute)
}
export const getAllDataTables = async (
  token: string,
  dataPodID?: string,
  dataSystemID?: number,
  searchText?: string,
  dataSystemType?: number,
) => {
  let url = dataTablePath.getAllDataTables
  if (dataPodID && dataSystemID) {
    url += `?dataPodID=${dataPodID}&dataSystemID=${dataSystemID}`
  } else if (dataPodID) {
    url += `?dataPodID=${dataPodID}`
  } else if (dataSystemID) {
    url += `?dataSystemID=${dataSystemID}`
  }
  if (searchText) {
    url += `&searchText=${searchText}`
  }
  if (dataSystemType) {
    url += `&dataSystemType=${dataSystemType}`
  }
  return sendRequest(url, 'GET', token)
}

export const getDataTablesByName = async (token: string, dataTableName?: string, dataPodId?: number) => {
  const config = getAxiosRequestConfig(token)
  let url = dataTablePath.getAllDataTables
  if (dataTableName && dataPodId) {
    url += `?dataTableName=${dataTableName}&dataPodId=${dataPodId}`
  } else if (dataTableName) {
    url += `?dataTableName=${dataTableName}`
  } else if (dataPodId) {
    url += `?dataPodId=${dataPodId}`
  }
  return sendRequest(url, 'GET', token)
}
export const getDataTablesById = async (token: string, dataTableId: number) => {
  return sendRequest(dataTablePath.getDataTablesById + `?dataTableId=${dataTableId}`, 'GET', token)
}

const getDataTableByPodIdAndDataSystemId = async (
  token: string,
  dataPodId?: string,
  dataSystemID?: number,
  searchText?: string,
) => {
  let url = dataTablePath.getAllDataTables
  if (dataPodId && dataSystemID) {
    url += `?dataPodId=${dataPodId}&dataSystemID=${dataSystemID}`
  } else if (dataPodId) {
    url += `?dataPodId=${dataPodId}`
  }
  if (searchText) {
    url += `&searchText=${searchText}`
  }
  return sendRequest(url, 'GET', token)
}

const updateDataTable = async <T>(token: string, dataPodId: string, updateTableBody: T) => {
  return sendRequest(dataTablePath.updateDataTable + `?dataPodId=${dataPodId}`, 'PUT', token, updateTableBody)
}

export { getDataTableByPodIdAndDataSystemId }

export const dataTableServices = {
  updateDataTable,
}
