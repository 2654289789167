import { useEffect, useState } from "react";
import { AxiosError } from "axios";

import { EnterprisePlans, UserPlans } from "businessObjects";
import { useLucidAuthContext } from "LucidAuthContext/LucidAuthContext";
import { subscriptionServices } from "apiServices/subscription";

export const useGetEnterprisePlans = () => {
  const [enterprisePlans, setEnterprisePlans] = useState<EnterprisePlans[]>([]);
  const {getBearerToken} = useLucidAuthContext(); 

  const getEnterprisePlans  = async (): Promise<void> => {
    try {
      const bearerToken = await getBearerToken();
      if (bearerToken ) {
          const {data, status, headers} = await subscriptionServices.getEnterprisePlans(bearerToken);
          
          if(status == 200) {
            setEnterprisePlans(data);
          }
      }
    } catch (error) {
        const err = error as AxiosError;
        console.log(err.message);
    }
  }

  useEffect(() => {
    if(!enterprisePlans.length) {
      getEnterprisePlans();
    }
  }, [])

  return {enterprisePlans}
}

export const useAddAccountSubscription = () : {
  addAccountSubscription: (accountId: string, planId: number) => Promise<number | undefined>
} => {
  const {getBearerToken} = useLucidAuthContext(); 

  const addAccountSubscription  = async (accountId: string, planId: number): Promise<number | undefined> => {
    try {
      const bearerToken = await getBearerToken();
      if (bearerToken ) {
          const {data, status, headers} = await subscriptionServices.addAccountSubscription(bearerToken, accountId, planId);
          
          if(status == 200) {
            return data;
          }
      }
    } catch (error) {
        const err = error as AxiosError;
        console.log(err.message);
    }
  }

  return {addAccountSubscription}
}

export const useGetUserPlans = () => {
  const [userPlans, setUserPlans] = useState<UserPlans[]>([]);
  const {getBearerToken} = useLucidAuthContext(); 

  const getUserPlans  = async (): Promise<void> => {
    try {
      const bearerToken = await getBearerToken();
      if (bearerToken ) {
          const {data, status, headers} = await subscriptionServices.getUserPlans(bearerToken);
          
          if(status == 200) {
            setUserPlans(data);
          }
      }
    } catch (error) {
        const err = error as AxiosError;
        console.log(err.message);
    }
  }

  useEffect(() => {
    if(!userPlans.length) {
      getUserPlans();
    }
  }, [])

  return {userPlans}
}

export const useAddUserSubscription = () : {
  addUserSubscription: (userId: number, planId: number) => Promise<number | undefined>
} => {
  const {getBearerToken} = useLucidAuthContext(); 

  const addUserSubscription  = async (userId: number, planId: number): Promise<number | undefined> => {
    try {
      const bearerToken = await getBearerToken();
      if (bearerToken ) {
          const {data, status, headers} = await subscriptionServices.addUserSubscription(bearerToken, userId, planId);
          
          if(status == 200) {
            return data;
          }
      }
    } catch (error) {
        const err = error as AxiosError;
        console.log(err.message);
    }
  }

  return {addUserSubscription}
}