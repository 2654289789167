import {
  Grid,
  IconButton,
  Paper,
  ToggleButton,
  ToggleButtonGroup,
  styled,
  toggleButtonGroupClasses,
} from '@mui/material'
import TableRowsOutlinedIcon from '@mui/icons-material/TableRowsOutlined'
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined'
import React from 'react'

import CodeOutlinedIcon from '@mui/icons-material/CodeOutlined'
import TocOutlinedIcon from '@mui/icons-material/TocOutlined'

export type ToggleEditorType = 'Code' | 'Form'
type Props = {
  viewType: ToggleEditorType
  handleToggle: (v: ToggleEditorType) => void
  disabledForm?:boolean
}
const StyledToggleButtonGroup = styled(ToggleButtonGroup)(({ theme }) => ({
  [`& .${toggleButtonGroupClasses.grouped}`]: {
    margin: theme.spacing(0.4),
    border: 0,
    // backgroundColor: theme.palette.grey[700],
    borderRadius: theme.shape.borderRadius,
    [`&.${toggleButtonGroupClasses.disabled}`]: {
      border: -1,
    },
    ['&.Mui-selected']: {
      backgroundColor: theme.palette.background.paper,
      width: '34px',
      height: '24px',
    },
  },
  [`& .${toggleButtonGroupClasses.middleButton},& .${toggleButtonGroupClasses.lastButton}`]: {
    marginLeft: -1,
    borderLeft: '1px solid transparent',
  },
}))

const ToggleEditor: React.FC<Props> = ({ viewType, handleToggle, disabledForm }: Props) => {
  // const [isTableView, setIsTableView] = useState<ViewType>('Table')

  return (
    <Grid item xs={0}>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          border: (theme) => `0.1px  ${theme.palette.divider}`,
          // flexWrap: 'wrap',
          backgroundColor: (theme) => `${theme.palette.grey[300]}`,
          // boxSizing: (theme) => `${theme.spacing(0)}`
        }}
      >
        <StyledToggleButtonGroup
          size="small"
          value={viewType}
          fullWidth
          exclusive
          onChange={(_, value) => value && handleToggle(value as ToggleEditorType)}
          aria-label="toggle-editor"
          color={'primary'}
          sx={{ width: '78px', height: '30px' }}
        >
          {/* <IconButton title="list" color={isTableView ? 'primary' : 'default'} onClick={viewTable}>
            <TableRowsOutlinedIcon />
          </IconButton> */}
          <ToggleButton disabled={disabledForm} value="Form" title='Form' aria-label="table view">
            <TocOutlinedIcon fontSize="small" />
          </ToggleButton>
          <ToggleButton value="Code" title="Code Editor" aria-label="table view">
            <CodeOutlinedIcon fontSize="small" />
          </ToggleButton>
          {/* <IconButton title="grid view" color={!isTableView ? 'primary' : 'default'} onClick={viewGrid}>
            <GridViewOutlinedIcon />
          </IconButton> */}
        </StyledToggleButtonGroup>
      </Paper>
    </Grid>
  )
}

export default ToggleEditor
