import { sendRequest } from '../requestManager/sendRequest'

const applicationPath = {
  dataEntityRelationships: 'Application/getEntityRelationShips',
  getEnrichedEntityRelationships: 'Application/getEnrichedEntityRelationShips',
  dataTableRelationships: 'Application/GetdataTableRelationships',
  newTableRelationships: 'Application/AddDataTableRelationships',
  addMultipleEntityRelationships: 'Application/AddMultipleEntityRelationships',
  newEntityRelationship: 'Application/AddEntityRelationship',
  updateEntityRelationship: 'Application/UpdateEntityRelationship',
  updateTableRelationship: 'Application/UpdateDataTableRelationships',
  entityReconPath: 'Application/GetEntityRecon',
  addEntityRecon: 'Application/AddEntityRecon',
  updateEntityRecon: 'Application/UpdateEntityRecon',
  deleteEntityRecon: 'Application/DeleteEntityRecon',
  deleteEntityRelation: 'Application/DeleteDataCoreEntityRelationShip',
}
const getEntityRelationships = async (
  token: string,
  datapodID?: string,
  searchText?: string,
  records?: number,
  pageNumber?: number,
) => {
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  const queryParams = []
  if (datapodID) {
    queryParams.push(`datapodID=${datapodID}`)
  }

  if (searchText) {
    queryParams.push(`searchText=${searchText}`)
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
  const url = applicationPath.dataEntityRelationships + queryString
  return await sendRequest(paginationParams ? url + paginationParams : url, 'GET', token)
}

const getEnrichedEntityRelationships = async (
  token: string,
  datapodID: string,
  enrichedEntityId: number
) => {
  const queryParams = []
  if (datapodID) {
    queryParams.push(`datapodID=${datapodID}`)
  }

  if (enrichedEntityId) {
    queryParams.push(`enrichedEntityId=${enrichedEntityId}`)
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
  const url = applicationPath.getEnrichedEntityRelationships + queryString
  return await sendRequest(url, 'GET', token)
}

const getTableRelationShips = async (token: string, datapodID?: string, searchText?: string) => {
  let urlEndPoint = applicationPath.dataTableRelationships
  if (datapodID && !searchText) {
    urlEndPoint += `?dataPodID=${datapodID}`
  }
  if (datapodID && searchText) {
    urlEndPoint += `?dataPodID=${datapodID}&searchText=${searchText}`
  }

  return await sendRequest(urlEndPoint, 'GET', token)
}

const addNewTableRelationship = async <T>(token: string, dataPodId: string, params: T) => {
  const urlEndPoint = applicationPath.newTableRelationships + `?datapodId=${dataPodId}`
  return await sendRequest(urlEndPoint, 'POST', token, params)
}
const updateTableRelationship = async <T>(token: string, dataPodId: string, params: T) => {
  const urlEndPoint = applicationPath.updateTableRelationship + `?datapodId=${dataPodId}`
  return await sendRequest(urlEndPoint, 'PUT', token, params)
}

const getEntityReconciliation = async <T>(
  token: string,
  dataPodID: string,
  dataCoreEntityId?: number,
  dataSytemEntityId?: number,
  params?: T,
) => {
  let urlEndPoint = applicationPath.entityReconPath + `?dataPodId=${dataPodID}`
  if (dataCoreEntityId) {
    urlEndPoint += `&dataCoreEntityId=${dataCoreEntityId}`
  }
  if (dataSytemEntityId) {
    urlEndPoint += `&dataSytemEntityId=${dataSytemEntityId}`
  }

  return await sendRequest(urlEndPoint, 'GET', token, params)
}

const addEntityReconciliation = async <T>(token: string, dataPodId: string, params?: T) => {
  let urlEndPoint = applicationPath.addEntityRecon + `?dataPodId=${dataPodId}`

  return await sendRequest(urlEndPoint, 'PUT', token, params)
}

const addMultipleEntityRelationships = async <T>(token: string, dataPodId: string, params?: T) => {
  let urlEndPoint = applicationPath.addMultipleEntityRelationships + `?dataPodId=${dataPodId}`

  return await sendRequest(urlEndPoint, 'POST', token, params)
}

const addEntityRelationShip = async <T>(token: string, dataPodId: string, params?: T) => {
  let urlEndPoint = applicationPath.newEntityRelationship + `?dataPodId=${dataPodId}`

  return await sendRequest(urlEndPoint, 'POST', token, params)
}

const updateEntityRelationShip = async <T>(token: string, dataPodId: string, params?: T) => {
  let urlEndPoint = applicationPath.updateEntityRelationship + `?dataPodId=${dataPodId}`

  return await sendRequest(urlEndPoint, 'PUT', token, params)
}

const updateEntityReconciliation = async <T>(token: string, dataPodId: string, params?: T) => {
  let urlEndPoint = applicationPath.updateEntityRecon + `?datapodId=${dataPodId}`

  return await sendRequest(urlEndPoint, 'PUT', token, params)
}

const getCustomEnrichedEntityRelationships = async (
  token: string,
  datapodID: string,
  entityId?: number[],
  searchText?: string,
  records?: number,
  pageNumber?: number,
) => {
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  const queryParams = []
  if (datapodID) {
    queryParams.push(`datapodID=${datapodID}`)
  }
  if (entityId && entityId.length) {
    entityId?.forEach((id) => {
      queryParams.push(`entityId=${id}`)
    })
  }
  if (searchText) {
    queryParams.push(`searchText=${searchText}`)
  }

  const queryString = queryParams.length > 0 ? `?${queryParams.join('&')}` : ''
  const url = applicationPath.dataEntityRelationships + queryString
  return await sendRequest(paginationParams ? url + paginationParams : url, 'GET', token)
}

const deleteEntityRecon = async (token: string, entityReconIds: number[]) => {
  return await sendRequest(applicationPath.deleteEntityRecon, 'DELETE', token, entityReconIds)
}

const deleteEntityRelationShip = async (token: string, DataCoreEntityRelationShipId: number[]) => {
  return await sendRequest(applicationPath.deleteEntityRelation, 'DELETE', token, DataCoreEntityRelationShipId)
}

export const applicationServices = {
  getEntityRelationships,
  getEnrichedEntityRelationships,
  getTableRelationShips,
  addNewTableRelationship,
  updateTableRelationship,
  getEntityReconciliation,
  addEntityReconciliation,
  updateEntityReconciliation,
  addMultipleEntityRelationships,
  addEntityRelationShip,
  updateEntityRelationShip,
  deleteEntityRelationShip,
  getCustomEnrichedEntityRelationships,
  deleteEntityRecon,
}
