import { useEffect, useMemo, useState } from 'react'
import { useSearchParams, useNavigate, useParams, generatePath } from 'react-router-dom'
import { useGetQueryString } from '../../hooks/queryStringHook'
import { isValidDescription, isValidEntry } from '../../utils/constants'
import ActionPage from '../../ui-components/ActionPage'
import {
  CardHeader,
  Button,
  Card,
  Typography,
  Grid,
  Stack,
  MenuItem,
  TextField,
  Select,
  Skeleton,
  Menu,
  Tooltip,
  Autocomplete,
} from '@mui/material'
import {
  AnalyticsMeasure,
  AnalyticsMeasureType,
  AnalyticsGroup,
  DimensionData,
  OperationState,
  DataAttribute,
  Entity,
  CoreEntity,
} from '../../businessObjects'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import { useGetAnalyticGroups, useGetEntitiesByAnalyticsGroup } from '../../hooks/analyticsGroupHooks'
import {
  useGetAnalyticMeasureByGroupMeasureId,
  useGetAnalyticMeasure,
  useGetGenerateKPI,
  useSynchronizeMeasureDimensions,
  useGetMeasureDimensions,
} from '../../hooks/analyticsMeasureHooks'
import ResultStatus from '../../ui-components/ResultStatus'
import { ROUTES } from '../../Routes/constants'
import GroupEntities from './GroupEntities'
import GenerateMeasureNotebook from './GenerateMeasureNotebook'
import { useGetDataAttributesByMultipleEntityId } from 'hooks/dataAttrbutesHooks'
import { useGetCoreEntityAttributes } from 'hooks/coreEntityAttributeHooks'
import { useGetCoreEntity } from 'hooks/entityHooks'

type ViewResultsParams = {
  dataPodId: string
}

const normalizeMeasureId = (measureId: string | null) => {
  return measureId && measureId.length > 0 ? Number(measureId) : undefined
}

type UpdatedDataAttribute = DataAttribute & {
  dataEntityName: string | undefined | null,
  combinedName: string
}

const AddAnalyticsMeasure = () => {
  const groupId = useGetQueryString('groupId')
  const measureId = useGetQueryString('measureId')
  const isEdit = useGetQueryString('action')
  const { dataPodId } = useParams<ViewResultsParams>()

  const [selectedGroup, selectGroup] = useState<string>('')
  const [measureName, setMeasureName] = useState<string>('')
  const [measureDescription, setMeasureDescription] = useState<string>('')

  const { analyticsGroupList } = useGetAnalyticGroups(dataPodId)

  const { analyticsMeasureList, analyticsGroupMeasureLoading } = useGetAnalyticMeasureByGroupMeasureId(
    dataPodId,
    groupId,
    measureId,
  )

  const [fetchCoreAttributes, coreAttributes] = useGetCoreEntityAttributes(dataPodId)

  const { analyticsMeasureLoading, updateAnalyticsMeasure, addAnalyticsMeasure, postMeasure, measureError } =
    useGetAnalyticMeasure(dataPodId)

  const [selectedAnalyticMeasure, setSelectAnalyticMeasure] = useState<AnalyticsMeasure>()
  const [resultState, setResultState] = useState<OperationState>()

  const navigate = useNavigate()

  const [anchorEl, setAnchorEl] = useState<HTMLInputElement | null>(null)
  const [inputValue, setInputValue] = useState<string>('')
  const [group_Id, setGroup_Id] = useState<string>()

  const [coreEntity, setCoreEntity] = useState<CoreEntity>();

  const { generateKPIResponse, generateKPILoading, generateKPIError, generateKPI } = useGetGenerateKPI(
    dataPodId,
    selectedAnalyticMeasure?.analyticsMeasureId,
    measureDescription.length > 0 ? measureDescription : undefined,
  )

  const { syncDimensionsError, synchronizeDimensions } = useSynchronizeMeasureDimensions(
    dataPodId,
    normalizeMeasureId(measureId),
  )

  const { getDimensionsResponse, getDimensionsError, getDimensionsLoading } = useGetMeasureDimensions(
    dataPodId,
    normalizeMeasureId(measureId),
  )

  const [groupEntityAttributes,setGroupEntityAttributes] = useState<UpdatedDataAttribute[]>([])

  const [coreEntities] = useGetCoreEntity(dataPodId)

  const castStringToDimensionalData = (input: string): DimensionData[] => {
    if (!input.length) return []

    const tempDimData: DimensionData[] = []

    
    if(coreEntity) {
      input.split('@').forEach((element) => {
        if (element.length == 0) {
          return
        }
        element = element.trim()

        const index = tempDimData.findIndex((entity) => entity.entityName === coreEntity.dataEntityName)
  
        if (index == -1) {
          tempDimData.push({ entityName: coreEntity.dataEntityName, attributes: [element] })
        } else {
          tempDimData[index].attributes.push(element)
        }
      })
  
    }
    return tempDimData
  }

  const castDimensionalDataToString = (dimensions: DimensionData[]) => {
    return dimensions.reduce((acc, dim) => {
      let tempAcc = acc
      dim.attributes.forEach((attr) => {
        tempAcc += `@${attr} `
      })

      return tempAcc
    }, '')
  }

  useEffect(() => {
    if(selectedGroup && coreEntities.length && analyticsGroupList.length) {
      const group = analyticsGroupList.find(group => group.id == Number(selectedGroup))
      if(group){
        const entity = coreEntities.find(entity => entity.dataEntityName == group.analyticsGroupName)

        if(entity)
        {
          setCoreEntity(entity)
          fetchCoreAttributes(entity.id)
        }
      }
    }
  }, [selectedGroup, coreEntities, analyticsGroupList])

  useEffect(() => {
    if(coreAttributes)
    {
      if(coreEntity)
      {
        const mappedDimensions = coreAttributes.map(attr => ({
          id: attr.id,
          dataPodId: attr.dataPodId,
          isActive: attr.isActive,
          dataEntityId: attr.dataCoreEntityId,
          dataAttributeName: attr.dataCoreAttributeName,
          dataAttributeDataType: attr.dataCoreAttributeDataType,
          description: attr.description,
          isBusinessKey: attr.isBusinessKey,
          dataClassification: attr.dataCoreClassification,
          dataAttributeType: attr.dataAttributeType,
          dataEntityName: coreEntity.dataEntityName, 
          combinedName: `${coreEntity.dataEntityName}.${attr.dataCoreAttributeName}`
        }));

        setGroupEntityAttributes(mappedDimensions)
      }
    }
  }, [coreAttributes])

  useEffect(() => {
    if (measureError) {
      setResultState({ result: 'error', display: true, message: measureError?.measure })
    } else if (generateKPIError) {
      setResultState({ result: 'error', display: true, message: generateKPIError?.measure })
    } else if (syncDimensionsError) {
      setResultState({ result: 'error', display: true, message: syncDimensionsError?.measure })
    } else if (getDimensionsError) {
      setResultState({ result: 'error', display: true, message: getDimensionsError?.measure })
    }
  }, [measureError, generateKPIError])

  useEffect(() => {
    if (measureId && analyticsMeasureList.length) {
      const { analyticsMeasureName, analyticsMeasureDescription, analyticsGroupId } = analyticsMeasureList[0]
      setSelectAnalyticMeasure(analyticsMeasureList[0])
      selectGroup(analyticsGroupId?.toString())
      setMeasureDescription(analyticsMeasureDescription)
      setMeasureName(analyticsMeasureName)
    }
  }, [analyticsMeasureList])

  useEffect(() => {
    if (groupId && analyticsGroupList.length) {
      selectGroup(groupId)
      setGroup_Id(groupId)
    }
  }, [groupId, analyticsGroupList])

  useEffect(() => {
    if (postMeasure?.id && groupId) {
      setResultState({ display: true, result: 'success', message: 'Added/Updated measure successfully' })
      //below selects mesaure details + group details
      setSelectAnalyticMeasure({ ...selectedAnalyticMeasure, ...postMeasure, analyticsMeasureId: postMeasure?.id })
    }
  }, [postMeasure])

  useEffect(() => {
    if (getDimensionsResponse) {

      const dimensionsString = castDimensionalDataToString(getDimensionsResponse)
      setInputValue(dimensionsString)
    }
  }, [getDimensionsResponse])

  const attributesSuggestion : UpdatedDataAttribute[] = useMemo(() => {
    if (inputValue && groupEntityAttributes.length) {
        const dimensions = castStringToDimensionalData(inputValue);

        return dimensions.flatMap(dimension => 
            groupEntityAttributes.filter(attr => 
                !dimension.attributes.includes(attr.dataAttributeName)
            )
        );
    }

    return [];
  }, [groupEntityAttributes, inputValue]);

  const addNewMeasure = async () => {
    const payload: AnalyticsMeasureType = {
      measureGroupId: parseInt(selectedGroup),
      analyticsMeasureName: measureName,
      analyticsMeasureDescription: measureDescription,
      runId: 0,
      measureQuery: '',
    }
    if (selectedAnalyticMeasure?.analyticsMeasureId) {
      updateAnalyticsMeasure({
        id: selectedAnalyticMeasure?.analyticsMeasureId,
        ...payload,
      })
    } else {
      await addAnalyticsMeasure(payload)
    }

    callSyncDimensions()
  }

  const callSyncDimensions = () => {
    const result = castStringToDimensionalData(inputValue)

    synchronizeDimensions(result)
  }

  const callGenerateKPI = () => {
    const result = castStringToDimensionalData(inputValue)

    if (result) {
      generateKPI(result)
    }
  }

  const onClickBack = () => {
    if (isEdit) {
      let measurePage = generatePath(ROUTES.DataAnalyticsMeasuresResults, {
        dataPodId: dataPodId,
      })

      if (groupId) {
        measurePage = measurePage + '?groupId=' + groupId
      }

      if (measureId) {
        measurePage = measurePage + '&measureId=' + measureId
      }

      navigate(measurePage)
    } else {
      let groupPage = generatePath(ROUTES.DataAnalyticsGroupResults, {
        dataPodId: dataPodId,
      })

      if (groupId) {
        groupPage = groupPage + '?groupId=' + groupId
      }

      navigate(groupPage)
    }
  }

  const handleSelectGroup = (groupVal: any) => {
    selectGroup(groupVal)
  }

  const handleSelectMention3 = (attributeName: string, attributeId: number, description: string, type: string, combinedName:string) => {
    const promptText = inputValue.replace(/@$/, `@${attributeName}`)
    setInputValue(promptText)

    setAnchorEl(null)
  }
  const transformationTextChangeHandler3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setInputValue(value)

    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
  }

  return (
    <ActionPage>
      <Grid xs={3}>
        <Button variant="outlined" onClick={onClickBack} startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}>
          Back
        </Button>
      </Grid>
      <Grid item container xs={14} spacing={2} alignItems={'flex-start'} justifyContent={'center'}>
        <CardHeader
          disableTypography
          title={
            <Stack display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'center'}>
              <Typography variant="h4" color="primary">
                {measureId ? 'Update Analytics Measure' : 'Add Analytics Measure'}
              </Typography>
            </Stack>
          }
        />
      </Grid>
      <ResultStatus
        severtiy={resultState?.result}
        showStatus={!!resultState?.display}
        alertMessage={resultState?.message}
        closeStatus={() => setResultState(undefined)}
      />

      <Grid item container justifyContent={'center'} xs={14} spacing={2}>
        <Grid item xs={12}>
        </Grid>
        <Grid item xs={4}>
          <Card sx={{ p: 2 }}>
            <CardHeader
              sx={{ p: 0, mb: 2 }}
              title={
                <Typography variant="h5">
                  {selectedAnalyticMeasure?.analyticsMeasureId
                    ? 'Update Analytics Measure'
                    : 'Add New Analytics Measure'}
                </Typography>
              }
            />
            {analyticsGroupMeasureLoading ? (
              <>
                <Skeleton variant="rectangular" height={30} sx={{ p: 1, mb: 1 }} />
                <Skeleton variant="rectangular" height={30} sx={{ p: 1, mb: 1 }} />
                <Skeleton variant="rectangular" height={50} sx={{ p: 1, mb: 1 }} />
              </>
            ) : (
              <>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Typography component="span" variant="subtitle1">
                    Measure Group
                  </Typography>
                  <Select
                    fullWidth
                    id="measure-groupId"
                    value={selectedGroup}
                    size="small"
                    onChange={({ target }) => handleSelectGroup(target?.value as any)}
                  >
                    {analyticsGroupList?.map((data: AnalyticsGroup) => (
                      <MenuItem key={data?.id} value={data?.id?.toString()}>
                        {data?.analyticsGroupName}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Typography component="span" variant="subtitle1">
                    Measure (KPI) Name
                  </Typography>
                  <TextField
                    rows={4}
                    fullWidth
                    id="measure-name"
                    variant="outlined"
                    size="small"
                    value={measureName}
                    onChange={({ target }) => setMeasureName(target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Typography component="span" variant="subtitle1">
                    Prompt Text (Measure Description)
                  </Typography>
                  <TextField
                    multiline
                    rows={4}
                    fullWidth
                    id="measure-description"
                    variant="outlined"
                    size="small"
                    value={measureDescription}
                    onChange={({ target }) => setMeasureDescription(target.value)}
                  />
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }}>
                  <Tooltip
                    componentsProps={{
                      tooltip: {
                        sx: {
                          textAlign: 'justify',
                        },
                      },
                    }}
                    title="This measure is calculated against the following dimensions."
                    placement={'right'}
                  >
                    <Typography component="span" variant="subtitle1">
                      Measure Dimensions
                    </Typography>
                  </Tooltip>
                  <>
                    <TextField
                      value={inputValue}
                      helperText={!inputValue ? 'Type @ to mention' : ''}
                      // onChange={handleToggle}
                      placeholder="Please enter the dimensions using the '@' operator or leave the field blank."
                      fullWidth
                      multiline
                      rows={4}
                      inputProps={{ style: { textAlign: 'justify' } }}
                      onChange={transformationTextChangeHandler3}
                    /> 
                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={() => setAnchorEl(null)}
                      PaperProps={{
                        style: {
                          width: '20%',
                        },
                      }}
                    >
                      <Autocomplete
                        options={attributesSuggestion}
                        getOptionLabel={(option) => `${option.dataAttributeName}`}
                        onChange={(event, newValue) => {
                          if (newValue) {
                            handleSelectMention3(
                              newValue.dataAttributeName,
                              newValue.id,
                              newValue.description,
                              newValue.dataAttributeType,
                              newValue.combinedName
                            );
                          }
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Search..."
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        )}
                        renderOption={(props, option) => (
                          <MenuItem
                            {...props}
                            key={option.id}
                            onClick={() => {
                              handleSelectMention3(
                                option.dataAttributeName,
                                option.id,
                                option.description,
                                option.dataAttributeType,
                                option.combinedName
                              );
                            }}
                          >
                            {option.dataAttributeName}
                          </MenuItem>
                        )}
                        style={{ width: '100%' }}
                      />
                    </Menu>
                  </>
                </Grid>
                <Grid item xs={12} sx={{ lineHeight: 3 }} textAlign={'end'}>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={addNewMeasure}
                    disabled={!selectedGroup || !isValidEntry(measureName) || !isValidDescription(measureDescription)}
                  >
                    Save
                  </Button>
                </Grid>
              </>
            )}
          </Card>
        </Grid>

        <Grid item xs={8}>
          <Grid item xs={12}>
            <GenerateMeasureNotebook
              measureId={selectedAnalyticMeasure?.analyticsMeasureId}
              measureName={selectedAnalyticMeasure?.analyticsMeasureName}
              groupId={group_Id}
              measureDescription={measureDescription}
              callSyncDimensions={callSyncDimensions}
              generateKPI={callGenerateKPI}
              generateKPILoading={generateKPILoading}
              generateKPIResponse={generateKPIResponse}
              isEditOrAddMode={true}
            />
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <GroupEntities />
          </Grid>
        </Grid>
      </Grid>
    </ActionPage>
  )
}

export default AddAnalyticsMeasure
