import { useLucidAuthContext } from 'LucidAuthContext/LucidAuthContext'
import { dataLoads } from '../apiServices/dataLoad'
import { useEffect, useState } from 'react'

type DynamicObject = {
  [key: string]: any
}

export const useGetCanonicalData = (dataPodId?: string, dataEntityId?: number) => {
  const { getBearerToken } = useLucidAuthContext()
  const [fetchedCanonicalData, setFetchedCanonicalData] = useState<DynamicObject[]>([])

  const fetchCanonicalDataByDatapodId = async (pageNumber?: number, entityName?: string) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && dataEntityId) {
        const { data, status } = await dataLoads.getCanonicalData(
          bearerToken,
          dataPodId,
          dataEntityId,
          entityName,
          pageNumber,
        )
        if (status === 200 && data.length > 0) {
          // Assuming data.canonicalData is an array of objects with dynamic keys
          setFetchedCanonicalData(data)
        }
      }
    } catch (err) {
      console.log('Error fetching canonical data:', err)
    }
    return false
  }

  useEffect(() => {
    // Example initial fetch with default datapodId
    fetchCanonicalDataByDatapodId()
  }, [dataPodId, dataEntityId])

  return { fetchCanonicalDataByDatapodId, fetchedCanonicalData }
}

export const useGetEnrichedlData = (dataPodId?: string, dataEntityId?: number) => {
  const { getBearerToken } = useLucidAuthContext()
  const [fetchedEnrichedData, setFetchedEnrichedData] = useState<DynamicObject[]>([])

  const fetchEnrichedDataByDatapodId = async (pageNumber?: number, entityName?: string) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && dataEntityId) {
        const { data, status } = await dataLoads.getEnrichedData(
          bearerToken,
          dataPodId,
          dataEntityId,
          entityName,
          pageNumber,
        )
        if (status === 200 && data.length > 0) {
          // Assuming data.canonicalData is an array of objects with dynamic keys
          setFetchedEnrichedData(data)
        }
      }
    } catch (err) {
      console.log('Error fetching canonical data:', err)
    }
    return false
  }

  useEffect(() => {
    // Example initial fetch with default datapodId
    fetchEnrichedDataByDatapodId()
  }, [dataPodId, dataEntityId])

  return { fetchEnrichedDataByDatapodId, fetchedEnrichedData }
}

export const useGetSemanticData = (dataPodId?: string, measureId?: number) => {
  const { getBearerToken } = useLucidAuthContext()
  const [fetchedSemanticData, setFetchedSemanticData] = useState<DynamicObject[]>([])

  const fetchSemanticDataByDatapodId = async (pageNumber?: number, measureName?: string) => {
    try {
      const bearerToken = await getBearerToken()
      if (bearerToken && dataPodId && measureId) {
        const { data, status } = await dataLoads.getSemanticData(
          bearerToken,
          dataPodId,
          measureId,
          measureName,
          pageNumber,
        )
        if (status === 200 && data.length > 0) {
          // Assuming data.canonicalData is an array of objects with dynamic keys
          setFetchedSemanticData(data)
        }
      }
    } catch (err) {
      console.log('Error fetching canonical data:', err)
    }
    return false
  }

  useEffect(() => {
    // Example initial fetch with default datapodId
    fetchSemanticDataByDatapodId()
  }, [dataPodId, measureId])

  return { fetchSemanticDataByDatapodId, fetchedSemanticData }
}
