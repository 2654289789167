import { Card, CardHeader, Grid, Typography, CardContent, Button } from '@mui/material'
import React, { useMemo } from 'react'
import { generatePath, useNavigate } from 'react-router-dom'
import TaskAltIcon from '@mui/icons-material/TaskAlt'
import { ROUTES } from '../../Routes/constants'

type DataProcessCardProps = {
  headingText: React.ReactNode
  //getJobStatus: (id: number) => void
  dataPodId?: string
  type?:
    | 'Data Mapping'
    | 'Data Profiling'
    | 'Data Modelling'
    | 'Data Engineering'
    | 'Data Lakes'
    | 'Analytics Group Results'
    | 'Analytics Measures Results'
    | 'Data Lake Results'
    | 'Data Quality Recommendations'
    | 'Generative Integrations'
    | 'Generate Entity Relationships'
    | 'Generate Enterprise Data Models'
    | 'Entity Reconciliation'
    | 'Data Flow'
    | 'Canonical Model'
    | 'Generative Integration Source To Target'
    | 'Source Canonical Model'
    | 'Source to Target Mapping'
    | 'Target Canonical Model'
    | 'Data Engineering Pipeline Schedules'
    | 'Data Engineering Pipeline Monitoring'
    | 'Add Service Connection'
    | 'List Service Connection'
  dataSystemID?: number
  labelType: 'DataPod' | 'Data System'
  subLabel?: string
  //currentJobTriggerResp: JobTriggerResp
  disableActionButtons?: boolean
}

const DataProcessCard = ({
  headingText,
  subLabel,
  dataPodId,
  disableActionButtons,
  labelType,
  type,
  dataSystemID,
}: DataProcessCardProps) => {
  const navigate = useNavigate()
  const onClick = () => {
    let resultsRoute = ''
    switch (type) {
      case 'Canonical Model':
        resultsRoute = generatePath(ROUTES.DataModelingCanonicalModel, {
          dataPodId: dataPodId,
        })
        break
      case 'Data Quality Recommendations':
        resultsRoute = generatePath(ROUTES.DataQualityRecommendations, {
          dataPodId: dataPodId,
        })
        break
      case 'Source Canonical Model':
        resultsRoute =
          generatePath(ROUTES.DataModelingCanonicalModel, {
            dataPodId: dataPodId,
          }) + `?typeId=1`
        break
      case 'Target Canonical Model':
        resultsRoute =
          generatePath(ROUTES.DataModelingCanonicalModel, {
            dataPodId: dataPodId,
          }) + `?typeId=2`
        break
      case 'Entity Reconciliation':
        resultsRoute = generatePath(ROUTES.DataModelingEntityReconciliation, {
          dataPodId: dataPodId,
        })
        break

      case 'Generate Enterprise Data Models':
        resultsRoute = generatePath(ROUTES.DataModelingEnterpriseModels, {
          dataPodId: dataPodId,
        })
        break
      case 'Generate Entity Relationships':
        resultsRoute = generatePath(ROUTES.DataModelingEntityRelationships, {
          dataPodId: dataPodId,
        })
        break
      case 'Generative Integration Source To Target':
        resultsRoute =
          generatePath(ROUTES.SourceToTargetMapping, {
            dataPodId: dataPodId,
          }) + `?typeId=1`
        break
      case 'Data Mapping':
        resultsRoute = generatePath(ROUTES.DataMappingResults, {
          dataPodId: dataPodId,
          dataSystemID: dataSystemID,
        })
        break
      case 'Data Profiling':
        resultsRoute = generatePath(ROUTES.DataProfilingViewResults, {
          dataPodId: dataPodId,
          dataSystemID: dataSystemID,
        })
        break
      case 'Data Engineering Pipeline Schedules':
        resultsRoute = generatePath(ROUTES.DataEngineeringPipelineSchedules, {
          dataPodId: dataPodId,
        })
        break
      case 'Data Engineering Pipeline Monitoring':
        resultsRoute = generatePath(ROUTES.DataEngineeringPipelineMonitoring, {
          dataPodId: dataPodId,
        })
        break
      case 'Data Lake Results':
        resultsRoute = generatePath(ROUTES.DataLakeResults, {
          dataPodId: dataPodId,
        })
        break
      case 'List Service Connection':
        resultsRoute = generatePath(ROUTES.ListServiceConnection, {})
        break
      case 'Analytics Group Results':
        resultsRoute = generatePath(ROUTES.DataAnalyticsGroupResults, {
          dataPodId: dataPodId,
        })
        break
      case 'Analytics Measures Results':
        resultsRoute = generatePath(ROUTES.DataAnalyticsMeasuresResults, {
          dataPodId: dataPodId,
        })
        break
      default:
        break
    }
    navigate(resultsRoute)
  }

  const disableButton = useMemo(() => {
    const disFlag = labelType === 'DataPod' ? dataPodId : dataSystemID
    return !disFlag || disableActionButtons
  }, [labelType, dataPodId, dataSystemID, disableActionButtons])

  return (
    <Card sx={{ backgroundColor: 'white', boxShadow: 3, borderRadius: 3, maxWidth: 350 }}>
      <CardHeader
        sx={{ mb: -2 }}
        disableTypography
        title={
          <Grid container justifyItems={'center'} alignContent={'baseline'} direction={'row'}>
            <Grid item xs={12}>
              <Typography gutterBottom variant="h4">
                {headingText}
              </Typography>
            </Grid>
          </Grid>
        }
      />
      <CardContent>
        <Grid item xs={14} container spacing={2}>
          <Grid item xs={12} container>
            <Grid item xs={6}>
              <Typography variant="h5">{labelType === 'Data System' ? 'Data Source name' : 'Data Pod name'}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="body1">{subLabel ?? ''}</Typography>
            </Grid>
          </Grid>
          <Grid item xs={12} container spacing={1}>
            <Grid item xs={8}>
              <Button
                variant="contained"
                onClick={onClick}
                disabled={disableButton}
                startIcon={<TaskAltIcon />}
                title="View Details"
              >
                <Typography variant="caption">{'View Details'}</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

export default DataProcessCard
