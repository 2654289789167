import { sendGenApiRequest } from '../requestManager/sendGenApiRequest'

type DataProfilingBody = {
  dataPodId: string
  dataSystemId: number
  dataTableId?: number
}

type CanonicalBody = {
  dataPodId: string
  dataEntityId: number
}

const dataPodPaths = {
  generatemappings: 'GenerateMappings',
  DataProfiling: 'DataProfiling',
  DataTableScan: 'DataTableScan',
  LoadCanonical: 'LoadCanonical',
  MappingByColumn:'GenerateMappingsByColumn'
}

const generateMappings = async (dataPodId: string, dataSystemID: number, dataTableID: number, token?: string) => {
  const cmBody = {
    dataPodId: dataPodId,
    dataSystemId: dataSystemID,
    dataTableId: dataTableID,
  }
  return await sendGenApiRequest(dataPodPaths.generatemappings, 'POST', token ?? 'Lucid123', cmBody)
}

//DataProfiling

const getNotebookStringBySystemId = async (body: DataProfilingBody, token?: string) => {
  return await sendGenApiRequest(dataPodPaths.DataProfiling, 'POST', token ?? 'Lucid123', body)
}

const getNotebookStringBySystemIdAndTableId = async (body: DataProfilingBody, token?: string) => {
  return await sendGenApiRequest(dataPodPaths.DataTableScan, 'POST', token ?? 'Lucid123', body)
}

const loadCanonicalNotebookStringByEntityId = async (body: CanonicalBody, token?: string) => {
  return await sendGenApiRequest(dataPodPaths.LoadCanonical, 'POST', token ?? 'Lucid123', body)
}
const generateMappingByColumn = async (body: DataProfilingBody, token?: string) => {
  return await sendGenApiRequest(dataPodPaths.MappingByColumn, 'POST', token ?? 'Lucid123', body)
}

export const genServices = {
  generateMappings,
  getNotebookStringBySystemId,
  getNotebookStringBySystemIdAndTableId,
  loadCanonicalNotebookStringByEntityId,
  generateMappingByColumn
}
