import { AddDataHubEntityMap, createDataHubBody, updateDataHubBody } from 'businessObjects'
import { sendRequest } from '../requestManager/sendRequest'

const dataHubsPath = {
  createDataHub: 'DataHub/CreateDataHubs',
  getDataHubs: 'DataHub/GetDataHubs',
  updateDataHubs: 'DataHub/UpdateDataHubs',
  deleteDataHubs: 'Industry/',
  createDataHubEntityMaps:'DataHub/CreateDataHubEntityMaps',
  getDataHubEntityMaps:'DataHub/GetDataHubEntityMaps',
  getDataHubEntityAttributes:'DataHub/GetDataHubAttributes'
}

const createDataHub = async (token: string, body: createDataHubBody[]) => {
  return sendRequest(dataHubsPath.createDataHub, 'POST', token, body)
}

const getAllDataHubs = async (
  token: string,
  dataHubId?: string,
  accountId?: string,
  records?: number,
  pageNumber?: number,
  // searchText?: string,
) => {
  const paginationParams = records && pageNumber ? `&RecordsPerPage=${records}&PageNumber=${pageNumber}` : null
  let endpoint = dataHubsPath.getDataHubs
  if (dataHubId && accountId) {
    endpoint += `?id=${dataHubId}&accountId=${accountId}`
  } else if (accountId) {
    endpoint += `?accountId=${accountId}`
  }
  const finalEndpoint = paginationParams ? endpoint + paginationParams : endpoint
  return sendRequest(finalEndpoint, 'GET', token)
}

const getIndustryById = async (token: string, industryId: number) => {
  return sendRequest(dataHubsPath.updateDataHubs + industryId, 'GET', token)
}

const updateDataHub = async(token:string,body:updateDataHubBody[]) =>{
  return sendRequest(dataHubsPath.updateDataHubs, 'PUT', token, body)
}

const addDataHubEntityMaps = async(token:string, body:AddDataHubEntityMap[]) =>{
  return sendRequest(dataHubsPath.createDataHubEntityMaps, 'POST', token, body)
}

const getDataHubEntityMaps = async(
  token:string,
  hubMapId?:number,
  dataHubId?:string,
  dataEntityId?:number,
  dataPodId?:string
) =>{
  let endpoint = dataHubsPath.getDataHubEntityMaps
  if (dataHubId && hubMapId) {
    endpoint += `?id=${hubMapId}&dataHubId=${dataHubId}`
  } else if (dataHubId) {
    endpoint += `?dataHubId=${dataHubId}`
  }else if (hubMapId){
      endpoint += `?id=${hubMapId}`
  }
  return sendRequest(endpoint,'GET', token)
}

const getDataHubEntityAttributes = async(
  token:string,
  dataPodId:string,
  entityId:number,
  hubId?:number
) =>{
  let endpoint = dataHubsPath.getDataHubEntityAttributes
  if(dataPodId && entityId && hubId){
    endpoint += `?datapodId=${dataPodId}&dataHubId=${hubId}&entityId=${entityId}`
  }else if(dataPodId && hubId && !entityId){
    endpoint += `?datapodId=${dataPodId}&dataHubId=${hubId}`
  }else if(dataPodId && entityId && !hubId){
    endpoint += `?datapodId=${dataPodId}&entityId=${entityId}`
  }
  return sendRequest(endpoint, 'GET', token)
}

export const dataHubServices = {
  createDataHub,
  getAllDataHubs,
  // getIndustryById,
  updateDataHub,
  addDataHubEntityMaps,
  getDataHubEntityMaps,
  getDataHubEntityAttributes
}
