import {
  Typography,
  Grid,
  TextField,
  MenuItem,
  Button,
  Menu,
  IconButton,
  Card,
  Box,
  CardContent,
  Link,
  Paper,
  Autocomplete,
  Tooltip,
  Collapse,
} from '@mui/material'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import DeleteIcon from '@mui/icons-material/Delete'
// import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import RefreshIcon from '@mui/icons-material/Refresh'
import React, { useEffect, useMemo, useState } from 'react'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import ActionPage from '../../../ui-components/ActionPage'
import {
  AddCoreEntityAttribute,
  CoreEntity,
  CreateJobParams,
  CreateJobResponse,
  CustomEntityData,
  DataAttribute,
  DataTransformer,
  DataTransformerDetail,
  Entity,
  EntityReconData,
  EntityRelationship,
  FabricNotebookCreateRequest,
  JobClusterConfig,
  JobDetails,
  NewEntityData,
  NewEntityRelationShip,
  NewParent,
  RelationShipResponse,
  RunNewJobParams,
  RunStatusResponse,
  UpdateEntityNameBody,
  coreEntityAttributeData,
  getCoreEntityAttributeResponse,
} from '../../../businessObjects'
import { isValidDescription, isValidEntry } from '../../../utils/constants'
import {
  useAddCustomEntity,
  useAddNewEntity,
  useDeleteEntity,
  useGetCoreEntity,
  useGetCoreEntityByID,
  useGetCustomEntityRecon,
  useGenerateDataEntityRelations,
  useGenerateDataEntityRecommendation,
  useGetEntities,
  useGetEntityByName,
  useGetEntityNB,
  useSaveEntityNB,
  useUpdateCustomEntity,
  useUpdateEntityNB,
  useUpdateEntityName,
} from '../../../hooks/entityHooks'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ProgressBar } from '../../../ui-components/ProgressBar'
import {
  useAddEntityRecon,
  useAddEntityRelationship,
  useAddMultipleEntityRelationships,
  useCustomEnrichedGetEntityRelationships,
  useDeleteEntityRecon,
  useDeleteEntityRelation,
  useGetEnrichedEntityRelations,
  useUpdateEntityRelationship,
} from '../../../hooks/applicationHooks'
import { LucidJupyter } from '../../../ui-components/JupyterComponents/LucidJupyter'
import { ROUTES } from '../../../Routes/constants'
import { AddOutlined, HomeOutlined, Insights } from '@mui/icons-material'
import { SparkCode, useGenerateEnrichedModel } from '../../../hooks/sparkHooks'
import {
  useAddNewCustomAttributeTransformer,
  useGetCustomEntityDataTransformer,
  useGetDataEntityTransformer,
  useUpdateDataTransformer,
} from '../../../hooks/dataTransformerHooks'
import DataPodDetailRibbon from '../../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import AnimatedLoader from '../../../ui-components/AnimatedLoader'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import ShadowTable, { ShadowTableAction } from '../../../ui-components/ShadowTable/ShadowTable'
import { usePostCreateDatabricksJob, usePostDatabricksJobRunNew } from '../../../hooks/databricksHooks'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { RootState } from '../../../app/store'
import { filterActiveRunIds, initiateActiveRunId } from '../../../features/notification/notificationSlice'
import ResultStatus from '../../../ui-components/ResultStatus'
import { useGetServiceConnection } from '../../../hooks/dataEngineeringHooks'
import {
  useAddMultipleCoreEntityAttributes,
  useGetCoreEntityAttributes,
  useUpdateCoreAttributeDetails,
} from '../../../hooks/coreEntityAttributeHooks'
import {
  useGetDataAttributesByEntityId,
  useGetDataAttributesByMultipleEntityId,
} from '../../../hooks/dataAttrbutesHooks'
import { paddingLeft } from '../../../styles/globalStyles'
import { useGetGenAiEnrichedCoreEntityAttributes } from '../../../hooks/genAIApiHooks'
// import { useGetGenAiCoreEntityAttributes } from '../../../hooks/genAIApiHooks'
import constants from '../../constants'
import LoadingButton from '@mui/lab/LoadingButton'
import { info } from 'console'
import SettingsIcon from '@mui/icons-material/Settings'
import { DeletePopUp } from '../DataMapping/DataMappingPopUps'
import { isTemplateExpression } from 'typescript'
import { ConsoleCallbackHandler } from 'langchain/dist/callbacks'
import { useCreateAndRunNewJob, useGetCreateJobPayload, useGetRunNewJobPayload, useRunNewJob } from 'hooks/executionHooks'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
  customEntityId: string
}

type EntityType = {
  id: number
  type: string
}

type FunctionsType = {
  id: number
  function: string
}

type SelectedAttribute = {
  attributeName: string
  attributeDescription: string
  attributeType: string
  entityId: number
}
type GeneratedEnrichedAttribute = {
  attributeName: string
  attributeDescription: string
}

type UpdatedDataAttribute = DataAttribute & {
  dataEntityName: string | undefined | null
}

const initCoreEntity = {
  coreEntity: true,
  profileName: 'clientApp',
  description: '',
  dataEntityName: '',
  entityType: '1',
  dataLakeZone: 2,
}
const entityTypes = [
  { id: 1, value: 'Reference' },
  { id: 2, value: 'Transaction' },
]

const promptTextFunctions = [
  { id: 1, function: 'aggregate' },
  { id: 2, function: 'groupBy' },
  { id: 3, function: 'filter' },
]

export const CustomEnrichedModel = () => {
  const { dataPodId, customEntityId } = useParams<ViewResultsParams>()
  // const currentEntityId = customEntityId ? Number(customEntityId) : undefined
  const [currentEntityId, setCurrentEntityId] = useState(customEntityId ? Number(customEntityId) : undefined)
  const [enrichedTableData, setEnrichedTableData] = useState<NewEntityData>(initCoreEntity)
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const [userInput, setUserInput] = useState<string>('')
  const [userInput2, setUserInput2] = useState<string>('')
  const [userInput3, setUserInput3] = useState<string>('')
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null)
  const [anchorEl3, setAnchorEl3] = useState<null | HTMLElement>(null)
  const [anchorEl4, setAnchorEl4] = useState<null | HTMLElement>(null)
  const [loading, setLoading] = useState<boolean>(false)

  const [coreEntityEdit, setCoreEntityEdit] = useState<boolean>(false)
  //const [coreEntityDisplay, setCoreEntityDisplay] = useState<boolean>(false)
  const { saveNewEntity } = useAddNewEntity(dataPodId ?? '')
  const [entityData, setEntityData] = useState<Entity[]>([])
  const [entityDataAll, setEntityDataAll] = useState<Entity[]>([])
  const [coreEntityAttribute, setCoreEntityAttributeData] = useState<UpdatedDataAttribute[]>([])
  const [currentChildEntityIds, setCurrentChildEntityIds] = useState<EntityReconData[]>([])
  const { entities: fetchedEntities } = useGetEntities(dataPodId)
  const { addEntityRecon } = useAddEntityRecon(dataPodId)

  const {getCreateJobPayload} = useGetCreateJobPayload()
  const {createAndRunNewJob} = useCreateAndRunNewJob()

  const [updateEntityName, newEntityName] = useUpdateEntityName()
  const [notebookString, setNotebookString] = useState<string>()
  const [commitMessage, setcommitMessage] = useState<string>('initial commit')
  const [processingReq, setProcessingReq] = useState<boolean>(false)

  const [query, setQuery] = useState<SparkCode>()
  const [newCoreEntityId, setNewCoreEntityId] = useState<number>()
  const { updateCustomEntity, errorUpdatingEntity } = useUpdateCustomEntity()
  const { confirmDeleteEntityRecon } = useDeleteEntityRecon()
  const { entity: customEntity } = useGetCoreEntityByID(dataPodId, currentEntityId)
  const { fetchCustomEntityChildren } = useGetCustomEntityRecon(dataPodId)

  const { saveNewCustomEntity, addCustomEntityError } = useAddCustomEntity(dataPodId)
  const { genEnrichedSparkCode } = useGenerateEnrichedModel(dataPodId)
  const { entityNBstring, error } = useGetEntityNB(dataPodId, currentEntityId)
  const { saveNoteBoook } = useSaveEntityNB(dataPodId)
  const { updateEntityNB } = useUpdateEntityNB(dataPodId)
  const { addTransformer } = useAddNewCustomAttributeTransformer(dataPodId)
  const { updateTransformer } = useUpdateDataTransformer()
  // const { dataTransformers } = useGetDataEntityTransformer(dataPodId, currentEntityId)
  const { dataTransformer } = useGetCustomEntityDataTransformer(dataPodId, currentEntityId)
  const { getConnections } = useGetServiceConnection()
  const [entityId, setEntityId] = useState<number[]>([])
  const [attributeSelected, setAttributeSelected] = useState<String[]>([])
  const [attributesToDelete, setAttributesToDelete] = useState<string[]>([])
  const fetchCustomEntityRelationships = useCustomEnrichedGetEntityRelationships()
  const { getRelationShipData, loadingEnrichedRelationships, errorFetching } = useGetEnrichedEntityRelations(dataPodId)
  const [fetchedRelations, setFetchedRelations] = useState<RelationShipResponse[]>([])
  const [relationTableFlag, setRelationTableFlag] = useState<boolean>(false)
  const navigate = useNavigate()
  const [isEditMode, setIsEditMode] = useState<boolean>(false)
  const [showPopUp, setShowPopUp] = useState<boolean>(false)
  const [customEntId, setCustomEntId] = useState<number>()
  const [customEntName, setCustomEntName] = useState<string>()
  const [errorMessage, setErrorMessage] = useState<string>()
  const [errorState, setErrorState] = useState<boolean>(false)

  const [successMessage, setSuccessMessage] = useState<string>()
  const [successState, setSuccessState] = useState<boolean>(false)

  const [jobClusterParams, setJobClusterParams] = useState<JobClusterConfig | null>(null)

  const activeRunIds = useAppSelector((state: RootState) => state.notification.activeRunIds)
  const activeFabricRunStatus = useAppSelector((state: RootState) => state.notification.fabricRunStatus)
  // const { fetchCoreEntityAttributes } = useGetGenAiCoreEntityAttributes()
  const { fetchEnrichedCoreEntityAttributes } = useGetGenAiEnrichedCoreEntityAttributes()
  const [fetchCoreAttributes, coreAttributes] = useGetCoreEntityAttributes(dataPodId)
  const { refetchAttributes } = useGetDataAttributesByMultipleEntityId()
  const [attributeChanged, setAttributeChanged] = useState<boolean>(false)
  const [entityChanged, setEntityChanged] = useState<boolean>(false)
  const [submitNewAttribute] = useAddMultipleCoreEntityAttributes()
  const [selectedAttributes, setSelectedAttributes] = useState<SelectedAttribute[]>([])
  const [generatedEnrichedAttributes, setGeneratedEnrichedAttributes] = useState<any[]>([])
  const [nbStringPresent, setnbStringPresent] = useState<boolean>(false)
  const dispatch = useAppDispatch()
  const [DataSystemEntityIds, setDataSystemEntityIds] = useState<any>([])
  const [fetchLoading, setFetchLoading] = useState<boolean>(false)
  const [info, setInfo] = useState<boolean>(false)
  const [selectedValue, setSelectedValue] = useState<string>('')
  const [loadToWarehouse, setLoadToWarehouse] = useState<boolean>(false)
  const [runId, setRunId] = useState<number | string>()
  const [collapseForm, setCollapseForm] = useState<boolean>(true)
  const [codeGenerating, setCodeGenerating] = useState<boolean>(false)
  const { isEntityRecommendationGenerating, errorGeneratingEntityRecommendation, generateEntityRecommendation } =
    useGenerateDataEntityRecommendation(dataPodId)
  const { isEntityRelationsGenerating, errorGeneratingEntityRelations, generateEntityRelations } =
    useGenerateDataEntityRelations(dataPodId)

  const { addMultipleEntityRelations } = useAddMultipleEntityRelationships(dataPodId)
  // const { fetchEntityByName } = useGetEntityByName()

  const [showAddRelationPopUp, setShowAddRelationPopUp] = useState<boolean>(false)
  const [showEditRelationPopUp, setShowEditRelationPopUp] = useState<boolean>(false)
  const [showDeleteRelationWarning, setShowDeleteRelationWarning] = useState<boolean>(false)
  const [selectedEntityRelation, setSelectedEntityRelation] = useState<RelationShipResponse>()

  const [transformer, setTransformer] = useState<DataTransformer>()

  const {runNewJob} = useRunNewJob()
  const { getRunNewJobPayload } = useGetRunNewJobPayload();

  const entityRelationSuggestion: Entity[] = useMemo(() => {
    if (entityId.length == 0) {
      return []
    }

    return entityDataAll.filter((entity) => entityId.includes(entity.id))
  }, [entityId, entityDataAll])

  useEffect(() => {
    const fetchJobClusterParams = async () => {
      if (dataPodId) {
        const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')

        if (serviceConnection && serviceConnection.length > 0) {
          const jsonTemplate = serviceConnection[0].serviceConnectionJsonTemplate
          const parsedJson = JSON.parse(jsonTemplate)
          const params: JobClusterConfig = {
            url: parsedJson.workspace_Url,
            token: parsedJson.PAT_Token,
            clusterKey: parsedJson.cluster_Id,
            sparkVersion: parsedJson.sparkVersion,
            nodeTypeId: parsedJson.nodeTypeId,
          }
          setJobClusterParams(params)
        }
      }
    }

    fetchJobClusterParams()
  }, [])
  useEffect(() => {
    if (customEntId) {
      setCurrentEntityId(customEntId)
    }
  }, [customEntId])

  useEffect(() => {
    if (customEntity && !newCoreEntityId) {
      setEnrichedTableData({
        coreEntity: true,
        profileName: customEntity.profileName,
        description: customEntity.description,
        dataEntityName: customEntity.dataEntityName,
        entityType: customEntity.entityType,
        dataLakeZone: 2,
      })
      setCustomEntId(customEntity.id)
      setCustomEntName(customEntity.dataEntityName)
    }
  }, [customEntity, newCoreEntityId])

  const getEntityChilds = async (coreEntityId: number) => {
    const childEntites = await fetchCustomEntityChildren(coreEntityId)
    // console.log({ childEntites })
    if (childEntites) {
      setCurrentChildEntityIds(childEntites)
      const usrInpt = childEntites.map((ce) => `@${ce.child}`).join(' ')
      setUserInput(usrInpt)
      const ids = childEntites.map((item) => item.dataSystemEntityId)
      // getEntityRelations(ids)
      setEntityId(ids)
    }
  }

  const onClickGenerateRelations = async () => {
    if (entityId.length >= 2) {
      // console.log("Inside the use effect")
      const uniqueIds = new Set(entityId)
      const attributeNames = userInput3
        .split('@')
        .filter((name) => name.trim().length > 0)
        .map((name) => name.trim())
      const response = await generateEntityRelations(Array.from(uniqueIds), attributeNames)

      if (response?.length) {
        const uniqueRelations = mergeUniqueRelations(response)
        // const relations = response.map((item, index) => ({ ...item, id: index }))

        setRelationTableFlag(true)
        setFetchedRelations(uniqueRelations)
        setSuccessMessage('Generated relations successfully.')
        setSuccessState(true)
      } else if (response && response.length == 0) {
        setSuccessMessage("Gen AI couldn't generate any relationships.")
        setSuccessState(true)
      } else if (errorGeneratingEntityRelations) {
        setErrorMessage(errorGeneratingEntityRelations)
        setErrorState(true)
      }
    } else {
      setRelationTableFlag(false)
    }
  }

  const mergeUniqueRelations = (input: RelationShipResponse[]) => {
    const newFetchedRelations = fetchedRelations

    if (input.length == 0) {
      return newFetchedRelations
    }

    input.forEach((inputRelation) => {
      const relationExists = fetchedRelations.some(
        (relation) =>
          relation.leftEntityId === inputRelation.leftEntityId &&
          relation.rightEntityId === inputRelation.rightEntityId &&
          relation.leftAttributeId === inputRelation.leftAttributeId &&
          relation.rightAttributeId === inputRelation.rightAttributeId &&
          relation.cardinality === inputRelation.cardinality,
      )

      if (relationExists) {
        return
      }

      const length = newFetchedRelations.length

      newFetchedRelations.push({ ...inputRelation, id: -(length == 0 ? 1 : length) })
    })

    return newFetchedRelations
  }

  const getEntityRelations = async (entityIds: any) => {
    // console.log("Inside get entity relations")
    const entityArray: number[] = Array.from(entityIds)
    if (currentEntityId) {
      const response = await getRelationShipData(currentEntityId)
      if (response && response.length) {
        setRelationTableFlag(true)
        setFetchedRelations((prevRelations) => {
          let updatedRelations = [...prevRelations]

          response.forEach((responseRelation) => {
            const relationIndex = updatedRelations.findIndex((prevRelation) => prevRelation.id === responseRelation.id)
            if (relationIndex > -1) {
              // Update existing relation
              updatedRelations[relationIndex] = responseRelation
            } else {
              // Add new relation if not found
              updatedRelations.push(responseRelation)
            }
          })

          return updatedRelations
        })
      }
    }
  }

  const getAttributes = async (coreEntityId: number) => {
    if (dataPodId) {
      await fetchCoreAttributes(coreEntityId)
    }
  }

  const concatenateAttributeNames = (attributes: getCoreEntityAttributeResponse[]): string => {
    // console.log({ attributes })
    return attributes.map((attribute) => `@${attribute.dataCoreAttributeName}`).join(' ')
  }

  // const getAttributeEntityNameByAttributeName = (attributeName: string, attributeProfiles: DataAttribute[]) => {
  //   // if(attributeProfiles){
  //   const attribute = attributeProfiles.find((attr) => attr.dataAttributeName === attributeName);
  //   if (!attribute) {
  //     return null;
  //   }

  //   const entity = fetchedEntities.find((ent) => ent.id === attribute.dataEntityId);
  //   if (!entity) {
  //     return null;
  //   }

  //   return `${entity.dataEntityName}.${attribute.dataAttributeName}`;
  //   // }

  // }

  // const fetchAllAttributesOfEntities = async (dataSystemEntityIds: number[]) => {
  //   const attributeProfiles = await refetchAttributes(dataSystemEntityIds)
  //   // console.log({attributeProfiles})
  //   // console.log({fetchedEntities})
  //   if (attributeProfiles?.length) {
  //     console.log({attributeProfiles})
  //     setCoreEntityAttributeData(attributeProfiles)
  //   }
  // }

  const fetchAllAttributesOfEntities = async (dataSystemEntityIds: number[]) => {
    const attributeProfiles = await refetchAttributes(dataSystemEntityIds)
    if (attributeProfiles?.length) {
      // Create a map of dataEntityId to dataEntityName
      const entityMap = new Map<number, string>()
      fetchedEntities.forEach((entity) => {
        entityMap.set(entity.id, entity.dataEntityName)
      })

      // Add dataEntityName to each attribute in attributeProfiles
      const enrichedAttributes = attributeProfiles.map((attr) => ({
        ...attr,
        dataEntityName: entityMap.get(attr.dataEntityId) || 'Unknown',
      }))
      // console.log(userInput)
      // console.log({ enrichedAttributes })
      setCoreEntityAttributeData(enrichedAttributes)
    }
  }

  useEffect(() => {
    if (!showEditRelationPopUp) {
      getEntityRelations(entityId)
    }
  }, [showEditRelationPopUp])

  useEffect(() => {
    if (!showAddRelationPopUp) {
      getEntityRelations(entityId)
    }
  }, [showAddRelationPopUp])

  useEffect(() => {
    if (coreAttributes?.length) {
      const attributesString = concatenateAttributeNames(coreAttributes)
      console.log({coreAttributes})
      // console.log(attributesString)
      if (attributesString) {
        setUserInput3(attributesString)
      }
    }
  }, [coreAttributes, coreAttributes?.length])
  useEffect(() => {
    if (customEntityId && !userInput) {
      getEntityChilds(Number(customEntityId))
    }
  }, [customEntityId, userInput])
  // useEffect(() => {
  //   if (coreAttributes) {
  //     const selectedAttributeNames = new Set(attributeSelected);
  //     const currentAttributeNames = new Set(coreAttributes.map(attr => attr.dataCoreAttributeName));

  //     // Attributes to be deleted are in coreAttributes but not in attributeSelected
  //     const toDelete = Array.from(currentAttributeNames).filter(attr => !selectedAttributeNames.has(attr));
  //     console.log('Attributes to delete:', toDelete);
  //     setAttributesToDelete(toDelete);
  //   }
  // }, [coreAttributes, attributeSelected, userInput3.length]);

  useEffect(() => {
    if (entityId) {
      const tempEntArray = Array.from(new Set(entityId.map((item) => item)))
      fetchAllAttributesOfEntities(tempEntArray)
    }
  }, [entityId])

  useEffect(() => {
    if (fetchedEntities && !entityData.length) {
      const dataSystemEntityIds = fetchedEntities.map((entity) => entity.id)
      // console.log(entityId)
      // console.log(dataSystemEntityIds)
      setDataSystemEntityIds(dataSystemEntityIds)
      setEntityData(fetchedEntities)
      setEntityDataAll(fetchedEntities)
    }
  }, [fetchedEntities, fetchedEntities.length, entityId])

  useEffect(() => {
    if (entityNBstring && !notebookString) {
      if (entityNBstring !== 'Notebook not available!') {
        setNotebookString(entityNBstring)
        setnbStringPresent(true)
      }
    }
    if (error) {
      console.log(error)
    }
  }, [customEntityId, entityNBstring, error])

  useEffect(() => {
    if (notebookString?.length) {
      // console.log("the code already is present")
    } else {
      // console.log("code is not present")
    }
  }, [notebookString])

  useEffect(() => {
    // console.log("Inside the use effect of the data transformers")
    if (dataTransformer && !userInput2) {
      //console.log({ dataTransformers })
      setUserInput2(dataTransformer.transformerText)
      setTransformer(dataTransformer)
    }
  }, [dataTransformer])

  useEffect(() => {
    // console.log(entityId)
    if (entityId.length >= 2) {
      // console.log("Inside the use effect")
      const uniqueIds = new Set(entityId)
      getEntityRelations(uniqueIds)
    } else {
      setRelationTableFlag(false)
    }
  }, [entityId, entityId.length])
  useEffect(() => {
    if (userInput && entityDataAll.length) {
      syncEntityIds()
    } else {
      setRelationTableFlag(false)
    }
  }, [userInput, userInput.length, entityDataAll])
  useEffect(() => {
    if (currentEntityId) {
      // console.log("current entity id", currentEntityId)
      getAttributes(currentEntityId)
    }
  }, [newCoreEntityId, currentEntityId])
  // useEffect(() => {
  //   let typingTimer: any = 0
  //   function handleKeyUp(event: any) {
  //     const { key } = event
  //     if (key === 'Enter' || key === 'Tab') {
  //       setIsTyping(false)
  //       setSaveData(true)
  //       console.log('Enter or Tab is pressed')
  //     } else {
  //       setIsTyping(true)
  //       clearTimeout(typingTimer)
  //       typingTimer = setTimeout(() => {
  //         setIsTyping(false)
  //       }, 30000)
  //     }
  //   }
  //   const inputElement = document.getElementById('enrichedTableDescription')
  //   if (inputElement) {
  //     inputElement.addEventListener('keyup', handleKeyUp)

  //     return () => {
  //       inputElement.removeEventListener('keyup', handleKeyUp)
  //       clearTimeout(typingTimer)
  //     }
  //   }
  // }, [])
  // useEffect(() => {
  //   if (
  //     enrichedTableData?.dataEntityName &&
  //     enrichedTableData?.entityType &&
  //     enrichedTableData?.description &&
  //     saveData == true &&
  //     isTyping == false &&
  //     !newCoreEntity
  //   ) {
  //     onConfirmSave()
  //   }
  // }, [enrichedTableData, saveData])
  // useEffect(() => {
  //   if (newCoreEntityEdit && newCoreEntity) {
  //     const updatedCoreEntity = newCoreEntity
  //     if (
  //       enrichedTableData &&
  //       enrichedTableData.dataEntityName &&
  //       enrichedTableData.entityType &&
  //       enrichedTableData.description &&
  //       saveData == true &&
  //       isTyping == false
  //     ) {
  //       updatedCoreEntity.dataEntityName = enrichedTableData?.dataEntityName
  //       updatedCoreEntity.entityType = enrichedTableData.entityType
  //       updatedCoreEntity.description = enrichedTableData.description
  //       console.log(updatedCoreEntity)
  //       setNewCoreEntity(updatedCoreEntity)
  //     }
  //   }
  // }, [enrichedTableData, isTyping])

  const generateCodeHandler = async () =>{
    if(customEntId && userInput2.length){
      setCodeGenerating(true)
      const pycode = await genEnrichedSparkCode(userInput2, customEntId)
      if (pycode?.data) {
        setNewCoreEntityId(customEntId)
        setNotebookString(pycode.data)
        // console.log(notebookString)
        // console.log(newCoreEntityId)
        // console.log(commitMessage)
        // console.log("about to call save Notebook")
        // const success = await saveNoteBoook(res.id, pycode.data, commitMessage)
        // success && success && onClickHome()
        setCodeGenerating(false)
      } else {
        console.log('error: generate code')
      }
    }
  }

  const saveEnrichedModel = async () => {
    setLoading(true)
    setIsEditMode(false)
    if (enrichedTableData?.dataEntityName && enrichedTableData?.entityType && enrichedTableData?.description) {
      const res = await saveNewCustomEntity(enrichedTableData)
      if (res?.id) {
        setCustomEntId(res.id)
        setCustomEntName(res.dataEntityName)
        // console.log(res)
        // console.log(userInput)
        const selectedEntitiesStrings = userInput
          .split('@')
          .filter((uv) => uv.trim().length > 0)
          .map((cs) => cs.trim())
        const selen = entityDataAll.filter((ed) =>
          selectedEntitiesStrings.some((ss) => ss.toLowerCase() === ed.dataEntityName.toLowerCase()),
        )
        const uniqEn = selen.filter((ed, ix) => selen.findIndex((ss) => ss.id === ed.id) === ix)
        let allEntityReconIds: number[] = []
        for (let i = 0; i < uniqEn.length; i++) {
          const reqBody = {
            dataCoreEntityId: res.id,
            dataSystemEntityId: uniqEn[i].id,
            profileName: 'string',
          }
          const enRecon = await addEntityRecon(reqBody)
          if (enRecon?.id) {
            allEntityReconIds = [...allEntityReconIds, enRecon?.id]
          }
        }
        getEntityChilds(res.id)
        if (userInput3.length && dataPodId && res && coreEntityAttribute.length) {
          const attributeNames = userInput3
            .split('@')
            .filter((name) => name.trim().length > 0)
            .map((name) => name.trim())
          // console.log({ attributeNames })
          // console.log({ coreEntityAttribute })
          const combinedBody: AddCoreEntityAttribute[] | boolean = generateEnrichedCoreAttributesBodySave(
            selectedAttributes,
            generatedEnrichedAttributes,
            dataPodId,
            res.id,
          )
          if (combinedBody && combinedBody.length) {
            const response = await submitNewAttribute(combinedBody)
            // console.log(response)
          } else if (!combinedBody) {
            console.log('No need to create attributes')
          }
          // }
        }
        if (res && fetchedRelations.length > 0) {
          await saveMultipleCoreEntityRelationships(res.id)
        }
        // console.log(allEntityReconIds)
        // console.log(selectedEntitiesStrings)
        if (allEntityReconIds.length === selectedEntitiesStrings.length) {
          // console.log('Inside the if of the transformers')
          const transformBody = {
            dataAttributeMapId: allEntityReconIds[0],
            transformerText: userInput2,
            transformerQuery: '',
            dataEntityId: res.id,
            transformerTypeName: 'Enriched',
          }
          const success = await addTransformer(transformBody)
          if (success && success) {
            setTransformer(success.newTransformer)
            // const pycode = await genEnrichedSparkCode(userInput2, res?.id)
            // if (pycode?.data) {
            //   setNewCoreEntityId(res.id)
            //   setNotebookString(pycode.data)
            //   setTransformer(success.newTransformer)
            //   // console.log(notebookString)
            //   // console.log(newCoreEntityId)
            //   // console.log(commitMessage)
            //   // console.log("about to call save Notebook")
            //   // const success = await saveNoteBoook(res.id, pycode.data, commitMessage)
            //   // success && success && onClickHome()
            // } else {
            //   console.log('error: generate code')
            // }
          }
        } else {
          console.log('Error: EntityRecon')
        }
      } else {
        setErrorState(true)
        setErrorMessage("Failed to add enriched data entity.")
      }
    }
    setLoading(false)
  }

  const saveMultipleCoreEntityRelationships = async (enrichedEntityId: number) => {
    const payload: NewEntityRelationShip[] = []

    fetchedRelations.forEach((relation) => {
      if (relation.id > 0) {
        return
      }

      const newEntityRelationship: NewEntityRelationShip = {
        dataCoreEntityId: relation.leftEntityId,
        dataSystemEntityId: relation.rightEntityId,
        joinCoreAttributeId: relation.leftAttributeId,
        joinSystemAttributeId: relation.rightAttributeId,
        similarityScoreByName: relation.similarityScoreByName,
        similarityScoreByData: relation.similarityScoreByData,
        runId: 0,
        profileName: 'generateRelationships',
        cardinality: relation.cardinality,
        enrichedEntityId,
        overlapPercentage: relation.sampleOverlapPercentage || null,
        schemaCompatibility: (relation.schemaCompatibility == 1 ? true : false) || null,
      }

      payload.push(newEntityRelationship)
    })

    if (!payload.length) {
      return
    }

    const response = await addMultipleEntityRelations(payload)

    if (response) {
      setFetchedRelations((prevRelations) => {
        let updatedRelations = [...prevRelations]

        response.forEach((responseRelation) => {
          const relationIndex = updatedRelations.findIndex(
            (prevRelation) =>
              prevRelation.leftEntityId === responseRelation.leftEntityId &&
              prevRelation.rightEntityId === responseRelation.rightEntityId &&
              prevRelation.leftAttributeId === responseRelation.leftAttributeId &&
              prevRelation.rightAttributeId === responseRelation.rightAttributeId,
          )
          if (relationIndex > -1) {
            // Update existing relation
            updatedRelations[relationIndex] = responseRelation
          } else {
            // Add new relation if not found
            updatedRelations.push(responseRelation)
          }
        })

        return updatedRelations
      })
    }
  }

  const updateEnrichedModel = async () => {
    if (
      enrichedTableData?.dataEntityName &&
      enrichedTableData?.entityType &&
      enrichedTableData?.description &&
      currentEntityId
    ) {
      setLoading(true)
      const updateSuccess = await updateCustomEntity({
        id: currentEntityId,
        ...enrichedTableData,
        profileState: 'update',
      })
      if (updateSuccess) {
        const selectedEntitiesStrings = userInput
          .split('@')
          .filter((uv) => uv.trim().length > 0)
          .map((cs) => cs.trim())

        // const selen = entityData.filter((ed) => selectedEntitiesStrings.some((ss) => ss === ed.dataEntityName))
        const selen = entityDataAll.filter((ed) =>
          selectedEntitiesStrings.some((ss) => ss.toLowerCase() === ed.dataEntityName.toLowerCase()),
        )
        const uniqEn = selen.filter((ed, ix) => selen.findIndex((ss) => ss.id === ed.id) === ix)
        // const uniqEn = selen.filter((ed, ix) => selen.findIndex((ss) => ss.id === ed.id) === ix)
        let allEntityReconIds: number[] = []

        // 1. eval newChild entites with ref current child entites
        // 2. eval child entites toDelete that are not included in the update
        const currentChildEIDS = currentChildEntityIds.map((ce) => ce.dataSystemEntityId)

        const selectedEIds = uniqEn.map((ue) => ue.id)

        const newEntitiesToAdd = uniqEn.filter((uq) => !currentChildEIDS.includes(uq.id))

        const childEntitiesToDelete = currentChildEntityIds.filter(
          (cID) => !selectedEIds.includes(cID.dataSystemEntityId),
        )

        for (let i = 0; i < newEntitiesToAdd.length; i++) {
          const reqBody = {
            dataCoreEntityId: currentEntityId,
            dataSystemEntityId: newEntitiesToAdd[i].id,
            profileName: 'string',
          }
          const enRecon = await addEntityRecon(reqBody)
          if (enRecon?.id) {
            allEntityReconIds = [...allEntityReconIds, enRecon?.id]
          }
        }
        getEntityChilds(currentEntityId)
        if (userInput3.length && dataPodId && currentEntityId && coreEntityAttribute.length) {
          const attributeNames = userInput3
            .split('@')
            .filter((name) => name.trim().length > 0)
            .map((name) => name.trim())
          const combinedBody: AddCoreEntityAttribute[] | boolean = generateEnrichedCoreAttributesBodySave(
            selectedAttributes,
            generatedEnrichedAttributes,
            dataPodId,
            currentEntityId,
          )
          if (combinedBody && combinedBody.length) {
            const response = await submitNewAttribute(combinedBody)
            // console.log(response)
          } else if (!combinedBody) {
            console.log('No need to create attributes')
          }
        }
        const toDeleteReconIds = childEntitiesToDelete.map((ct) => ct.id)
        // console.log({ toDeleteReconIds })
        if (toDeleteReconIds.length) {
          const deleteReconSuccess = await confirmDeleteEntityRecon(toDeleteReconIds)
          // console.log({ deleteReconSuccess })
        }
        await saveMultipleCoreEntityRelationships(currentEntityId)
        if (
          allEntityReconIds.length === newEntitiesToAdd.length &&
          newEntitiesToAdd.length &&
          dataPodId?.length &&
          transformer
        ) {
          const transformBody = {
            id: transformer.id,
            dataAttributeMapId: allEntityReconIds.length != 0 ? allEntityReconIds[0] : transformer.dataAttributeMapId,
            transformerText: userInput2,
            transformerQuery: '',
            dataEntityId: currentEntityId,
            transformerTypeName: 'Enriched',
          }
          const success = await updateTransformer(transformBody, dataPodId)
          // if (success && success) {
          // const pycode = await genEnrichedSparkCode(userInput2, currentEntityId)
          // if (pycode?.data) {
          //   //setNewCoreEntityId(res.id)
          //   setNotebookString(pycode.data)
          //   setCoreEntityEdit(false)
          // } else {
          //   console.log('error: generate code')
          // }
          // }
        }
        if (!newEntitiesToAdd.length && !allEntityReconIds.length && transformer) {
          const transformBody = {
            id: transformer.id,
            dataAttributeMapId: allEntityReconIds.length != 0 ? allEntityReconIds[0] : transformer.dataAttributeMapId,
            transformerText: userInput2,
            transformerQuery: '',
            dataEntityId: currentEntityId,
            transformerTypeName: 'Enriched',
          }
          if (dataPodId?.length) {
            const success = await updateTransformer(transformBody, dataPodId)
          }
          // const pycode = await genEnrichedSparkCode(userInput2, currentEntityId)
          // if (pycode?.data) {
          //   //setNewCoreEntityId(res.id)
          //   setNotebookString(pycode.data)
          //   // if (notebookString && newCoreEntityId && commitMessage) {
          //   //   // console.log("about to call save Notebook")
          //   //   const success = await saveNoteBoook(newCoreEntityId, notebookString, commitMessage)
          //   //   // success && success && onClickHome()
          //   // }
          //   // if(!dataTransformers.length){
          //   // }
          //   setCoreEntityEdit(false)
          // } else {
          //   console.log('error: generate code')
          // }
        } else {
          if (currentEntityId && !transformer && userInput2.length && currentChildEntityIds) {
            const transformBody = {
              dataAttributeMapId: currentChildEntityIds[0].id,
              transformerText: userInput2,
              transformerQuery: '',
              dataEntityId: currentEntityId,
              transformerTypeName: 'Enriched',
            }
            const success = await addTransformer(transformBody)
            if (success) {
              // const pycode = await genEnrichedSparkCode(userInput2, currentEntityId)
              // if (pycode) {
              //   setNotebookString(pycode.data)
              //   // setUserInput2('')
              //   setTransformer(success.newTransformer)
              // }
            }
          }
        }
      } else {
        console.log('Error: addNew entity')
      }
      setLoading(false)
    } else {
      console.log('No custom enriched entity Id')
    }
  }

  const addEnrichedModelData = async (name: string, value: string) => {
    // if(name == 'dataEntityName' && dataPodId){
    //   console.log(value)
    //   const ent = await fetchEntityByName(dataPodId,value)
    //   if(ent){
    //     console.log("True")
    //   }else{
    //     console.log("False")
    //   }
    // }
    setEnrichedTableData(
      (prev) =>
        ({
          ...prev,
          [name]: value,
        } as NewParent),
    )
    if (currentEntityId) {
      setCoreEntityEdit(true)
    }
  }
  const handleSelectMention = (id: number, entityName: string) => {
    const tempEntities = userInput.replace(/@$/, `@${entityName}`)
    if (!entityId.includes(id)) {
      setEntityId([...entityId, id])
    }
    setEntityChanged(true)
    setUserInput(tempEntities)
    // if (newCoreEntity && id) {
    //   const reqBody = {
    //     dataCoreEntityId: newCoreEntity.id,
    //     dataSystemEntityId: id,
    //     profileName: 'string',
    //   }
    //   saveNewEntityRecon(reqBody)
    // }
    setAnchorEl(null)
    if (currentEntityId && !coreEntityEdit) {
      setCoreEntityEdit(true)
    }
  }

  const syncEntityIds = () => {
    const mentionPattern = /@(\w+)/g
    let matches = userInput.match(mentionPattern)
    let currentMentions = matches ? matches.map((mention) => mention.substring(1)) : []
    const filteredEntityIds = entityId.filter((id, index) => {
      const entity = entityDataAll.find((ent) => ent.id === id)
      return entity && currentMentions.includes(entity.dataEntityName)
    })
    setEntityId(filteredEntityIds)
    const tempEntities = entityDataAll.filter((ent) => !entityId.includes(ent.id))
    setEntityData(tempEntities)
  }
  const sourceDataRow = useMemo(() => {
    if (entityId.length || fetchedRelations.length) {
      return fetchedRelations.map((relation) => ({
        id: `${relation.id}`,
        values: [
          relation.leftEntityName,
          relation.leftAttributeName,
          relation.rightEntityName,
          relation.rightAttributeName,
          relation.cardinality,
          relation.sampleOverlapPercentage,
          (relation.schemaCompatibility == true ? 1 : 0) || null,
          'EDIT',
          'DELETE',
        ],
      })) as DataTableRow[]
    }
    return []
  }, [fetchedRelations, fetchedRelations.length, entityId, entityId.length])
  const sourcePopUpHeaders = useMemo(() => {
    return [
      {
        label: 'Left Entity',
        sortableColumn: true,
      },
      {
        label: 'Left Attribute',
        sortableColumn: true,
      },
      {
        label: 'Right Entity',
        sortableColumn: true,
      },
      {
        label: 'Right Attribute',
        sortableColumn: true,
      },
      {
        label: 'Cardinality',
      },
      {
        label: 'Sample Overlap Percentage',
      },
      {
        label: 'SchemaCompatibility',
      },
      {
        label: 'Edit',
      },
      {
        label: 'Delete',
      },
    ]
  }, [])

  // const saveNewEntityRecon = async (addReconData: AddEntityRecon) => {
  //   if (addReconData) {
  //     const enRecon = await addEntityRecon(addReconData)
  //     console.log(userEnteredEntityIds)
  //     if (enRecon && userEnteredEntityIds.length >= 2) {
  //     }
  //   }
  // }
  // console.log(userEnteredEntityIds)
  const transformationTextChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    // const entityNamesArray = value.split(',')
    // entityNamesArray.forEach((entityName) => {
    //   const matchingEntities = fetchedEntities.filter((ent) => `@${ent.dataEntityName}` === entityName)
    //   if (matchingEntities.length > 0) {
    //     tempIds.push(...matchingEntities.map((ent) => ent.id))
    //   }
    // })

    // console.log(tempIds)
    // setUserEnteredEntityIds([...tempIds])
    if (value == '') {
      setEntityId([])
    }
    if (value != '') {
      const splitArray = value
        .split('@')
        .map((item) => item.trim())
        .filter((item) => item !== '')

      const userEntityIds: number[] = []

      splitArray.map((name) => {
        const entity = entityDataAll.find((entity) => entity.dataEntityName === name)
        if (entity) {
          if (!entityId.includes(entity.id)) {
            userEntityIds.push(entity.id)
          }
        }
      })

      setEntityId([...entityId, ...userEntityIds])
    }

    setUserInput(value)
    // setShowColumnList(value.endsWith('@'))

    if (value.endsWith('@')) {
      setAnchorEl(e.currentTarget)
    } else {
      setAnchorEl(null)
    }
    if (currentEntityId && !coreEntityEdit) {
      setCoreEntityEdit(true)
    }
  }
  const transformationTextChangeHandler2 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setUserInput2(value)
    // setShowColumnList(value.endsWith('@'))
    if (value.endsWith('@')) {
      setAnchorEl2(e.currentTarget)
    } else {
      setAnchorEl2(null)
    }
  }
  const transformationTextChangeHandler3 = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value
    setUserInput3(value)

    // setShowColumnList(value.endsWith('@'))

    if (value.endsWith('@')) {
      setAnchorEl3(e.currentTarget)
    } else {
      setAnchorEl3(null)
    }
  }
  const handleSelectMention2 = (columnName: string) => {
    const promptText = userInput2.replace(/@$/, `@${columnName}`)
    setUserInput2(promptText)
    // const tempId = userEnteredEntityIds.includes(id)
    //   ? userEnteredEntityIds.filter((ix) => {
    //       return ix !== id
    //     })
    //   : [...userEnteredEntityIds, id]
    // const tmpUserInput = entities
    //   .filter((e) => tempId.includes(e.id))
    //   .map((ec) => `@${ec.dataEntityName}`)
    //   .join(',')
    // setUserInput(tmpUserInput)
    // setUserEnteredEntityIds(tempId)
    setAnchorEl2(null)
    if (currentEntityId && !coreEntityEdit) {
      setCoreEntityEdit(true)
    }
  }
  const handleSelectMention3 = (
    attributeName: string,
    attributeId: number,
    description: string,
    type: string,
    entityId: number,
  ) => {
    const promptText = userInput3.replace(/@$/, `@${attributeName}`)
    const selectedCoreAttribute = coreEntityAttribute.find((attr) => attr.id === attributeId)
    const isAttrDuplicate = !!selectedAttributes.find(
      (attr) => attr.entityId == entityId && attr.attributeName == attributeName,
    )

    if (selectedCoreAttribute && !isAttrDuplicate) {
      setSelectedAttributes((prevAttributes) => [
        ...prevAttributes,
        { attributeName, attributeDescription: description, attributeType: type, entityId },
      ])

      setAttributeChanged(true)
      setUserInput3(promptText)
    }
    // const tempId = userEnteredEntityIds.includes(id)
    //   ? userEnteredEntityIds.filter((ix) => {
    //       return ix !== id
    //     })
    //   : [...userEnteredEntityIds, id]
    // const tmpUserInput = entities
    //   .filter((e) => tempId.includes(e.id))
    //   .map((ec) => `@${ec.dataEntityName}`)
    //   .join(',')
    // setUserInput(tmpUserInput)
    // setUserEnteredEntityIds(tempId)
    setAnchorEl3(null)
    // if (currentEntityId && !coreEntityEdit) {
    //   setCoreEntityEdit(true)
    // }
  }
  // const handleSelectMention3 = (attributeName: string, attributeId: number) => {
  //   const promptText = userInput3.replace(/@$/, `@${attributeName}`);

  //   // Find the selected attribute in the coreEntityAttribute array
  //   const selectedAttribute = coreEntityAttribute.find(attr => attr.id === attributeId);

  //   if (selectedAttribute) {
  //     setAttributeSelected(prevSelected => {
  //       if (!prevSelected.includes(attributeName)) {
  //         return [...prevSelected, attributeName];
  //       }
  //       return prevSelected;
  //     });

  //     setAttributeDict(prevDict => {
  //       if (!prevDict[attributeId]) {
  //         return {
  //           ...prevDict,
  //           [attributeId]: {
  //             AttributeName: attributeName,
  //             AttributeDescription: selectedAttribute.dataAttributeDescription // Use the correct property for description
  //           }
  //         };
  //       }
  //       return prevDict;
  //     });

  //     setAttributeChanged(true);
  //   }

  //   setUserInput3(promptText);
  //   setAnchorEl3(null);
  // };

  // const handleEditClick = () => {
  //   console.log('inside the edit click')
  //   setCoreEntityDisplay(!coreEntityDisplay)
  //   setNewCoreEntityEdit(true)
  //   // updateEntityName(newCoreEntity as UpdateEntityNameBody)
  // }

  // const handleDeleteClick = async () => {
  //   console.log('inside the delete click')
  //   const entityId: number[] = []
  //   if (newCoreEntity) {
  //     entityId.push(newCoreEntity?.id)
  //   }
  //   const res = await deleteEntity(entityId)
  //   if (res) {
  //   }
  // }

  const onClickHome = () => {
    const enrichedModelPage = generatePath(ROUTES.DataModelingEnterpriseEnrichedTables, {
      dataPodId: dataPodId,
    })
    navigate(enrichedModelPage)
  }
  const onButtonClick = () => {
    const enrichedModelToRelations = generatePath(ROUTES.DataModelingEntityRelationships, { dataPodId: dataPodId })
    navigate(enrichedModelToRelations)
  }

  const onActionClick = (rowId: string, actionType: ShadowTableAction) => {
    const tbID = Number(rowId)
    const selRow = fetchedRelations.find((r) => r.id === tbID)

    if (selRow) {
      setSelectedEntityRelation(selRow)
    }
    if (actionType === ShadowTableAction.Editable) {
      setShowEditRelationPopUp(true)
    }
    if (actionType === ShadowTableAction.Deletable) {
      setShowDeleteRelationWarning(true)
    }
  }

  // const clickNextAction = () => {
  //   console.log("Inside click next action")
  //   console.log(currentEntityId)
  // }
  const disableContinue = useMemo(() => {
    const disableCon = !(
      isValidEntry(enrichedTableData?.dataEntityName) &&
      isValidDescription(enrichedTableData?.description) &&
      userInput &&
      userInput2
    )
    if (!newCoreEntityId) return disableCon || loading
    if (newCoreEntityId) return !notebookString
    if (currentEntityId) return loading
    return
  }, [
    enrichedTableData?.dataEntityName,
    enrichedTableData?.description,
    userInput,
    userInput2,
    loading,
    newCoreEntityId,
    currentEntityId,
  ])

  const clickHandler = async () => {
    if (!currentEntityId && clickUpdateLabel == 'Save') {
      // console.log("there is no core entity id present")
      // if (notebookString && newCoreEntityId && commitMessage) {
      //   // console.log("about to call save Notebook")
      //   const success = await saveNoteBoook(newCoreEntityId, notebookString, commitMessage)
      //   success && success && onClickHome()
      // } else {
      // console.log("note book else")
      // console.log("inside else of notebook string new core entity id and commit message")
      await saveEnrichedModel()
      // }
    } else {
      // if (!coreEntityEdit && notebookString && commitMessage) {
      //   setShowPopUp(!showPopUp)
      // } else {
      // console.log("inside corentityedit else")

      // if (transformer) {
      await updateEnrichedModel()
      // }else if(currentEntityId && !transformer && userInput2.length){

      // }

      // }
    }
  }
  const clickUpdateLabel = useMemo(() => {
    if (!nbStringPresent && !customEntId) {
      return 'Save'
    } else {
      return 'Update'
    }
  }, [isEditMode, nbStringPresent, customEntId])

  const SaveUpdateLabel = useMemo(() => {
    if (nbStringPresent) {
      return 'Update Notebook'
    }
    return 'Save Notebook'
  }, [isEditMode, nbStringPresent])

  // const clickUpdateSaveOrEdit = useMemo(() => {
  //   if (attributeChanged) {
  //     return 'Save'
  //   }
  // }, [attributeChanged,coreAttributes?.length, attributeSelected])

  const handleNext = async () => {
    // console.log('comes inside the handle next')
    // console.log(notebookString, commitMessage, currentEntityId, coreEntityEdit)
    if (!coreEntityEdit && notebookString && commitMessage && currentEntityId) {
      setShowPopUp(false)
      const success = await updateEntityNB(currentEntityId, notebookString, commitMessage)

      if (!success) {
        setErrorMessage('Failed to update notebook.')
        setErrorState(true)
      } else if (success) {
        setSuccessMessage('Updated notebook successfully.')
        setSuccessState(true)
      }
      // success && success && onClickHome()
    }
  }
  const onTextChange = (name: string, value: string) => {
    setcommitMessage(value)
  }

  const executeNotebook = async () => {
    if (dataPodId && currentEntityId && customEntName && notebookString) {
      const databricksParams: CreateJobParams = {
        dataPodId,
        measureID: currentEntityId
      }

      const fabricParams: FabricNotebookCreateRequest = {
        workspaceId: '',
        displayName: dataPodId + "_" + customEntName || '',
        description: `Executing measure ${customEntName} for datapodId ${dataPodId}`,
        accessToken: '',
        code: notebookString,
        executionData: {},
        environmentId: '',
      }

      const routePage = generatePath(ROUTES.EditCustomEnrichedModel, {
        dataPodId: dataPodId,
        customEntityId: customEntId,
      })

      const jobDetails: JobDetails = {
        triggerType: 'Enriched Entity Code Execution',
        parameters: {
          id: currentEntityId,
          name: customEntName,
          route: routePage,
        },
      }
      try {
        const payload = await getCreateJobPayload(dataPodId, databricksParams, fabricParams)
  
        if(payload) {
          await createAndRunNewJob(jobDetails, payload)
        } else {
          setErrorMessage('Failed to create Databricks Job')
          setErrorState(true)
        }
      } catch (err: any) {
        setErrorMessage(err.message)
        setErrorState(true)
      }
    }
  }

  const closeErrorMessage = () => {
    setErrorState(false)
    setErrorMessage(undefined)
  }

  const closeSuccessMessage = () => {
    setSuccessState(false)
    setSuccessMessage(undefined)
  }

  const generateEnrichedCoreAttributesBodySave = (
    userEnteredAttribute: SelectedAttribute[],
    genAIGeneratedAttributes: any[],
    dataPodId: string,
    coreEntityId: number,
  ) => {
    const attributes = [...userEnteredAttribute, ...genAIGeneratedAttributes]
    // console.log({ attributes })
    if (attributes.length) {
      return attributes.map((attribute) => ({
        dataCoreEntityId: coreEntityId,
        dataCoreAttributeName: attribute.attributeName !== undefined ? attribute.attributeName : attribute,
        dataPodId: dataPodId,
        dataCoreAttributeDataType: 'string',
        dataCoreClassification: 'string',
        description: attribute.attributeDescription !== undefined ? attribute.attributeDescription : attribute,
        isBusinessKey: true,
        dataAttributeType: 'string',
      })) as AddCoreEntityAttribute[]
    } else {
      return false
    }
  }

  // const saveNewlyAddedCoreAttributes = async () => {
  //   if (attributeChanged) {
  //     if (selectedAttributes && dataPodId && currentEntityId) {
  //       // console.log({selectedAttributes})
  //       const cummulativeBody = generateCoreAttributesBody(selectedAttributes, dataPodId, currentEntityId)
  //       // console.log({cummulativeBody})
  //       const response = await submitNewAttribute(cummulativeBody)
  //       if (response) {
  //         getAttributes(currentEntityId)
  //         setAttributeChanged(false)
  //       }
  //     }
  //     // const resp = await updateCoreEntityAttibuteFunc()
  //   }
  // }
  const getGenAiEnrichedCoreEntityAttributes = async () => {
    // console.log('clicked')
    if (entityChanged && enrichedTableData && dataPodId && entityId.length) {
      setFetchLoading(true)
      // console.log("Inside if")
      // const entityIds = currentChildEntityIds.map((entity:any) => entity.dataSystemEntityId);
      // console.log(entityIds)
      if (DataSystemEntityIds) {
        const attributeProfiles = await refetchAttributes(DataSystemEntityIds)
        if (attributeProfiles) {
          const attributes = await fetchEnrichedCoreEntityAttributes(
            dataPodId,
            enrichedTableData.dataEntityName,
            enrichedTableData.description,
            enrichedTableData.entityType,
            entityId,
          )
          if (attributes) {
            // console.log(attributes)
            // const coreEntityGeneratedAttributes = attributes
            // console.log(coreEntityGeneratedAttributes)
            setGeneratedEnrichedAttributes(attributes)
            // const attributesString = coreEntityGeneratedAttributes.map(attribute => `@${attribute.AttributeName}`).join(' ');
            const attributesString = attributes
              .map((attribute) => {
                return `@${attribute}`
              })
              .join(' ')

            setUserInput3(userInput3 + ' ' + attributesString)
          } else {
            setErrorMessage('Failed to generated core attributes.')
            setErrorState(true)
          }
          setFetchLoading(false)
        }
      }
    }
  }

  const onClickBack = (event: any): void => {
    onClickHome()
  }

  const handleNotebookString = async () => {
    console.log('Inside the handle notebook string')
    console.log(nbStringPresent)
    if (!nbStringPresent) {
      console.log('Inaside the nbStrint present if')
      console.log(notebookString, commitMessage, currentEntityId)
      let cstId: number | undefined
      if (customEntId) {
        cstId = customEntId
      } else if (currentEntityId) {
        cstId = currentEntityId
      }

      if (notebookString && commitMessage && cstId) {
        // console.log("about to call save Notebook")
        const success = await saveNoteBoook(cstId, notebookString, commitMessage)
        console.log('It is in the save')
        setSuccessMessage('Saved notebook successfully.')
        setSuccessState(true)
        setIsEditMode(true)
        success && setnbStringPresent(true)
        // success && success && onClickHome()
      }
    } else {
      if (!coreEntityEdit && notebookString && commitMessage) {
        setShowPopUp(!showPopUp)
      }
    }
  }

  const onPreviewButtonClick = async () => {
    const enrichedPreviewRoute = generatePath(ROUTES.DataModelingEnrichedDataPreview, {
      dataPodId: dataPodId,
      customEntityID: customEntityId,
    })
    navigate(enrichedPreviewRoute)
  }
  const loadOptions = [{ value: 'Data Warehouse Load', label: 'Data Warehouse Load' }]

  const handleClick = (event: any) => {
    setAnchorEl4(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl4(null)
  }

  useEffect(() => {
    if (activeRunIds && runId) {
      const temp = activeRunIds.find((element) => element.runId === runId)
      if (temp && temp.state.resultState === 'SUCCESS') {
        console.log({ temp })
        if (temp.runName?.includes('LDH_LoadToDataWarehouse') && customEntityId && dataPodId) {
          setLoadToWarehouse(false)
        }
      }
    }
  }, [activeRunIds])

  useEffect(() => {
    if (activeFabricRunStatus && runId) {
      const temp = activeFabricRunStatus.find((element) => element.id === runId)
      if (temp && temp.status === 'Completed') {
        console.log({ temp })
        if (temp.jobDetails?.triggerType === 'Data Warehouse Load' && customEntityId && dataPodId) {
        setLoadToWarehouse(false)
        }
      }
    }
  }, [activeFabricRunStatus])

  const runDatawarehouseLoadJob = async () => {
    if(dataPodId && customEntityId) {
      const requestBody = {
        i_data_pod_id: dataPodId || '',
        i_data_entity_id: JSON.stringify(customEntityId),
      };
      
      const jobParams = {
        name: customEntName || '',
        route: generatePath(ROUTES.DataAnalyticsGroupResults, {
          dataPodId: dataPodId,
        }),
      };
  
      try {
        const payload = await getRunNewJobPayload(dataPodId, requestBody, 'dataWarehouse_Job_Id');
        
        if(payload) {
          const runId = await runNewJob(activeRunIds, jobParams, 'Data Warehouse Load', payload);
          
          if(runId){
            setRunId(runId)
          }
        }
      } catch (error: any) {
        setErrorMessage(error.message)
        setErrorState(true)
      }
    }
  };

  const handleSubmit = (value: string) => {
    if (customEntityId) {
      // console.log(value)
      handleClose()
      switch (value) {
        case 'Data Warehouse Load':
          runDatawarehouseLoadJob()
          break
      }
    }
  }

  const onClickEntityRecommendation = async () => {
    const entityType = entityTypes.find((type) => type.id == Number(enrichedTableData.entityType))

    const response = await generateEntityRecommendation(
      enrichedTableData.dataEntityName,
      entityType?.value || entityTypes[0].value,
      enrichedTableData.description,
    )

    if (response && response.length > 0) {
      let userInputEntitiesTemp = ''
      let canonicalEntitiesTemp = entityId
      response.forEach((id) => {
        const entity = entityDataAll.find((ent) => ent.id == id)

        if (entity) {
          if (!canonicalEntitiesTemp.includes(id)) {
            canonicalEntitiesTemp.push(id)
            userInputEntitiesTemp += '@' + entity.dataEntityName + ' '
          }
        }
      })
      setEntityId(canonicalEntitiesTemp)
      setEntityChanged(true)
      setUserInput(userInput + userInputEntitiesTemp)
      setSuccessMessage('Generated recommendations successfully.')
      setSuccessState(true)
    } else if (response && response.length == 0) {
      setSuccessMessage('Gen AI did not generate any recommendations.')
      setSuccessState(true)
    } else if (!response && errorGeneratingEntityRecommendation) {
      setErrorMessage(errorGeneratingEntityRecommendation)
      setErrorState(true)
    }
  }

  return (
    <>
      {errorMessage && (
        <ResultStatus
          showStatus={errorState}
          alertMessage={errorMessage}
          severtiy="error"
          closeStatus={() => closeErrorMessage()}
        />
      )}
      {successState && (
        <ResultStatus
          showStatus={successState}
          alertMessage={successMessage}
          severtiy="success"
          closeStatus={() => closeSuccessMessage()}
        />
      )}
      <ActionPage>
        {/* <CardContent>
          <DataPodDetailRibbon
            dataPodId={dataPodId}
            dataPodName={dataPodInfo?.name}
            dataPodIndustryName={dataPodInfo?.industryName}
          />
        </CardContent> */}
        <Grid
          item
          container
          xs={15}
          alignItems="center"
          spacing={20}
          display={'flex'}
          flexDirection={'row'}
          justifyContent={'space-between'}
        >
          <Grid item xs={10}>
            <Typography variant="h3" color="primary" gutterBottom>
              Enriched Data Entity
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="outlined"
              onClick={onClickBack}
              startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
            >
              Back
            </Button>
          </Grid>
        </Grid>

        {/* <Grid item xs={2.5}>
              <IconButton onClick={onClickHome} size="small" title="Data Modelling ">
                <HomeOutlined />
              </IconButton>
            </Grid> */}
        {/* <Grid item xs={4}> */}

        {/* <ProgressBar loading={loading} /> */}
        {/* <AnimatedLoader height="50%" width="40%" loading={loading} /> */}
        <Grid container item xs={14} alignItems={'stretch'} alignContent={'center'} spacing={2} paddingTop={'10px'}>
          <Grid item xs={collapseForm ? 8 : 4} container alignItems={'stretch'} alignContent={'baseline'} spacing={1}>
            <Grid container item sx={{ display: 'flex' }} spacing={2} xs={12}>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  Enriched Entity Name
                </Typography>

                <TextField
                  placeholder="E.g Department Totals"
                  size="small"
                  fullWidth
                  variant="outlined"
                  id="outlined-basic"
                  // label="Enter Enriched Table Name"
                  required
                  onChange={({ target }) => addEnrichedModelData('dataEntityName', target.value)}
                  value={enrichedTableData?.dataEntityName}
                  error={!isValidEntry(enrichedTableData?.dataEntityName)}
                  disabled={currentEntityId || customEntId ? true : false}
                />
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  A clear and concise name for the enriched entity name. Eg: "Customer Churn Prediction"
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="h6" gutterBottom>
                  Entity Type
                </Typography>

                <TextField
                  fullWidth
                  id="outlined-select-entityType"
                  select
                  required
                  size="small"
                  value={enrichedTableData?.entityType ?? 1}
                  onChange={({ target }) => addEnrichedModelData('entityType', target.value)}
                  //disabled={coreEntityDisplay}
                >
                  {entityTypes.map((name, ix) => (
                    <MenuItem key={name.id} value={name.id}>
                      {name.value}
                    </MenuItem>
                  ))}
                </TextField>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  A clear distinction based on transaction data or reference data involves specifying the nature of the
                  data for enriched entity.
                </Typography>
              </Grid>
            </Grid>
            <Grid item xs={12} />
            <Grid item spacing={2} container sx={{ display: 'flex', flexDirection: 'column' }} xs={12}>
              <Grid item>
                <Typography gutterBottom>Description</Typography>

                <TextField
                  placeholder="E.g Department Totals"
                  id="enrichedTableDescription"
                  required
                  size="small"
                  fullWidth
                  multiline
                  value={enrichedTableData?.description}
                  onChange={({ target }) => addEnrichedModelData('description', target.value)}
                  rows={3}
                  variant="outlined"
                  //disabled={coreEntityDisplay}
                />
                <Typography variant="body2" color="textSecondary" gutterBottom>
                  A detailed explanation of the enriched entity name, including its objectives, importance, Outcome &
                  Reporting. Eg: "This enriched entity aims to identify customers who are likely to churn based on their
                  interaction history and demographic data. The objectives is to reduce customer churn rate by 10% over
                  the next quarter. The expected outcome is weekly churn prediction, monthly impact assessment on churn
                  rates."
                </Typography>
              </Grid>
              <Grid item container justifyContent="flex-end">
                <Tooltip title="Generate Canonical Entities">
                  <span>
                    <LoadingButton
                      loading={isEntityRecommendationGenerating}
                      onClick={onClickEntityRecommendation}
                      color="primary"
                      variant="outlined"
                      disabled={
                        !enrichedTableData.dataEntityName ||
                        !enrichedTableData.description ||
                        !enrichedTableData.entityType
                      }
                    >
                      {<RefreshIcon fontSize="small" />}
                    </LoadingButton>
                  </span>
                </Tooltip>
              </Grid>
            </Grid>
            <Grid item xs={12} />

            <Grid item xs={12}>
              <Grid item xs={10} alignItems={'center'}>
                <Typography variant="h6" gutterBottom>
                  Canonical Entities
                </Typography>
              </Grid>
              <TextField
                placeholder="@Sales, @Department"
                size="small"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={userInput}
                onChange={transformationTextChangeHandler}
              />
              <Menu
                PaperProps={{
                  style: {
                    width: '20%',
                  },
                }}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={() => setAnchorEl(null)}
              >
                {/* {entityData.map((ent) => (
                  <MenuItem key={ent.id} onClick={() => handleSelectMention(ent.id, ent.dataEntityName)}>
                    {ent.dataEntityName}
                  </MenuItem>
                ))} */}
                <Autocomplete
                  options={entityData}
                  getOptionLabel={(option) => `${option.dataEntityName}`}
                  onChange={(event, ent) => {
                    if (ent) {
                      handleSelectMention(ent.id, ent.dataEntityName)
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search..." variant="outlined" size="small" fullWidth />
                  )}
                  renderOption={(props, option) => (
                    <MenuItem
                      {...props}
                      key={option.id}
                      onClick={() => {
                        handleSelectMention(option.id, option.dataEntityName)
                      }}
                    >
                      {option.dataEntityName}
                    </MenuItem>
                  )}
                  style={{ width: '100%' }}
                />
              </Menu>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                A detailed list of entities (tables) needed for the enriched entity. Eg. @Transactions @Customers
                @Subscriptions @CustomerSupportInteractions
              </Typography>
            </Grid>
            {/* <Grid item xs={12} /> */}
            {/* <Grid item xs={12} /> */}
            <br></br>
            <Grid
              item
              container
              xs={12}
              display={'flex'}
              flexDirection={'row'}
              justifyContent={'space-between'}
              style={{ marginTop: '2%' }}
            >
              <Typography variant="h6" gutterBottom>
                Core Attributes
              </Typography>
              <Tooltip title="Refresh Core Attributes">
                <span>
                  <LoadingButton
                    color="primary"
                    onClick={() => getGenAiEnrichedCoreEntityAttributes()}
                    variant="outlined"
                    loading={fetchLoading}
                    disabled={
                      !enrichedTableData.coreEntity ||
                      !enrichedTableData.entityType ||
                      !enrichedTableData.dataEntityName ||
                      !enrichedTableData.description
                    }
                  >
                    {/* Refresh Core Attributes */}
                    {<RefreshIcon fontSize="small" />}
                  </LoadingButton>
                </span>
              </Tooltip>
            </Grid>
            <Grid item xs={12}>
              <TextField
                placeholder="@Id, @Name"
                size="small"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={userInput3}
                onChange={transformationTextChangeHandler3}
              />
              <Menu
                anchorEl={anchorEl3}
                open={Boolean(anchorEl3)}
                onClose={() => setAnchorEl3(null)}
                PaperProps={{
                  style: {
                    width: '20%',
                  },
                }}
              >
                <Autocomplete
                  options={coreEntityAttribute}
                  getOptionLabel={(option) => `${option.dataEntityName}:${option.dataAttributeName}`}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleSelectMention3(
                        newValue.dataAttributeName,
                        newValue.id,
                        newValue.description,
                        newValue.dataAttributeType,
                        newValue.dataEntityId,
                      )
                    }
                  }}
                  renderInput={(params) => (
                    <TextField {...params} placeholder="Search..." variant="outlined" size="small" fullWidth />
                  )}
                  renderOption={(props, option) => (
                    <MenuItem
                      {...props}
                      key={option.id}
                      onClick={() => {
                        handleSelectMention3(
                          option.dataAttributeName,
                          option.id,
                          option.description,
                          option.dataAttributeType,
                          option.dataEntityId,
                        )
                      }}
                    >
                      {option.dataEntityName}:{option.dataAttributeName}
                    </MenuItem>
                  )}
                  style={{ width: '100%' }}
                />
              </Menu>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Specific list of data attributes needed for the enriched entity. Eg. @CustomerID @TransactionDate
                @TransactionAmount @SubscriptionStart @@SubscriptionEnd, @InteractionHistory @CustomerDemographics
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!fetchedRelations.length && (
                <Grid justifyContent={'center'}>
                  <Typography variant="body1" color="textSecondary">
                    Warning: No relationships have been discovered between the selected entities. Code generation is
                    disabled until valid relationships are defined.
                  </Typography>
                </Grid>
              )}
              <Grid
                item
                container
                style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '5%' }}
              >
                <Grid item>
                  <Typography variant="h6" gutterBottom>
                    Entity Relationships
                  </Typography>
                </Grid>
                <Grid item>
                  <Button onClick={() => setShowAddRelationPopUp(true)}>Add New Relationship</Button>
                  <Tooltip title="Generate Entity Relationships">
                    <span>
                      <LoadingButton
                        loading={isEntityRelationsGenerating}
                        disabled={entityId.length >= 2 ? false : true}
                        onClick={() => onClickGenerateRelations()}
                        color="primary"
                        variant="outlined"
                      >
                        {<RefreshIcon fontSize="small" />}
                      </LoadingButton>
                    </span>
                  </Tooltip>
                </Grid>
                {/* {currentEntityId && <Button onClick={() => onButtonClick()}>Edit In Relationship Page</Button>} */}
              </Grid>
              <ShadowTable
                rows={sourceDataRow}
                columns={sourcePopUpHeaders}
                // selectedRow={`${userSelectedTarget?.targetColumnId ?? 0}`}
                tableActionParams={{
                  onButtonClick: onActionClick,
                  actions: [ShadowTableAction.Editable, ShadowTableAction.Deletable],
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Enter the Prompt text
              </Typography>

              <TextField
                placeholder="possible to use @Aggregate, @groupby & @filter"
                size="small"
                fullWidth
                multiline
                rows={3}
                variant="outlined"
                value={userInput2}
                onChange={transformationTextChangeHandler2}
              />
              <Menu anchorEl={anchorEl2} open={Boolean(anchorEl2)} onClose={() => setAnchorEl2(null)}>
                {promptTextFunctions.map((ent) => (
                  <MenuItem key={ent.id} onClick={() => handleSelectMention2(ent.function)}>
                    {ent.function}
                  </MenuItem>
                ))}
              </Menu>
              <Typography variant="body2" color="textSecondary" gutterBottom>
                Specify the additional information such as implementation steps, aggregation logic, granularity of data,
                range, and any other instruction that are important logic for generating code.
              </Typography>
            </Grid>
            <Grid item xs={12} />
            <Grid item xs={12} container justifyContent={'flex-end'} spacing={1}>
              <Grid item xs={3}>
                <Button variant="contained" fullWidth onClick={onClickHome}>
                  Cancel
                </Button>
              </Grid>
              <Grid item />
              <Grid item xs={5}>
                <LoadingButton
                  fullWidth
                  variant="contained"
                  onClick={clickHandler}
                  loading={loading || processingReq}
                  disabled={
                    !enrichedTableData.coreEntity ||
                    !enrichedTableData.dataEntityName ||
                    !enrichedTableData.description ||
                    !entityId.length ||
                    (entityId.length >= 2 && !fetchedRelations.length) ||
                    !isValidEntry(enrichedTableData.dataEntityName) ||
                    !userInput2 ||
                    !userInput3.length
                  }
                >
                  {clickUpdateLabel}
                </LoadingButton>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container xs={collapseForm ? 4 : 8}>
            <Card>
              <IconButton onClick={() => setCollapseForm(!collapseForm)} size="small">
                {collapseForm ? <ChevronLeftIcon /> : <ChevronRightIcon />}
              </IconButton>
              {/* <ProgressBar loading={processingReq} /> */}
              <AnimatedLoader height="50%" width="40%" loading={processingReq} />
              <Grid container item display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
              <Grid item xs={3}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={generateCodeHandler}
                    startIcon={<Insights/>}
                    disabled={!currentEntityId || !userInput3.length}
                    loading={codeGenerating}
                  >
                    Generate Code
                  </LoadingButton>
                </Grid>
                <Grid item xs={3}>
                  <LoadingButton
                    variant="contained"
                    color="primary"
                    onClick={handleNotebookString}
                    startIcon={<AddOutlined />}
                    disabled={!notebookString}
                    loading={processingReq}
                  >
                    {SaveUpdateLabel}
                  </LoadingButton>
                </Grid>
                <Grid item xs={3}>
                  <Button variant="outlined" onClick={onPreviewButtonClick} disabled={!customEntityId}>
                    Data Preview
                  </Button>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    aria-label="more"
                    id="long-button"
                    color="primary"
                    aria-controls={anchorEl ? 'long-menu' : undefined}
                    aria-haspopup="true"
                    onClick={handleClick}
                    disabled={!customEntityId}
                  >
                    <SettingsIcon />
                  </IconButton>
                  <Menu id="long-menu" anchorEl={anchorEl4} open={Boolean(anchorEl4)} onClose={handleClose}>
                    {loadOptions.map((loadOption) => (
                      <MenuItem
                        key={loadOption.value}
                        onClick={() => handleSubmit(loadOption.value)}
                        selected={loadOption.value === selectedValue}
                      >
                        {loadOption.label}
                      </MenuItem>
                    ))}
                  </Menu>
                </Grid>
              </Grid>
              <LucidJupyter
                executeNotebook={executeNotebook}
                noteBookString={notebookString}
                headingText={constants.codeHeaderText}
                updateNoteBookString={setNotebookString}
                commitMessage={commitMessage}
                updateCommitMessage={setcommitMessage}
              />
            </Card>
          </Grid>
        </Grid>

        <LucidPopUp
          showCloseIcon
          closePopup={setShowPopUp}
          openPopUp={showPopUp}
          onConfirm={handleNext}
          headingText=""
          confirmText="Commit"
        >
          <Grid item container xs={12} spacing={2}>
            <Grid item xs={12} />
            <Grid item xs={12}>
              <Link target="blank">Git Hub Repository</Link>
            </Grid>
            <Grid item xs={12}>
              <TextField
                sx={{ fontSize: 12 }}
                fullWidth
                id="outlined-basic"
                label="Enter Commit Message"
                variant="outlined"
                color="secondary"
                required
                rows={2}
                multiline
                value={commitMessage}
                onChange={({ target }) => onTextChange('commitMessage', target.value)}
              />
            </Grid>
          </Grid>
        </LucidPopUp>
        {selectedEntityRelation && dataPodId && (
          <EditEntityRelationPopup
            openPopUp={showEditRelationPopUp}
            closePopUp={setShowEditRelationPopUp}
            showDeleteWarning={showDeleteRelationWarning}
            setShowDeleteWarning={setShowDeleteRelationWarning}
            dataPodId={dataPodId}
            currentEntityId={currentEntityId}
            selectedCoreEntityName={enrichedTableData?.dataEntityName}
            selectedEntityRelation={selectedEntityRelation}
            setSelectedEntityRelation={setSelectedEntityRelation}
            allEntities={entityRelationSuggestion}
            entityRelationData={fetchedRelations}
            setEntityRelationData={setFetchedRelations}
          />
        )}
        {showAddRelationPopUp && dataPodId && (
          <AddNewEntityRelation
            openPopUp={showAddRelationPopUp}
            closePopUp={setShowAddRelationPopUp}
            dataPodId={dataPodId}
            currentEntityId={currentEntityId}
            selectedCoreEntityName={''}
            allEntities={entityRelationSuggestion}
            entityRelationData={fetchedRelations}
            setEntityRelationData={setFetchedRelations}
          />
        )}
      </ActionPage>
    </>
  )
}

interface EditEntityRelationPopupProps {
  openPopUp: boolean
  closePopUp: React.Dispatch<React.SetStateAction<boolean>>
  dataPodId: string
  currentEntityId: number | undefined
  selectedCoreEntityName: string
  setSelectedEntityRelation: React.Dispatch<React.SetStateAction<RelationShipResponse | undefined>>
  entityRelationData: RelationShipResponse[]
  selectedEntityRelation: RelationShipResponse
  allEntities: Entity[]
  showDeleteWarning: boolean
  setShowDeleteWarning: React.Dispatch<React.SetStateAction<boolean>>
  setEntityRelationData: React.Dispatch<React.SetStateAction<RelationShipResponse[]>>
}

const EditEntityRelationPopup: React.FC<EditEntityRelationPopupProps> = ({
  openPopUp,
  closePopUp,
  dataPodId,
  currentEntityId,
  selectedCoreEntityName,
  selectedEntityRelation,
  setSelectedEntityRelation,
  allEntities,
  showDeleteWarning,
  setShowDeleteWarning,
  entityRelationData,
  setEntityRelationData,
}) => {
  const { updateEntityRelation, updateRelationError } = useUpdateEntityRelationship(dataPodId)
  const { confirmDeleteFunc } = useDeleteEntityRelation()

  const { attributeProfiles: leftAttributes } = useGetDataAttributesByEntityId(selectedEntityRelation.leftEntityId)
  const { attributeProfiles: rightAttributes } = useGetDataAttributesByEntityId(selectedEntityRelation.rightEntityId)
  const [alertInfoState, setAlertInfoState] = useState<boolean>(false)
  const [alertInfoMessage, setAlertInfoMessage] = useState<string>('')
  const [errorState, setErrorState] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successState, setSuccessState] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')

  const leftEntitySuggestions = useMemo(() => {
    if (!selectedEntityRelation?.rightEntityId) {
      return allEntities
    }

    return allEntities.filter((entity) => entity.id !== selectedEntityRelation.rightEntityId)
  }, [selectedEntityRelation?.rightEntityId])

  const rightEntitySuggestions = useMemo(() => {
    if (!selectedEntityRelation?.leftEntityId) {
      return allEntities
    }

    return allEntities.filter((entity) => entity.id !== selectedEntityRelation.leftEntityId)
  }, [selectedEntityRelation?.leftEntityId])

  const onClickUpdateRelation = async () => {
    //Checking validation
    if (
      selectedEntityRelation.leftEntityId == 0 ||
      selectedEntityRelation.rightEntityId == 0 ||
      selectedEntityRelation.leftAttributeId == 0 ||
      selectedEntityRelation.rightAttributeId == 0 ||
      selectedEntityRelation.cardinality == ''
    ) {
      setAlertInfoState(true)
      setAlertInfoMessage('Please fill out all the fields.')
      return
    }

    // Checking if relation already exists
    const relationExists = entityRelationData.some(
      (relation) =>
        relation.leftEntityId === selectedEntityRelation.leftEntityId &&
        relation.rightEntityId === selectedEntityRelation.rightEntityId &&
        relation.leftAttributeId === selectedEntityRelation.leftAttributeId &&
        relation.rightAttributeId === selectedEntityRelation.rightAttributeId &&
        relation.cardinality === selectedEntityRelation.cardinality,
    )

    if (relationExists) {
      setAlertInfoState(true)
      setAlertInfoMessage('This relation already exists.')
      return
    }

    //If validation success and enriched entity exists make an api call to update.
    //Else if its new entity, update the relationship in the state itself.
    if (currentEntityId && selectedEntityRelation.id > 0) {
      await callUpdateEntityRelation()
    } else {
      setEntityRelationData((prevData) => {
        const res = prevData.map((relation) =>
          relation.id === selectedEntityRelation.id ? selectedEntityRelation : relation,
        )
        return res
      })
      setSelectedEntityRelation(undefined)
      closePopUp(false)
    }
  }

  const callUpdateEntityRelation = async () => {
    if (!currentEntityId) {
      return
    }

    const payload: EntityRelationship = {
      dataCoreEntityId: selectedEntityRelation.leftEntityId,
      dataSystemEntityId: selectedEntityRelation.rightEntityId,
      joinCoreAttributeId: selectedEntityRelation.leftAttributeId,
      joinSystemAttributeId: selectedEntityRelation.rightAttributeId,
      similarityScoreByName: selectedEntityRelation.similarityScoreByName,
      similarityScoreByData: selectedEntityRelation.similarityScoreByData,
      runId: 0,
      profileName: '',
      cardinality: selectedEntityRelation.cardinality,
      enrichedEntityId: currentEntityId,
      overlapPercentage: selectedEntityRelation.sampleOverlapPercentage || null,
      schemaCompatibility: (selectedEntityRelation.schemaCompatibility == 1 ? true : false) || null,
      datapodId: dataPodId,
      id: selectedEntityRelation.id,
    }

    const updatedEntity = await updateEntityRelation({
      ...payload,
      datapodId: dataPodId,
    })

    if (updateRelationError && !updatedEntity) {
      setErrorState(true)
      setErrorMessage(updateRelationError)
    } else if (updatedEntity) {
      // console.log(successState)
      setSuccessState(true)
      setSuccessMessage('Relationship updated successfully.')
      setSelectedEntityRelation(undefined)
      closePopUp(false)
      return updatedEntity
    }
  }

  const closeEntityRelation = () => {
    setSelectedEntityRelation(undefined)
    closePopUp(false)
  }

  const handleEditEntityRelation = (name: string, value: unknown) => {
    setSelectedEntityRelation((prevData) => ({ ...prevData, [name]: value } as RelationShipResponse))
  }

  const onConfirmDelete = async () => {
    //If enriched entity exist make an api call to delete
    //Else skip this if block and update in the state itself.
    if (selectedEntityRelation?.id > 0 && currentEntityId) {
      const deleteMapSuccess = await confirmDeleteFunc([selectedEntityRelation?.id])
      if (!deleteMapSuccess && selectedEntityRelation?.id) {
        setErrorState(true)
        setErrorMessage('Failed to delete the relationship.')
        return
      } else {
        setSuccessState(true)
        setSuccessMessage('Deleted the relationship successfully.')
      }
    }

    const filteredRows = entityRelationData.filter((r) => r.id !== selectedEntityRelation.id)
    setEntityRelationData(filteredRows)
    closeDeleteWarning()
  }

  const closeDeleteWarning = () => {
    setShowDeleteWarning(false)
  }

  const closeAlertInfo = () => {
    setAlertInfoState(false)
  }
  const closeErrorInfo = () => {
    setErrorState(false)
  }
  const closeSuccessInfo = () => {
    setSuccessState(false)
  }

  return (
    <>
      <ResultStatus
        showStatus={alertInfoState}
        alertMessage={alertInfoMessage}
        severtiy="info"
        closeStatus={() => closeAlertInfo()}
      />
      <ResultStatus
        showStatus={errorState}
        alertMessage={errorMessage}
        severtiy="error"
        closeStatus={() => closeErrorInfo()}
      />
      <ResultStatus
        showStatus={successState}
        alertMessage={successMessage}
        severtiy="success"
        closeStatus={() => closeSuccessInfo()}
      />
      <DeletePopUp
        showDeleteWarning={showDeleteWarning}
        confirmDeleteMap={onConfirmDelete}
        closeDeleteWarning={closeDeleteWarning}
        itemToDelete={''}
        relationToDelete={entityRelationData.find((ed) => ed.id === selectedEntityRelation.id)}
      />
      <LucidPopUp
        headingText={<Typography variant="h4">Edit New Relationship</Typography>}
        openPopUp={openPopUp}
        onConfirm={onClickUpdateRelation}
        closePopup={closeEntityRelation}
        confirmText={'Save'}
        solidCancelButton
        cancelText={'Cancel'}
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h5">Core Entity Name :</Typography>
          </Grid>
          <Grid item xs={6} textAlign={'center'}>
            <Typography variant="h6">{selectedCoreEntityName}</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Left</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-left-entity"
                  options={leftEntitySuggestions}
                  getOptionLabel={(option) => option.dataEntityName}
                  value={allEntities.find((entity) => entity.id === selectedEntityRelation?.leftEntityId) || null}
                  onChange={(event, newValue) => {
                    handleEditEntityRelation('leftEntityId', newValue?.id || 0)
                    handleEditEntityRelation('leftEntityName', newValue?.dataEntityName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Left Entity" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource"
                  options={leftAttributes}
                  getOptionLabel={(option) => option.dataAttributeName}
                  value={leftAttributes.find((attr) => attr.id === selectedEntityRelation?.leftAttributeId) || null}
                  onChange={(event, newValue) => {
                    handleEditEntityRelation('leftAttributeId', newValue?.id || 0)
                    handleEditEntityRelation('leftAttributeName', newValue?.dataAttributeName || 0)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Left Attribute" size="small" required />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Right</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-right-entity"
                  options={rightEntitySuggestions}
                  getOptionLabel={(option) => option.dataEntityName}
                  value={allEntities.find((entity) => entity.id === selectedEntityRelation?.rightEntityId) || null}
                  onChange={(event, newValue) => {
                    handleEditEntityRelation('rightEntityId', newValue?.id || 0)
                    handleEditEntityRelation('rightEntityName', newValue?.dataEntityName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Right Entity" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-right"
                  options={rightAttributes}
                  getOptionLabel={(option) => option.dataAttributeName}
                  value={rightAttributes.find((attr) => attr.id === selectedEntityRelation?.rightAttributeId) || null}
                  onChange={(event, newValue) => {
                    handleEditEntityRelation('rightAttributeId', newValue?.id || 0)
                    handleEditEntityRelation('rightAttributeName', newValue?.dataAttributeName || 0)
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Right Attribute" size="small" required />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={selectedEntityRelation?.cardinality ?? ''}
                  onChange={({ target }) => handleEditEntityRelation('cardinality', target.value)}
                  required
                  size="small"
                  label="Cardinality"
                  select
                >
                  {['one-to-many', 'one-to-one', 'many-to-one', 'many-to-many'].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LucidPopUp>
    </>
  )
}

type AddNewEntityRelationProps = {
  openPopUp: boolean
  closePopUp: React.Dispatch<React.SetStateAction<boolean>>
  selectedCoreEntityName: string
  dataPodId: string
  currentEntityId: number | undefined
  allEntities: Entity[]
  entityRelationData: RelationShipResponse[]
  setEntityRelationData: React.Dispatch<React.SetStateAction<RelationShipResponse[]>>
}

const AddNewEntityRelation: React.FC<AddNewEntityRelationProps> = ({
  openPopUp,
  closePopUp,
  dataPodId,
  currentEntityId,
  selectedCoreEntityName,
  allEntities,
  entityRelationData,
  setEntityRelationData,
}) => {
  const [alertInfoState, setAlertInfoState] = useState<boolean>(false)
  const [alertInfoMessage, setAlertInfoMessage] = useState<string>('')

  const [newEntityRelation, setNewEntityRelation] = useState<RelationShipResponse | undefined>({
    id: entityRelationData.length == 0 ? 1 : entityRelationData.length,
    database: '',
    dataTableId: 0,
    dataTableName: '',
    leftEntityId: 0,
    leftEntityName: '',
    leftAttributeId: 0,
    leftAttributeName: '',
    rightEntityId: 0,
    rightEntityName: '',
    rightAttributeId: 0,
    rightAttributeName: '',
    cardinality: '',
    similarityScoreByName: 1,
    similarityScoreByData: 1,
    dataPodId: '',
    sampleOverlapPercentage: undefined,
    schemaCompatibility: undefined,
  })

  const { attributeProfiles: leftAttributes } = useGetDataAttributesByEntityId(newEntityRelation?.leftEntityId)
  const { attributeProfiles: rightAttributes } = useGetDataAttributesByEntityId(newEntityRelation?.rightEntityId)

  const [errorState, setErrorState] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [successState, setSuccessState] = useState<boolean>(false)
  const [successMessage, setSuccessMessage] = useState<string>('')

  const { addEntityRelation } = useAddEntityRelationship(dataPodId)

  const leftEntitySuggestions = useMemo(() => {
    if (!newEntityRelation?.rightEntityId) {
      return allEntities
    }

    return allEntities.filter((entity) => entity.id !== newEntityRelation.rightEntityId)
  }, [newEntityRelation?.rightEntityId])

  const rightEntitySuggestions = useMemo(() => {
    if (!newEntityRelation?.leftEntityId) {
      return allEntities
    }

    return allEntities.filter((entity) => entity.id !== newEntityRelation.leftEntityId)
  }, [newEntityRelation?.leftEntityId])

  const handleEntityRelationInp = (name: string, value: unknown) => {
    setNewEntityRelation((prevData) => ({ ...prevData, [name]: value } as RelationShipResponse))
  }

  const saveNewEntityRelation = async () => {
    if (newEntityRelation == undefined) {
      return
    } else if (
      newEntityRelation.leftEntityId == 0 ||
      newEntityRelation.rightEntityId == 0 ||
      newEntityRelation.leftAttributeId == 0 ||
      newEntityRelation.rightAttributeId == 0 ||
      newEntityRelation.cardinality == ''
    ) {
      setAlertInfoState(true)
      setAlertInfoMessage('Please fill out all the fields.')
      return
    }

    // Checking if relation already exists
    const relationExists = entityRelationData.some(
      (relation) =>
        relation.leftEntityId === newEntityRelation.leftEntityId &&
        relation.rightEntityId === newEntityRelation.rightEntityId &&
        relation.leftAttributeId === newEntityRelation.leftAttributeId &&
        relation.rightAttributeId === newEntityRelation.rightAttributeId &&
        relation.cardinality === newEntityRelation.cardinality,
    )

    if (relationExists) {
      setAlertInfoState(true)
      setAlertInfoMessage('This relation already exists.')
      return
    }

    if (currentEntityId) {
      callAddEntityRelation()
    } else {
      const length = entityRelationData.length

      setEntityRelationData((prevData) => [...prevData, { ...newEntityRelation, id: length == 0 ? -1 : -length }])
      setNewEntityRelation(undefined)
      closePopUp(false)
    }
  }

  const callAddEntityRelation = async () => {
    if (currentEntityId && newEntityRelation) {
      const payload: EntityRelationship = {
        dataCoreEntityId: newEntityRelation.leftEntityId,
        dataSystemEntityId: newEntityRelation.rightEntityId,
        joinCoreAttributeId: newEntityRelation.leftAttributeId,
        joinSystemAttributeId: newEntityRelation.rightAttributeId,
        similarityScoreByName: newEntityRelation.similarityScoreByName,
        similarityScoreByData: newEntityRelation.similarityScoreByData,
        runId: 0,
        profileName: 'manually-added',
        cardinality: newEntityRelation.cardinality,
        enrichedEntityId: currentEntityId,
        overlapPercentage: newEntityRelation.sampleOverlapPercentage || null,
        schemaCompatibility: (newEntityRelation.schemaCompatibility == 1 ? true : false) || null,
        datapodId: dataPodId,
        id: newEntityRelation.id,
      }

      const enRelation = await addEntityRelation({
        ...payload,
        similarityScoreByData: 1,
        similarityScoreByName: 1,
      })

      if (!enRelation) {
        setErrorState(true)
        setErrorMessage('Failed to add the relationship.')
      } else {
        setSuccessState(true)
        setSuccessMessage('Added the relationship successfully.')
        setNewEntityRelation(undefined)
        closePopUp(false)
      }
    }
  }

  const closeAlertInfo = () => {
    setAlertInfoState(false)
  }

  const closeErrorInfo = () => {
    setErrorState(false)
  }

  const closeSuccessInfo = () => {
    setSuccessState(false)
  }

  return (
    <>
      <ResultStatus
        showStatus={alertInfoState}
        alertMessage={alertInfoMessage}
        severtiy="info"
        closeStatus={() => closeAlertInfo()}
      />
      <ResultStatus
        showStatus={errorState}
        alertMessage={errorMessage}
        severtiy="error"
        closeStatus={() => closeErrorInfo()}
      />
      <ResultStatus
        showStatus={successState}
        alertMessage={successMessage}
        severtiy="success"
        closeStatus={() => closeSuccessInfo()}
      />
      <LucidPopUp
        headingText={<Typography variant="h4">Add New Relationship</Typography>}
        openPopUp={openPopUp}
        onConfirm={saveNewEntityRelation}
        closePopup={closePopUp}
        confirmText="Save"
        solidCancelButton
        cancelText="Cancel"
      >
        <Grid container spacing={2}>
          <Grid item>
            <Typography variant="h5">Core Entity Name :</Typography>
          </Grid>
          <Grid item xs={6} textAlign="center">
            <Typography variant="h6">{selectedCoreEntityName}</Typography>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Left</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-left-entity"
                  options={leftEntitySuggestions}
                  getOptionLabel={(option) => option.dataEntityName}
                  value={allEntities.find((entity) => entity.id === newEntityRelation?.leftEntityId) || null}
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('leftEntityId', newValue?.id || '')
                    handleEntityRelationInp('leftEntityName', newValue?.dataEntityName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Left Entity" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource"
                  options={leftAttributes}
                  getOptionLabel={(option) => option.dataAttributeName}
                  value={leftAttributes.find((attr) => attr.id === newEntityRelation?.leftAttributeId) || null}
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('leftAttributeId', newValue?.id || 0)
                    handleEntityRelationInp('leftAttributeName', newValue?.dataAttributeName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="left Attribute" size="small" required />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item>
              <Typography variant="h5">Right</Typography>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-right-entity"
                  options={rightEntitySuggestions}
                  getOptionLabel={(option) => option.dataEntityName}
                  value={allEntities.find((entity) => entity.id === newEntityRelation?.rightEntityId) || null}
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('rightEntityId', newValue?.id || 0)
                    handleEntityRelationInp('rightEntityName', newValue?.dataEntityName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Right Entity" size="small" required />
                  )}
                />
              </Grid>
              <Grid item xs={6}>
                <Autocomplete
                  fullWidth
                  id="outlined-select-dataSource-right"
                  options={rightAttributes}
                  getOptionLabel={(option) => option.dataAttributeName}
                  value={rightAttributes.find((attr) => attr.id === newEntityRelation?.rightAttributeId) || null}
                  onChange={(event, newValue) => {
                    handleEntityRelationInp('rightAttributeId', newValue?.id || 0)
                    handleEntityRelationInp('rightAttributeName', newValue?.dataAttributeName || '')
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" label="Right Attribute" size="small" required />
                  )}
                />
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="outlined-select-dataSource"
                  variant="outlined"
                  value={newEntityRelation?.cardinality ?? ''}
                  onChange={({ target }) => handleEntityRelationInp('cardinality', target.value)}
                  required
                  size="small"
                  label="cardinality"
                  select
                >
                  <MenuItem></MenuItem>
                  {['one-to-many', 'one-to-one', 'many-to-one', 'many-to-many'].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </LucidPopUp>
    </>
  )
}

export default CustomEnrichedModel
