import axios, { AxiosRequestConfig } from 'axios'
import { environment } from '../utils/environment'

import { getAxiosRequestConfig } from './apiserviceHelpers'
import { sendRequest } from '../requestManager/sendRequest'
import { addDataTargetToSourceColumnMapV2body, updateDataTargetToSourceColumnMapV2body } from '../businessObjects'
const generativeIntegartionsPath = {
  dataSourceToTargetAtrributeMap: 'GenerativeIntegrations/GetDataSourceToTargetAttributeMap',
  getSourceToTargetMapRanking: 'GenerativeIntegrations/GetSourceToTargetMapRanking',
  getDataSourceColumnSelectedTargetsMapV2: 'GenerativeIntegrations/get_vw_dataSourceColumnSelectedTargetsMapV2',
  getDataColumnMapLucidMeansV2: 'GenerativeIntegrations/get_vw_dataColumnMapLucidMeansV2',
  addDataSourceToTargetColumnMapV2: 'GenerativeIntegrations/AdddataSourceToTargetColumnMapV2',
  updateDataSourceToTargetColumnMapV2: 'GenerativeIntegrations/UpdatedataSourceToTargetColumnMapV2',
  getSourceToTargetColumnMapV2: 'GenerativeIntegrations/GetdataSourceToTargetColumnMapV2',
  addDataTableProfiling: 'GenerativeIntegrations/AddDataTableProfiling',
  getDataTableProfiling: 'GenerativeIntegrations/GetdataTableProfiling',
  getDataTableColumnProfiling: 'GenerativeIntegrations/GetDataColumnProfiling',
  getDataColumnProfilingByAttributeId: 'GenerativeIntegrations/GetDataColumnProfilingByAttributeId',
  updateDataTableProfiling:'GenerativeIntegrations/UpdateDataTableProfiling'
}

const getDataSourceToTargetAttributeMap = async (
  token: string,
  datapodId: string,
  Id?: number,
  SourceEntityId?: number,
  TargetEntityId?: number,
  SourceAttributeId?: number,
  TargetAttributeId?: number,
  sourceTableID?: number,
  targetTableID?: number,
  sourceDatasystemID?: number,
  targetDataSystemID?: number,
) => {
  let urlEndPoint = generativeIntegartionsPath.dataSourceToTargetAtrributeMap + `?datapodId=${datapodId}`
  if (Id) {
    urlEndPoint += `&Id=${Id}`
  }
  if (SourceEntityId) {
    urlEndPoint += `&SourceEnityId=${SourceEntityId}`
  }
  if (TargetEntityId) {
    urlEndPoint += `&TargetEntityId=${TargetEntityId}`
  }
  if (SourceAttributeId) {
    urlEndPoint += `&SourceAttributeId=${SourceAttributeId}`
  }
  if (TargetAttributeId) {
    urlEndPoint += `&TargetAttributeId=${TargetAttributeId}`
  }
  if (sourceTableID) {
    urlEndPoint += `&sourceTableID=${sourceTableID}`
  }
  if (targetTableID) {
    urlEndPoint += `&targetTableID=${targetTableID}`
  }
  if (sourceDatasystemID) {
    urlEndPoint += `&sourceDatasystemID=${sourceDatasystemID}`
  }
  if (targetDataSystemID) {
    urlEndPoint += `&targetDataSystemID=${targetDataSystemID}`
  }
  console.log({ urlEndPoint })
  return await sendRequest(urlEndPoint, 'GET', token)
}

const downloadSourceToTargetMapRanking = async (token: string, datapodId: string) => {
  let urlEndPoint = generativeIntegartionsPath.getSourceToTargetMapRanking + `?datapodId=${datapodId}`
  return await sendRequest(urlEndPoint, 'GET', token)
}

const getDataSourceColumnSelectedTargetsMapV2 = async (
  token: string,
  datapodId: string,
  dataTableId?: number,
  sourceColumnId?: number,
  targetColumnId?: number,
) => {
  let urlEndPoint = generativeIntegartionsPath.getDataSourceColumnSelectedTargetsMapV2 + `?datapodId=${datapodId}`
  if (dataTableId) {
    urlEndPoint += `&dataTableId=${dataTableId}`
  }
  if (sourceColumnId) {
    urlEndPoint += `&sourceColumnId=${sourceColumnId}`
  }
  if (targetColumnId) {
    urlEndPoint += `&targetColumnId=${targetColumnId}`
  }
  return await sendRequest(urlEndPoint, 'GET', token)
}
const geDataSourceToTargetColumnMapV2 = async (
  token: string,
  datapodId: string,
  sourceColumnId?: number,
  targetColumnId?: number,
  Id?: number,
) => {
  let urlEndPoint = generativeIntegartionsPath.getSourceToTargetColumnMapV2 + `?datapodId=${datapodId}`
  if (sourceColumnId) {
    urlEndPoint += `&sourceColumnId=${sourceColumnId}`
  }
  if (targetColumnId) {
    urlEndPoint += `&targetColumnId=${targetColumnId}`
  }
  if (Id) {
    urlEndPoint += `&id=${Id}`
  }
  return await sendRequest(urlEndPoint, 'GET', token)
}

const getDataColumnMapLucidMeansV2 = async (
  token: string,
  datapodId: string,
  sourceColumnId?: number,
  targetColumnId?: number,
) => {
  let urlEndPoint = generativeIntegartionsPath.getDataColumnMapLucidMeansV2 + `?datapodId=${datapodId}`
  if (sourceColumnId) {
    urlEndPoint += `&sourceColumnId=${sourceColumnId}`
  }
  if (targetColumnId) {
    urlEndPoint += `&targetColumnId=${targetColumnId}`
  }
  return await sendRequest(urlEndPoint, 'GET', token)
}

const addDataTargetToSourceColumnMapV2 = async (
  token: string,
  datapodId: string,
  body: addDataTargetToSourceColumnMapV2body,
) => {
  let urlEndPoint = generativeIntegartionsPath.addDataSourceToTargetColumnMapV2 + `?datapodId=${datapodId}`
  return await sendRequest(urlEndPoint, 'POST', token, body)
}
const updateDataTargetToSourceColumnMapV2 = async (
  token: string,
  datapodId: string,
  body: updateDataTargetToSourceColumnMapV2body,
) => {
  let urlEndPoint = generativeIntegartionsPath.updateDataSourceToTargetColumnMapV2 + `?datapodId=${datapodId}`
  return await sendRequest(urlEndPoint, 'PUT', token, body)
}

const addDataTableProfiling = async <T>(token: string, datapodId: string, body?: T) => {
  return await sendRequest(
    generativeIntegartionsPath.addDataTableProfiling + `?dataPodId=${datapodId}`,
    'POST',
    token,
    body,
  )
}

const updateDataTableProfiling = async <T>(token: string, datapodId: string, body?: T) => {
  return await sendRequest(
    generativeIntegartionsPath.updateDataTableProfiling + `?dataPodId=${datapodId}`,
    'PUT',
    token,
    body,
  )
}
const getDataTableProfiling = async (token: string, datapodId: string, datatableId: number) => {
  const url = generativeIntegartionsPath.getDataTableProfiling + `?dataPodId=${datapodId}&datatableId=${datatableId}`
  return await sendRequest(url, 'GET', token)
}
const getDataColumnProfiling = async (token: string, datapodId: string, datatableId: number, columnId: number) => {
  const url =
    generativeIntegartionsPath.getDataTableColumnProfiling +
    `?dataPodId=${datapodId}&datatableId=${datatableId}&datacolumnId=${columnId}`
  return await sendRequest(url, 'GET', token)
}

const getDataColumnProfilingByAttributeId = async (token: string, attributeId: number) => {
  const url = generativeIntegartionsPath.getDataColumnProfilingByAttributeId + `?attributeID=${attributeId}`
  return await sendRequest(url, 'GET', token)
}

export const generativeIntegartionServices = {
  getDataSourceToTargetAttributeMap,
  downloadSourceToTargetMapRanking,
  geDataSourceToTargetColumnMapV2,
  getDataSourceColumnSelectedTargetsMapV2,
  getDataColumnMapLucidMeansV2,
  addDataTargetToSourceColumnMapV2,
  updateDataTargetToSourceColumnMapV2,
  addDataTableProfiling,
  updateDataTableProfiling,
  getDataTableProfiling,
  getDataColumnProfiling,
  getDataColumnProfilingByAttributeId,
}
