import React, { useEffect, useMemo, useState } from 'react'
import {
  Grid,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Button,
  Typography,
} from '@mui/material'
import { useGetEnrichedlData } from '../../../hooks/dataLoadHooks'
import { useParams, generatePath, useNavigate } from 'react-router-dom'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import { ROUTES } from 'Routes/constants'
import ActionPage from 'ui-components/ActionPage'

type ViewResultsParams = {
  dataPodId: string
  customEntityID: string
}

export const EnrichedDataPreview = () => {
  const { dataPodId, customEntityID } = useParams<ViewResultsParams>()
  const entityId = customEntityID ? Number(customEntityID) : undefined
  const { fetchEnrichedDataByDatapodId, fetchedEnrichedData } = useGetEnrichedlData(dataPodId, entityId)
  const [tableData, setTableData] = useState<any[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [sortBy, setSortBy] = useState<string | null>(null)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')
  const navigate = useNavigate()

  const scrollableContainer = {
    maxWidth: '150vh',
    overflowY: 'auto',
  }

  const tableValues = useMemo(() => {
    if (!fetchedEnrichedData.length) return null

    // Copy the fetched data to avoid mutations
    const clonedData = [...fetchedEnrichedData]

    // Sort data if sortBy is set
    if (sortBy) {
      clonedData.sort((a, b) => {
        const aValue = a[sortBy]
        const bValue = b[sortBy]

        if (aValue < bValue) return sortOrder === 'asc' ? -1 : 1
        if (aValue > bValue) return sortOrder === 'asc' ? 1 : -1
        return 0
      })
    }

    const columnHeaders = Object.keys(clonedData[0])
    let rows: any[] = []
    clonedData.forEach((cd, ix) => {
      rows = [...rows, columnHeaders.map((ckey) => cd[ckey])]
    })
    return { columnHeaders, rows }
  }, [fetchedEnrichedData, sortBy, sortOrder])

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  const onClickBack = (event: any): void => {
    navigate(generatePath(ROUTES.EditCustomEnrichedModel, { dataPodId: dataPodId, customEntityId: customEntityID }))
  }

  const handleSort = (column: string) => {
    if (sortBy === column) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortBy(column)
      setSortOrder('asc')
    }
  }

  return (
    <Grid container>
      <Grid item container direction="row" justifyContent="flex-start" xs={12} sx={{ ...scrollableContainer }}>
        <Grid item xs={12}>
          <Button
            variant="outlined"
            onClick={onClickBack}
            startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
          >
            Back
          </Button>
        </Grid>
        <TableContainer sx={{ maxHeight: 460, mt: 2, ...scrollableContainer }}>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {tableValues ? (
                  tableValues.columnHeaders.map((header, index) => (
                    <TableCell key={index} onClick={() => handleSort(header)}>
                      {header} {sortBy === header ? (sortOrder === 'asc' ? '▲' : '▼') : ''}
                    </TableCell>
                  ))
                ) : (
                  <TableCell align="center">No data found</TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {tableValues?.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ix) => (
                <TableRow key={ix}>
                  {row.map(
                    (
                      cell:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined,
                      cellIx: React.Key | null | undefined,
                    ) => (
                      <TableCell key={cellIx}> {cell} </TableCell>
                    ),
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={tableValues?.rows.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Grid>
      <Grid item container xs={12} justifyContent="center" alignItems="center">
        <Typography color={'primary'} variant="caption">
          *Only Top 100 rows are loaded in preview
        </Typography>
      </Grid>
    </Grid>
  )
}
