import { useState } from "react"
import { useLucidAuthContext } from "../LucidAuthContext/LucidAuthContext"
import { createMultipleCoreEntityAttributes, getCoreEntityAttribute, updateCoreEntityAttribute } from "../apiServices/coreEntityAttributes"
import { AddCoreEntityAttribute, AddDataHubEntityMap, GenAIRelationsBody, addEntityMapResponse, coreEntityAttributeData, createDataHubBody, getCoreEntityAttributeResponse, getDataHubEntityMapResponse, getDataHubResponse, updateDataHubBody } from "../businessObjects"
import { dataHubServices } from "apiServices/dataHubs"

type GetDataHubs = (id?:string, accountId?:string) => Promise<getDataHubResponse[] | void>

type GetDataHubById = (id?:string, accountId?:string) => Promise<getDataHubResponse | undefined>
type updateDataHubById = (body:updateDataHubBody[]) => Promise<string | undefined>

type  Pagination = {
  updatePageCount: (pageCount: number) => void;
}

type addSuccessType ={
  flag:boolean,
  data?: getDataHubResponse
  message?:string
}
type addSuccessHubEntityType ={
  flag:boolean,
  data?: addEntityMapResponse
  message?:string
}

export const useAddDataHub = () => {
    const { getBearerToken } = useLucidAuthContext()
    const submitNewdataHub = async (params: createDataHubBody[]): Promise<addSuccessType | undefined> => {
      const bearerToken = await getBearerToken()
      try{
        if (bearerToken) {
          const { data, status } = await dataHubServices.createDataHub(bearerToken, params)
          if (status == 201 && data){
            const returnSuccess = {
              flag:true,
              data: data[0] as getDataHubResponse
            }
            return returnSuccess
          }else{
            const returnFailure = {
              flag:false,
              message:'Failed Creating the Hub'
            }
            return returnFailure
          }
        }
      }catch(err){
        const returnError = {
          flag:false,
          message: err as string
        }
        return returnError
      }
    }
    return [submitNewdataHub]
}
// export const useGetAllDataHubs= (
//   ): [GetDataHubs, getDataHubResponse[] | undefined, boolean | undefined, boolean | undefined] => {
//     const [dataHubs, setDataHubs] = useState<getDataHubResponse[]>()
//     const [loading, setLoading] = useState<boolean>(false)
//     const [createError, setCreateError] = useState<boolean>()
//     const { getBearerToken, accountId } = useLucidAuthContext()
//     const fetchDataHubs = async (id?:string): Promise<getDataHubResponse[] | void> => {
//       setLoading(true)
//       try {
//         const bearerToken = await getBearerToken()
//         if (bearerToken) {
//             console.log("Inside the if of the bearer token")
//             if( id && accountId){
//                 const {data, status} = await dataHubServices.getAllDataHubs(bearerToken, id, accountId)
//                 if(status == 200){
//                     setDataHubs(data)
//                     return data
//                 }else{
//                     return []
//                 }
//             }else if (accountId){
//                 const {data, status} = await dataHubServices.getAllDataHubs(bearerToken,undefined, accountId)
//                 if(status == 200){
//                     setDataHubs(data)
//                     return data
//                 }else{
//                     return []
//                 }
//             }
//             setLoading(false)
//         }
//       } catch (error) {
//         setCreateError(true)
//         setLoading(false)
//       }
//     }
//     return [fetchDataHubs, dataHubs, loading, createError]
// }
export const useGetAllDataHubs = (
  pagination?: Pagination,
  searchText?: string,
  recordCount?: number,
  pageNumber?: number
): [GetDataHubs, getDataHubResponse[] | undefined, boolean | undefined, boolean | undefined] => {
  const [dataHubs, setDataHubs] = useState<getDataHubResponse[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [createError, setCreateError] = useState<boolean>();
  const { getBearerToken, accountId } = useLucidAuthContext();

  const fetchDataHubs = async (id?: string): Promise<getDataHubResponse[] | void> => {
    setLoading(true);
    try {
      const bearerToken = await getBearerToken();
      if (bearerToken && accountId) {
        const { data, status, headers } = await dataHubServices.getAllDataHubs(
          bearerToken,
          id,
          accountId,
          recordCount ? recordCount : 100,
          pageNumber ? pageNumber : 1,
          // searchText
        );

        if (status == 200) {
          setDataHubs(data);
          setLoading(false);
          if (pagination?.updatePageCount && recordCount) {
            const totalRecords = headers['x-total-count'];
            const totalPages = Math.ceil(totalRecords / recordCount);
            pagination?.updatePageCount(totalPages);
          }   
          return data;
        } else {
          setDataHubs([]);
          setLoading(false);
          return [];
        }
      } else {
        setDataHubs([]);
        setLoading(false);
        return [];
      }
    } catch (error) {
      setCreateError(true);
      setLoading(false);
    }
  };

  return [fetchDataHubs, dataHubs, loading, createError];
}

export const useGetDataHubById = (
): [GetDataHubById, getDataHubResponse | undefined, boolean | undefined, boolean | undefined] => {
  const [dataHubs, setDataHubs] = useState<getDataHubResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [createError, setCreateError] = useState<boolean>();
  const { getBearerToken, accountId } = useLucidAuthContext();

  const fetchDataHubById = async (id?: string): Promise<getDataHubResponse | undefined> => {
    setLoading(true);
    try {
      const bearerToken = await getBearerToken();
      if (bearerToken && accountId && id) {
        const { data, status } = await dataHubServices.getAllDataHubs(
          bearerToken,
          id,
          accountId,
        );

        if (status == 200 && data.length) {
          setDataHubs(data);
          setLoading(false);
          return data[0] as getDataHubResponse;
        } else {
          setDataHubs(undefined);
          setLoading(false);
          return undefined;
        }
      } else {
        setDataHubs(undefined);
        setLoading(false);
        return undefined;
      }
    } catch (error) {
      setCreateError(true);
      setLoading(false);
      return undefined
    }
  };

  return [fetchDataHubById, dataHubs, loading, createError];
}

export const useUpdateDataHub = (
): [updateDataHubById, getDataHubResponse | undefined, boolean | undefined, unknown | undefined] => {
  const [dataHubs, setDataHubs] = useState<getDataHubResponse>();
  const [loading, setLoading] = useState<boolean>(false);
  const [createError, setCreateError] = useState<unknown>();
  const { getBearerToken, accountId } = useLucidAuthContext();

  const updateDataHubById = async (body: updateDataHubBody[]): Promise<string | undefined> => {
    setLoading(true);
    try {
      const bearerToken = await getBearerToken();
      if (bearerToken && accountId && body) {
        const { data, status } = await dataHubServices.updateDataHub(
          bearerToken,
          body
        );

        if (status == 200) {
          setDataHubs(data);
          setLoading(false);
          return data;
        } else {
          setDataHubs(undefined);
          setLoading(false);
          return undefined;
        }
      } else {
        setDataHubs(undefined);
        setLoading(false);
        return undefined;
      }
    } catch (error) {
      setCreateError(error);
      setLoading(false);
      return undefined
    }
  };

  return [updateDataHubById, dataHubs, loading, createError];
}

export const useAddDataHubEntityMap = () => {
  const { getBearerToken } = useLucidAuthContext()
  const submitNewDataHubEntityMap = async (params: AddDataHubEntityMap[]): Promise<addSuccessHubEntityType| undefined> => {
    const bearerToken = await getBearerToken()
    try{
      if (bearerToken) {
        const { data, status } = await dataHubServices.addDataHubEntityMaps(bearerToken, params)
        if (status == 201 && data){
          const returnSuccess = {
            flag:true,
            data: data[0] as addEntityMapResponse
          }
          return returnSuccess
        }else{
          const returnFailure = {
            flag:false,
            message:'Failed Creating the Hub'
          }
          return returnFailure
        }
      }
    }catch(err){
      const returnError = {
        flag:false,
        message: err as string
      }
      return returnError
    }
  }
  return [submitNewDataHubEntityMap]
}

export const useGetDataHubEntityMaps = () =>{
  const {getBearerToken} = useLucidAuthContext()
  const getDataHubEntities = async(dataHubId?:string, id?:number,dataEntityId?:number, dataPodId?:string, ) =>{
    const bearerToken = await getBearerToken()
    try{
      if(bearerToken && dataHubId){
        const {data,status} = await dataHubServices.getDataHubEntityMaps(bearerToken, undefined, dataHubId)
        if(status == 200 && data){
          return data
        }else{
          return false
        }
      }else if(bearerToken && id){
        const {data,status} = await dataHubServices.getDataHubEntityMaps(bearerToken, id)
        if(status == 200 && data){
          return data
        }else{
          return false
        }
      }
    }catch(err){
      return err
    }
  }
  return [getDataHubEntities]
}

export const useGetEntityAttributes = () =>{
  const {getBearerToken} = useLucidAuthContext()
  const getDataHubEntityAttributes = async(dataPodId:string, dataEntityId?:number, hubId?:number) =>{
    const bearerToken = await getBearerToken()
    try{
      if(bearerToken && dataEntityId && hubId){
        const {data,status} = await dataHubServices.getDataHubEntityAttributes(bearerToken, dataPodId, dataEntityId, hubId)
        if(status == 200 && data){
          return data
        }else{
          return false
        }
      }else if(bearerToken && dataEntityId){
        const {data,status} = await dataHubServices.getDataHubEntityAttributes(bearerToken, dataPodId, dataEntityId)
        if(status == 200 && data){
          return data
        }else{
          return false
        }
      }
      else if(bearerToken && hubId){
        const {data,status} = await dataHubServices.getDataHubEntityAttributes(bearerToken, dataPodId, hubId)
        if(status == 200 && data){
          return data
        }else{
          return false
        }
      }
    }catch(err){
      return err
    }
  }
  return [getDataHubEntityAttributes]
}