import { useAccount, useIsAuthenticated, useMsal } from '@azure/msal-react'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { getAccessToken } from '../apiServices/getAccessToken'
import { accountServices } from '../apiServices/lucidAccount'
import { LucidAccount, UserAccount } from '../businessObjects'
import { AxiosError } from 'axios'
import { getAccountToken, setAccountToken } from '../utils/tokens'
import { useNavigate } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import { environment } from 'utils/environment'
import { userServices } from 'apiServices/user'
import { callMsGraph } from 'graph'
import { graphConfig } from 'authConfig'
import { useGetConfigurations } from 'hooks/lucidConfigurationHooks'
import { configurationServices } from 'apiServices/lucidConfiguration'
import { InteractionRequiredAuthError } from '@azure/msal-browser'

interface ApiToken {
  token_type: string
  expires_in: number
  ext_expires_in: number
  access_token: string
}

export interface LuciAuthContextProps {
  tenantId: string | null
  accountId: string | null
  lucidAccount: LucidAccount | null
  userAccount: UserAccount | null
  isUserAdmin?: boolean
  updatePreferredAccountId: (id: string) => void
  getBearerToken: () => Promise<string | undefined>
  getFabricBearerToken: () => Promise<string | undefined>
  getGraphAPIBearerToken: () => Promise<string | undefined>
  lucidUserId: number | null
  setLucidUserId: (id: number) => void
}

export const LucidAuthContext = createContext<LuciAuthContextProps>({
  tenantId: null,
  accountId: null,
  lucidAccount: null,
  userAccount: null,
  isUserAdmin: false,
  updatePreferredAccountId: (id: string) => {
    return
  },
  getBearerToken: async () => undefined,
  getFabricBearerToken: async () => undefined,
  getGraphAPIBearerToken: async () => undefined,
  lucidUserId: null,
  setLucidUserId: (id: number) => {
    return
  },
})
// const calculateTokenExpiration = (expiresIn?: number): number => {
//   const currentTime = new Date().getTime()
//   const expiresInMs = expiresIn ? expiresIn * 1000 : 0 // Convert expiresIn seconds to milliseconds
//   const expirationTime = currentTime + expiresInMs
//   return expirationTime
// }

export const isTokenExpired = (expriryTime: number) => {
  const currentTime = new Date().getTime()
  // console.log({ expriryTime }, { currentTime })
  const expTime = (expriryTime ? expriryTime : 0) * 1000
  return expTime - currentTime <= 60000
}

// const evaluateIDTokenExpiry = (tokenExp?: number) => {
//   // const expTime = calculateTokenExpiration(tokenExp)
//   // console.log({ expTime })
//   return isTokenExpired(tokenExp)
// }
export const useLucidAuthContext = () => useContext<LuciAuthContextProps>(LucidAuthContext)

export const LucidAuthContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userId, setUserId] = useState<number | null>(null)
  const [tenantId, setTenantId] = useState<string | null>(null)
  const [accountId, setAccountId] = useState<string | null>(getAccountToken())
  const [lucidAccount, setLucidAccount] = useState<LucidAccount | null>(null)
  const [userAccount, setUserAccount] = useState<UserAccount | null>(null)
  const [isUserAdmin, setIsUserAdmin] = useState<boolean>()
  const { getConfigurations } = useGetConfigurations()
  // const [expiresIn, setExpiresIn] = useState<number | null>(null)
  // const [bearerToken, setBearerToken] = useState<string | null>(null)
  const isAuthenticated = useIsAuthenticated()
  const { accounts, instance } = useMsal()
  const account = useAccount(accounts[0] || {})
  const navigate = useNavigate()

  useEffect(() => {
    if (isAuthenticated && account && !tenantId) {
      setTenantId(account.tenantId)
      getLucidAccount(account.tenantId)
    }

    //If account exists but there is no user to it.
    if(isAuthenticated && accountId && !userId) {
      getUserAccount(accounts[0].username)
    }
  }, [isAuthenticated, account, accountId])

  useEffect(() => {
    if(environment.isAdminRestricted && isUserAdmin == undefined)
      fetchUserAdmin();
    else 
      setIsUserAdmin(true);
  }, [isAuthenticated, account]);
  
  const fetchUserAdmin = async () => {
    if(account?.idToken)
    {
      try {
        const token = await getGraphAPIBearerToken();
        if (!token) return;
  
        const {data} = await configurationServices.getConfigurationValue(account?.idToken, 'AzureEntraAdminGroup');
        
        const endpoint = graphConfig.graphMeGrpEndPoint.replace('GROUP_NAME_VALUE', data);
  
        const res = await callMsGraph(token, endpoint);
        const member = res?.value[0]?.members?.find((member: any) => member.id === accounts[0]?.localAccountId);
  
        if (member) {
          setIsUserAdmin(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
        // Handle errors as needed
      }
    }
  };

  // const getIdToken = async (): Promise<string | undefined> => {
  //   return accounts[0].idToken
  // }

  const getNewBearerToken = async (): Promise<string | undefined> => {
    // Id token issued by azureAD gets expired  approx 1 hour.
    // Idtoken expiry is evaluated and refetch new IdToken. The refresh happens silently without affecting user session
    // https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/token-lifetimes.md
    try {
      const idTokenExp = account?.idTokenClaims?.exp

      if (idTokenExp && !isTokenExpired(idTokenExp)) {
        return account.idToken
      }
      const tokenRefreshRes = await instance.acquireTokenSilent({
        scopes: [],
        forceRefresh: true,
        refreshTokenExpirationOffsetSeconds: 3000,
      })

      // console.log({ tokenRefreshRes })
      return tokenRefreshRes.idToken
    } catch (error) {
      console.log(error)
    }

    // try {
    //   const { data, status } = await getAccessToken()
    //   if (status == 200) {
    //     const { access_token, expires_in } = data as ApiToken
    //     setBearerToken(access_token)
    //     setExpiresIn(calculateTokenExpiration(expires_in))
    //     return access_token
    //   }
    // } catch (error) {
    //   console.log(error)
    // }
  }

  const getGraphAPIBearerToken = async (): Promise<string | undefined> => {
    try {
      if(isAuthenticated)
      {
        const response = await instance.acquireTokenSilent({
          scopes: ["user.read"],
          account: accounts[0],
        })
  
        return response.accessToken
      }

    } catch (error) {
      console.log(error)
    }
  }

  // Function to acquire token silently
  const acquireTokenSilently = async (scopes: string[]): Promise<string | undefined> => {
    try {
      const response = await instance.acquireTokenSilent({
        scopes: scopes,
        account: accounts[0],
      });

      return response.accessToken;
    } catch (error) {
      console.error("Silent token acquisition failed", error);
      throw error; // Rethrow error to handle it in the main function
    }
  };

  // Function to acquire token via popup
  const acquireTokenWithPopup = async (scopes: string[]): Promise<string | undefined> => {
    try {
      const response = await instance.acquireTokenPopup({
        scopes: scopes,
        account: accounts[0],
      });

      return response.accessToken;
    } catch (error) {
      console.error("Popup token acquisition failed", error);
      return undefined;
    }
  };

  // Function to acquire token via redirect as fallback
  const acquireTokenWithRedirect = async (scopes: string[]): Promise<void> => {
    try {
      await instance.acquireTokenRedirect({
        scopes: scopes,
        account: accounts[0],
      });
    } catch (error) {
      console.error("Redirect token acquisition failed", error);
    }
  };

  const getFabricBearerToken = async (): Promise<string | undefined> => {
    if (!isAuthenticated) return undefined;

    const scopes = [
      'https://api.fabric.microsoft.com/Workspace.ReadWrite.All',
      'https://api.fabric.microsoft.com/Item.ReadWrite.All',
      'https://api.fabric.microsoft.com/Item.Execute.All',
    ]

    try {
      // Try to acquire token silently first
      return await acquireTokenSilently(scopes);
    } catch (silentError) {
      // If silent acquisition fails due to interaction required, fall back to popup
      if (silentError instanceof InteractionRequiredAuthError) {
        // return await acquireTokenWithPopup(scopes);
        await acquireTokenWithRedirect(scopes);
      } else {
        console.error("Unhandled error during token acquisition", silentError);
        return undefined;
      }
    }
  };


  const getLucidAccount = async (usrTenantId: string) => {
    try {
      const token = await getNewBearerToken()

      if (token && usrTenantId) {
        const { data, status } = await accountServices.getAllAccountsByTenantID(token, usrTenantId)
        if (status == 200) {
          const usrAccount = data as LucidAccount[]
          //'067B78ED-825E-493F-A9D0-F0A13512AF15'
          if (usrAccount[0].accountId) {
            setAccountToken(usrAccount[0].accountId)
            setAccountId(usrAccount[0].accountId)
            setLucidAccount(usrAccount[0])
          } else {
            navigate(ROUTES.SignUpHome)
          }
        }
      }
    } catch (error) {
      const err = error as AxiosError
      console.log(err.message)
    }
  }
  const updateAccountId = (id: string) => {
    setAccountId(id)
    setAccountToken(id)
  }

  const getUserAccount = async (mailId: string) => {
    try {
      const token = await getNewBearerToken()

      if (token && mailId) {
        const { data, status } = await userServices.getUserByMailId(token, mailId)
        if (status == 200) {
          const usrAccount = data as UserAccount
          if (usrAccount) {
            setUserId(usrAccount.id)
            setUserAccount(usrAccount)
          } else {
            navigate(ROUTES.SignUpHome)
          }
        }
      }
    } catch (error) {
      const err = error as AxiosError

      if (err.response && err.response.status === 404) {
        navigate(ROUTES.SignUpHome)
      }
      console.log(err.message)
    }
  }

  return (
    <LucidAuthContext.Provider
      value={{
        tenantId,
        accountId,
        lucidUserId: userId,
        lucidAccount,
        userAccount,
        isUserAdmin,
        setLucidUserId: setUserId,
        updatePreferredAccountId: updateAccountId,
        // getBearerToken: getIdToken,
        getBearerToken: getNewBearerToken,
        getFabricBearerToken,
        getGraphAPIBearerToken,
      }}
    >
      {children}
    </LucidAuthContext.Provider>
  )
}

export default LucidAuthContextProvider
