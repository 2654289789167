import { Grid, GridSize, IconButton, InputAdornment, TextField } from '@mui/material'
import React from 'react'
import { LucidTextLabel } from '../LucidTextLabel'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
type Props = {
  labelText?: string
  value: string
  changeHandle: (text: string, key?: string) => void
  columnSize?: 'auto' | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12
  required?: boolean
  fullWidth?: boolean
  validateInput?: boolean
  validateErrorText?: string
  placeholderText?: string
  disabled?: boolean
  searchType?: boolean
  labelPostion?: 'top' | 'left'
  multiline?: 2 | 3 | 4
}

const TextFormInput = ({
  labelText,
  value,
  changeHandle,
  columnSize,
  required,
  fullWidth,
  validateErrorText,
  validateInput,
  placeholderText,
  disabled,
  searchType,
  multiline,
  labelPostion = 'top',
}: Props) => {
  return (
    <Grid item xs={columnSize ? columnSize : 15} container direction={labelPostion === 'top' ? 'row' : 'column'}>
      {labelText && (
        <Grid item xs={15}>
          <LucidTextLabel labelText={labelText} required={required} />
        </Grid>
      )}

      <Grid item xs={15}>
        <TextField
          fullWidth
          id={labelText + 'label-id'}
          placeholder={placeholderText}
          variant="outlined"
          color="primary"
          size="small"
          onChange={({ target }) => changeHandle(target.value)}
          value={value}
          disabled={disabled}
          error={validateInput}
          multiline={!!multiline}
          rows={multiline}
          helperText={validateErrorText}
          type={searchType ? 'search' : undefined}
          sx={{ fill: 'grey' }}
          InputProps={{
            startAdornment: <InputAdornment position="start">{searchType && <SearchOutlinedIcon />}</InputAdornment>,
            // endAdornment: !!searchText ? (
            //   <InputAdornment position="end">
            //     <IconButton onClick={() => setSearchText('')}>
            //       <CancelIcon fontSize="small" />
            //     </IconButton>
            //   </InputAdornment>
            // ) : (
            //   <></>
            // ),
          }}
        />
      </Grid>
    </Grid>
  )
}

export default TextFormInput
