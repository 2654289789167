import { Button, Grid, IconButton, Typography } from '@mui/material'
import { getDataHubEntityMapResponse, getDataHubResponse, PaginationParams, ResultStatusSeverity } from 'businessObjects'
import { useGetAllDataHubs, useGetDataHubEntityMaps } from 'hooks/dataHubHooks'
import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import { PreviousPage } from 'ui-components/Button/PreviousPage'
import { HeadingLabel, LucidTextLabel } from 'ui-components/LucidTextLabel'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import { useGetQueryString } from 'hooks/queryStringHook'
import ShadowTable, { ShadowTableAction } from 'ui-components/ShadowTable/ShadowTable'
import ResultStatus from 'ui-components/ResultStatus'
import { DataTableRow } from 'ui-components/DataTable/DataTableTypes'
import { useGetAllEntitiesByDataPodId } from 'hooks/entityHooks'

type ViewResultsParams = {
    dataHubId?: string
}

export const ViewDataHubs = () => {
    const navigate = useNavigate()
    const [searchDataPod, setSearchDataPod] = useState<string>()
    // const dataHubId = useGetQueryString('dataHubId')
    const { dataHubId } = useParams<ViewResultsParams>();
    const [pages, setPages] = useState<number>(0)
    const [dataHub, setDataHub] = useState<getDataHubResponse>()
    const [fetchDataHubs, dataHubs] = useGetAllDataHubs()
    const [dataHubEntities, setDataHubEntities] = useState<getDataHubEntityMapResponse[]>()
    const [resultStatus, setResultStatus] = useState<boolean>(false)
    const [severity, setSeverity] = useState<ResultStatusSeverity>('info')
    const [resultStatusMessage, setResultStatusMessage] = useState<string>()
    const [getDataHubEntities] = useGetDataHubEntityMaps()
    useEffect(() => {
        if (!dataHubs?.length) {
            fetchDataHubs()
        }
        if (dataHubs?.length && (dataHubId) ) {
            // console.log(dataHubId)
            const temp = dataHubs.find((dh) => dh.id ===  dataHubId)
            // console.log({temp})
            setDataHub(temp)
            if(temp) fetchDataHubEnts(temp)
        }
    }, [dataHubs])

    const fetchDataHubEnts = async (dh:getDataHubResponse) =>{
        if(dh){
            const dataHubEnts = await getDataHubEntities(dh.id)
            if (dataHubEnts.length) {
                // console.log({ dataHubEnts })
                setDataHubEntities(dataHubEnts as getDataHubEntityMapResponse[])
            }
            // } else {
            //     // console.log("Inside the else")
            //     setSeverity('error')
            //     setResultStatus(true)
            //     // console.log(dataHubEnts)
            //     setResultStatusMessage(dataHubEnts.response.data)
            // }
        }

    }
    const leftRows = useMemo(() => {
        // console.log("Inside the left rows use memo", dataHubEntities);

        if (dataHubEntities?.length) {
            const dataTableRows = dataHubEntities
                .map((dhe) => {
                    // const matchingEntity = entity.find(e => e.dataEntityName === dhe.dataEntityname);
                    // console.log(matchingEntity)
                    // let lastValue = 'FETCHENTITY';
                    // if (matchingEntity && matchingEntity.dataHubMapId !== null && matchingEntity.dataHubCloneTypeId !== null) {
                    //     lastValue = 'COMPLETEDFETCH';
                    // }

                    return {
                        id: `${dhe.dataEntityId}`,
                        values: [
                            dhe.dataEntityname,
                            dhe.description,
                            dhe.entityType,
                            dhe.dataPodId,
                            // lastValue
                        ]
                    };
                }) as DataTableRow[];

            return dataTableRows;
        } else {
            return [];
        }
    }, [dataHubEntities]);

    const leftTableColumns = useMemo(() => {

        if (dataHubEntities?.length) {
            // console.log("Comes in here")
            return [
                { label: 'Entity Name' },
                { label: 'Entity Description' },
                { label: 'Entity Type' },
                { label: 'Entity DatapodId' },
                // { label: 'Consume Entitiy' }
            ]
        }
        else {
            return []
        }
    }, [dataHubEntities])
    // const onButtonClick = async (rowId: string, actionType: ShadowTableAction) => {
    //     if (actionType === ShadowTableAction.FetchEntity) {
    //         // console.log("Inside the fetch entity")
    //         // const tempEntBody = entity.find((ent) => ent.id === Number(rowId))
    //         // cons temp = updateEntityProfileName()
    //         const row = leftRows.find((lr) => lr.id === rowId)
    //         // console.log({ row })
    //         const consumingEnt = await fetchEntityById(row?.values[3], Number(rowId))
    //         // console.log({ consumingEnt })
    //         if (consumingEnt && typeof consumingEnt === typeof entity[0] && dataHubEntities?.length && dataPodId) {
    //             const tempCons = consumingEnt as Entity
    //             const flag = await entityNameDuplicateCheck(dataPodId, tempCons.dataEntityName)
    //             if (flag) {
    //                 // console.log(tempCons.dataEntityName, flag)
    //                 setResultStatus(true)
    //                 setSeverity('error')
    //                 setResultStatusMessage("Entity Name Already Exists For This Datapod")
    //                 return
    //             } else {
    //                 // console.log(tempCons.dataEntityName, flag)
    //                 const tempDataMapEnt = dataHubEntities?.find((dhe) => dhe.dataEntityId === tempCons.id)
    //                 const saveConsumedEntBody: NewEntity = {
    //                     dataEntityName: tempCons.dataEntityName,
    //                     profileName: 'HubFetched',
    //                     description: tempCons.description,
    //                     coreEntity: tempCons.coreEntity,
    //                     dataHubMapId: tempDataMapEnt?.id,
    //                     dataHubCloneTypeId: 1
    //                 }
    //                 // console.log({ saveConsumedEntBody })
    //                 const temp = await saveNewEntity(saveConsumedEntBody)
    //                 if (temp && temp.id) {
    //                     // const consumedEnt = consumedAnEntity
    //                     // if (consumedEnt?.length) {
    //                     //     setConsumedAnEntity([...consumedEnt, temp])
    //                     // } else {
    //                     //     consumedEnt?.push(temp)
    //                     //     setConsumedAnEntity(consumedEnt)
    //                     // }
    //                     setResultStatus(true)
    //                     setSeverity('success')
    //                     setResultStatusMessage('Sucessfully Consumed  Entity')
    //                     fetchAllEntities({}, dataPodId, undefined, undefined)
    //                 } else {
    //                     setResultStatus(true)
    //                     setSeverity('error')
    //                     setResultStatusMessage('There was an error while  consuming  entity')
    //                 }
    //             }

    //         } else if (!consumingEnt && error) {
    //             // console.log("Inside the else if", error)
    //             setResultStatus(true)
    //             setSeverity('error')
    //             setResultStatusMessage('Error Occured while fetching the Consuiming Entity')
    //         } else if (!consumingEnt) {
    //             // console.log("Just the boolean check")
    //             setResultStatus(true)
    //             setSeverity('error')
    //             setResultStatusMessage('Error Occured while fetching the Consuiming Entity')
    //         }
    //     }
    // }
    // const entityNameDuplicateCheck = async (datapodId: string, entityName: string) => {
    //     const temp = await fetchEntityByName(datapodId, entityName)
    //     return temp
    // }
    return (
        <>
            <ResultStatus
                severtiy={severity}
                showStatus={resultStatus}
                closeStatus={setResultStatus}
                alertMessage={resultStatusMessage}
            />
            <Grid container display={'flex'} flexDirection={'row'} justifyContent={'flex-end'}>
                <Grid item xs={3}>
                    <PreviousPage onClick={() => navigate(ROUTES.DataHubs)} pageName='Data Hubs Home' />
                </Grid>

            </Grid>
            {/* <Grid> */}
            <Grid item xs={12} container spacing={4} mt={'5%'}>
                <Grid item xs={10} container alignItems={'baseline'}>
                    <Grid item xs={3}>
                        <HeadingLabel headingText={'Data Hub details'} />
                    </Grid>
                    <Grid item xs={0}>
                        <IconButton aria-label="edit" size="small" onClick={() => navigate(generatePath(ROUTES.EditDataHub, { dataHubId: dataHub?.id }))}>
                            <ModeEditOutlineIcon />
                        </IconButton>
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={8}>
                        <LucidTextLabel labelText="Data Hub Name" />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" color={'secondary'}>
                            {dataHub?.dataHubName}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={8}>
                        <LucidTextLabel labelText="Data Hub Description" />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" color={'secondary'}>
                            {dataHub?.dataHubDesc}
                        </Typography>
                    </Grid>
                </Grid>
                <Grid item xs={12} container spacing={1}>
                    <Grid item xs={8}>
                        <LucidTextLabel labelText="Data Hub Id" />
                    </Grid>
                    <Grid item xs={8}>
                        <Typography variant="body2" color={'secondary'}>
                            {dataHub?.id}
                        </Typography>
                    </Grid>
                </Grid>
            </Grid>
            {/* </Grid> */}
            {dataHubEntities?.length && dataHub && (<Grid item xs={12} mt={5}>
                <ShadowTable
                    rows={leftRows}
                    columns={leftTableColumns}
                    // tableActionParams={{
                    //     onButtonClick: onButtonClick,
                    //     actions: [
                    //         ShadowTableAction.FetchEntity
                    //     ],
                    // }}
                />
            </Grid>)}
        </>
    )
}
