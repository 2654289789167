import axios, { AxiosResponse } from 'axios'
import { getAxiosRequestConfig } from '../apiServices/apiserviceHelpers'
import { appInsights } from '../utils/ApplicationInsightsService'
import { environment } from '../utils/environment'
import { AxiosRequestConfig } from 'axios'

/** @POST: requires input params 
@PUT:  requires input params */

type HttpMethod = 'GET' | 'POST' | 'PUT' | 'DELETE'

/** @POST: requires input params 
@PUT:  requires input params */
export const sendRequest = async <T>(
  endPoint: string,
  reqType: HttpMethod,
  token: string,
  params?: T,
): Promise<AxiosResponse<any, any>> => {
  const config = getAxiosRequestConfig(token)
  let resp: AxiosResponse<any, any>
  axios.interceptors.request.use((req) => {
    // appInsights.trackEvent({
    //   name: 'request',
    //   properties: { req },
    // })
    // Important: request interceptors **must** return the request.
    return req
  })

  // axios.interceptors.response.use((res) => {
  //   // Important: response interceptors **must** return the response.
  //   appInsights.trackTrace({
  //     message: 'response',
  //     properties: { res },
  //   })
  //   return res
  // })

  // let reqUrl: string = 'https://localhost:7095/api/' + endPoint
  let reqUrl: string = environment.baseURI + endPoint

  switch (reqType) {
    case 'GET':
      resp = await axios.get(reqUrl, config)
      break
    case 'POST':
      resp = await axios.post(reqUrl, params, config)
      break
    case 'PUT':
      resp = await axios.put(reqUrl, params, config)
      break
    case 'DELETE':
      if (!params) {
        resp = await axios.delete(reqUrl, config)
      } else {
        resp = await axios.delete(reqUrl, {
          ...config,
          data: params,
        })
      }
      break
    default:
      return new Promise(() => undefined)
  }
  // appInsights.trackEvent({
  //   name: 'response',
  //   properties: { resp },
  // })
  //const { request, data } = resp

  return resp
}
