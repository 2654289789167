import { Backdrop, Box, Container, Stack } from '@mui/material'
import React, { useMemo } from 'react'

type Props = {
  height: string
  width: string
  loading: boolean | undefined
}

const AnimatedLoader = ({ height, width, loading }: Props) => {
  const showStatus = useMemo(() => {
    return !!loading
  }, [loading])

  return (
    <>
    </>
    // <Backdrop open={showStatus} sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
    //   <Container maxWidth="sm" style={{ marginTop: '2%' }}>
    //     <img src={require('../ui-icons/Animation2.gif')} height={'50%'} width={'50%'} />
    //     {/* </Box> */}
    //   </Container>
    // </Backdrop>
  )
}

// const AnimatedLoader = ({ height, width, loading }: Props) => {
//     const showStatus = useMemo(() => {
//       return !!loading
//     }, [loading])

//     return (
//       <>
//         <Backdrop open={showStatus}>
//           <Stack sx={{ color: 'grey.500' }}>
//             <img src={require('../ui-icons/Animation2.gif')} height={'50%'} width={'50%'} />
//           </Stack>
//         </Backdrop>
//       </>
//     )
//   }

export default AnimatedLoader
