import { Box, Button, Grid, MenuItem, TextField, Typography } from '@mui/material'
import BusinessCenterIcon from '@mui/icons-material/BusinessCenter'

import { LucidAccount, UserAccount } from 'businessObjects'
import TextFormInput from 'ui-components/TextForm/TextFormInput'

import { Country } from 'country-state-city'
import { LucidTextLabel } from 'ui-components/LucidTextLabel'
import { useState, ChangeEvent } from 'react'
import { useLucidAuthContext } from "LucidAuthContext/LucidAuthContext";

type Props = {
  onNext: () => void
  onBack: () => void
  lucidAccount: LucidAccount
  setLucidAccount: React.Dispatch<React.SetStateAction<LucidAccount>>
  userAccount: UserAccount
  setUserAccount: React.Dispatch<React.SetStateAction<UserAccount>>
}

const UserDetails = ({ onNext, onBack, lucidAccount, setLucidAccount, userAccount, setUserAccount }: Props) => {
  const { accountId } = useLucidAuthContext()

  const phnNumberPattern = new RegExp(/^\d{9,11}$/)
  const fullNamepattern = new RegExp(/^[a-zA-Z ' ']{4,100}$/)
  
  const handleChange = (value: string, field: string) => {
    setUserAccount({ ...userAccount, [field]: value })
  }
  
  const checkValidity = (value: string, pattern: RegExp) => pattern.test(value)
  
  const isFormValid =
    userAccount.fullName &&
    userAccount.phoneNumber &&
    userAccount.countryCode &&
    userAccount.country &&
    phnNumberPattern.test(userAccount.phoneNumber) &&
    fullNamepattern.test(userAccount.fullName)


  return (
    <>
      <Box sx={{ height: '100%', width: '100%', display: 'flex', flexDirection: 'column', gap: 5 }}>
        <BusinessCenterIcon color="primary" />

        <Box>
          <Typography variant="h2" gutterBottom>
            User Details
          </Typography>
          <Typography variant="body1" gutterBottom>
            Enter your information to get access to Lucid Data Hub.
          </Typography>
        </Box>

        <form onSubmit={onNext} style={{ height: '100%', width: '100%' }}>
          <Box sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '30%',
                gap: 3,
              }}
            >
              {/* Full Name */}
              <TextFormInput
                fullWidth
                required
                labelText="Full Name"
                value={userAccount.fullName?.toString() || ''}
                // validateErrorText={!formData.organizationName ? 'This field is required' : ''}
                changeHandle={(value) => handleChange(value, "fullName")}
                validateErrorText={!checkValidity(userAccount.fullName, fullNamepattern) ? 'Invalid full Name' : undefined}
                // validateInput={!formData.organizationName}
              />

              {/* Phone Number */}
              <Grid container spacing={2}>
                <Grid item xs={5}>
                  <LucidTextLabel labelText={'Country code'} required={true} />
                  <TextField
                    fullWidth
                    id="outlined-select-countryCode"
                    select
                    color="secondary"
                    required
                    size="small"
                    value={userAccount.countryCode}
                    onChange={({ target }) => setUserAccount({ ...userAccount, countryCode: target.value })}
                  >
                    {Country.getAllCountries().map((country) => (
                      <MenuItem key={country.isoCode} value={country.phonecode}>
                        {country.flag} {country.name} {country.phonecode}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={7}>
                  {/* Phone Number*/}
                  <TextFormInput
                    fullWidth
                    required
                    labelText="Phone Number"
                    value={userAccount.phoneNumber?.toString() || ''}
                    // validateErrorText={!formData.organizationName ? 'This field is required' : ''}
                    // validateInput={!formData.organizationName}
                    // changeHandle={(value) => setUserAccount({ ...userAccount, phoneNumber: value })}
                    changeHandle={(value) => handleChange(value, "phoneNumber")}
                    validateErrorText={!checkValidity(userAccount.phoneNumber, phnNumberPattern) ? 'Invalid Phone Number' : undefined}
                  />
                  {/* <h5 style={{ color: isValid ? 'green' : 'red' }}>
                    {isValid ? 'Valid phone number' : 'Invalid phone number'}
                  </h5> */}
                </Grid>
              </Grid>

              {/* Country */}
              <Grid>
                <LucidTextLabel labelText={'Country'} required={true} />
                <TextField
                  fullWidth
                  id="outlined-select-country"
                  select
                  color="secondary"
                  required
                  size="small"
                  value={userAccount.country}
                  onChange={({ target }) => setUserAccount({ ...userAccount, country: target.value })}
                >
                  {Country.getAllCountries().map((country) => (
                    <MenuItem key={country.name} value={country.name}>
                      {country.flag} {country.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Box>

            <Box sx={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
              {!accountId && <Button onClick={onBack} variant="outlined">
                Back
              </Button> }
              <Button type="submit" variant="contained" disabled={!isFormValid} color="primary" sx={{ ml: 2 }}>
                Save and Continue
              </Button>
            </Box>
          </Box>
        </form>
      </Box>
    </>
  )
}

export default UserDetails
