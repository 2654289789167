import {
  Grid,
  Button,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Typography,
} from '@mui/material'
import React, { useMemo, useState } from 'react'

import { useParams, generatePath, useNavigate } from 'react-router-dom'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined'
import { ROUTES } from 'Routes/constants'
import ActionPage from '../../ui-components/ActionPage'
type ViewResultsParams = {
  dataPodId: string
}

export const TaskLogs = () => {
  const { dataPodId } = useParams<ViewResultsParams>()
  // const entityId = dataEntityID ? Number(dataEntityID) : undefined
  //const { fetchCanonicalDataByDatapodId, fetchedCanonicalData } = useGetCanonicalData(dataPodId, entityId)
  const [tableData, setTableData] = useState<any[]>([])
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const navigate = useNavigate()

  const scrollableContainer = {
    maxWidth: '150vh',
    overflowY: 'auto',
  }

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    setPage(0)
  }

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage)
  }

  return (
    <ActionPage>
      <Grid container>
        <Grid item container direction="row" justifyContent="flex-start" xs={12} sx={{ ...scrollableContainer }}>
          <TableContainer sx={{ maxHeight: 460, mt: 2, ...scrollableContainer }}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  <TableCell>Task ID</TableCell>
                  <TableCell>User ID</TableCell>
                  <TableCell>Job Type</TableCell>
                  <TableCell>Datapod ID</TableCell>
                  <TableCell>Table ID</TableCell>
                  <TableCell>Entity ID</TableCell>
                  <TableCell>Data System ID</TableCell>
                  <TableCell>Task Status</TableCell>
                  <TableCell>Created at</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {/* {tableValues?.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, ix) => (
                <TableRow key={ix}>
                  {row.map(
                    (
                      cell:
                        | string
                        | number
                        | boolean
                        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
                        | Iterable<React.ReactNode>
                        | React.ReactPortal
                        | null
                        | undefined,
                      cellIx: React.Key | null | undefined,
                    ) => (
                      <TableCell key={cellIx}> {cell} </TableCell>
                    ),
                  )}
                </TableRow>
              ))} */}
              </TableBody>
            </Table>
          </TableContainer>
          {/* <TablePagination
          rowsPerPageOptions={[10, 25, 50]}
          component="div"
          count={tableValues?.rows.length ?? 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        </Grid>
      </Grid>
    </ActionPage>
  )
}
