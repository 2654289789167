import { Grid, Typography, Button, TextField, MenuItem } from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import ActionPage from 'ui-components/ActionPage'
import { isValidEntry, isValidDescription } from 'utils/constants'
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from 'Routes/constants'
import { Entity, getDataHubEntityMapResponse, getDataHubResponse, NewEntity, ResultStatusSeverity } from 'businessObjects'
import { useGetAllDataHubs, useGetDataHubEntityMaps } from 'hooks/dataHubHooks'
import ResultStatus from 'ui-components/ResultStatus'
import ShadowTable, { ShadowTableAction } from 'ui-components/ShadowTable/ShadowTable';
import { DataTableRow } from 'ui-components/DataTable/DataTableTypes';
import { useAddNewEntity, useGetAllEntitiesByDataPodId, useGetEntityById, useGetEntityByName, useUpdateEntityName } from 'hooks/entityHooks';

type ViewResultsParams = {
    dataPodId: string;
    dataSystemID: string;
    entityID: string;
    dataTableId: string;
};

export const FetchEntity = () => {
    const navigate = useNavigate();
    const { dataPodId, dataSystemID, entityID, dataTableId } = useParams<ViewResultsParams>();
    const [userSelectedDataHub, setuserSelectedDataHub] = useState<getDataHubResponse>()
    const [userSelectedDataEntity, setuserSelectedDataEnity] = useState<getDataHubEntityMapResponse>()
    const [fetchDataHubs, dataHubs] = useGetAllDataHubs()
    const [getDataHubEntities] = useGetDataHubEntityMaps()
    const [resultStatus, setResultStatus] = useState<boolean>(false)
    const [severity, setSeverity] = useState<ResultStatusSeverity>('info')
    const [resultStatusMessage, setResultStatusMessage] = useState<string>(`Info: Select Entity to perform the action`)
    const [dataHubEntities, setDataHubEntities] = useState<getDataHubEntityMapResponse[]>()
    const [fetchAllEntities, entity] = useGetAllEntitiesByDataPodId()
    const { fetchEntityByName } = useGetEntityByName()
    const { fetchEntityById, fetchedEntity, error } = useGetEntityById()
    const { saveNewEntity } = useAddNewEntity(dataPodId)
    // const [consumedAnEntity, setConsumedAnEntity] = useState<Entity[]>()
    useEffect(() => {
        if (!dataHubs?.length) {
            fetchDataHubs()
        }
    }, [])

    useEffect(() => {
        if (dataPodId?.length) {
            fetchAllEntities({}, dataPodId, undefined, undefined)
        }
    }, [dataPodId])

    useEffect(() => {
        if (entity.length) {
            const tempHubEnt = entity.filter((ent) => ent.dataHubMapId !== undefined && ent.dataHubCloneTypeId !== undefined)
            // console.log({ entity })
            // console.log({ tempHubEnt })
        }
    }, [entity])

    // useEffect(() => {
    //     console.log("Inside the dataHub entities")
    //     console.log(dataHubEntities)
    // }, [dataHubEntities])
    const handleOnChangeDataHub = async (key: string, value: string) => {
        const dataHub = dataHubs?.find((dh) => dh.id === value)
        setuserSelectedDataHub(dataHub)
        if (dataHub) {
            const dataHubEnts = await getDataHubEntities(dataHub.id)
            if (dataHubEnts.length) {
                // console.log({ dataHubEnts })
                setDataHubEntities(dataHubEnts as getDataHubEntityMapResponse[])
            } else {
                // console.log("Inside the else")
                setSeverity('error')
                setResultStatus(true)
                // console.log(dataHubEnts)
                setResultStatusMessage(dataHubEnts.response.data)
                setDataHubEntities(undefined)
            }
        }
    }
    const leftRows = useMemo(() => {
        // console.log("Inside the left rows use memo", dataHubEntities);

        if (dataHubEntities?.length && entity.length) {
            const dataTableRows = dataHubEntities
                .map((dhe) => {
                    const matchingEntity = entity.find(e => e.dataEntityName === dhe.dataEntityname);
                    const matchingEntityDPLevel = entity.find(e => e.datapodId === dhe.dataPodId)
                    // console.log({matchingEntity})
                    let lastValue = 'FETCHENTITY';
                    if (matchingEntity && matchingEntity.dataHubMapId !== null && matchingEntity.dataHubCloneTypeId !== null) {
                        lastValue = 'COMPLETEDFETCH';
                    }else if(matchingEntityDPLevel){
                        lastValue = 'COMPLETEDFETCH';
                    }

                    return {
                        id: `${dhe.dataEntityId}`,
                        values: [
                            dhe.dataEntityname,
                            dhe.description,
                            dhe.entityType,
                            dhe.dataPodId,
                            lastValue
                        ]
                    };
                }) as DataTableRow[];

            return dataTableRows;
        } else {
            return [];
        }
    }, [dataHubEntities, entity]);

    const leftTableColumns = useMemo(() => {

        if (dataHubEntities?.length) {
            // console.log("Comes in here")
            return [
                { label: 'Entity Name' },
                { label: 'Entity Description' },
                { label: 'Entity Type' },
                { label: 'Entity DatapodId' },
                { label: 'Consume Entitiy' }
            ]
        }
        else {
            return []
        }
    }, [dataHubEntities])
    const onButtonClick = async (rowId: string, actionType: ShadowTableAction) => {
        if (actionType === ShadowTableAction.FetchEntity) {
            // console.log("Inside the fetch entity")
            const tempEntBody = entity.find((ent) => ent.id === Number(rowId))
            // cons temp = updateEntityProfileName()
            const row = leftRows.find((lr) => lr.id === rowId)
            // console.log({ row })
            const consumingEnt = await fetchEntityById(row?.values[3], Number(rowId))
            // console.log({ consumingEnt })
            if (consumingEnt && typeof consumingEnt === typeof entity[0] && dataHubEntities?.length && dataPodId) {
                // console.log({consumingEnt})
                const tempCons = consumingEnt as Entity
                // console.log({tempCons})
                const flag = await entityNameDuplicateCheck(dataPodId, tempCons.dataEntityName)
                if (flag) {
                    // console.log(tempCons.dataEntityName, flag)
                    setResultStatus(true)
                    setSeverity('error')
                    setResultStatusMessage("Entity Name Already Exists For This Datapod")
                    return 
                } else {
                    // console.log(tempCons.dataEntityName, flag)
                    const tempDataMapEnt = dataHubEntities?.find((dhe) => dhe.dataEntityId === tempCons.id)
                    const saveConsumedEntBody: NewEntity = {
                        dataEntityName: tempCons.dataEntityName,
                        profileName: 'HubFetched',
                        description: tempCons.description,
                        coreEntity: tempCons.coreEntity,
                        dataHubMapId: tempDataMapEnt?.id,
                        dataHubCloneTypeId: 1
                    }
                    // console.log({ saveConsumedEntBody })
                    const temp = await saveNewEntity(saveConsumedEntBody)
                    if (temp && temp.id) {
                        // const consumedEnt = consumedAnEntity
                        // if (consumedEnt?.length) {
                        //     setConsumedAnEntity([...consumedEnt, temp])
                        // } else {
                        //     consumedEnt?.push(temp)
                        //     setConsumedAnEntity(consumedEnt)
                        // }
                        setResultStatus(true)
                        setSeverity('success')
                        setResultStatusMessage('Sucessfully Consumed  Entity')
                        fetchAllEntities({}, dataPodId, undefined, undefined)
                    } else {
                        setResultStatus(true)
                        setSeverity('error')
                        setResultStatusMessage('There was an error while  consuming  entity')
                    }
                }

            } else if (!consumingEnt && error) {
                // console.log("Inside the else if", error)
                setResultStatus(true)
                setSeverity('error')
                setResultStatusMessage('Error Occured while fetching the Consuiming Entity')
            } else if (!consumingEnt) {
                // console.log("Just the boolean check")
                setResultStatus(true)
                setSeverity('error')
                setResultStatusMessage('Error Occured while fetching the Consuiming Entity')
            }
        }
    }
    const entityNameDuplicateCheck = async (datapodId: string, entityName: string) => {
        const temp = await fetchEntityByName(datapodId, entityName)
        return temp
    }
    return (
        <>
            <ActionPage>
                <ResultStatus
                    severtiy={severity}
                    showStatus={resultStatus}
                    closeStatus={setResultStatus}
                    alertMessage={resultStatusMessage}
                />
                <Grid
                    container
                    alignItems="center"
                    spacing={2}
                    sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                >
                    <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <Typography variant="h3" color="primary" gutterBottom>
                            Fetch Entity From Data Hub
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={1.5}>
                        <Button
                            variant="outlined"
                            startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
                            onClick={() => navigate(generatePath(ROUTES.DataModelingCanonicalModel, { dataPodId: dataPodId }))}
                        >
                            Back
                        </Button>
                    </Grid>
                </Grid>
                <Grid item container flexDirection="column" xs={15} md={10} mt={10}>
                    <div>
                        <Typography color="text.primary">
                            <span style={{ color: 'red' }}>*</span>Select  Data Hub  Name
                        </Typography>
                        <TextField
                            fullWidth
                            id="current-table-id"
                            placeholder="Data Hub Name"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            value={userSelectedDataHub?.id ?? ''}
                            onChange={({ target }) => handleOnChangeDataHub('dataHubName', target.value)}
                            select
                        >
                            {dataHubs?.map((dh) => (
                                <MenuItem key={dh.id} value={dh.id}>
                                    {dh.dataHubName}
                                </MenuItem>
                            ))}å
                        </TextField>
                    </div>
                    {userSelectedDataHub && (
                        <>
                            <Grid item xs={12} mt={2}>
                                <Typography color="text.primary">
                                    Data Hub  Description
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="current-table-id"
                                    placeholder="Data Hub Name"
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    value={userSelectedDataHub?.dataHubDesc ?? ''}
                                    disabled
                                >
                                    {userSelectedDataHub?.dataHubDesc}
                                </TextField>
                            </Grid>
                            <Grid item xs={12} mt={2}>
                                <Typography color="text.primary">
                                    Data Hub  Created By
                                </Typography>
                                <TextField
                                    fullWidth
                                    id="current-table-id"
                                    placeholder="Data Hub Name"
                                    variant="outlined"
                                    color="secondary"
                                    size="small"
                                    value={userSelectedDataHub?.createdBy ?? ''}
                                    disabled
                                >
                                    {userSelectedDataHub?.dataHubDesc}
                                </TextField>
                            </Grid>
                        </>)}
                </Grid>
                {dataHubEntities?.length && userSelectedDataHub && (<Grid item xs={12} mt={5}>
                    <ShadowTable
                        rows={leftRows}
                        columns={leftTableColumns}
                        tableActionParams={{
                            onButtonClick: onButtonClick,
                            actions: [
                                ShadowTableAction.FetchEntity
                            ],
                        }}
                    />
                </Grid>)}
            </ActionPage>
        </>
    )
}
