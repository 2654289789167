import * as React from 'react'
import Timeline from '@mui/lab/Timeline'
import TimelineItem, { timelineItemClasses } from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { useGetProfileInputs } from '../../hooks/dataProfilingHooks'

import { Grid, Typography, CardContent } from '@mui/material'

import { useMemo, useState } from 'react'
import { DataPod, PaginationParams } from '../../businessObjects'

import LooksOneOutlinedIcon from '@mui/icons-material/LooksOneOutlined'
import LooksTwoOutlinedIcon from '@mui/icons-material/LooksTwoOutlined'
import Looks3OutlinedIcon from '@mui/icons-material/Looks3Outlined'
import Looks4OutlinedIcon from '@mui/icons-material/Looks4Outlined'
import DataSourceCard from './DataSourceCard'
import { DataEngineering } from '../DataProcess/DataEngineering/DataEngineering'
import DataProcessCard from '../DataProcess/DataProcessCard'
import Looks5OutlinedIcon from '@mui/icons-material/Looks5Outlined'
import Looks6Outlined from '@mui/icons-material/Looks6Outlined'
import DataPodDetailRibbon from '../../ui-components/DataPodDetailRibbon/DataPodDetailRibbon'
import { environment } from 'utils/environment'

type Props = {
  dataPod?: DataPod
}
export const ProcessVerticalFlow = ({ dataPod }: Props) => {
  const [pages, setPages] = useState<number>(0)
  const [pagination, setPagination] = useState<PaginationParams>({ pageNumber: 1, recordsPerPage: 5 })
  const { profileInputs } = useGetProfileInputs(dataPod?.id ?? undefined, undefined, undefined, pagination, setPages)

  const dataSystemList = useMemo(() => {
    if (!profileInputs) return []
    return profileInputs
  }, [profileInputs, pagination])

  return (
    <>
      {/* <Grid container alignItems={'flex-end'} spacing={2}>
        <Grid item xs={14} />
        <Grid item xs={9} />

        <Grid item xs={3}>
          <DataSourceCard dataPodInfo={dataPod} />
        </Grid>
      </Grid> */}

      <CardContent>
        <DataPodDetailRibbon
          dataPodId={dataPod?.id}
          dataPodName={dataPod?.name}
          dataPodIndustryName={dataPod?.industryName}
        />
      </CardContent>

      <Timeline
        sx={{
          [`& .${timelineItemClasses.root}:before`]: {
            flex: 0,
            paddingLeft: 0,
            marginLeft: -4,
          },
        }}
      >
        <TimelineItem>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <LooksOneOutlinedIcon sx={{ color: 'white' }} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent>
            <Grid container spacing={1}>
              <Grid item xs={12} />
              <Grid item xs={15} container>
                <Grid item xs={2}>
                  <Typography variant="h4" gutterBottom color="primary">
                    Data Profiling
                  </Typography>
                </Grid>

                <Grid item sx={{ paddingLeft: 2 }} xs={10} alignItems={'flex-start'} container spacing={1}>
                  {dataSystemList.map((d, ix) => (
                    <Grid item xs={3} md={5.7} key={d.dataSystemID}>
                      <DataProcessCard
                        headingText="Scan & Profile Data Source"
                        type="Data Profiling"
                        key={d.dataSystemID}
                        labelType="Data System"
                        dataPodId={dataPod?.id}
                        dataSystemID={d.dataSystemID}
                        subLabel={d.dataSystemName}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ marginTop: 3 }}>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <LooksTwoOutlinedIcon sx={{ color: 'white' }} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>

          <TimelineContent>
            <Grid container spacing={1}>
              <Grid item xs={12} />
              <Grid item xs={15} container>
                <Grid item xs={2}>
                  <Typography variant="h4" gutterBottom color="primary">
                    Data Mapping
                  </Typography>
                </Grid>
                <Grid item xs={10} sx={{ paddingLeft: 2 }} container spacing={1}>
                  {dataSystemList.map((d) => (
                    <Grid item xs={3} md={5.7} key={d.dataSystemID}>
                      <DataProcessCard
                        headingText="Generate Data Mapping"
                        type="Data Mapping"
                        key={d.dataSystemID}
                        dataPodId={dataPod?.id}
                        dataSystemID={d.dataSystemID}
                        subLabel={d.dataSystemName}
                        labelType="Data System"
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </Grid>
          </TimelineContent>
        </TimelineItem>
        <TimelineItem sx={{ marginTop: 3 }}>
          <TimelineSeparator>
            <TimelineDot color="primary">
              <Looks3OutlinedIcon sx={{ color: 'white' }} />
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Grid container spacing={1}>
              <Grid item xs={12} />
              <Grid item xs={15} container>
                <Grid item xs={2}>
                  <Typography variant="h4" gutterBottom color="primary">
                    {dataPod?.datapodType == 2 ? 'Generative Integrations' : 'Data Modeling'}
                  </Typography>
                </Grid>
                <Grid item sx={{ paddingLeft: 2 }} xs={10} container spacing={1}>
                  
                  <Grid item xs={3} md={5.7}>
                    <DataProcessCard
                      headingText={dataPod?.datapodType == 2 ? 'Source Canonical Model' : 'Canonical Model'}
                      type={dataPod?.datapodType == 2 ? 'Source Canonical Model' : 'Canonical Model'}
                      labelType={'DataPod'}
                      dataPodId={dataPod?.id}
                      subLabel={dataPod?.name}
                    />
                  </Grid>
                  <Grid item xs={3} md={5.7}>
                    <DataProcessCard
                      headingText={'Data Quality Recommendations'}
                      type={'Data Quality Recommendations'}
                      labelType={'DataPod'}
                      dataPodId={dataPod?.id}
                      subLabel={dataPod?.name}
                    />
                  </Grid>

                  {dataPod?.datapodType == 2 ? (
                    <Grid item xs={3} md={5.7}>
                      <DataProcessCard
                        headingText="Target Canonical Model"
                        type={dataPod?.datapodType == 2 ? 'Target Canonical Model' : 'Canonical Model'}
                        labelType="DataPod"
                        dataPodId={dataPod.id}
                        subLabel={dataPod.name}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  {dataPod?.datapodType == 2 ? (
                    <Grid item xs={3} md={5.7}>
                      <DataProcessCard
                        headingText="Entity Reconciliation"
                        type="Entity Reconciliation"
                        labelType="DataPod"
                        dataPodId={dataPod.id}
                        subLabel={dataPod.name}
                      />
                    </Grid>
                  ) : (
                    <></>
                  )}
                  <Grid item xs={3} md={5.7}>
                    <DataProcessCard
                      headingText={dataPod?.datapodType == 2 ? 'Source to Target Mapping' : 'Entity Reconciliation'}
                      type={
                        dataPod?.datapodType == 2 ? 'Generative Integration Source To Target' : 'Entity Reconciliation'
                      }
                      dataPodId={dataPod?.id}
                      labelType={'DataPod'}
                      subLabel={dataPod?.name}
                    />
                  </Grid>
                  {dataPod?.datapodType != 2 ? (
                    <>
                      <Grid item xs={3} md={5.7}>
                        <DataProcessCard
                          headingText={'Discover Relationships & Data Models'}
                          type="Generate Entity Relationships"
                          dataPodId={dataPod?.id}
                          labelType={'DataPod'}
                          subLabel={dataPod?.name}
                        />
                      </Grid>
                      <Grid item xs={3} md={5.7}>
                        <DataProcessCard
                          headingText="Enriched Data Model"
                          type="Generate Enterprise Data Models"
                          dataPodId={dataPod?.id}
                          labelType={'DataPod'}
                          subLabel={dataPod?.name}
                        />
                      </Grid>
                    </>
                  ) : (
                    <></>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </TimelineContent>
        </TimelineItem>
        {dataPod?.datapodType != 2 ? (
          <>
            {!environment.isAdvanceEnergy && (
              <TimelineItem sx={{ marginTop: 3 }}>
                <TimelineSeparator>
                  <TimelineDot color="primary">
                    <Looks4OutlinedIcon sx={{ color: 'white' }} />
                  </TimelineDot>
                  <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                  <Grid container spacing={1}>
                    <Grid item xs={12} />
                    <Grid item xs={15} container>
                      <Grid item xs={2}>
                        <Typography variant="h4" gutterBottom color="primary">
                          Data Engineering
                        </Typography>
                      </Grid>
                      <Grid item sx={{ paddingLeft: 2 }} xs={10} container spacing={1}>
                        {/* <Grid item xs={3} md={5.7}>
                          <DataEngineering
                            headingText={'Generate ELT Queries'}
                            dataPod={dataPod}
                            type="GERARATE_ELT_QUERIES"
                          />
                        </Grid>

                        <Grid item xs={3} md={5.7}>
                          <DataEngineering
                            headingText={'Generate ELT Pipelines'}
                            dataPod={dataPod}
                            type="GENERATE_ELTP_PIPELINES"
                          />
                        </Grid>

                        <Grid item xs={3} md={5.7}>
                          <DataEngineering
                            headingText={'Load Enterprise Data Model'}
                            dataPod={dataPod}
                            type="LOAD_ENTERPRISE_DATA_MODELS"
                          />
                        </Grid> */}
                        <Grid item xs={3} md={5.7}>
                          <DataProcessCard
                            headingText="Pipeline Schedules"
                            type="Data Engineering Pipeline Schedules"
                            dataPodId={dataPod?.id}
                            labelType={'DataPod'}
                            subLabel={dataPod?.name}
                          />
                        </Grid>
                        <Grid item xs={3} md={5.7}>
                          <DataProcessCard
                            headingText="Pipeline Monitoring"
                            type="Data Engineering Pipeline Monitoring"
                            dataPodId={dataPod?.id}
                            labelType={'DataPod'}
                            subLabel={dataPod?.name}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </TimelineContent>
              </TimelineItem>
            )}
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  {environment.isAdvanceEnergy ? (
                    <Looks4OutlinedIcon sx={{ color: 'white' }} />
                  ) : (
                    <Looks5OutlinedIcon sx={{ color: 'white' }} />
                  )}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>

              <TimelineContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} />
                  <Grid item xs={15} container>
                    <Grid item xs={2}>
                      <Typography variant="h4" gutterBottom color="primary">
                        Data Lakes
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingLeft: 2 }} xs={10} alignItems={'flex-start'} container spacing={1}>
                      <Grid item xs={3} md={5.7}>
                        <DataProcessCard
                          headingText="Data Lakes"
                          type="Data Lake Results"
                          dataPodId={dataPod?.id}
                          labelType={'DataPod'}
                          subLabel={dataPod?.name}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
            <TimelineItem>
              <TimelineSeparator>
                <TimelineDot color="primary">
                  {environment.isAdvanceEnergy ? (
                    <Looks5OutlinedIcon sx={{ color: 'white' }} />
                  ) : (
                    <Looks6Outlined sx={{ color: 'white' }} />
                  )}
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent>
                <Grid container spacing={1}>
                  <Grid item xs={12} />
                  <Grid item xs={15} container>
                    <Grid item xs={2}>
                      <Typography variant="h4" gutterBottom color="primary">
                        Data Analytics
                      </Typography>
                    </Grid>
                    <Grid item sx={{ paddingLeft: 2 }} xs={10} container spacing={1}>
                      <Grid item xs={3} md={5.7}>
                        <DataProcessCard
                          headingText="Analytics Groups"
                          type="Analytics Group Results"
                          dataPodId={dataPod?.id}
                          labelType={'DataPod'}
                          subLabel={dataPod?.name}
                        />
                      </Grid>
                      <Grid item xs={3} md={5.7}>
                        <DataProcessCard
                          headingText="Analytics Measures (KPIs)"
                          type="Analytics Measures Results"
                          dataPodId={dataPod?.id}
                          labelType={'DataPod'}
                          subLabel={dataPod?.name}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </TimelineContent>
            </TimelineItem>
          </>
        ) : (
          <></>
        )}
      </Timeline>
    </>
  )
}
