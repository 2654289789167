import {
  Grid,
  IconButton,
  Box,
  Typography,
  TextField,
  Button,
  Select,
  Chip,
  OutlinedInput,
  ListItemText,
  MenuItem,
  Checkbox,
  Menu,
} from '@mui/material'
import React, { useEffect, useMemo, useState } from 'react'
import { cardContainer } from '../../../styles/globalStyles'
import ActionPage from '../../../ui-components/ActionPage'
import { green } from '@mui/material/colors'
import {
  AppProfileEntity,
  AppProfileAttribute,
  EntityReconData,
  Entity,
  NewEntity,
  NewParent,
  GenAIReconcilationResponse,
  AddEntityRecon,
  coreEntityAttributeData,
} from '../../../businessObjects'
import { DataTableRow } from '../../../ui-components/DataTable/DataTableTypes'
import ShadowTable from '../../../ui-components/ShadowTable/ShadowTable'
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined'
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined'
import { generatePath, useNavigate, useParams } from 'react-router-dom'
import { ROUTES } from '../../../Routes/constants'
import { useGetDataPodInfo } from '../../../hooks/datapodHooks'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import {
  useAddEntityRecon,
  useDeleteEntityRecon,
  useGetEntityRelationships,
  useGetRelationData,
} from '../../../hooks/applicationHooks'
import { useAddNewEntity, useAddNewParentEntity, useDeleteEntity, useGetEntity } from '../../../hooks/entityHooks'
import { useGetGenAiCoreEntityAttributes, useGetGenAiReconcilation } from '../../../hooks/genAIApiHooks'
import { useGetDataReconcilationByEntityId } from '../../../hooks/dataAttrbutesHooks'
import { LucidPopUp } from '../../../ui-components/LucidPopUp/LucidPopUp'
import { AddOutlined } from '@mui/icons-material'
import SyncIcon from '@mui/icons-material/Sync'
import SettingsIcon from '@mui/icons-material/Settings'
import ParentChildComponent from './ParentChild'
import { isValidDescription, isValidEntry } from '../../../utils/constants'
import Spinner from 'react-spinner-material'
import { ProgressBar } from '../../../ui-components/ProgressBar'
import AnimatedLoader from '../../../ui-components/AnimatedLoader'
import { entityServices } from '../../../apiServices/entities'
import { useLucidAuthContext } from '../../../LucidAuthContext/LucidAuthContext'

import { Node } from 'reactflow'
import { useAddMultipleCoreEntityAttributes } from '../../../hooks/coreEntityAttributeHooks'
import TextFormInput from 'ui-components/TextForm/TextFormInput'
import { LucidTextLabel } from 'ui-components/LucidTextLabel'
import Delete from '@mui/icons-material/Delete'
import { DeletePopUp } from '../DataMapping/DataMappingPopUps'
import LoadingButton from '@mui/lab/LoadingButton'
import { environment } from 'utils/environment'

type Props = {}
type ViewResultsParams = {
  dataPodId: string
  dataSystemID: string
}
// const ITEM_HEIGHT = 48
// const ITEM_PADDING_TOP = 8
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// }

export const EntityReconciliation = (props: Props) => {
  const { dataPodId } = useParams<ViewResultsParams>()
  const [showNewReconPopup, setShowNewReconPopup] = useState(false)
  const [showEditReconPopup, setShowEditReconPopup] = useState(false)
  const [tableView, setTableView] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(false)
  const [showGenerateWarning, setShowGenerateWarning] = useState<boolean>(false)
  const [showDeleteWarning, setShowDeleteWarning] = useState<boolean>(false)
  const navigate = useNavigate()
  const { dataPodInfo } = useGetDataPodInfo(dataPodId)
  const [entities] = useGetEntity({}, dataPodId)
  const [newEntityReconData, setNewEntityReconData] = useState<NewParent>()
  const { reconDataLoaded, refetchAttributes } = useGetDataReconcilationByEntityId(dataPodId)
  const [reconData, setReconData] = useState<EntityReconData[]>([])
  const [showParentChild, setShowParentChild] = useState<boolean>(false)
  const { fetchEntity } = useGetGenAiReconcilation()
  const [genAiData, setGenAiData] = useState<GenAIReconcilationResponse>()
  const { saveNewEntity } = useAddNewParentEntity(dataPodId)
  const { addEntityRecon } = useAddEntityRecon(dataPodId)
  const [generateReconPromptText, setGenerateReconPromptText] = useState<string>('')
  const [selectedChildEntity, setSelectedChildEntity] = useState<Entity[]>([])
  let [selectionNodeClickCount, setSelectionNodeClickCount] = useState<number>(0)
  const [showGenerateButton, setShowGenerateButton] = useState<boolean>(false)
  //const [relations] = useGetRelationData({}, dataPodId)
  const { getBearerToken } = useLucidAuthContext()
  const { fetchCoreEntityAttributes } = useGetGenAiCoreEntityAttributes()
  const [submitNewAttribute] = useAddMultipleCoreEntityAttributes()
  const { entityRelationShips, refreshEntityRelations } = useGetEntityRelationships(dataPodId)
  const { confirmDeleteEntityRecon } = useDeleteEntityRecon()
  const { deleteEntity } = useDeleteEntity()
  const [dataDeleted, setDataDeleted] = useState<boolean>(false)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  useEffect(() => {
    if (reconDataLoaded.length) {
      // console.log("Inside if of the reconDataLoaded length")
      setShowGenerateButton(true)
      setReconData(reconDataLoaded)
      setShowParentChild(true)
    } else {
      // console.log("Inside the use effect where the recon data does not have any length")
      setReconData([])
      setShowParentChild(false)
    }
  }, [reconDataLoaded, reconDataLoaded.length])
  useEffect(() => {
    if (selectionNodeClickCount <= 2 && selectionNodeClickCount != 0) {
      refetchAttributes()
      // setTimeout(refetchAttributes, 3000)
      setSelectionNodeClickCount(0)
    }
  }, [selectionNodeClickCount])
  useEffect(() => {
    if (!entityRelationShips.length) {
      refreshEntityRelations()
    }
  }, [])
  const onClickHome = () => {
    const dataProfilingRoute = generatePath(ROUTES.Overview, { dataPodId: dataPodId })
    navigate(dataProfilingRoute)
  }
  // console.log('newEntityReconData', newEntityReconData)
  const changeUserParams = (name: string, value: unknown) => {
    setNewEntityReconData(
      (prv: any) =>
        ({
          ...prv,
          [name]: value,
        } as NewParent),
    )
  }
  const onSubmit = () => {
    saveNewParent()
    refetchAttributes()
    setShowNewReconPopup(!showNewReconPopup)
    // setNewEntityReconData((prev: any) => { return { ...prev, dataEntityName: '', description: '' } })
  }

  const updateChildParent = async () => {
    await setShowEditReconPopup(false)
  }

  const disableSubmit = useMemo(() => {
    return !isValidEntry(newEntityReconData?.dataEntityName) || !isValidDescription(newEntityReconData?.description)
  }, [newEntityReconData?.dataEntityName, newEntityReconData?.description])

  // const reconDataFiltered = useMemo(() => {
  //   if (reconData.length) {
  //     const reconTemp = reconData.filter((rd, ix) => {
  //       return reconData.findIndex((cr) => cr.dataCoreEntityId === rd.dataCoreEntityId) === ix
  //     })
  //     const newData = reconTemp.map((cp) => ({
  //       parentId: cp.dataCoreEntityId,
  //       parentName: cp.parent,
  //       childrenData: reconData
  //         .filter((rd) => rd.dataCoreEntityId === cp.dataCoreEntityId)
  //         .map((cx) => ({ childId: cx.dataSystemEntityId, child: cx.child })),
  //     }))
  //     return newData
  //   }
  // }, [reconData])

  useEffect(() => {
    if (!showNewReconPopup) {
      setNewEntityReconData(undefined)
    }
  }, [showNewReconPopup])

  const generateReqbody = (parentname: string) => {
    return {
      dataEntityName: parentname,
      profileName: 'clientApp',
      description: parentname,
      coreEntity: true,
      entityType: '1',
      dataLakeZone: 2,
    }
  }
  const generateChidrenBody = (childName: string) => {
    return {
      dataEntityName: childName,
      profileName: 'clientApp',
      description: childName,
      coreEntity: false,
      entityType: '1',
      dataLakeZone: 1,
    }
  }
  const generateReconbody = (parent: Entity, child: Entity) => {
    return {
      dataCoreEntityId: parent.id,
      dataSystemEntityId: child.id,
      profileName: 'clientApp',
    }
  }
  const generateCoreAttributesBody = (
    attributes: coreEntityAttributeData[],
    dataPodId: string,
    coreEntityId: number,
  ) => {
    return attributes.map((attribute) => ({
      dataCoreEntityId: coreEntityId,
      dataCoreAttributeName: attribute.AttributeName,
      dataPodId: dataPodId,
      dataCoreAttributeDataType: 'string',
      dataCoreClassification: 'string',
      description: attribute.AttributeDescription,
      isBusinessKey: true,
      dataAttributeType: 'string',
    }))
  }
  const createParentAndChild = async (data: Record<string, string[]>) => {
    setLoading(true)
    const token = await getBearerToken()
    let processCompletion: boolean = false
    if (token) {
      for (const [parent, child] of Object.entries(data)) {
        let coreEnt: Entity | undefined
        const { data: parentEntity } = await entityServices.getEntityName(
          token,
          undefined,
          parent,
          undefined,
          dataPodId,
          undefined,
          undefined,
          undefined,
          true,
        )
        if (parentEntity.message.includes('No Records Found' || parentEntity.dataEntities === null)) {
          const parentBody: NewParent = generateReqbody(parent)
          const newParent = await saveNewEntity(parentBody)
          coreEnt = newParent
        } else {
          coreEnt = parentEntity.dataEntities[0]
        }
        if (coreEnt) {
          // console.log("new parent is created")
          // console.log({ coreEnt })
          if (child.length > 1) {
            for (const c of child) {
              let childEnt: Entity | undefined
              const { data: childEntity } = await entityServices.getEntityName(
                token,
                undefined,
                c,
                undefined,
                dataPodId,
              )
              if (childEntity.message.includes('No Records Found' || parentEntity.dataEntities === null)) {
                const childBody = generateChidrenBody(c)
                const newChild = await saveNewEntity(childBody)
                childEnt = newChild
              } else {
                childEnt = childEntity.dataEntities[0]
              }
              if (childEnt) {
                // console.log("inside if of new child")
                const reconBody: AddEntityRecon = generateReconbody(coreEnt, childEnt)
                // console.log({ reconBody })
                const newRecon = await addEntityRecon(reconBody)
                if (newRecon) {
                  if (coreEnt && dataPodId) {
                    // console.log("inside if")
                    const attributes = await fetchCoreEntityAttributes(dataPodId, coreEnt.id)
                    if (attributes) {
                      const addMultipleCoreAttributesBody = generateCoreAttributesBody(
                        attributes.data,
                        dataPodId,
                        coreEnt.id,
                      )
                      // console.log([addMultipleCoreAttributesBody])
                      const response = await submitNewAttribute(addMultipleCoreAttributesBody)
                      if (response) {
                        // console.log(response)
                        processCompletion = true
                      } else {
                        // console.log("Some error occured")
                        processCompletion = true
                      }
                    } else {
                      // console.log("Error occured when generationg core entity attributes")
                      processCompletion = true
                    }
                  }
                }
                // console.log(newRecon)
              }
            }
          } else {
            // console.log("inside else")
            // console.log(child[0])
            let childEnt: Entity | undefined
            const { data: childEntity } = await entityServices.getEntityName(
              token,
              undefined,
              child[0],
              undefined,
              dataPodId,
            )
            if (childEntity.message.includes('No Records Found' || parentEntity.dataEntities === null)) {
              const childBody = generateChidrenBody(child[0])
              const newChild = await saveNewEntity(childBody)
              childEnt = newChild
            } else {
              childEnt = childEntity.dataEntities[0]
            }
            if (childEnt) {
              const reconBody: AddEntityRecon = generateReconbody(coreEnt, childEnt)
              // console.log({reconBody})
              const newRecon = await addEntityRecon(reconBody)
              // console.log(newRecon)
              if (newRecon) {
                if (coreEnt && dataPodId) {
                  // console.log("inside if")
                  const attributes = await fetchCoreEntityAttributes(dataPodId, coreEnt.id)
                  if (attributes) {
                    const addMultipleCoreAttributesBody = generateCoreAttributesBody(
                      attributes.data,
                      dataPodId,
                      coreEnt.id,
                    )
                    // console.log([addMultipleCoreAttributesBody])
                    const response = await submitNewAttribute(addMultipleCoreAttributesBody)
                    if (response) {
                      // console.log(response)
                      processCompletion = true
                    } else {
                      // console.log("Some error occured")
                      processCompletion = true
                    }
                  } else {
                    // console.log("Error in core Entity attribute")
                    processCompletion = true
                  }
                }
              }
            }
          }
        }
      }
      if (!reconData.length && processCompletion) {
        refetchAttributes()
        setLoading(false)
      } else {
        if (processCompletion) {
          // console.log("Process completed")
          setLoading(false)
        }
      }
    }
  }
  const saveNewParent = async () => {
    if (newEntityReconData) {
      const params: NewParent = {
        ...newEntityReconData,
        profileName: 'clientApp',
        coreEntity: true,
        entityType: '',
        dataLakeZone: 2,
      }
      const response = await saveNewEntity(params)
      if (response) {
        for (const childEntity of selectedChildEntity) {
          const reconBody = generateReconbody(response, childEntity)
          const newRecon = await addEntityRecon(reconBody)
        }
        refetchAttributes()
      }
    }
  }

  // const convertToDictionary = (response: GenAIReconcilationResponse): Record<string, string[]> => {
  //   // const dictionary: Record<string, string[]> = {};
  //   const dictionary: { [parent: string]: string[] } = {};
  //   for (const child in response.data) {
  //     const parent = response.data[child];
  //     if (dictionary[parent]) {
  //       dictionary[parent].push(child);
  //     } else {
  //       dictionary[parent] = [child];
  //     }
  //   }
  //   return dictionary;
  // };
  const convertToDictionary = (response: GenAIReconcilationResponse): Record<string, string[]> => {
    // const dictionary: Record<string, string[]> = {};
    const dictionary: { [parent: string]: string[] } = {}
    for (const child in response.data) {
      const parent = response.data[child]
      if (dictionary[parent]) {
        dictionary[parent].push(child)
      } else {
        dictionary[parent] = [child]
      }
    }
    return dictionary
  }

  const handleScan = async () => {
    setShowGenerateWarning(false)
    if (dataPodId) {
      setLoading(true)
      const genAidata = await fetchEntity(dataPodId, generateReconPromptText)
      if (genAidata) {
        setGenAiData(genAidata)
        const parentChildDict = convertToDictionary(genAidata)
        // console.log({parentChildDict})
        createParentAndChild(parentChildDict)
        setGenerateReconPromptText('')
      }
    }
  }
  const handleNodeClick = async (nodeData: Node | undefined) => {
    // console.log("Node clicked in ParentChildComponent:",nodeData);
    // Additional logic to handle the node click event
    if (nodeData?.type === 'SelectionNode') {
      // console.log("selection node is clicked")
      selectionNodeClickCount += 1
      // console.log(selectionNodeClickCount)
      setSelectionNodeClickCount(selectionNodeClickCount)
      refetchAttributes()
    }
    // if (nodeData?.type === 'NodeEditor') {
    //   console.log('editor node has been clicked')
    // }
  }
  const handleRefetchRecon = async () => {
    refetchAttributes()
  }
  const confirmEntityRecon = async () => {
    const reconIds = reconData.map((rd) => rd.id)
    // console.log({reconData})
    // console.log(reconIds)
    const resp = await confirmDeleteEntityRecon(reconIds)
    const uniqueDataCoreEntityIds = Array.from(new Set(reconData.map((item) => item.dataCoreEntityId)))
    // console.log(uniqueDataCoreEntityIds)
    if (resp) {
      // console.log("Recon is deleted")
      const entResp = await deleteEntity(uniqueDataCoreEntityIds)
      if (entResp) {
        // console.log("Deleted the core entities")
        setShowDeleteWarning(false)
        setShowGenerateButton(false)
        setDataDeleted(true)
        setReconData([])
        setShowParentChild(false)
      }
    }
  }
  return (
    <>
      <ActionPage>
        <LucidPopUp
          openPopUp={showNewReconPopup}
          onConfirm={onSubmit}
          closePopup={setShowNewReconPopup}
          headingText={<Typography variant="h4">Add New Parent Entity</Typography>}
          confirmText="Save"
          disabledConfirm={disableSubmit}
          showCloseIcon
        >
          <Grid item container justifyContent={'center'} alignItems={'stretch'} spacing={1.5}>
            <Grid item xs={10}>
              <Typography variant="subtitle2"> Entity Name</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter Core Entity Name"
                variant="outlined"
                color="secondary"
                required
                error={!isValidEntry(newEntityReconData?.dataEntityName)}
                helperText={!isValidEntry(newEntityReconData?.dataEntityName) ? 'Invalid Entry' : ''}
                value={newEntityReconData?.dataEntityName ?? ''}
                onChange={({ target }) => changeUserParams('dataEntityName', target.value)}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle2">Description</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField
                fullWidth
                id="outlined-basic"
                label="Enter Core Entity Description"
                variant="outlined"
                color="secondary"
                required
                error={!isValidDescription(newEntityReconData?.description)}
                helperText={!isValidDescription(newEntityReconData?.description) ? 'Invalid Entry' : ''}
                value={newEntityReconData?.description ?? ''}
                onChange={({ target }) => changeUserParams('description', target.value)}
                multiline
                rows={3}
              />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle2">Child Entities</Typography>
            </Grid>
            <Grid item xs={10}>
              <Select
                fullWidth
                id="outlined-select-dataSource"
                input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
                variant="outlined"
                value={selectedChildEntity.map((se) => se.id)}
                size="small"
                label={'Select Core Entity'}
                onChange={({ target }) => {
                  const selEntity = entities.filter((ed) => (target.value as number[]).includes(ed.id))
                  setSelectedChildEntity(selEntity)
                }}
                multiple
                renderValue={() => (
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                    {selectedChildEntity.map((sce) => (
                      <Chip key={sce.id} label={sce.dataEntityName} />
                    ))}
                  </Box>
                )}
              >
                {entities.map((d) => (
                  <MenuItem key={d.id} value={d.id}>
                    <Checkbox checked={selectedChildEntity.some((sc) => sc.id === d.id)} />
                    <ListItemText primary={d.dataEntityName} />
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </LucidPopUp>
        <LucidPopUp
          openPopUp={showEditReconPopup}
          onConfirm={() => {
            return
          }}
          closePopup={setShowEditReconPopup}
          headingText={<Typography variant="h4">Edit Parent Entity</Typography>}
          confirmText="Save"
          showCloseIcon
        >
          <Grid item container justifyContent={'center'} alignItems={'stretch'} spacing={1.5}>
            <Grid item xs={10}>
              <Typography variant="subtitle2"> Entity Name</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField fullWidth size="small" />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle2"> Decription</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField fullWidth size="small" multiline rows={3} />
            </Grid>
            <Grid item xs={10}>
              <Typography variant="subtitle2"> Child</Typography>
            </Grid>
            <Grid item xs={10}>
              <TextField fullWidth size="small" />
            </Grid>
          </Grid>
        </LucidPopUp>

        <LucidPopUp
          showCloseIcon
          confirmText="Confirm"
          onConfirm={handleScan}
          openPopUp={showGenerateWarning}
          closePopup={setShowGenerateWarning}
          headingText={<LucidTextLabel labelText="Generate Entity Reconciliation" />}
        >
          <Grid container item alignItems="center" justifyItems="center">
            <Grid item xs />
            <Grid item xs>
              <Typography variant="body1">Are You Sure? </Typography>
            </Grid>
            <Grid item xs />
          </Grid>
        </LucidPopUp>

        {/* <Grid item container spacing={2}>
          <Grid item xs={2}>
            <IconButton onClick={onClickHome}>
              <ArrowBackIcon />
              <Typography>Back</Typography>
            </IconButton>
          </Grid>
        </Grid> */}
        <Box style={cardContainer}>
          {/* <Grid container spacing={2}>
            <Grid item container spacing={2}>
              <Grid item xs={12}>
                <Box sx={{ mt: 2 }}>
                  <Grid container spacing={4} alignItems={'center'}>
                    <Grid item container xs={15} spacing={1}>
                      <Grid item xs={6}>
                        <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                          Data Pod Name
                        </Typography>
                      </Grid>
                      <Grid item />

                      <Grid item xs={5}>
                        <Typography component="span" variant="subtitle1" sx={{ fontSize: 14 }}>
                          Data pod ID
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md={15} />

                      <Grid item container xs={15} spacing={1}>
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            id="outlined-select-dataSource"
                            variant="outlined"
                            value={dataPodInfo?.name}
                            required
                            size="small"
                            disabled
                          />
                        </Grid>
                        <Grid item xs={1} />
                        <Grid item xs={5}>
                          <TextField
                            fullWidth
                            id="outlined-select-dataSource"
                            variant="outlined"
                            value={dataPodInfo?.id}
                            required
                            size="small"
                            disabled
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
              <Grid item xs={12} />
              <Grid item xs={12} />
            </Grid>
          </Grid> */}
          <Grid item container spacing={2}>
            <Grid item container>
              {/* <Grid item xs={1}>
                <IconButton onClick={onClickHome}>
                  <HomeOutlinedIcon />
                </IconButton>
              </Grid> */}
              {/* <Grid item xs={3}>
              <TextField
                placeholder="Search"
                //value={searchText}
                //onChange={({ target }) => handleSearch(target.value)}
                color="primary"
                margin="dense"
                size="small"
                type="search"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchOutlinedIcon />
                    </InputAdornment>
                  ),
                  // // endAdornment: !!searchText ? (
                  // //   <InputAdornment position="end">
                  // //     <IconButton onClick={() => setSearchText('')}>
                  // //       <CancelIcon fontSize="small" />
                  // //     </IconButton>
                  // //   </InputAdornment>
                  // ) : (
                  //   <></>
                  // ),
                }}
              />
            </Grid> */}

              <Grid item xs={6} />
            </Grid>
            <Grid item md={12} />
            {/* <Grid item xs={12} container spacing={1.5}> */}

            <TextFormInput
              columnSize={3}
              multiline={2}
              placeholderText="Prompt Text"
              value={generateReconPromptText}
              changeHandle={setGenerateReconPromptText}
            />

            <Grid item xs={3}>
              <LoadingButton
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => setShowGenerateWarning(true)}
                startIcon={<SyncIcon fontSize="small" />}
                disabled={showGenerateButton && !dataDeleted}
                loading={loading}
              >
                {' '}
                Generate Entity Reconcillation
              </LoadingButton>
            </Grid>
            <Grid item xs={3}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => setShowNewReconPopup(true)}
                startIcon={<AddOutlined fontSize="small" />}
              >
                {' '}
                Add Parent & Child Entities
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                fullWidth
                color="primary"
                variant="contained"
                onClick={() => setShowDeleteWarning(true)}
                startIcon={<Delete fontSize="small" />}
                disabled={!showGenerateButton}
              >
                {' '}
                Delete Reconcilation
              </Button>
            </Grid>
            {!environment.isAdvanceEnergy &&
              <Grid item xs={1}>
                <Box>
                  <IconButton
                    aria-label="more"
                    id="entityReconOptionsBtn"
                    size="small"
                    aria-controls={anchorEl ? 'entityReconOptionsMenu' : undefined}
                    aria-haspopup="menu"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    <SettingsIcon />
                  </IconButton>
                  <Menu
                    id="entityReconOptionsMenu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'right',
                    }}
                  >
                    <MenuItem
                      key={'DataFlowBtn'}
                      onClick={() =>
                        navigate(
                          generatePath(ROUTES.DataModelingEntityDataFlow, {
                            dataPodId: dataPodId,
                          }),
                        )
                      }
                      // selected={option.value === selectedValue}
                    >
                      {/* <ListItemIcon> {option.icon} </ListItemIcon> */}
                      Data Flow
                    </MenuItem>
                  </Menu>
                </Box>
              </Grid>
            }
            {loading && (
              <Grid item xs={12} container>
                <Grid item container spacing={2}>
                  <center>
                    {/* <Spinner radius={50} color={'2E92DA'} stroke={2} visible={true} />{' '} */}
                    {/* <ProgressBar loading={true}></ProgressBar> */}
                    {/* <AnimatedLoader height="50%" width="40%" loading={true} /> */}
                  </center>
                </Grid>
              </Grid>
            )}
            {!loading && showParentChild && (
              <Grid item xs={12} container>
                <Grid container spacing={2}>
                  <ParentChildComponent
                    reconData={reconData}
                    onNodeClicked={handleNodeClick}
                    refetchRecon={handleRefetchRecon}
                  />
                </Grid>
              </Grid>
            )}
            {/* <EntityChild reconData={reconData}  /> */}
            {/* {reconData.map((entity) => (
              <Grid item xs={4} key={entity.dataCoreEntityId}>
                <EntityChild parentName={entity.parent} childName={entity.child}  />
              </Grid>
            ))} */}
            {/* </Grid> */}
          </Grid>
        </Box>
      </ActionPage>
      <DeletePopUp
        showDeleteWarning={showDeleteWarning}
        confirmDeleteMap={confirmEntityRecon}
        closeDeleteWarning={() => setShowDeleteWarning(false)}
        itemToDelete={'Delete Entity Recon Data'}
      />
    </>
  )
}
// type EntityChildProps = {
//   reconData: EntityReconData[]
//   parentName?: string
//   childName?:string
//   entityId?: number
//   entityName?: string
// }
// const EntityChild = ({  reconData }: EntityChildProps) => {
//   const reconDataFiltered = useMemo(() => {
//     if (reconData.length)  {
//       const reconTemp = reconData.filter((rd, ix) => {
//         return reconData.findIndex((cr) => cr.dataCoreEntityId === rd.dataCoreEntityId) === ix
//       })
//       const newData = reconTemp.map((cp) => ({
//         parentId: cp.dataCoreEntityId,
//         parentName: cp.parent,
//         childrenData: reconData.filter((rd) => rd.dataCoreEntityId === cp.dataCoreEntityId).map((cx) => ({ childId: cx.dataSystemEntityId, child: cx.child}))
//       }))
//       return newData
//     }
//   }, [ reconData])

//   return (
//     <Grid item xs={12} container spacing={1.5}>
//     {reconDataFiltered?.map((d) => (
//     <Grid item xs={4} key={d.parentId}>
//       <Card sx={{ maxWidth: 300, borderRadius: 0.5 }}>
//         <CardHeader
//           sx={{ backgroundColor: '#316896' }}
//           title={<Typography sx={{ color: '#FFFFFF', fontSize: 14 }}> {
//           d.parentName}</Typography>}
//         />
//         <CardContent sx={{ backgroundColor: '#F2F2F2' }}>
//           <Grid item container spacing={2}>
//           <Typography sx={{ fontSize: 12 }}>Child Entities</Typography>

//             {d.childrenData.map((child, ix) => (
//               <Grid item container key={ix + child.childId} direction={'row'}>
//                 <Grid item xs={0.5} />
//                 <Grid item xs={6}>
//                   {child.child.length > 15 ? (
//                     <Tooltip title={<Typography variant="body2">{child.child}</Typography>} placement="top">
//                       <Typography sx={{ fontSize: 12 }}>{child.child.slice(0, 15) + '...'}</Typography>
//                     </Tooltip>
//                   ) : (
//                     <Typography sx={{ fontSize: 12 }}>{child.child}</Typography>
//                   )}
//                 </Grid>
//                 <Grid item xs={1} />
//               </Grid>
//             ))}
//           </Grid>
//         </CardContent>
//       </Card>
//     </Grid>))}
//     {/* <Grid item xs={4}>
//       <Card sx={{ maxWidth: 300, borderRadius: 0.5 }}>
//         <CardHeader
//           sx={{ backgroundColor: '#316896' }}
//           title={<Typography sx={{ color: '#FFFFFF', fontSize: 14 }}> {
//             parentName? parentName: ''}</Typography>}
//         />
//         <CardContent sx={{ backgroundColor: '#F2F2F2' }}>
//           <Grid item container spacing={2}>
//           <Typography sx={{ fontSize: 12 }}>Child Entities</Typography>
//           <Typography sx={{ fontSize: 12 }}>{childName? childName: ''}</Typography>
//             {reconDataFiltered?.map((c) => (
//               <Grid item container key={c.dataCoreEntityId} direction={'row'}>
//                 <Grid item xs={0.5} />
//                 <Grid item xs={6}>
//                   {c.child.length > 15 ? (
//                     <Tooltip title={<Typography variant="body2">{c.child}</Typography>} placement="top">
//                       <Typography sx={{ fontSize: 12 }}>Child Entities :{c.child.slice(0, 15) + '...'}</Typography>
//                     </Tooltip>
//                   ) : (
//                     <Typography sx={{ fontSize: 12 }}>{c.child}</Typography>
//                   )}
//                 </Grid>
//                 <Grid item xs={1} />
//               </Grid>
//             ))}
//           </Grid>
//         </CardContent>
//       </Card>
//       </Grid> */}
//       </Grid>
//   )
// }
