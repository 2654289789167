

import { sendRequest } from '../requestManager/sendRequest'

const subscriptionsPath = {
  getEnterprisePlans: 'Subscription/getEnterprisePlans',
  addAccountSubscription: 'Subscription/addAccountSubscription',
  getUserPlans: 'Subscription/getUserPlans',
  addUserSubscription: 'Subscription/addUserSubscription',
}

const getEnterprisePlans = async (token: string) => {
  const endPoint = subscriptionsPath.getEnterprisePlans 
  return sendRequest(endPoint, 'GET', token)
}

const addAccountSubscription = async (token: string, accountId: string, planId: number) => {
  const endPoint = subscriptionsPath.addAccountSubscription + `?accountId=${accountId}&planId=${planId}`
  return sendRequest(endPoint, 'POST', token)
}

const getUserPlans = async (token: string) => {
  const endPoint = subscriptionsPath.getUserPlans 
  return sendRequest(endPoint, 'GET', token)
}

const addUserSubscription = async (token: string, userId: number, planId: number) => {
  const endPoint = subscriptionsPath.addUserSubscription + `?userId=${userId}&planId=${planId}`
  return sendRequest(endPoint, 'POST', token)
}

export const subscriptionServices = {
  getEnterprisePlans,
  addAccountSubscription,
  getUserPlans,
  addUserSubscription,
}

