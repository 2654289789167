import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Grid,
    TextField,
    Typography
} from '@mui/material';
import KeyboardBackspaceOutlinedIcon from '@mui/icons-material/KeyboardBackspaceOutlined';
import React, { useEffect, useMemo, useState } from 'react';
import ActionPage from 'ui-components/ActionPage';
import { generatePath, useNavigate, useParams } from 'react-router-dom';
import { ROUTES } from 'Routes/constants';
import { AppProfileAttribute, Entity, GenAiGenerateMappingDetials, NewAttribute, NewAttributeMapBody, NewEntityMapBody } from 'businessObjects';
import { isValidDescription, isValidEntry } from '../../../utils/constants';
import ShadowTable, { ShadowTableAction } from 'ui-components/ShadowTable/ShadowTable';
import { DataTableRow } from 'ui-components/ExtendedShadowTable';
import { useGetProfileAttibuteOutput } from 'hooks/dataProfilingHooks';
import { useAddEntityMap, useAddNewEntity, useAddNewEntityMap, useDeleteEntityMap, useGetEntity, useGetEntityByName, useGetEntityMapping } from 'hooks/entityHooks';
import LoadingButton from '@mui/lab/LoadingButton';
import { useGenerateAttributeTypes, useGetGenAiNewMappings } from 'hooks/genAIApiHooks';
import { LucidPopUp } from 'ui-components/LucidPopUp/LucidPopUp';
import { useAddNewDataAttribute, useDeleteAttributeMap } from 'hooks/dataAttrbutesHooks';
import { useAddttributeMap } from 'hooks/attributeProfileHooks';

type ViewResultsParams = {
    dataPodId: string;
    dataSystemID: string;
    entityID: string;
    dataTableId: string;
};

type AttributeUserEdited = {
    attributeName: string,
    attributeDescription: string
}
const DataMappingEditNewEntity = () => {
    const navigate = useNavigate();
    const { dataPodId, dataSystemID, entityID, dataTableId } = useParams<ViewResultsParams>();
    const [newEntity, setNewEntity] = useState<Entity>();
    // const [expanded, setExpanded] = useState<string | false>('');
    // const [userSelectedTarget, setUserSelectedTarget] = useState<any>();
    const [userEditedAttribute, setUserEditedAttribute] = useState<AttributeUserEdited>()
    const { profileAttributes, refetch } = useGetProfileAttibuteOutput(
        dataPodId,
        Number(dataSystemID),
        Number(entityID),
        Number(dataTableId),
        undefined,
        {  
            recordsPerPage: 1000,
            pageNumber: 1
          },
    )
    const [attributeMaps, setAttributeMaps] = useState<AppProfileAttribute[]>()
    const [entity] = useGetEntity({}, dataPodId, Number(entityID))
    const [dataTableName, setDataTableName] = useState<string>('');
    const [currentEntityName, setCurrentEntityName] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false)
    const [confirmLoading, setConfirmLoading] = useState<boolean>(false)
    const { fetchNewMappings } = useGetGenAiNewMappings()
    const [genAIGeneratedData, setGenAiGeneratedData] = useState<GenAiGenerateMappingDetials>()
    const [editing, setEditing] = useState<boolean>(false)
    const [refreshTable, setRefreshTable] = useState<boolean>(false)
    const [atleastOneEdit, setAtleastOneEdit] = useState<boolean>(false)
    const [selectedRow, setSelectedRow] = useState<DataTableRow>()
    const [initialRows, setInitialRows] = useState<DataTableRow[]>()
    const { fetchEntityByName } = useGetEntityByName()
    const { saveNewEntity } = useAddNewEntity(dataPodId)
    const [erorMessage, setErrorMessage] = useState<string | undefined>(undefined)
    const { deleteEntityMapId } = useDeleteEntityMap()
    const { deleteAttributeMapId } = useDeleteAttributeMap()
    const { addAttributeMapFunc } = useAddttributeMap(dataPodId)
    const [newAttributeFunc] = useAddNewDataAttribute()
    // const [disableSubmit, setDisableSubmit] = useState<boolean>(true)
    const [addNewEntityMap, newEntityMap, createError] = useAddNewEntityMap()
    const { fetchAttributeTypes } = useGenerateAttributeTypes()
    const [completedWholeSteps, setCompletedWholeSteps] = useState<boolean>(false)
    const [newEntityId, setNewEntityId] = useState<number>()

    useEffect(()=>{
        if(completedWholeSteps){
            setNewEntity(undefined)
            setAtleastOneEdit(false)
            setGenAiGeneratedData(undefined)
            refetch(newEntityId)
        }
    },[completedWholeSteps == true])
    
    useEffect(() => {
        if (profileAttributes?.length) {
            setAttributeMaps(profileAttributes)
            setDataTableName(profileAttributes[0].dataTableName);
            setCurrentEntityName(profileAttributes[0].dataEntityName);
        } else if (entity.length) {
            setCurrentEntityName(entity[0].dataEntityName)
        }
    }, [profileAttributes]);

    const addNewEntityAttributes = async () => {
        if (newEntity && dataPodId) {
            const newEntityBody = newEntity
            newEntityBody.profileName = 'New Mapping Entity'
            newEntityBody.coreEntity = false
            newEntity.dataEntityName = newEntity.dataEntityName.replace(/\s/g, '')
            // console.log({ newEntityBody })
            const entityResp = await saveNewEntity(newEntityBody)
            // console.log({ entityResp })
            if (entityResp && entityResp.id && genAIGeneratedData && initialRows?.length) {
                setNewEntityId(entityResp.id)
                // console.log("Entity is added")
                // console.log(entityResp)
                const entityCreatedId = entityResp.id
                const nEntMap: NewEntityMapBody = {
                    dataEntityId: entityCreatedId,
                    dataTableId: Number(dataTableId),
                    runId: 0,
                    profileName: 'generated',
                }
                // console.log({ nEntMap })
                const resp = await addNewEntityMap(nEntMap, dataPodId)
                // console.log(resp?.flag)
                // console.log(resp?.data)
                if (resp?.flag) {
                    let count = 0
                    genAIGeneratedData.attributes.forEach(async (attribute, index) => {
                        if (index < genAIGeneratedData.columns.length && index < genAIGeneratedData.dataClassification.length) {
                            const sourceColumn = genAIGeneratedData.columns[index]
                            const initialRow = initialRows.find((ir) => ir.values[0] === sourceColumn)
                            if (initialRow) {
                                // console.log({ initialRow })
                                // console.log({ index })
                                const attributeName = initialRow?.values[3].replace(/\s/g, '')
                                const attributeDescription = initialRow?.values[4];
                                const attributeDataClasification = genAIGeneratedData.dataClassification[index]
                                // console.log(`Attribute: ${attributeName}, Description: ${attributeDescription}`);
                                const newAttributeBody: NewAttribute = {
                                    dataEntityId: entityCreatedId,
                                    dataAttributeName: attributeName,
                                    dataAttributeDataType: '',
                                    description: attributeDescription,
                                    isBusinessKey: false,
                                    dataClassification: attributeDataClasification,
                                    dataAttributeType: ''

                                }
                                // console.log({ newAttributeBody })
                                const attributeResponse = await newAttributeFunc(newAttributeBody, dataPodId)
                                if (attributeResponse) {
                                    // console.log("Inside the if of the newly created attributes")
                                    const newAttributeMapBody: NewAttributeMapBody = {
                                        dataTableId: Number(dataTableId),
                                        dataEntityId: entityResp.id,
                                        dataAttributeId: attributeResponse.id,
                                        runid: 0,
                                        dataColumnId: Number(initialRow.id),
                                        dataEntityMapId: resp.data.id,
                                    }
                                    // console.log({ newAttributeBody })
                                    const atrrResp = await addAttributeMapFunc(newAttributeMapBody)
                                    if (atrrResp && atrrResp.id) {
                                        // console.log('Map is generated')
                                        count += 1
                                    }
                                }
                            }
                        } else {
                            // console.log(`Attribute: ${attribute}, Description: Not available`);
                        }
                    });
                    // console.log(genAIGeneratedData.columns.length)
                    // console.log(count)
                    // if (count === genAIGeneratedData.columns.length) {
                    //     console.log("No chance of duplicates")
                    // } else {
                    //     console.log("Chance of duplicates")
                    // }
                    const attributeTypeResp = await fetchAttributeTypes(dataPodId, entityResp.id, Number(dataTableId))
                    if (attributeTypeResp) {
                        // console.log("Types have been generated")
                        setCompletedWholeSteps(true)
                    }
                } else {
                    // console.log("Entity Map was not added")
                }
            }
            return true
        } else {
            // console.log("something failed")
            return false
        }
    }

    const inactiveExistingMapping = async () => {
        // console.log("Inside inactive existing mapping")
        if (attributeMaps?.length) {
            const entityMapFlag = await deleteEntityMapId(attributeMaps[0].dataEntityMapId)
            if(entityMapFlag){
                return true
            }
            // if (attributeMaps?.length && entityMapFlag) {
                // let count = 0
                // attributeMaps?.forEach(async (attr, idx) => {
                //     // console.log(attr.dataAttributeMapID)
                //     // console.log(idx)
                //     const temp = await deleteAttributeMapId(attr.dataAttributeMapID)
                //     if (temp) {
                //         count++
                //     }
                // })
                // console.log(count)
                // console.log(attributeMaps.length)
                // if (count == attributeMaps.length-1) {
                //     console.log("Equal number of maps where deleted")
                //     return true
                // } else {
                //     console.log("Does not match the lengths")
                //     return true
                // }
            // }
        } else {
            // console.log("There is no entity mapping")
            return true
        }
    }

    const onClickBack = (event: any): void => {
        navigate(generatePath(ROUTES.DataMappingResults, { dataPodId: dataPodId, dataSystemID: dataSystemID }));
    };

    const onChangeNewEntity = (name: string, value: string) => {
        setErrorMessage(undefined)
        setNewEntity((prv) => ({ ...prv, [name]: value } as Entity));
    };

    const onChangeNewAttribute = (name: string, value: string) => {
        setAtleastOneEdit(true)
        if(name == 'attributeName'){
           value = value.replace(/\s/g, '')
        }
        setUserEditedAttribute((prv) => ({ ...prv, [name]: value } as AttributeUserEdited));
    };

    const onButtonClick = (rowId: string, actionType: ShadowTableAction) => {
        if (actionType === ShadowTableAction.Editable) {
            // console.log('Edit is clicked');
            const selectedRow = leftRows.find((lr) => lr.id === rowId);
            if (selectedRow) {
                // console.log(selectedRow);
                const attributeName = selectedRow.values[3].replace(/\s/g, '') ?? '';
                const attributeDescription = selectedRow.values[4] ?? '';

                setSelectedRow({
                    ...selectedRow,
                    values: selectedRow.values.map(value => value ?? ''),
                });

                const tempAttr: AttributeUserEdited = {
                    attributeName,
                    attributeDescription,
                };

                setUserEditedAttribute(tempAttr);
                setEditing(true);
            }
        }
    };

    const entityNameDuplicateCheck = async (datapodId: string, entityName: string) => {
        const temp = await fetchEntityByName(datapodId, entityName)
        return temp
    }

    const handleSubmit = async () => {
        setLoading(true)
        if (
            dataPodId &&
            dataSystemID &&
            dataTableId &&
            newEntity?.dataEntityName &&
            newEntity.description
        ) {
            const temp = await entityNameDuplicateCheck(dataPodId, newEntity?.dataEntityName)
            if (temp) {
                setErrorMessage('There exists an entity with this name')
                setLoading(false)
            } else {
                // console.log("There is no entity with this name so continue")
                const resp = await fetchNewMappings(
                    dataPodId,
                    Number(dataSystemID),
                    Number(dataTableId),
                    newEntity?.dataEntityName,
                    newEntity?.description)
                if (resp) {
                    // console.log({ resp })
                    setGenAiGeneratedData(resp)
                    setLoading(false)
                }
            }

        }
    };

    const leftTableColumns = useMemo(() => {

        if (genAIGeneratedData?.columns.length) {
            return [
                { label: 'Column Name' },
                { label: 'Current Attribute Name' },
                { label: 'Current Attribute Description' },
                { label: 'New Attribute Name', isTextField: true },
                { label: 'New Attribute Description', isTextField: true },
                { label: 'EDIT' }
            ]
        } else {
            return [
                { label: 'Column Name' },
                { label: 'Current Attribute Name' },
                { label: 'Current Attribute Description' }
            ];
        }
    }, [genAIGeneratedData])
    const leftRows = useMemo(() => {

        if (profileAttributes && profileAttributes.length && !genAIGeneratedData?.columns.length) {
            const dataTableRows = profileAttributes.map((tl) => ({
                id: `${tl.dataColumnId}`,
                values: [
                    tl.dataColumnName,
                    tl.dataAttributeName,
                    tl.dataAttributeDescription,
                ]
            })) as DataTableRow[];
            setInitialRows(dataTableRows)
            return dataTableRows;
        }

        if (genAIGeneratedData?.columns.length && initialRows?.length && !atleastOneEdit) {
            const oldRowsMap = new Map(initialRows.map(row => [row.values[0], row]));
            const combinedRows = initialRows.map(row => {
                const columnIndex = genAIGeneratedData.columns.indexOf(row.values[0]);
                if (columnIndex !== -1) {
                    return {
                        ...row,
                        values: [
                            row.values[0],
                            row.values[1],
                            row.values[2],
                            genAIGeneratedData.attributes[columnIndex].replace(/\s/g,'') || '',
                            genAIGeneratedData.attribute_description[columnIndex] || '',
                            'EDIT'
                        ]
                    };
                }
                return row;
            });
            setInitialRows(combinedRows);
            return combinedRows;
        }

        if (atleastOneEdit && initialRows?.length && !editing && selectedRow) {
            const updatedRows = initialRows.map(row => {
                if (row.id === selectedRow.id) {
                    const newValues = [...row.values];
                    newValues[3] = userEditedAttribute ? userEditedAttribute.attributeName.replace(/\s/g, '') : '';
                    newValues[4] = userEditedAttribute ? userEditedAttribute.attributeDescription : '';

                    return {
                        ...row,
                        values: newValues
                    };
                }
                return row;
            });
            setInitialRows(updatedRows);
            setSelectedRow(undefined);
            return updatedRows;
        } else if (initialRows) {
            return initialRows;
        }

        return [];
    }, [profileAttributes, genAIGeneratedData, editing == true]);

    const closeEditingPopup = () => {
        // console.log('Click on close popup')
        setEditing(false)
    }

    const saveChanges = () => {
        setEditing(false)
    }
    const pageConfirm = async () => {
        setConfirmLoading(true)
        if (dataPodId && newEntity && newEntity.dataEntityName) {
            const tempFlag = await entityNameDuplicateCheck(dataPodId, newEntity?.dataEntityName)
            // console.log({tempFlag})
            if(!tempFlag){
                const mapInactiveFlag = await inactiveExistingMapping()
                if (mapInactiveFlag) {
                    const entityAttributeFlag = await addNewEntityAttributes()
                    if (entityAttributeFlag) {
                        console.log("Successfully created attributes and their maps ,along with the entity")
                        setConfirmLoading(false)
                    }
                }
            }else{
                setErrorMessage("Already Exists")
                setConfirmLoading(false)
            }
        }
    }

    return (
        <>
            <ActionPage>
                <>
                    <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}
                    >
                        <Grid item xs={12} md={4} sx={{ display: 'flex', justifyContent: 'flex-start' }}>
                            <Typography variant="h3" color="primary" gutterBottom>
                                New Entity Mapping
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={1.5}>
                            <Button
                                variant="outlined"
                                onClick={onClickBack}
                                startIcon={<KeyboardBackspaceOutlinedIcon fontSize="small" />}
                            >
                                Back
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center" spacing={2} sx={{ width: '100%' }}>
                        <Grid item xs={15} md={10} sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
                            <Grid item container flexDirection="column" xs={15} md={7}>
                                <Grid item mb={1.5}>
                                    <Typography color="text.primary">Table Name</Typography>
                                    <TextField
                                        fullWidth
                                        id="current-table-id"
                                        placeholder="Current Table Name"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disabled={true}
                                        value={dataTableName ?? ''}
                                    />
                                </Grid>
                                <Grid mb={1.5}>
                                    <Typography color="text.primary">Current Entity Name</Typography>
                                    <TextField
                                        fullWidth
                                        id="current-entity-id"
                                        placeholder="Current Entity Name"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disabled={true}
                                        value={currentEntityName ?? ''}
                                    />
                                </Grid>
                                <>
                                    <Typography color="text.primary">
                                        Current Entity Description
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="current-entity-description"
                                        placeholder="Current Entity Description"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        disabled={true}
                                        rows={2}
                                        multiline
                                        value={entity[0]?.description ?? ''}
                                    />
                                </>
                            </Grid>
                            <Grid item container flexDirection="column" xs={15} md={10}>
                                <div>
                                    <Typography color="text.primary">
                                        <span style={{ color: 'red' }}>*</span> New Entity Name
                                    </Typography>
                                    {erorMessage &&
                                        <Typography color="error">
                                            Already Name Exists
                                        </Typography>
                                    }
                                    <TextField
                                        fullWidth
                                        id="edit-new-entity-id"
                                        placeholder="New Entity Name"
                                        variant="outlined"
                                        color="secondary"
                                        size="small"
                                        onChange={({ target }) => onChangeNewEntity('dataEntityName', target.value)}
                                        value={newEntity?.dataEntityName ?? ''}
                                        error={!isValidEntry(newEntity?.dataEntityName)}
                                        helperText={!isValidEntry(newEntity?.dataEntityName) ? 'Invalid Entry' : ''}
                                    />
                                </div>
                                <div style={{ marginTop: '10px' }}>
                                    <Typography color="text.primary">
                                        <span style={{ color: 'red' }}>*</span> New Entity Description
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        id="outlined-basic"
                                        placeholder="Enter description"
                                        variant="outlined"
                                        color="secondary"
                                        size="medium"
                                        rows={2}
                                        multiline
                                        onChange={({ target }) => onChangeNewEntity('description', target.value)}
                                        value={newEntity?.description ?? ''}
                                        error={!isValidDescription(newEntity?.description)}
                                        helperText={!isValidDescription(newEntity?.description) ? 'Invalid Entry' : ''}
                                    />
                                </div>
                                <Grid item display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2} mb={2}>
                                    <LoadingButton
                                        variant="outlined"
                                        loading={loading}
                                        onClick={handleSubmit}
                                        disabled={!newEntity?.dataEntityName || !newEntity.description}
                                    >
                                        Generate New Attributes
                                    </LoadingButton>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        justifyContent={'space-between'}
                        mt={2}
                    >
                        <Grid item xs={15}
                            sx={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'space-between'
                            }}
                            spacing={1}
                        >
                            {profileAttributes && profileAttributes.length && (
                                <>
                                    <>
                                        <Grid item container flexDirection="column" xs={15} md={12} width='100%'>
                                            <ShadowTable
                                                rows={leftRows}
                                                columns={leftTableColumns}
                                                tableActionParams={{
                                                    onButtonClick: onButtonClick,
                                                    actions: [
                                                        ShadowTableAction.Editable,
                                                    ],
                                                }}
                                            />
                                            <Grid item display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} mt={2} mb={2}>
                                                <LoadingButton
                                                    loading={confirmLoading}
                                                    variant="outlined"
                                                    onClick={pageConfirm}
                                                    disabled={!genAIGeneratedData?.columns.length || editing}
                                                >
                                                    Confirm
                                                </LoadingButton>
                                            </Grid>
                                        </Grid>
                                    </>
                                </>
                            )}
                        </Grid>
                    </Grid>
                </>
            </ActionPage>
            <LucidPopUp
                openPopUp={editing}
                headingText={<Typography variant="h4">Editing Suggested Mappings</Typography>}
                closePopup={closeEditingPopup}
                onConfirm={saveChanges}
                disabledConfirm={!atleastOneEdit}
                showCloseIcon
                confirmText='Save'
                cancelText='Cancel'
            >
                <Grid container direction="column" justifyContent="center" alignItems="left" spacing={2}>
                    <Grid item xs={10} md={7}>
                        <Typography color="text.primary">
                            <span style={{ color: 'red' }}>*</span> Attribute Name
                        </Typography>
                        <TextField
                            fullWidth
                            id="current-table-id"
                            placeholder="Current Table Name"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            value={userEditedAttribute?.attributeName ?? ''}
                            error={!isValidEntry(userEditedAttribute?.attributeName)}
                            helperText={!isValidEntry(userEditedAttribute?.attributeName) ? 'Invalid Entry' : ''}
                            onChange={({ target }) => onChangeNewAttribute('attributeName', target.value)}
                        />
                    </Grid>
                    <Grid item xs={10} md={7}>
                        <Typography color="text.primary">
                            <span style={{ color: 'red' }}>*</span> Attribute Description
                        </Typography>
                        <TextField
                            fullWidth
                            id="current-entity-id"
                            placeholder="Current Entity Name"
                            variant="outlined"
                            color="secondary"
                            size="small"
                            rows={3}
                            multiline
                            value={userEditedAttribute?.attributeDescription ?? ''}
                            error={!isValidEntry(userEditedAttribute?.attributeDescription)}
                            helperText={!isValidEntry(userEditedAttribute?.attributeDescription) ? 'Invalid Entry' : ''}
                            onChange={({ target }) => onChangeNewAttribute('attributeDescription', target.value)}
                        />
                    </Grid>
                </Grid>
            </LucidPopUp>

        </>
    );
};

export default DataMappingEditNewEntity;
