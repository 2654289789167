import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { GenAIApiStatus, RunIdStatus, RunStatusResponse, ApiStatus, FabricJobStatusResponse } from '../../businessObjects'

interface NotificationState {
  activeRunIds: RunStatusResponse[]
  fabricRunStatus: FabricJobStatusResponse[]
  genAIApiStatus: GenAIApiStatus[]
}

const initialState: NotificationState = {
  activeRunIds: [],
  fabricRunStatus: [],
  genAIApiStatus: [],
}

export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    initiateActiveRunId: (state, action: PayloadAction<RunStatusResponse>) => {
      state.activeRunIds.push({ ...action.payload })
    },
    addActiveRunIds: (state: NotificationState, action: PayloadAction<RunStatusResponse[]>) => {
      state.activeRunIds = state.activeRunIds.map(run => {
        const newEle = action.payload.find(element => element.runId === run.runId);
    
        if (newEle) {
          if (newEle.state === run.state) {
            return run; // No change, return original element
          } else {
            return { ...run, ...newEle }; // Return updated element with new status and other changes
          }
        }
    
        return run; // Return original if no match found in action.payload
      });
    },
    removeActiveRunId: (state, action: PayloadAction<number>) => {
      state.activeRunIds = state.activeRunIds.filter((element) => element.runId !== action.payload)
    },
    filterActiveRunIds: (state: NotificationState) => {
      //Remove "FAILED" OR "SUCCESS"
      //So these won't appear again in the notifications
      state.activeRunIds = state.activeRunIds.filter(
        (element) => !(element.state.resultState === 'FAILED' || element.state.resultState === 'SUCCESS'),
      )
    },
    initiateFabricRunStatus: (state, action: PayloadAction<FabricJobStatusResponse>) => {
      state.fabricRunStatus.push({ ...action.payload })
    },
    addFabricRunStatus: (state: NotificationState, action: PayloadAction<FabricJobStatusResponse[]>) => {
      state.fabricRunStatus = state.fabricRunStatus.map(run => {
        const newEle = action.payload.find(element => element.id === run.id);
    
        if (newEle) {
          if (newEle.status === run.status) {
            return run; // No change, return original element
          } else {
            return { ...run, ...newEle }; // Return updated element with new status and other changes
          }
        }
    
        return run; // Return original if no match found in action.payload
      });
    },
    removeFabricRunStatus: (state, action: PayloadAction<string>) => {
      state.fabricRunStatus = state.fabricRunStatus.filter((element) => element.id !== action.payload)
    },
    filterFabricRunStatus: (state: NotificationState) => {
      //Filter "InProgress" OR "NotStarted"
      //So other status won't appear again in the notifications
      state.fabricRunStatus = state.fabricRunStatus.filter(
        (element) => (element.status === 'InProgress' || element.status === 'NotStarted'),
      )
    },
    addGenAIApiStatus: (state, action: PayloadAction<GenAIApiStatus>) => {
      const filteredGenAIApiStatus = state.genAIApiStatus.filter(
        (element) =>
          !(
            element.status === ApiStatus.Success ||
            element.status === ApiStatus.Failed ||
            element.GenAIApiStatusId === action.payload.GenAIApiStatusId
          ),
      )

      state.genAIApiStatus = [action.payload, ...filteredGenAIApiStatus]
    },
    removeGenAIApiStatus: (state, action: PayloadAction<number>) => {
      state.genAIApiStatus = state.genAIApiStatus.filter((element) => element.GenAIApiStatusId !== action.payload)
    },
  },
})

// Action creators are generated for each case reducer function
export const {
  initiateActiveRunId,
  addActiveRunIds,
  filterActiveRunIds,
  removeActiveRunId,
  initiateFabricRunStatus,
  addFabricRunStatus,
  filterFabricRunStatus,
  removeFabricRunStatus,
  addGenAIApiStatus,
  removeGenAIApiStatus,
} = notificationSlice.actions

export default notificationSlice.reducer
