import { fabricServices } from "apiServices/fabric"
import { FabricJobStatusRequest, FabricJobStatusResponse, FabricNotebookCreateRequest, FabricRunNewJobRequest } from "businessObjects"
import { useLucidAuthContext } from "LucidAuthContext/LucidAuthContext"


export const usePostFabricJobRunNew = (): {fabricPostRunNewJob: (params: FabricRunNewJobRequest) => Promise<FabricJobStatusResponse | undefined>} => {
  const { getBearerToken, getFabricBearerToken, userAccount } = useLucidAuthContext()

  const fabricPostRunNewJob = async (params: FabricRunNewJobRequest): Promise<FabricJobStatusResponse | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      const fabricToken = await getFabricBearerToken()
      if (bearerToken && fabricToken && userAccount) {
        params.token = fabricToken
        const { data, status } = await fabricServices.postRunNewJob(bearerToken, params)
        if (status == 200) {
          return data;
        }
      }
    } catch (error) {
      console.log("Error in getting Job Status", error);
    }
  }
  return {fabricPostRunNewJob}
}

export const usePostFabricCreateAndRun = (): {fabricPostCreateAndRun: (params: FabricNotebookCreateRequest) => Promise<FabricJobStatusResponse | undefined>} => {
  const { getBearerToken, getFabricBearerToken, userAccount } = useLucidAuthContext()

  const fabricPostCreateAndRun = async (params: FabricNotebookCreateRequest): Promise<FabricJobStatusResponse | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      const fabricToken = await getFabricBearerToken()
      if (bearerToken && fabricToken && userAccount) {
        params.displayName = params.displayName + "_" + userAccount.email
        params.accessToken = fabricToken
        const { data, status } = await fabricServices.postCreateAndRun(bearerToken, params)
        if (status == 200) {
          return data;
        }
      }
    } catch (error) {
      console.log("Error in getting Job Status", error);
    }
  }
  return {fabricPostCreateAndRun}
}


export const useGetFabricJobStatus = (): {fetchFabricJobStatus: (jobStatusPayload: FabricJobStatusRequest[]) => Promise<FabricJobStatusResponse[] | undefined>} => {
  const { getBearerToken, getFabricBearerToken } = useLucidAuthContext()

  const fetchFabricJobStatus = async (jobStatusPayload: FabricJobStatusRequest[]): Promise<FabricJobStatusResponse[] | undefined> => {
    try {
      const bearerToken = await getBearerToken()
      const fabricToken = await getFabricBearerToken()
      if (bearerToken && fabricToken) {
          
        jobStatusPayload.forEach(job => job.token = fabricToken)

        const { data, status } = await fabricServices.getJobStatus(bearerToken, jobStatusPayload)
        if (status == 200) {
          return data;
        }
      }
    } catch (error) {
        console.log("Error in getting Job Status", error);
    }
  }
  return {fetchFabricJobStatus}
}