import { useDispatch } from "react-redux";
import { usePostFabricCreateAndRun, usePostFabricJobRunNew } from "./fabricHooks";
import { useGetConfigurationValue } from "./lucidConfigurationHooks";
import { usePostCreateDatabricksJob, usePostDatabricksJobRunNew } from "./databricksHooks";

import { CreateJobParams, dataBricksTriggerTypes, FabricNotebookCreateRequest, FabricRunNewJobRequest, JobDetails, RunNewJobParams, RunStatusResponse, ServiceConnections } from "businessObjects";
import { useGetServiceConnection } from "./dataEngineeringHooks";
import { filterActiveRunIds, filterFabricRunStatus, initiateActiveRunId, initiateFabricRunStatus } from "features/notification/notificationSlice";

const getNewRunIdObject = (result: number, payload: RunNewJobParams, jobParams: {name: string, route: string}, triggerType: dataBricksTriggerTypes) => {
  const newRunId: RunStatusResponse = {
    runId: result,
    state: {
      lifeCycleState: 'INITIATED',
      resultState: 'LOADING',
    },
    params: {
      runId: result,
      url: payload.url,
      token: payload.token,
    },
    jobDetails: {
      triggerType,
      parameters: {
        id: result,
        ...jobParams,
      },
    },
  }

  return newRunId
}

const getExecutionEnvironment = async (
  getConfigurationValue: (configKey?: string) => Promise<string>,
): Promise<string> => {
  const executionEnv = await getConfigurationValue('JobExecutionEnvironment')
  if (!executionEnv) {
    throw new Error("The 'JobExecutionEnvironment' configuration does not exist.")
  }
  return executionEnv
}

const getServiceConnection = async (
  dataPodId: string,
  executionEnv: string,
  getConnections: (
    datapodId: string,
    id?: number,
    serviceConnectionTypeID?: string,
    serviceTypeID?: number,
  ) => Promise<ServiceConnections[] | undefined>,
) => {
  const serviceConnection = await getConnections(dataPodId, undefined, 'Spark')
  if (!serviceConnection) {
    throw new Error('No service connections available.')
  }

  const connection = serviceConnection.find((conn) => conn.serviceTypeName === executionEnv)
  if (!connection) {
    throw new Error('Service connection does not exist for the given execution environment.')
  }

  return connection
}

const parseServiceConnectionTemplate = (jsonTemplate: string) => {
  try {
    return JSON.parse(jsonTemplate);
  } catch {
    throw new Error("Failed to parse the service connection template. Please check the service connection details.");
  }
};

export const useRunNewJob = () => {
  const { fabricPostRunNewJob } = usePostFabricJobRunNew();
  const { postRunNewJob } = usePostDatabricksJobRunNew();
  const dispatch = useDispatch();

  const handleAzureDatabricksJob = async (
    payload: RunNewJobParams,
    activeRunIds: any[],
    jobParams: {name: string, route: string},
    triggerType: dataBricksTriggerTypes
  ): Promise<number | undefined> => {
    const result = await postRunNewJob(payload);
    if (result) {
      const activeRunId = activeRunIds.find(element => element.runId === result);
      if (!activeRunId) {
        const newRunId = getNewRunIdObject(result, payload, jobParams, triggerType);
        // dispatch(filterActiveRunIds());
        dispatch(initiateActiveRunId(newRunId));
        return result;
      }
    }
  };

  const handleMicrosoftFabricSparkJob = async (payload: FabricRunNewJobRequest, jobParams: {name: string, route: string}, triggerType: dataBricksTriggerTypes) => {
    const result = await fabricPostRunNewJob(payload);

    if(result) {
      result.workspaceId = payload.workspaceId;
      result.jobDetails = {
        triggerType,
        parameters: {
          id: result.id,
          ...jobParams,
        },
      };

      // dispatch(filterFabricRunStatus());
      dispatch(initiateFabricRunStatus(result));
      return result.id;
    }
    // Additional logic can be added here in the future.
  };

  const runNewJob = async (
    activeRunIds: any[], 
    jobParams: {name: string, route: string}, 
    triggerType: dataBricksTriggerTypes, 
    payload: RunNewJobParams | FabricRunNewJobRequest
  ) => {
    try {
      if ("jobId" in payload) {
        return await handleAzureDatabricksJob(payload as RunNewJobParams, activeRunIds, jobParams, triggerType);
      } else if ("workspaceId" in payload) {
        return await handleMicrosoftFabricSparkJob(payload as FabricRunNewJobRequest, jobParams, triggerType);
      } else {
        throw new Error("Unsupported execution environment.");
      }
    } catch (error: any) {
      console.error("Error in runNewJob:", error.message);
      throw new Error("Internal server error occurred while running the job.");
    }
  };

  return { runNewJob };
};

export const useGetRunNewJobPayload = () => {
  const { getConnections } = useGetServiceConnection();
  const { getConfigurationValue } = useGetConfigurationValue();

  const buildPayloadForAzureDatabricks = (requestBody: Record<string, any>, parsedTemplate: any, jobKey: string): RunNewJobParams => {
    return {
      notebookParams: { ...requestBody },
      url: parsedTemplate.workspace_Url,
      jobId: parsedTemplate[jobKey],
      token: parsedTemplate.PAT_Token,
    };
  };

  const buildPayloadForMicrosoftFabricSpark = (requestBody: Record<string, any>, parsedTemplate: any, jobKey: string): FabricRunNewJobRequest => {
    return {
      executionData: { ...requestBody },
      workspaceId: parsedTemplate.WorkspaceId,
      itemId: parsedTemplate[jobKey], 
      jobType: 'Pipeline',
      token: '',
      environmentId: parsedTemplate.environmentId,
    };
  };

  const getRunNewJobPayload = async (dataPodId: string, requestBody: Record<string, any>, jobKey: string) => {
    const executionEnv = await getExecutionEnvironment(getConfigurationValue);

    if (dataPodId) {
      const connection = await getServiceConnection(dataPodId, executionEnv, getConnections);
      const parsedJsonTemplate = parseServiceConnectionTemplate(connection.serviceConnectionJsonTemplate);

      if (executionEnv === "Azure Databricks") {
        return buildPayloadForAzureDatabricks(requestBody, parsedJsonTemplate, jobKey);
      } else if (executionEnv === "Microsoft Fabric Spark") {
        return buildPayloadForMicrosoftFabricSpark(requestBody, parsedJsonTemplate, jobKey);
      } else {
        throw new Error("Unsupported execution environment. Please recheck the configuration.");
      }
    }
  };

  return { getRunNewJobPayload };
};


export const useGetCreateJobPayload = () => {
  const { getConnections } = useGetServiceConnection();
  const { getConfigurationValue } = useGetConfigurationValue();

  const buildPayloadForAzureDatabricks = ( parsedTemplate: any, databricksParams: CreateJobParams): CreateJobParams => {
    return {
      ...databricksParams,
      jobClusterConfig: {
        url: parsedTemplate.workspace_Url,
        token: parsedTemplate.PAT_Token,
        clusterKey: parsedTemplate.cluster_Id,
        sparkVersion: parsedTemplate.sparkVersion,
        nodeTypeId: parsedTemplate.nodeTypeId,
      }
    };
  };

  const buildPayloadForMicrosoftFabricSpark = ( parsedTemplate: any, fabricParams: FabricNotebookCreateRequest): FabricNotebookCreateRequest => {
    return {
      ...fabricParams,
      workspaceId: parsedTemplate.WorkspaceId,
      environmentId: parsedTemplate.environmentId,
    };
  };

  const getCreateJobPayload = async (dataPodId: string, databricksParams: CreateJobParams, fabricParams: FabricNotebookCreateRequest) => {
    const executionEnv = await getExecutionEnvironment(getConfigurationValue);

    if (dataPodId) {
      const connection = await getServiceConnection(dataPodId, executionEnv, getConnections);
      const parsedJsonTemplate = parseServiceConnectionTemplate(connection.serviceConnectionJsonTemplate);

      if (executionEnv === "Azure Databricks") {
        return buildPayloadForAzureDatabricks(parsedJsonTemplate, databricksParams);
      } else if (executionEnv === "Microsoft Fabric Spark") {
        return buildPayloadForMicrosoftFabricSpark(parsedJsonTemplate, fabricParams);
      } else {
        throw new Error("Unsupported execution environment. Please recheck the configuration.");
      }
    }
  };

  return { getCreateJobPayload };
}

export const useCreateAndRunNewJob = () => {
  const { fabricPostCreateAndRun } = usePostFabricCreateAndRun();
  const { postCreateDatabricksJob } = usePostCreateDatabricksJob()
  const dispatch = useDispatch();

  const handleAzureDatabricksJob = async (
    payload: CreateJobParams,
    jobParams: JobDetails
  ): Promise<number | undefined> => {
    const result = await postCreateDatabricksJob(payload);
    if (result) {
      const newRunId: RunStatusResponse = {
        runId: result.runId,
        state: {
          lifeCycleState: 'INITIATED',
          resultState: 'LOADING',
        },
        params: {
          runId: result.runId,
          url: result.url,
          token: result.token,
        },
        jobDetails: {...jobParams},
      }

      // dispatch(filterActiveRunIds());
      dispatch(initiateActiveRunId(newRunId));
      return result.runId;
    
    }
  };

  const handleMicrosoftFabricSparkJob = async (payload: FabricNotebookCreateRequest, jobParams: JobDetails) => {
    const result = await fabricPostCreateAndRun(payload);
    console.log(result)

    if(result) {
      result.workspaceId = payload.workspaceId;
      result.jobDetails = {...jobParams};

      // dispatch(filterFabricRunStatus());
      dispatch(initiateFabricRunStatus(result));
      return result.id;
    }
    // Additional logic can be added here in the future.
  };

  const createAndRunNewJob = async (
    jobParams: JobDetails, 
    payload: CreateJobParams | FabricNotebookCreateRequest
  ) => {
    try {
      if ("jobClusterConfig" in payload) {
        return await handleAzureDatabricksJob(payload as CreateJobParams, jobParams);
      } else if ("workspaceId" in payload) {
        return await handleMicrosoftFabricSparkJob(payload as FabricNotebookCreateRequest, jobParams);
      } else {
        throw new Error("Unsupported execution environment.");
      }
    } catch (error: any) {
      console.error("Error in runNewJob:", error.message);
      throw new Error("Internal server error occurred while running the job.");
    }
  };

  return { createAndRunNewJob };
}